import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from '../Layouts/LayoutWithNav';
import LayoutWithOutNav from '../Layouts/LayoutWithOutNav';
import {Loading} from '../Loaders';

export default ({ component: Component, props: Props, showNav = true, ...rest }) => {
  return (
    <Route
    {...rest}
     render={(props) => {
         if(Props.isAuthenticated){
           if(!showNav){
             return (
               <LayoutWithOutNav hideFooter={true}>
                  {Props.loadingUser ?
                    <Loading />
                    :<Component {...props} {...Props} />
                  }
               </LayoutWithOutNav>
             )
           }else{
             return (
               <Layout>
                  {Props.loadingUser ?
                    <Loading />
                    :<Component {...props} {...Props} />
                  }
               </Layout>
             )
           }

         }else{
           return (
             <Redirect
               to={{
                 pathname: "/login",
                 state: { from: props.location }
               }}
             />
           )
         }
       }
     }
    />
  )

};
