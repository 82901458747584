import React, { Component } from "react";
import { Button, Glyphicon, DropdownButton, MenuItem } from 'react-bootstrap';

export default class LiteElementHeader extends Component {

  render(){
    const mode = this.props.mode;
    if(mode === 'create' || mode === 'edit'){
      return (
        <div>
          <div className='action-bar'>
            <div {...this.props.dragHandleProps}  className='header-drag-handle'>

            </div>
            <DropdownButton id='action' title="">
              <MenuItem onClick={this.props.edit}>Configure</MenuItem>
              <MenuItem onClick={this.props.remove}>Delete</MenuItem>
            </DropdownButton>
          </div>
          <div className='lite-titlebar'>
            <h3>{this.props.title}</h3>
          </div>
        </div>

      )
    }else if(mode === 'public'){
      return (
        <div className='lite-titlebar'>
          <div>
            <h3>{this.props.title}</h3>
          </div>
        </div>
      )
    }

  }

}
