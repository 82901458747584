import React, { Component } from 'react';
import { connect } from "react-redux";

import { Modal, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import UIModals from './';
import LiteUIMethods from "../../methods";
import NotificationMethods from "../../../../components/Notifications/methods";

const ConfigurePageModal = ({
  show,
  name,
  id,
  title,
  handleClose,
  handleSave,
  handleNameChange,
  handleDelete
}) => {

  return (
    <UIModals.ModalContainer
      title={title}
      handleClose={handleClose}
      handleSave={handleSave}
      show={show}
      >
      <FormGroup controlId={'title'}>
        <ControlLabel>Name:</ControlLabel>
        <FormControl
          type='text'
          onChange={handleNameChange}
          defaultValue={name}
          />
      </FormGroup>
      <div>Delete Page?</div>
      <Button
        onClick={handleDelete}
      >
        Delete
      </Button>
    </UIModals.ModalContainer>
  );
}

class ConfigurePage extends Component {

  Handlers = {
    Add: () => {
      this.props.addView({});
    },
    Delete: (page) => {
      this.props.deletePage({ view: page });
      this.props.updateShowEditPageModal({ show: false });
      this.props.updateSelectedPage({ value: 0 });
    },
    ShowEditModal: (page) => {
      this.props.updateShowEditPageModal({ show: true, page });
    },
    CloseEdit: () => {
      this.props.updateShowEditPageModal({ show: false });
    },
    SaveEdits: (event) => {
      this.props.savePageEdits({});
      this.props.updateShowEditPageModal({ show: false });
    },
    NameChange: (event) => {
      this.props.updateEditPageName({ value: event.target.value });
    },
    Select: (event) => {
      const viewId = event.target.dataset['id'];
      this.props.updateSelectedView({ value: viewId });
    }
  }

  render(){
    if(this.props.liteui.ux.showEditPageModal.show){
      return (
        <ConfigurePageModal
          show={this.props.liteui.ux.showEditPageModal.show}
          name={this.props.liteui.ux.showEditPageModal.name}
          id={this.props.liteui.ux.showEditPageModal.pageId}
          title='Edit Page'
          handleClose={this.Handlers.CloseEdit}
          handleSave={this.Handlers.SaveEdits}
          handleNameChange={this.Handlers.NameChange}
          handleDelete={this.Handlers.Delete}
        />
      )
    }else{
      return null;
    }

  }
}

const mapStateToProps = state => {
  return {
    liteui: state.liteui,
    notifications: state.notifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...LiteUIMethods({dispatch}),
    ...NotificationMethods({dispatch})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurePage);
