import React from 'react';
import { Button } from 'react-bootstrap';
import { Glyphicon } from "react-bootstrap";

import { ConvertWeiToEth, ConvertEthToWei } from '../../helpers/Eth';

const ViewDetails = ({
  loading,
  instance,
  actionButton = false
}) => {
  if(loading){
    return (
      <div className="view-details-container">
        <div className="loading-container">
          <Glyphicon glyph="refresh" className="spinning" /> Loading
        </div>
      </div>
    )
  }else{
    return (
      <div className="view-details-container">
        <div>
          <div className="view-description">
            {instance.description ? instance.description : null}
          </div>
          <div className="view-details">
            <div>
              Status:{" "}
              {instance.status
                ? instance.status
                : null}
            </div>
            <div>
              ID:{" "}
              {instance.id
                ? instance.id
                : null}
            </div>
            <div>
              Goerli Address:{" "}
              {instance.goerli
                ? <a target="_blank" href={`https://goerli.etherscan.io/address/${instance.goerli}`}>{instance.goerli}</a>
                : null}
            </div>
          </div>
        </div>

        <div>
          { actionButton ?
            <Button
              disabled={instance.status !== 'Live' ? true : false }
              className='secondary-button'
              onClick={actionButton.handleClick}
            >
              {actionButton.text}
            </Button>

             : null}

        </div>
      </div>
    )
  }
}


const WalletDetails = ({
  loading,
  instance,
  liteui,
  actionButton = false
}) => {
  if(loading){
    return (
      <div className="view-details-container">
        <div className="loading-container">
          <Glyphicon glyph="refresh" className="spinning" /> Loading
        </div>
      </div>
    )
  }else{
    return (
      <div className="view-details-container">
        <div>
          <div className="view-description">
            {instance.description ? instance.description : null}
          </div>
          <div className="view-details">
            <div>
              <b>Status:{" "}</b>
              {instance.status || null}
            </div>
            <div>
              <b>ID:{" "}</b>
              {instance.id || null}
            </div>
            <div>
              <b>Type:{" "}</b>
              {instance.type || null}
            </div>
            <div>
              <b>Action Type:{" "}</b>
              {instance.actionType == 'liteui' ? <a href={`/liteui/edit/${liteui.id}`} target="_blank">{liteui.name}</a> : instance.actionType}
            </div>
            <div>
              <b>Number of Wallets:{" "}</b>
              {instance.numberOfWallets || null}
            </div>
            <div>
              <b>Inital ETH Per Wallet:{" "}</b>
              {instance.amountPerWallet || null}
              {" ETH"}
            </div>
            <div>
              <b>Inital Total Balance:{" "}</b>
              {instance.initBalance || null}
              {" ETH"}
            </div>
            <div>
              <b>Current Total Balance:{" "}</b>
              {ConvertWeiToEth(instance.balance) || 'loading'}
              {" ETH"}
            </div>
          </div>
        </div>

        <div>
          { actionButton ?
            <Button
              className='secondary-button'
              onClick={actionButton.handleClick}
            >
              {actionButton.text}
            </Button>

             : null}

        </div>
      </div>
    )
  }
}
export default ViewDetails;
export { WalletDetails };
