import React from 'react';

const KeyFeatures = ({
  keyFeatures,
  customClass
}) => {
  const styles = `key-features-container ${customClass}`;
  return (
    <div className={styles}>
      {keyFeatures.map((feature, i)=>{
        return <div>
          <img src={require('./dots.svg')}/>
          <div key={i} className='key-feature'>
            {feature}
          </div>
        </div>
      })}
    </div>
  )
}

export default KeyFeatures;
