import Actions from '../actions/dashboard';
class DefaultState {
  constructor(){
    this.loadingRecordCounts = true;
    this.loadingTables = true;
    this.tables = [];
    this.filter = {
      showArchived: false
    };
    this.stats = {
      total_records: 0,
      total_tables: 0,
      tables_live: 0,
      tables_inprog: 0
    };
    this.temps = {
      tables: [],
      total_records: 0,
      total_tables: 0,
      tables_live: 0,
      tables_inprog: 0
    };
  }
}

export default (state = new DefaultState(), action) => {
  switch (action.type) {
    case Actions.TOGGLE_FILTER :{
      let newState = {...state};
      newState.filter[action.data.value] = !newState.filter[action.data.value];
      return newState;
    }
    case Actions.UPDATE_DTABLES:
      var newState = {...state};

      if(newState.temps.tables.length){
        //loop through tables and if they are in the incoming set of table set the record count to the old count
        let newTables = action.data.value.map((table)=>{
          const tempTable = newState.temps.tables.find( t => t.id === table.id);
          let count = 0;
          if(tempTable){
            count = tempTable.record_count;
          }
          return {...table, record_count: count};
        })
        newState.temps.tables = newTables;
      }else{
        newState.temps.tables = action.data.value;
      }

      newState.temps.total_tables = newState.temps.tables.length;
      newState.temps.tables_live = newState.temps.tables.filter( t => t.status === 'Live' ).length;
      newState.temps.tables_inprog = newState.temps.tables.filter( t => t.status !== 'Live' ).length;
      return newState;
    case Actions.UPDATE_RECORD_COUNT:
      var newState = {...state};
      var table = newState.temps.tables.find( t => t.id === action.data.tableId);
      newState.temps.tables.find( t => t.id === action.data.tableId).record_count = action.data.recordCount;
      newState.temps.total_records = newState.temps.tables.filter( t => t.status === 'Live' ).reduce((accumulator, currentValue) => {
          return accumulator + parseInt(currentValue.record_count);
      },0);
      return newState;
    case Actions.UPDATE_LOADING_RECORDCOUNTS:
      var newState = {...state};
      newState.loadingRecordCounts = action.data.loading;
      if(!action.data.loading){
        newState.stats.total_records = newState.temps.total_records;
      }
      return newState;
    case Actions.UPDATE_LOADING_TABLES:
      var newState = {...state};
      newState.loadingTables = action.data.value;
      if(!action.data.value){
        newState.stats.total_tables = newState.temps.total_tables;
        newState.stats.tables_live = newState.temps.tables_live;
        newState.stats.tables_inprog = newState.temps.tables_inprog;
        newState.tables = [...newState.temps.tables];
      }
      return newState;
    case Actions.CLEAR_STATE:
      return new DefaultState();
    default:
      return state;
  }
};
