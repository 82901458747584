import React from 'react';

export default ({

}) => {
  return (
    <div className='liteui-public-banner-container'>
      <div className='banner-content container'>
        <a target='_blank' href='https://console.atra.io/'>
          Built with Atra, powered by Ethereum. Learn more.
        </a>
      </div>
    </div>
  )
}
