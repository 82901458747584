import Actions from "../actions/account-actions";
class DefaultState {
  constructor() {
    this.user = {
      email: "",
      username: "",
      address: "",
      created: 0,
      subscription: null,
      timezone: {
        offset: 0,
        code: "Europe/London",
      },
    };
    this.timezone = {
      offset: 0,
      code: "Europe/London",
      validationMessage: "",
    };
    this.api = {
      key: "",
      url: "",
    };
    this.loading = {
      apiKey: true,
      user: true,
    };
    this.isSubmitting = {
      apiKey: false,
      walletAddress: false,
      timezone: false,
    };
    this.newAddress = {
      address: "",
      submitting: false,
      show: false,
      validationMessage: "",
    };
    this.ux = {
      activeTab: "info",
      showUpgradeModal: false,
      showUsageAlertModal: false,
    };
  }
}

export default (state = new DefaultState(), action) => {
  switch (action.type) {
    //tabs
    case Actions.UPDATE_ACTIVE_TAB:
      return { ...state, ux: { ...state.ux, activeTab: action.data.value } };

    //Loading
    case Actions.UPDATE_LOADING_USER:
      return {
        ...state,
        loading: { ...state.loading, user: action.data.value },
      };
    case Actions.UPDATE_LOADING_KEYS:
      return {
        ...state,
        loading: { ...state.loading, apiKey: action.data.value },
      };

    //Submitting
    case Actions.UPDATE_IS_SUBMITTING_APIKEY:
      return {
        ...state,
        isSubmitting: { ...state.isSubmitting, apiKey: action.data.value },
      };
    case Actions.UPDATE_IS_SUBMITTING_WALLET_ADDRESS:
      return {
        ...state,
        isSubmitting: {
          ...state.isSubmitting,
          walletAddress: action.data.value,
        },
      };

    //User Account
    case Actions.UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.data.value } };
    case Actions.UPDATE_USERNAME:
      return { ...state, user: { ...state.user, username: action.data.value } };

    case Actions.UPDATE_USER_TIMEZONE: {
      let newState = { ...state };
      newState.timezone = { ...action.data };
      return newState;
    }
    case Actions.UPDATE_SAVING_USER_TIMEZONE: {
      return {
        ...state,
        isSubmitting: { ...state.isSubmitting, timezone: action.data.value },
      };
    }

    //Subscription
    case Actions.UPDATE_USER_SUBSCRIPTION:
      return {
        ...state,
        subscription: { ...state.subscription, ...action.data.value },
      };

    case Actions.UPDATE_SHOW_UPGRADE_MODAL:
      return {
        ...state,
        ux: { ...state.ux, showUpgradeModal: action.data.value },
      };

    //Usage
    case Actions.UPDATE_SHOW_USAGE_ALERT_MODAL:
      return {
        ...state,
        ux: { ...state.ux, showUsageAlertModal: action.data.value },
      };

    // API Key
    case Actions.UPDATE_API_KEY:
      return { ...state, api: { ...state.api, key: action.data.value } };

    //Wallet Address
    case Actions.UPDATE_SHOW_CHANGE_ADDRESS:
      return {
        ...state,
        newAddress: { ...state.newAddress, show: action.data.value },
      };
    case Actions.UPDATE_NEW_ADDRESS:
      return {
        ...state,
        newAddress: { ...state.newAddress, address: action.data.value },
      };
    case Actions.UPDATE_NEW_ADDRESS_FORM_VALIDATION_MESSAGE:
      return {
        ...state,
        newAddress: {
          ...state.newAddress,
          validationMessage: action.data.value,
        },
      };
    default:
      return state;
  }
};
