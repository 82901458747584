import { API } from "aws-amplify";
import { FormatDate, FormatDateTime } from "../helpers/Date";

const GetWallets = (timezone, environmentId = null) => {
  return new Promise((resolve, reject) => {
    try {
      const data = {
        response: true,
        queryStringParameters: {},
      };
      let code = null;
      if (timezone && timezone.code) {
        code = timezone.code;
      }
      if (environmentId) {
        data.queryStringParameters.environmentId = environmentId;
      }
      API.get("litewallet", "", data)
        .then((res) => {
          const wallets = res.data.map((wallets) => {
            return {
              ...wallets,
              date: FormatDateTime(wallets.created, code),
              link: `/awallet/view/${wallets.id}`,
              archived: wallets.archived || false,
              status: wallets.wallets.find((w) => w.status != "Funded")
                ? "Pending"
                : "Funded",
            };
          });
          resolve(wallets);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

const GetLiteWallet = (id, timezone) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        queryStringParameters: {
          id,
        },
      };
      let code = null;
      if (timezone && timezone.code) {
        code = timezone.code;
      }
      API.get("litewallet", "", data)
        .then((res) => {
          const wallet = res.data;
          const liteWallet = {
            ...wallet,
            date: FormatDateTime(wallet.created, code),
            shortDate: FormatDate(wallet.created, code),
            archived: wallet.archived || false,
            status: wallet.wallets.find((w) => w.status != "Funded")
              ? "Pending"
              : "Funded",
          };
          resolve(liteWallet);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

const CreateWallets = (options) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: options,
      };

      API.post("litewallet", "", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const UpdateWalletUser = (body) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body,
      };
      API.post("litewallet", "user/", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

//Public
const GetLiteWalletPublic = (walletId, activationCode, timezone) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        queryStringParameters: {
          walletId,
          activationCode,
        },
      };
      let code = null;
      if (timezone && timezone.code) {
        code = timezone.code;
      }
      API.get("litewallet/public", "", data)
        .then((res) => {
          const wallet = res.data;
          resolve(wallet);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

const LiteWalletPublicActivate = (postData) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: postData,
      };

      API.post("litewallet/public/activate", "", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

export default {
  CreateWallets,
  GetWallets,
  GetLiteWallet,
  UpdateWalletUser,
  GetLiteWalletPublic,
  LiteWalletPublicActivate,
};
