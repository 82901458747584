import { API } from "aws-amplify";
import FeatureFlags from "../components/FeatureFlags";

const GetPlans = () => {
  return new Promise((resolve, reject) => {
    try {
      const { StripeProd } = FeatureFlags;
      let data = {
        response: true,
        queryStringParameters: {
          isProd: StripeProd,
        },
      };

      API.get("subscription/plans", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export default { GetPlans };
