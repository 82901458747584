import React, { useState } from "react";
import { ModalContainer } from "../Modals";
import PaymentMethod from "./PaymentMethod";

const BillingModal = ({
  show,
  handleCancel,
  handleSubmit,
  children,
  isLoading = false,
}) => {
  const [saving, setSaving] = useState(false);
  const [getPaymentMethod, setGetPaymentMethod] = useState(false);

  const handleGetPaymentMethod = () => {
    setGetPaymentMethod(true);
  };

  const handleSave = ({ paymentMethod, error }) => {
    setGetPaymentMethod(false);
    if (error) {
      console.log(error);
      return;
    }
    handleSubmit({ paymentMethod });
  };
  return (
    <ModalContainer
      show={show}
      title={"Enter Card Number"}
      handleClose={handleCancel}
      handleSave={handleGetPaymentMethod}
      saveButtonText={"Upgrade"}
      isLoading={isLoading}
      className={""}
      loadingText="Processing"
    >
      <PaymentMethod
        invokePaymenthMethodHook={getPaymentMethod}
        createPaymentMethodHook={handleSave}
      />
    </ModalContainer>
  );
};

export default BillingModal;
