import Actions from '../actions/liteui';
const Methods = ({ dispatch }) => {
    return {
      updateTimezone: data => dispatch({ type: Actions.UPDATE_TIMEZONE, data }),

      //Errors
      updateError: data => dispatch({ type: Actions.UPDATE_ERROR, data }),

      //Success
      updateSuccess: data => dispatch({ type: Actions.UPDATE_SUCCESS, data }),

      //Warnings
      updateShowReadOnlyWarning: data => dispatch({ type: Actions.UPDATE_SHOW_READ_ONLY_WARNING, data}),

      //Loading
      updateLoadingTriggers: data => dispatch({ type: Actions.UPDATE_LOADING_TRIGGERS, data }),
      updateLoadingTables: data => dispatch({ type: Actions.UPDATE_LOADING_TABLES, data }),
      updateLoadingLiteUI: data => dispatch({ type: Actions.UPDATE_LOADING_LITEUI, data }),

      //Init States
      setState: data => dispatch({ type: Actions.UPDATE_STATE, data }),
      resetState: data => dispatch({ type: Actions.RESET_STATE, data }),
      setMode: data => dispatch({ type: Actions.UPDATE_MODE, data }),

      //Explore page
      updateExplorePageEnabled: data => dispatch({ type: Actions.UPDATE_EXPLORE_PAGE_ENABLED, data }),

      //Transactions
      updateShowTransactionView: data => dispatch({ type: Actions.UPDATE_SHOW_TX_VIEW, data }),
      addPendingTransaction: data => dispatch({ type: Actions.ADD_PENDING_TX, data }),
      removePendingTransaction: data => dispatch({ type: Actions.REMOVE_PENDING_TX, data }),

      //Nav
      updateSelectedView: data => dispatch({ type: Actions.UPDATE_SELECTED_VIEW, data }),

      //Edit Header
      updateShowHeaderModal: data => dispatch({ type: Actions.UPDATE_SHOW_EDIT_HEADER_MODAL, data }),
      //Header
      updateEditHeader: data => dispatch({ type: Actions.UPDATE_EDIT_HEADER, data }),
      //SubHeader
      updateEditSubHeader: data => dispatch({ type: Actions.UPDATE_EDIT_SUBHEADER, data }),
      //Logo
      updateEditLogo:  data =>  dispatch({ type: Actions.UPDATE_EDIT_LOGO, data }),
      //Save Header Edits
      saveHeaderModalEdits: data => dispatch({ type: Actions.SAVE_HEADER_MODAL_EDITS, data }),

      //Detail View
      updateDetailView: data => dispatch({ type: Actions.UPDATE_DETAIL_VIEW, data }),

      //Views
      addView: data => dispatch({ type: Actions.ADD_VIEW, data }),
      deleteView: data => dispatch({ type: Actions.DELETE_VIEW, data }),
      updateShowViewNameModal: data =>  dispatch({ type: Actions.UPDATE_SHOW_VIEW_NAME, data }),
      updateViewName: data =>  dispatch({ type: Actions.UPDATE_VIEW_NAME, data }),

      //Page
      addPage: data =>  dispatch({ type: Actions.ADD_PAGE, data }),
      deletePage: data =>  dispatch({ type: Actions.DELETE_PAGE, data }),
      updatePageName:  data =>  dispatch({ type: Actions.UPDATE_PAGE_NAME, data }),
      updateShowAddPageModalShow: data =>  dispatch({ type: Actions.UPDATE_SHOW_ADD_PAGE_MODAL_SHOW, data }),
      updateShowAddPageModalError: data =>  dispatch({ type: Actions.UPDATE_SHOW_ADD_PAGE_MODAL_ERROR, data }),
      updateShowAddPageModalName: data =>  dispatch({ type: Actions.UPDATE_SHOW_ADD_PAGE_MODAL_NAME, data }),
      updateSelectedPage: data => dispatch({ type: Actions.UPDATE_SELECTED_VIEW, data }),

      //Page Edit Modal
      updateShowEditPageModal: data => dispatch({ type: Actions.UPDATE_SHOW_EDIT_PAGE_MODAL, data }),
      updateEditPageName: data => dispatch({ type: Actions.UPDATE_EDIT_PAGE_NAME, data }),
      savePageEdits: data => dispatch({ type: Actions.SAVE_PAGE_EDITS, data }),

      //Elements
      addEditingElementToView: data =>  dispatch({ type: Actions.ADD_ELEMENT, data }),
      moveElement: data =>  dispatch({ type: Actions.MOVE_ELEMENT, data }),
      startAddElement: data =>  dispatch({ type: Actions.START_ADD_ELEMENT, data }),
      deleteElementFromView: data =>  dispatch({ type: Actions.DELETE_ELEMENT, data }),
      updateShowAddElementModal: data =>  dispatch({ type: Actions.UPDATE_ADD_ELEMENT_SHOW, data }),
      updateAddElementType: data =>  dispatch({ type: Actions.UPDATE_ADD_ELEMENT_TYPE, data }),
      updateAddElementSource: data =>  dispatch({ type: Actions.UPDATE_ADD_ELEMENT_SOURCE, data }),
      updateShowElementTitleModal: data =>  dispatch({ type: Actions.UPDATE_SHOW_ELEMENT_TITLE_MODAL, data }),
      updateElementTitle: data =>  dispatch({ type: Actions.UPDATE_ELEMENT_TITLE, data }),

      //LiteList
      updateShowEditListModal: data => dispatch({ type: Actions.UPDATE_SHOW_EDIT_LIST_MODAL , data}),
      addEditingListAction: data => dispatch({ type: Actions.ADD_LIST_MODAL_ACTION , data}),
      removeListAction: data => dispatch({ type: Actions.REMOVE_LIST_MODAL_ACTION , data}),
      saveListModalEdits: data => dispatch({ type: Actions.SAVE_LIST_MODAL_EDITS , data}),
      updateEditListName: data => dispatch({ type: Actions.UPDATE_LIST_MODAL_NAME , data}),
      updateEditActionName: data => dispatch({ type: Actions.UPDATE_LIST_MODAL_ACTION_NAME , data}),
      updateEditActionSource: data => dispatch({ type: Actions.UPDATE_LIST_MODAL_ACTION_SOURCE , data}),
      updateListModalRenderMapping: data => dispatch({ type: Actions.UPDATE_LIST_MODAL_RENDER_MAPPING, data}),
      updateListModelRenderMappingHyperLink: data => dispatch({ type: Actions.UPDATE_LIST_MODAL_RENDER_MAPPING_HYPERLINK, data}),
      updateListModelRenderMappingHyperLinkCustom: data => dispatch({ type: Actions.UPDATE_LIST_MODAL_RENDER_MAPPING_HYPERLINK_CUSTOM, data}),
      updateListModelRenderMappingDefaultSort : data => dispatch({ type: Actions.UPDATE_LIST_MODAL_RENDER_MAPPING_DEFAULT_SORT, data}),
      updateListModelRenderMappingSortDirection: data => dispatch({ type: Actions.UPDATE_LIST_MODAL_RENDER_MAPPING_SORT_DIRECTION, data}),
      updateListModelViewDetailsTitle: data => dispatch({ type: Actions.UPDATE_LIST_MODAL_VIEW_DETAILS_TITLE, data}),
      updateListModelViewFilterOnRecordId: data =>  dispatch({ type: Actions.UPDATE_LIST_MODAL_VIEW_FILTER_ON_RECORD_ID, data}),
      updateRefreshLiteList: data => dispatch({ type: Actions.UPDATE_LITE_LIST_REFRESH, data }),

      //LiteText
      updateShowEditTextModal: data =>  dispatch({ type: Actions.UPDATE_SHOW_EDIT_TEXT_MODAL, data }),
      updateEditTextModalValue: data =>  dispatch({ type: Actions.UPDATE_EDIT_TEXT_MODAL_VALUE, data }),
      saveTextModalEdits: data => dispatch({ type: Actions.SAVE_TEXT_MODAL_EDITS, data }),

      //LiteForm
      updateShowEditFormModal: data =>  dispatch({ type: Actions.UPDATE_SHOW_EDIT_FORM_MODAL, data }),
      saveFormModalEdits: data =>  dispatch({ type: Actions.SAVE_FORM_MODAL_EDITS, data }),
      updateEditFormName: data =>  dispatch({ type: Actions.UPDATE_FORM_MODAL_NAME, data }),
      updateShowEditFormAutofillPointer: data => dispatch({ type: Actions.UPDATE_FORM_MODAL_AUTOFILL_POINTER, data}),

      //LiteChat
      updateLiteChatThreadId: data => dispatch({ type: Actions.UPDATE_CHAT_THREAD_ID, data }),

      //Sources
      setdTables: data =>  dispatch({ type: Actions.SET_DTABLES, data }),
      setTriggers: data =>  dispatch({ type: Actions.SET_TRIGGERS, data }),

      //Create
      updateCanCreate: data =>  dispatch({ type: Actions.UPDATE_CAN_CREATE, data }),
      updateSubmitting: data => dispatch({ type: Actions.UPDATE_SUBMITTING, data }),

      //LiteWallet
      updateLiteWallet: data => dispatch({ type: Actions.UPDATE_LITEWALLET, data })

    }
}

export default Methods;
