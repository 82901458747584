import React from "react";
import { Link } from "react-router-dom";

const QuickLinks = ({ quicklinks }) => {
  return (
    <div className="quick-links-container">
      {quicklinks.map((quickLink, i)=>{
        if(quickLink.external){
          return <a key={i} target='_blank' href={quickLink.url}>
            {quickLink.linkText}
            {quickLink.icon ?
              <img src={require(`${quickLink.icon}`)} />
              :
              null
            }
          </a>
        }else{
          return <Link key={i} to={quickLink.url}>
            {quickLink.linkText}
            {quickLink.icon ?
              <img src={require(`${quickLink.icon}`)} />
              :
              null
            }
          </Link>
        }
      })}
    </div>
  );
};

export default QuickLinks;
