import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import ToolTip from '../Tooltips';

const Info = ({
  service,
  tooltip,
  onChange,
  name,
  description,
  nameError,
  descriptionError
}) => {
  return (
    <div className="service-info-container">
      <div className='section-title atra-tooltip-container'>
        <h4>{service} Info</h4>
        <ToolTip
          tip={tooltip}
        />
      </div>
      <form onSubmit={(e)=>e.preventDefault()} autoComplete="off">
        <FormGroup controlId="name">
          <ControlLabel>Name</ControlLabel>
          <FormControl
            placeholder="Enter name"
            onChange={onChange}
            value={name}
            type="text"
          />
        <div className='info-error'>{nameError}</div>
        </FormGroup>
        <FormGroup controlId="description">
          <ControlLabel>Description</ControlLabel>
          <FormControl
            placeholder="Enter description"
            onChange={onChange}
            value={description}
            type="text"
          />
          <div className='info-error'>{descriptionError}</div>
        </FormGroup>
      </form>
    </div>
  );
}

export default Info;
