import React, { Component } from 'react';
import { connect } from "react-redux";
import Actions from '../actions/notifications';
import { Alert } from 'react-bootstrap';


const PopupMessages = ({
  messages,
  removeMessage
})=>{
    if(messages){
      return (
        <div className='popup-notifications-container'>
          <div className="alert-wrapper flash-message container">
            {messages.map((message,i)=>{
              return (
                <Alert
                  key={i}
                  id="flash-disappear"
                  className="flash bounceInDown bounceIn-animated"
                  bsStyle={message.type}
                >
                  {message.message}
                </Alert>
              )
            })}
          </div>
        </div>
      )
    }else{
      return null;
    }
};


class Popup extends Component {
  render(){
    return (
      <PopupMessages
        messages={this.props.notifications.popupMessages}
        removeMessage={this.props.notificationsRemovePopupMessage}
         />
    )
  }
}


const mapStateToProps = state => {
  return {
    notifications: state.notifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notificationsAddPopupMessage: data => dispatch({ type: Actions.NOTIFICATIONS_ADD_POPUP_MESSAGE, data }),
    notificationsRemovePopupMessage: data => dispatch({ type: Actions.NOTIFICATIONS_REMOVE_POPUP_MESSAGE, data }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Popup);
