import React from 'react';
import JSONPretty from 'react-json-pretty';

const Headers = ({

}) => {
  const header = "x-api-key: [Atra API key]";
  return (
    <section>
      <h5>API Url</h5>
      <JSONPretty id="json-pretty" data={'https://api.atra.io/prod/v1'}></JSONPretty>

      <h5>Required Headers</h5>
      <JSONPretty id="json-pretty" data={header}></JSONPretty>
    </section>
  )
}

export default Headers;
