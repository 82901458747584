import Actions from '../actions/dashboard';
const Methods = ({ dispatch }) => {
    return {
      toggleFilter: data => dispatch({ type: Actions.TOGGLE_FILTER, data }),
      updateWallets: data => dispatch({ type: Actions.UPDATE_WALLETS, data }),
      updateLoading: data => dispatch({ type: Actions.UPDATE_LOADING_WALLETS, data }),
      clearState: data => dispatch({ type: Actions.CLEAR_STATE, data })
    }
}
export default Methods;
