import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/triggers-view";
import NotificationActions from "../../../../../components/Notifications/actions/notifications";
import LoadingTab from "./LoadingTab";
import { ControlLabel, FormControl, Button, Alert } from "react-bootstrap";
import PriceInput from "../../../../../components/PriceInput/PriceInput";
import DateInput from "../../../../../components/DateInput/DateInput";
import AddressInput from "../../../../../components/AddressInput/AddressInput";
import LoaderButton from "../../../../../components/Buttons/LoaderButton";
import {
  ConvertEthToWei,
  ConvertWeiToEth,
  IsAddress,
  ToHex,
} from "../../../../../helpers/Eth";
import {
  FormatDate,
  FormatDateTime,
  GetEpochFromTimeZoneDate,
} from "../../../../../helpers/Date";
import MetaMask from "../../../../../components/MetaMask";
import AtraAPI from "../../../../../apiv2";
import { CreateWeb3Object } from "../../../../../components/Web3";
import { GetRecordById } from "../../../../../sdk/dtables";

const RenderPaymentInput = ({ payment, value, onChange }) => {
  if (payment.enabled) {
    switch (payment.source.type) {
      case "static":
        return (
          <div>
            <label>Cost: {ConvertWeiToEth(payment.source.value)} ETH</label>
          </div>
        );
        break;
      case "dtable":
        return (
          <div>
            {!value ? (
              <label>Cost: Enter Record Address to calculate cost</label>
            ) : (
              <label>Cost: {value}</label>
            )}
          </div>
        );
      case "unlimited":
        return (
          <div>
            <label>Cost: Unlimited</label>
            <RenderInputField
              value={value}
              handleChange={onChange}
              type={"price"}
            />
          </div>
        );
        break;
      case "range":
        return (
          <div>
            <label>
              Cost: Range between {ConvertWeiToEth(payment.source.value.min)} -{" "}
              {ConvertWeiToEth(payment.source.value.max)} ETH
            </label>
            <RenderInputField
              value={value}
              handleChange={onChange}
              type={"price"}
            />
          </div>
        );
        break;
    }
  }
  return (
    <div>
      <label>Cost: Free</label>
    </div>
  );
};

const RenderInputField = ({ type, value, index, handleChange }) => {
  const childProps = {
    "data-index": index,
    "data-valuetype": type,
  };

  let dataSets = [];
  dataSets["valueType"] = type;
  dataSets["index"] = index;

  switch (type) {
    case "price":
      return (
        <div className="input-price-wrapper">
          <PriceInput
            value={value}
            onChange={handleChange}
            childProps={childProps}
          />
        </div>
      );
      break;
    case "date":
      return (
        <div className="input-date-wrapper">
          <DateInput
            datasets={dataSets}
            value={value}
            onChange={handleChange}
          />
        </div>
      );
      break;
    case "address":
      return (
        <div className="input-address-wrapper">
          <AddressInput
            childProps={childProps}
            value={value}
            onChange={handleChange}
            datasets={dataSets}
          />
        </div>
      );
      break;
    default:
      return (
        <div className="input-text-wrapper">
          <FormControl
            type="text"
            data-valuetype={type}
            data-index={index}
            placeholder={type}
            onChange={handleChange}
          />
        </div>
      );
      break;
  }
};

const EthStatus = ({ status }) => {
  switch (status.code) {
    case 200:
      return <Alert bsStyle="success">Eth Status: Wallet Connected</Alert>;
    default:
      return (
        <Alert bsStyle="danger">
          Eth Status: Wallet Not Connected - {status.message}
        </Alert>
      );
  }
};

class InvokeView extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
  }

  componentDidMount = async () => {
    this._mounted = true;

    //If there is a static price set it now
    const { trigger } = this.props.triggersView;
    this.setTriggerPaymentState(trigger, (cost) => {
      this.props.updateInvokeEth({ value: cost });
    });

    this.props.updateLoadingTab({ value: false });
  };

  componentWillUnmount = () => {
    this._mounted = false;
  };

  setTriggerPaymentState = (trigger, updateCost) => {
    if (trigger.input.payment.enabled) {
      switch (trigger.input.payment.source.type) {
        case "static":
          updateCost(trigger.input.payment.source.value);
          break;
        case "unlimited":
          break;
        case "range":
          break;
        case "dtable":
          break;
      }
    }
  };

  invokeWithMeataMask = (event) => {
    let web3 = new CreateWeb3Object(window.ethereum);
    const trigger = this.props.triggersView.trigger;
    const invoke = this.props.triggersView.invoke;

    web3.eth.getAccounts((err, res) => {
      web3.eth.defaultAccount = res[0];
      window.DefaultAccount = res[0];

      let contractInstance = new web3.eth.Contract(
        JSON.parse(trigger.abi),
        trigger.goerli
      );

      let sendData = {
        from: window.DefaultAccount,
      };

      //format inputs
      const inputs = invoke.inputs.map((input, i) => {
        switch (input.type) {
          case "date":
            return GetEpochFromTimeZoneDate(
              input.value,
              this.props.account.user.timezone.code
            );
          case "price":
            return ConvertEthToWei(input.value);
          default:
            return input.value;
        }
      });

      //if trigger uses value
      const payment = trigger.input.payment;
      if (payment.enabled) {
        switch (payment.source.type) {
          case "static": {
            const value = ToHex(payment.source.value);
            sendData.value = value;
            break;
          }
          case "range": {
            const value = ToHex(ConvertEthToWei(invoke.eth));
            sendData.value = value;
            break;
          }
          case "unlimited": {
            const value = ToHex(ConvertEthToWei(invoke.eth));
            sendData.value = value;
            break;
          }
          case "dtable": {
            const value = ToHex(ConvertEthToWei(invoke.eth));
            sendData.value = value;
            break;
          }
        }
      }

      //make request to contract
      try {
        if (trigger.input.action.method === "update") {
          let invokeWithIndex = contractInstance.methods
            .invoke(invoke.index, [...inputs])
            .send(sendData)
            .on("transactionHash", (hash) => {
              this.props.notificationsAddPopupMessage({
                message: "Success! - Invoke Transaction Sent",
                remove: (id) => {
                  this.props.notificationsRemovePopupMessage({ value: id });
                },
              });
            })
            .on("confirmation", (confirmationNumber, receipt) => {})
            .on("receipt", (receipt) => {})
            .on("error", (err) => {
              console.log(err);
              this.props.notificationsAddPopupMessage({
                type: "warning",
                message: "Failure! - Trigger Invoke Failed",
                remove: (id) => {
                  this.props.notificationsRemovePopupMessage({ value: id });
                },
              });
            });
        } else if (trigger.input.action.method === "delete") {
          //difference between update and delete is that delete always takes a single parameter and inputs will be null
          let invokeWithIndex = contractInstance.methods
            .invoke(invoke.index)
            .send(sendData)
            .on("transactionHash", (hash) => {
              this.props.notificationsAddPopupMessage({
                message: "Success! - Invoke Transaction Sent",
                remove: (id) => {
                  this.props.notificationsRemovePopupMessage({ value: id });
                },
              });
            })
            .on("confirmation", (confirmationNumber, receipt) => {})
            .on("receipt", (receipt) => {})
            .on("error", (err) => {
              console.log(err);
              this.props.notificationsAddPopupMessage({
                type: "warning",
                message: "Failure! - Trigger Invoke Failed",
                remove: (id) => {
                  this.props.notificationsRemovePopupMessage({ value: id });
                },
              });
            });
        } else if (trigger.input.action.method === "insert") {
          let invoke = contractInstance.methods
            .invoke([...inputs])
            .send(sendData)
            .on("transactionHash", (hash) => {
              this.props.notificationsAddPopupMessage({
                message: "Success! - Invoke Transaction Sent",
                remove: (id) => {
                  this.props.notificationsRemovePopupMessage({ value: id });
                },
              });
            })
            .on("confirmation", (confirmationNumber, receipt) => {})
            .on("receipt", (receipt) => {})
            .on("error", (err) => {
              console.log(err);
              this.props.notificationsAddPopupMessage({
                type: "warning",
                message: "Failure! - Trigger Invoke Failed",
                remove: (id) => {
                  this.props.notificationsRemovePopupMessage({ value: id });
                },
              });
            });
        }
      } catch (err) {
        this.props.notificationsAddPopupMessage({
          type: "warning",
          message: "Failure! - Trigger Invoke Failed",
          remove: (id) => {
            this.props.notificationsRemovePopupMessage({ value: id });
          },
        });
      }
    });
  };

  handleRecordAddressChange = (event) => {
    const { trigger, invoke } = this.props.triggersView;
    this.props.updateInvokeIndex({ value: event.target.value });
  };

  render() {
    const { trigger, invoke, loading } = this.props.triggersView;

    if (loading.tab) {
      return <LoadingTab title={"Invoke"} />;
    }
    return (
      <div className="invoke-tab-container">
        <MetaMask />
        <EthStatus status={this.props.metamask.status} />
        <h4>Manually Invoke Trigger</h4>
        <p>
          Invoking a trigger will make a request to execute the trigger aciton.
        </p>
        <p>
          Triggers are user driven components and require the use of metamask to
          invoke.
        </p>
        <h3>{trigger.name}</h3>
        {trigger.input.action.method === "update" ||
        trigger.input.action.method === "delete" ? (
          <div className="form-input-container">
            <label>Record Address</label>
            <RenderInputField
              value={invoke.index}
              handleChange={this.handleRecordAddressChange}
              type={"record address"}
            />
          </div>
        ) : null}
        {invoke.inputs.map((input, i) => {
          if (input.input) {
            return (
              <div className="form-input-container" key={i}>
                <label>{input.name}</label>
                <RenderInputField
                  value={input.value}
                  index={i}
                  handleChange={(event) => {
                    this.props.updateInvokeField({
                      value: event.target.value,
                      index: input.index,
                    });
                  }}
                  type={input.type}
                />
              </div>
            );
          }
        })}

        <RenderPaymentInput
          payment={trigger.input.payment}
          value={invoke.eth}
          onChange={(event) => {
            this.props.updateInvokeEth({ value: event.target.value });
          }}
        />
        <div className="invoke-button-container">
          <Button
            className="invoke-button"
            onClick={() => {
              this.invokeWithMeataMask();
            }}
          >
            Invoke
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    triggersView: state.triggersView,
    metamask: state.metamask,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateContract: (data) =>
      dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_CONTRACT, data }),
    updateLoadingTab: (data) =>
      dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_LOADING_TAB, data }),
    updateInvokeField: (data) =>
      dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_INVOKE_FIELD, data }),
    updateInvokeIndex: (data) =>
      dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_INVOKE_INDEX, data }),
    updateInvokeEth: (data) =>
      dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_INVOKE_ETH, data }),
    updateInvokeMetaMask: (data) =>
      dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_INVOKE_METAMASK, data }),

    notificationsAddPopupMessage: (data) =>
      dispatch({
        type: NotificationActions.NOTIFICATIONS_ADD_POPUP_MESSAGE,
        data,
      }),
    notificationsRemovePopupMessage: (data) =>
      dispatch({
        type: NotificationActions.NOTIFICATIONS_REMOVE_POPUP_MESSAGE,
        data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvokeView);
