export default {
  AppName: `The App Name is what users will know your dApp as. The name is also used to calculate the final url.`,
  View: `Views are pages within your website. Creating a view will add a link in the navigation. Clicking on a link in the navigation will switch to that page, there you can edit/delete and add UI Elements to the view.`,
  BlankPage: {
    Header: 'Add a Page then add UI elements',
    Description: `This is where you will build the layout of each section of your dApp. Adding a page to your nav bar will allow you to start
constructing the layout of your dApp. Think of your first page as your home page - it will be the landing page for your users.
Once you create a page, you will have the option to add one of 3 UI elements:`
  },
  NoElements: {
      Header: 'Add a UI Element'
  },
  ElementDescriptions: [
    {
      Title: 'Plain Text',
      Description: 'This element is just a text chunk, allowing you to add headers and body content in the form of text to your page.',
      Icon: 'faFont'
    },
    {
      Title: 'List',
      Description: 'Adding a list to your page allows you to run a view of a dTable on your dApp. You can interact with this list with actions based on the Triggers you’ve made.',
      Icon: 'faThList'
    },
    {
      Title: 'Interactive Form',
      Description: 'A form will allow you to engage with a trigger you’ve created. Based on the Trigger type, you will be able to insert, update or delete a new record in one of your dTables.',
      Icon: 'faKeyboard'
    }
  ]
}
