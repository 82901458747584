import React from "react";
import { Next } from "../Utils";
import Logo from "../../../../../components/Branding/ServiceIcons/liteui.svg";

const LiteUI = ({}) => {
  return (
    <div className="doc-section-content-container">
      <div className="service-branding-container">
        <h2>LiteUI</h2>
        <img src={Logo} />
      </div>

      <section>
        <h3>Overview</h3>
        <p>
          LiteUI is a point and click website builder for your decentralized
          Atra infrastructure. LiteUI also takes care of launching and hosting
          your websites.
        </p>
        <p>
          LiteUI interface builder works by adding UI Element to your site. UI
          Elements are sort of like widgets for displaying your Atra
          infrastructure (dTables and Triggers). UI Elements come prebuilt with
          the capability to connect to Ethereum. Simply add a element to your
          page and Atra will take care of the rest. Websites are instantly
          available for use after launching and updating.
        </p>
        <p>
          LiteUI connects Ethereum smart contracts created with the Atra
          services to a frontend website. Atra manages the integration between
          the smart contract and the user’s browser wallet. LiteUI officially
          supports the Metamask wallet.
        </p>
      </section>

      <section>
        <h3>Quick Start</h3>
        <p>
          Once logged into the console, select the LiteUI from the services
          dropdown in the navigation. The LiteUI dashboard page is a hub to all
          your LiteUI websites. Create a new LiteUI by clicking the plus button.
          LiteUI requires at least one dTable to be created before creating a
          new LiteUI. This is to ensure you have something to display for your
          users.
        </p>
      </section>

      <section>
        <h3>Create LiteUI</h3>
        <p>
          Creating a LiteUI is simple. Navigate to the LiteUI Dashboard and
          click the plus button in the upper right corner.
        </p>
        <p>
          Note: LiteUI requires at least one dTable to be created before
          creating a new LiteUI. This is to ensure you have something to display
          for your users.
        </p>
        <p>
          The LiteUI interface builder is where you decide what goes in your
          website. Here you can point and click to add new elements and
          configure the site. Every LiteUI website comes standard with a
          navigation menu, and a connection to the Ethereum network. This way
          Atra users don’t ever have to worry integrating their smart contracts
          with a UI, LiteUI does this for you.
        </p>
        <p>
          Once in the LiteUI interface builder, first thing we want to do is
          name your new website by entering the name at the top of the screen.
        </p>
        <p>
          The interface builder allows users to edit the website layout and
          elements inline. This means where ever you put an element or make a
          change to the interface builder, that’s generally how it will show up
          in the final public view of your site. UI Elements added to the
          interface builder are fully functional as well. You can see the data,
          and invoke forms using your metamask account as if you were using the
          final website.
        </p>
        <p>
          After naming your application, create the home page by clicking ‘Add
          Page’ and naming your page. The first page in your nav bar will be the
          default home page for your dApp, maybe you want to name it ‘Home’.
        </p>
        <p>
          After adding the first page, you now have a page to place the first UI
          Element. Select add UI element from the interface builder. Select any
          element you want and it will be placed on the page. Elements can not
          be reordered at this time, so add them in the order you want them to
          show up.
        </p>
        <p>
          After adding all the pages and elements you want, click save in the
          top right corner. Your website is now live. To view the url click back
          into the LiteUI from the dashboard and there will be a public url link
          in the top right. This is the url to the public view of your website.
        </p>
      </section>

      <section>
        <h3>Updating a LiteUI</h3>
        <p>
          Click into the LiteUI from the dashboard. Make changes to your site,
          and click Update. Updates to the website are live instantly.
        </p>
      </section>

      <section>
        <h3>Connecting Triggers to dTables</h3>
        <p>
          In many cases you will want to connect an Update or Delete type
          trigger to the dTable the trigger is updating or deleting records
          from. To do this is simple, place an List type UI Element on a page.
          Click the menu icon in the upper right of the element and select
          configure. Connecting a trigger to a dTable List Element is called an
          Action. Add an action for the trigger you want to connect, and save.
        </p>
        <p>
          To access this action, first select a record from the list, this will
          enable the actions dropdown to be active. Click the actions dropdown
          and select the action.
        </p>
      </section>

      <section>
        <h3>Use Case Examples</h3>
        <h4>Prototype</h4>
        <p>
          Don’t waste time creating a custom website, domain, and server. Use
          LiteUI to stand up a website to quickly use the underlying services to
          make sure they meet all your needs before going forward with your
          idea. If it’s not right, quickly make changes to your data or logic
          layers and update the LiteUI for instant interactions.
        </p>
        <h4>Production Website</h4>
        <p>
          There is no need for custom web development to display your
          decentralized application when starting off. Use LiteUI to get to
          market quickly.
        </p>
      </section>

      <Next title={"aWallet User Guide"} link={"/docs/user-guides/awallet"} />
    </div>
  );
};

export default LiteUI;
