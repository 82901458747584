import * as utils from 'web3-utils';

const ConvertEthToWei = eth => {
  return utils.toWei(parseFloat(eth).toString());
}

const ConvertWeiToEth = wei => {
  return utils.fromWei(wei.toString());
}

const IsAddress = address => {
  return utils.isAddress(address);
}

const CheckAddressChecksum = address => {
  return utils.checkAddressChecksum(address);
}

const ToHex = value => {
  return utils.toHex(value);
}

const FromHex = value => {
  return utils.hexToNumber(value);
}

const FromBigNumber = value => {
  return value;
}

export { ConvertEthToWei, ConvertWeiToEth, IsAddress, ToHex, FromBigNumber, CheckAddressChecksum, FromHex }
