import Header from './header';
import FAQ from './faq';
import Feedback from './feedback';
import ReportIssue from './report-issue';

export default {
  Header,
  FAQ,
  Feedback,
  ReportIssue
}
