import { API } from "aws-amplify";
import { FormatDate, FormatDateTime } from "../helpers/Date";

const GetTriggers = (timezone, environmentId = null) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        queryStringParameters: {}
      };

      if(environmentId){
        data.queryStringParameters.environmentId = environmentId;
      }

      let code = null;
      if(timezone && timezone.code){
        code = timezone.code;
      }

      API.get("triggers", "", data).then( res => {

        const triggers = res.data.map(trigger => {
          return {
            ...trigger,
            date: FormatDateTime(trigger.created, code),
            link: `/triggers/view/${trigger.id}`,
            archived: trigger.archived || false
          }
        });
        resolve(triggers);


      }).catch(err => {
        reject(err);
      });
    } catch (err) {
      reject(err);
    }
  });
};

const GetTriggersFullReturn = (timezone, environmentId = null) => {
  return new Promise((resolve, reject) => {
    try {

      let data = {
        response: true,
        queryStringParameters: {
          full: true
        }
      };
      if(environmentId){
        data.queryStringParameters.environmentId = environmentId;
      }

      let code = null;
      if(timezone && timezone.code){
        code = timezone.code;
      }

      API.get("triggers", "", data).then( res => {

        const triggers = res.data.map(trigger => {
          return {
            ...trigger,
            date: FormatDateTime(trigger.created, code),
            link: `/triggers/view/${trigger.id}`,
            archived: trigger.archived || false
          }
        });
        resolve(triggers);


      }).catch(err => {
        reject(err);
      });
    } catch (err) {
      reject(err);
    }
  });
};

const GetTrigger = (triggerId) => {
  return new Promise((resolve, reject)=>{
    try {
      let data = {
        queryStringParameters: {
          triggerId: triggerId
        }
      }
      API.get('triggers', '', data).then(res => {
        resolve(res);
      }).catch(err=>{
        resolve(err);
      });
    }catch (err) {
      console.log(err);
      reject();
    }
  });
}

const CreateTrigger = (trigger) => {
  return new Promise((resolve, reject)=>{
    try {
      let data = {
        response: true,
        body: trigger
      };

      API.post("triggers", "", data).then(res => {
        resolve(res);
      }).catch(err => {
        if (err && err.response && err.response.data && err.response.data.error) {
          reject(err.response.data.error);
        }else{
          reject('error');
        }
      });

    } catch (err) {
      reject(err);
    }
  });
}

export default { GetTriggers, GetTriggersFullReturn, GetTrigger, CreateTrigger };
