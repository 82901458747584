import React, { Component } from "react";
import { connect } from "react-redux";

import AtraAPI from "../../../apiv2";
import DashboardMethods from "../methods/dashboard";
import { FormatDate } from "../../../helpers/Date";
import { RefreshMiliseconds } from "../../../helpers/Timers";
import { Container } from "../components";
import {
  DashboardStatsContainer,
  DashboardActionHeader,
  DashboardGrid,
  DashboardFooter,
  DashboardGridNoContent,
  DashboardFilter,
} from "../../../components/ServiceDashboard";
import EnvMethods from "../../../components/Environments/methods";

class WalletsDashboard extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    this._noContent = (
      <DashboardGridNoContent
        title={"Create aWallet"}
        description={`A aWallet is a container that can manage many individual wallets at once. Generate, fund, and manage wallets for different use cases using this service.`}
        buttonText={"Create aWallet"}
        onClick={() => this.props.history.push("/awallet/create")}
        learnMoreClick={() =>
          this.props.history.push("/docs/user-guides/awallet")
        }
      />
    );
    this.props.updateEnvironmentsEnabled({ enabled: true });
  }

  async componentDidMount() {
    this._mounted = true;
    this.pollWalletBatches();
  }

  async componentWillUnmount() {
    this._mounted = false;
    this.props.clearState();
  }

  async pollWalletBatches() {
    if (this._mounted) {
      await this.getWalletBatches().then(async (tables) => {
        setTimeout(async () => {
          await this.pollWalletBatches();
        }, RefreshMiliseconds);
      });
    }
  }

  getWalletBatches = () => {
    return new Promise((resolve, reject) => {
      let envId = window.localStorage.getItem("activeEnvironment");
      AtraAPI.GetWallets(this.props.account.user.timezone, envId).then(
        (batches) => {
          if (this._mounted) {
            this.props.updateWallets({ value: batches });
            this.props.updateLoading({ value: false });
            resolve();
          } else {
            reject();
          }
        },
        (err) => {
          this.props.updateLoading({ value: false });
          reject();
        }
      );
    });
  };

  handleFilterChange = (event) => {
    this.props.toggleFilter({ value: event.target.id });
  };

  render() {
    const breadcrumbs = [];
    breadcrumbs.push({ text: "aWallet", to: "/awallet", icon: "litewallet" });

    const statsList = [];
    statsList.push({
      title: "aWallets",
      value: this.props.dashboard.stats.total_batches,
      loading: this.props.dashboard.loading,
    });
    statsList.push({
      title: "User Wallets",
      value: "-",
      loading: false,
    });
    statsList.push({
      title: "AccessPass Wallets",
      value: this.props.dashboard.stats.total_wallets,
      loading: this.props.dashboard.loading,
    });

    const wallets = this.props.dashboard.wallets;

    return (
      <Container
        className="service-dashboard-container"
        breadcrumbs={breadcrumbs}
      >
        <DashboardStatsContainer stats={statsList} />

        <DashboardActionHeader
          title={"Your aWallets"}
          onClick={() => this.props.history.push("/awallet/create")}
          actionButtonText={"Create aWallet"}
        />

        {/*
         <DashboardFilter
           filter={this.props.dashboard.filter}
           onChange={this.handleFilterChange}
           />
         */}

        <DashboardGrid
          type={"litewallet"}
          source={wallets.sort((a, b) => b.created - a.created)}
          loading={
            !this.props.dashboard.wallets.length &&
            this.props.dashboard.loadingWallets
          }
          noContent={this._noContent}
        />

        <DashboardFooter
          title={"aWallet"}
          description={`
          A single aWallet can create, fund, and manage multiple Ethereum wallets. One aWallet can also hold your imported public keys as well.
         `}
          link={"/docs/user-guides/awallet"}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.liteWalletDashboard,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...DashboardMethods({ dispatch }),
    ...EnvMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletsDashboard);
