import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

import Methods from '../methods/view';
import AtraAPI from '../../../apiv2';
import NotificationMethods from "../../../components/Notifications/methods";

import DashboardGridNoContent from '../../../components/ServiceDashboard/DashboardGridNoContent';

const PermissionRecord = ({
  revokeClick,
  address,
  pending,
  revoking
}) => {
  return (
    <div className="permission-wrapper">
      <div className="permission-address">
        {address}
      </div>

      {!pending ?
        revoking ?
          <Button disabled>
            Revoking
          </Button>
          :
          <Button onClick={()=>revokeClick(address)}>
            Revoke
          </Button>

      :
        <Button disabled={true}>
          Pending
        </Button>
      }
    </div>
  )
}

class UsersPermissions extends Component {
  showAddAddressForm = () => {
    this.props.updateShowNewPermissionForm({ value: true });
  };

  componentDidMount = () =>{

  }

  componentWillUnmount = () => {

  }

  revokePermission = (address) => {
    const tableId = this.props.dTablesView.table.id;
    this.props.updateRevokingPermission({ value: address });

    AtraAPI.RevokePermission(tableId, address).then(res=>{
      this.props.notificationsAddPopupMessage({
        message: 'Success! - Permission Revoked',
        remove: (id)=>{this.props.notificationsRemovePopupMessage({ value: id })}
      });
    },err=>{

    })
  };

  render() {
    const pending = this.props.dTablesView.table.permissions.pending;
    const live = this.props.dTablesView.table.permissions.live;
    return (
      <div className="permissions-wrapper">
        <div className="permissions-header-wrapper">
          <div id="header-grid-left" className="permissions-header-text">
            Permissioned Addresses
          </div>
          <div
            id="header-grid-right"
            className="permissions-header-button-wrapper w-clearfix"
          >
            <Button onClick={this.showAddAddressForm} className="secondary-button">
              New Permission
            </Button>
          </div>
        </div>
        <div className="permissions-users-wrapper">
          {!live.length && !pending.length ? (
            <DashboardGridNoContent
                title={'Add Permission'}
                description={'Give a contract or user permission to modify this table by adding the Ethereum address.'}
                buttonText={'New Permission'}
                onClick={() =>this.props.updateShowNewPermissionForm({ value: true })}
               />
          ) : (
            <div>
              {pending.map((permission, i) => {
                return (
                  <PermissionRecord
                    key={i}
                    revokeClick={this.revokePermission}
                    pending={true}
                    address={permission.address}
                    />
                );
              })}
              {live.map((permission, i) => {
                return (
                  <PermissionRecord
                    key={i}
                    revokeClick={this.revokePermission}
                    pending={false}
                    address={permission.address}
                    revoking={permission.revoking}
                    />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dTablesView: state.dTablesView
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...Methods({dispatch}),
    ...NotificationMethods({dispatch})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersPermissions);
