import React from 'react';
import {Link} from 'react-router-dom';
import Note from '../helpers/Note';
import Direction from '../helpers/Direction';

const Intro = ({

}) => {
  return (
    <div className='intro-section-container'>

      <section>
        <div className='reqs-container'>
          <h3>Overview</h3>
            <div className='req-container'>
              <div className='req-label'>Skill Level:</div>
              <div className='req'>Beginner -  No Coding</div>
            </div>

            <div className='req-container'>
              <div className='req-label'>System Requirements:</div>
              <div className='req'>Desktop Browser (Chrome, Brave, Firefox) with Metamask Extension</div>
            </div>

            <div className='req-container'>
              <div className='req-label'>Time:</div>
              <div className='req'>30 min</div>
            </div>

            <div className='req-container'>
              <div className='req-label'>Description</div>
            </div>

            <div>In this tutorial you will learn how to use dTables, Triggers, and LiteUI to create an organization with members and the ability to vote on new proposals in a transparent way.</div>

        </div>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/hWTzr857azI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


      </section>

    </div>
  )

}

export default Intro;
