import ConfigureLiteText from './ConfigureLiteText';
import ConfigureLiteList from './ConfigureLiteList';
import ConfigureLiteForm from './ConfigureLiteForm';
import ConfigureHeader from './ConfigureHeader';
import ConfigurePage from './ConfigurePage';
import AddUIElement from './AddUIElement';
import ModalContainer from './ModalContainer';
import InvokeTrigger from './InvokeTrigger';
import AddPage from './AddPage';
import Error from './Error';
import ReadOnlyWarning from './ReadOnlyWarning';
import PendingTransactions from './PendingTransactions';
import Wallet from './Wallet';

export default {
  ConfigureLiteList: ConfigureLiteList,
  ConfigureLiteForm: ConfigureLiteForm,
  ConfigurePage: ConfigurePage,
  ConfigureHeader: ConfigureHeader,
  ReadOnlyWarning: ReadOnlyWarning,
  PendingTransactions: PendingTransactions,
  Wallet: Wallet,
  ...ConfigureLiteText,
  AddPage: AddPage,
  Error: Error,
  ...AddUIElement,
  ...ModalContainer,
  InvokeTrigger
}
