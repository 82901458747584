import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Prompt } from "react-router";
import AtraAPI from "../../../apiv2";
import { Container, AdminBar } from "../components";
import { Layout } from "../components/UIElements";
import LiteUIMethods from "../methods";
import NotificationMethods from "../../../components/Notifications/methods";
import { Loading } from "../../../components/Loaders";
import EnvMethods from "../../../components/Environments/methods";

export class EditLiteUi extends Component {
  constructor(props) {
    super(props);
    this.props.history.replace(`/liteui/edit/${this.props.match.params.id}`);
    this.state = {
      id: null,
      tables: [],
      triggers: [],
      liteui: {},
      ready: false,
      baseUrl: "/liteui/edit/",
    };
    this.props.updateEnvironmentsEnabled({ enabled: false });
  }
  componentDidMount() {
    this.start();
  }

  componentDidUpdate(prevProps) {
    //console.log('New View:', this.props.match.params.view);
    if (prevProps.match.params.view !== this.props.match.params.view) {
      const id = this.props.match.params.id;
      const view = this.props.match.params.view;
      this.setState({
        id,
        view,
      });
    }
  }

  start = () => {
    document.body.classList.add("white");
    const id = this.props.match.params.id;
    const view = this.props.match.params.view;
    const parent = this.props.match.params.parent;
    const record = this.props.match.params.record;
    //console.log('detail',this.props.match.params.detail);
    if (id) {
      this.setState({
        id,
        view,
        parent,
        record,
      });
      let promiseList = [];

      //Load the state based off exisiting UI JSON
      this.props.updateLoadingTables({ value: true });
      this.props.updateLoadingTriggers({ value: true });
      this.props.updateLoadingLiteUI({ value: true });
      this.props.updateSelectedPage({ value: 0 });
      const jsonFile = axios
        .get(`https://ui.atra.io/${id}.json`, {
          responseType: "json",
        })
        .then((res) => {
          this.props.updateLoadingLiteUI({ value: false });
          this.setState({ liteui: res.data });
        })
        .catch((err) => {
          console.log(err);
        });

      let envId = window.localStorage.getItem("activeEnvironment");

      const getTrigger = AtraAPI.GetTriggersFullReturn(null, envId).then(
        (triggers) => {
          if (triggers.length) {
            const formattedTriggers = triggers
              .filter((t) => t.status === "Live" && !t.archived)
              .map((trigger) => {
                return {
                  ...trigger,
                  input: JSON.parse(trigger.input),
                };
              });
            this.props.updateLoadingTriggers({ value: false });
            this.setState({
              triggers: formattedTriggers.sort((a, b) => b.created - a.created),
            });
          }
        }
      );

      const getTable = AtraAPI.GetTablesFullReturn(null, envId).then(
        (tables) => {
          this.props.updateLoadingTables({ value: false });
          if (tables.length) {
            this.setState({
              tables: tables
                .filter((t) => t.status === "Live" && !t.archived)
                .sort((a, b) => b.created - a.created),
            });
          }
        }
      );

      promiseList.push(jsonFile);
      promiseList.push(getTrigger);
      promiseList.push(getTable);

      Promise.all(promiseList).then((res) => {
        this.setState({ ready: true });
      });

      //Setimezone
      this.props.updateTimezone({ value: this.props.account.user.timezone });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.liteui.ux.unsavedChanges && !this.props.liteui.ux.success) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };
  componentWillUnmount() {
    window.onbeforeunload = undefined;
    this.props.resetState();
  }

  update = async () => {
    const input = {
      name: this.props.liteui.name,
      subheader: this.props.liteui.subheader,
      id: this.props.liteui.id,
      views: this.props.liteui.views,
      detailViewElements: this.props.liteui.detailViewElements,
      explorePageEnabled: this.props.liteui.explorePageEnabled,
    };
    if (this.props.liteui.ux.logo.set && !this.props.liteui.ux.logo.tooLarge) {
      input.logo = this.props.liteui.ux.logo.base64;
    }

    this.props.updateSubmitting({ value: true });
    AtraAPI.UpdateLiteUI({ input }).then(
      (res) => {
        this.props.updateSubmitting({ value: false });
        this.props.notificationsAddPopupMessage({
          message: "Success! - LiteUI Updated",
          remove: (id) => {
            this.props.notificationsRemovePopupMessage({ value: id });
          },
        });
        this.props.updateSuccess({ value: true });
        this.start();
      },
      (err) => {
        this.props.updateSuccess({ value: false });
        this.props.updateSubmitting({ value: false });
        this.props.updateError({ value: err.response.data.error });
      }
    );
  };

  cancel = () => {
    this.props.history.push("/liteui");
  };

  render() {
    document.body.classList.add("white");
    const breadcrumbs = [];
    breadcrumbs.push({ text: "LiteUI", to: "/liteui", icon: "liteui" });
    breadcrumbs.push({ text: "Edit", to: `/liteui/edit/${this.state.id}` });

    const rightContent = (
      <AdminBar
        success={this.props.liteui.ux.success}
        id={this.props.liteui.id}
        submitting={this.props.liteui.submitting}
        mode={this.props.liteui.mode}
        update={this.update}
        save={() => {}}
        unsavedChanges={this.props.liteui.ux.unsavedChanges}
        cancel={this.cancel}
      />
    );

    if (this.state.ready) {
      return (
        <Container
          className="liteui-container"
          breadcrumbs={breadcrumbs}
          rightContent={rightContent}
          border={true}
        >
          <Prompt
            when={
              this.props.liteui.ux.unsavedChanges &&
              !this.props.liteui.ux.success
            }
            message="Are you sure you want to leave before saving?"
          />

          <Layout
            id={this.state.id}
            view={this.state.view}
            parent={this.state.parent}
            record={this.state.record}
            initalState={this.state.liteui}
            mode="edit"
            tables={this.state.tables}
            triggers={this.state.triggers}
            history={this.props.history}
            baseUrl={this.state.baseUrl}
          />
        </Container>
      );
    } else {
      return (
        <Container breadcrumbs={breadcrumbs}>
          <Loading />
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    liteui: state.liteui,
    notification: state.notification,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...LiteUIMethods({ dispatch }),
    ...NotificationMethods({ dispatch }),
    ...EnvMethods({ dispatch }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditLiteUi)
);
