import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import EnvMethods from "../../../components/Environments/methods";

import {
  MainBlurb,
  LoggedInMainBlurb,
  KeyFeatures,
  HelpingPoints,
  Services,
  boxClass as box,
  SignUpBox,
  GettingStartedGuides,
  LoggedInBox,
  Explore,
} from "../components";

const HomePageContainer = ({ children }) => {
  return <div className="home-page-container container">{children}</div>;
};

const HeaderSection = ({ children }) => {
  return <div className="header-section-container">{children}</div>;
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.props.updateEnvironmentsEnabled({ enabled: true });
    this.main = {
      loggedIn: {
        header: "Atra Developer Console",
        subHeader: "Build a decentralized application right now.",
        quicklinks: [
          {
            url: "/docs/overview/getting-started",
            linkText: "Get Started",
          },
          {
            url: "/docs/tutorials/my-first-dapp",
            linkText: "My First dApp Tutorial",
          },
          {
            url: "/docs/api/dtables",
            linkText: "API Docs",
          },
          {
            url: "/docs/support/feedback",
            linkText: "Contact Us",
            icon: "./mail_icon.png",
          },
        ],
      },
      notLoggedIn: {
        header: "Atra Management Console",
        subHeader: "Build an Ethereum application right now.",
      },
      keyFeatures: [
        "Atra provides easy to use web services to help you build, deploy, and maintain decentralized applications.",
        "Services require no coding knowledge or wallet to setup, just point and click.",
      ],
    };
    this.guides = [
      new box(
        "Account Setup",
        "5 minutes",
        "Beginner",
        "GuideIcons/account_setup.svg",
        "/docs/tutorials/account-setup",
        ["Get ready to build decentralized applications"],
        "blue",
        false
      ),
      new box(
        "My First dApp",
        "30 minutes",
        "Beginner",
        "GuideIcons/trophy.svg",
        "/docs/tutorials/my-first-dapp",
        ["Build a live decentralized application on the public test net"],
        "blue",
        false
      ),
      new box(
        "Supply Chain Traceability",
        "20 minutes",
        "Intermediate",
        "GuideIcons/supplychain.svg",
        "/docs/tutorials/supply-chain-traceability",
        ["Create an application to track the origins of coffee beans"],
        "blue",
        false
      ),
      new box(
        "Decentralized Organization",
        "40 minutes",
        "Intermediate",
        "GuideIcons/DAO.svg",
        "/docs/tutorials/decentralized-organization",
        ["Create a decentralized organization for voting on proposals"],
        "blue",
        false
      ),
      new box(
        "Price Oracle Using the API",
        "Coming Soon",
        "Intermediate",
        "GuideIcons/api.svg",
        "/docs/tutorials/price-oracle-api",
        [
          "Create a Price Oracle using dTables and the Atra API. Nodejs script included.",
        ],
        "blue",
        true
      ),
      new box(
        "Create Custom Contract",
        "Coming Soon",
        "Advanced",
        "GuideIcons/custom_contract.svg",
        "/docs/",
        [],
        "blue",
        true
      ),
    ];
    this.services = [
      new box(
        "dTables",
        "Beta",
        "Data Storage",
        "ServiceIcons/dtables.svg",
        "/dtables",
        ["Store decentralized data, the foundation of any application"],
        "purple",
        false
      ),
      new box(
        "Triggers",
        "Beta",
        "Logic",
        "ServiceIcons/triggers.svg",
        "/triggers",
        ["Deploy decentralized business logic to control your application"],
        "purple",
        false
      ),
      new box(
        "LiteUI",
        "Beta",
        "User Interface",
        "ServiceIcons/liteui.svg",
        "/liteui/",
        ["Point and click to build a user interface for your application"],
        "purple",
        false
      ),
      new box(
        "aWallet",
        "Beta",
        "Wallet Management",
        "ServiceIcons/litewallet.svg",
        "/awallet/",
        ["Generate, fund, and manage wallets for different use cases"],
        "purple",
        false
      ),
      new box(
        "Deploy Custom Solidity Contracts",
        "Coming Soon",
        "Developer",
        "",
        "/",
        [],
        "purple",
        true
      ),
    ];
  }

  render() {
    if (this.props.auth.isAuthenticated) {
      return (
        <HomePageContainer>
          <HeaderSection>
            <LoggedInMainBlurb {...this.main} />
          </HeaderSection>
          <Explore />
          <GettingStartedGuides guides={this.guides} />
          <Services services={this.services} />
        </HomePageContainer>
      );
    } else {
      return (
        <HomePageContainer>
          <HeaderSection>
            <MainBlurb {...this.main} />
            <SignUpBox
              handleSignUp={() => this.props.history.push("/signup")}
            />
            <KeyFeatures
              keyFeatures={this.main.keyFeatures}
              customClass={"mobile"}
            />
            <HelpingPoints customClass={"mobile"} />
          </HeaderSection>
          <Explore />
          <GettingStartedGuides guides={this.guides} />
          <Services services={this.services} />
        </HomePageContainer>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...EnvMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
