import Actions from '../actions/view';
const Methods = ({ dispatch }) => {
    return {
      updateActiveTab: data => dispatch({ type: Actions.UPDATE_ACTIVE_TAB, data }),
      updateLoadingTab: data => dispatch({ type: Actions.UPDATE_LOADING_TAB, data }),
      updateLoading: data => dispatch({ type: Actions.UPDATE_LOADING, data }),
      updateLoadingLiteWallet: data => dispatch({ type: Actions.UPDATE_LOADING_WALLET, data }),

      updateLiteWalletId: data => dispatch({ type: Actions.UPDATE_LITEWALLET_ID, data }),
      updateLiteWallet: data => dispatch({ type: Actions.UPDATE_LITEWALLET, data }),
      updateBalance: data => dispatch({ type: Actions.UPDATE_WALLET_BALANCE, data}),
      updateBalances: data => dispatch({ type: Actions.UPDATE_WALLET_BALANCES, data}),

      updateLiteUI: data => dispatch({ type: Actions.UPDATE_LITEUI, data }),

      toggleKey: data => dispatch({ type: Actions.TOGGLE_KEY, data}),

      updateAssignUserModal: data => dispatch({ type: Actions.UPDATE_ASSIGN_USER_MODAL, data}),

      resetState: data => dispatch({ type: Actions.RESET_STATE, data })
    }
}
export default Methods;
