import Header from './header';
import dTables from './dtables';
import Triggers from './triggers';
import LiteUI from './liteui';
import Headers from './headers';

export default {
  Header,
  Headers,
  dTables,
  Triggers,
  LiteUI
}
