import React from 'react';
import { Intro } from './DecentralizedOrganization/';
import { Next } from '../Utils';

const DecentralizedOrganization = ({

}) => {
  return (
    <div className='doc-section-content-container docs-tutorial-container '>
      <h2>Decentralized Organization</h2>

      <Intro />


      <Next
        title={'Create AccessPass Wallets'}
        link={'/docs/tutorials/create-accesspass-wallets'}
         />


    </div>
  )
}

export default DecentralizedOrganization;
