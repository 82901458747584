import React, { Component } from "react";
import MobileNav from "../Navigation/MobileNav";
import HamburgerIcon from "../Navigation/HamburgerIcon";
import DesktopNav from "../Navigation/DesktopNav";
import Routes from "../../Routes";
import Footer from "../Navigation/Footer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import PopupNotifications from "../Notifications/components/popup";
import { SubscriptionUsageModal } from "../SubscriptionUsageModal";

const Layout = ({
  handleLogout,
  history,
  mobileNavOpen,
  children,
  sidebar,
  loggedIn,
  className = "",
}) => {
  const classNames = `App ${mobileNavOpen ? "mobile-nav-open" : ""}`;
  return (
    <div className={`master-container ${className}`}>
      <div className={"master-content-wrapper"}>
        <PopupNotifications />
        <SubscriptionUsageModal />
        <div id="App" className={classNames}>
          <MobileNav handleLogout={handleLogout} history={history} />
          <div id="main_content_area">
            <div className="main-wrapper">
              <DesktopNav
                fullwidth={sidebar}
                handleLogout={handleLogout}
                history={history}
              />
              <div
                className={`content-wrapper ${
                  loggedIn ? "environments-bar-active" : ""
                }`}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
        <Footer sidebar={sidebar} />
      </div>
    </div>
  );
};

class LayoutWithNav extends Component {
  handleLogout = async (event) => {
    await Auth.signOut();
    await this.props.authUserLogout();
    window.localStorage.setItem("activeEnvironment", "");
    this.props.history.push("/login");
  };
  render() {
    return (
      <Layout
        sidebar={this.props.sidebar}
        handleLogout={this.handleLogout}
        history={this.props.history}
        loggedIn={this.props.auth.isAuthenticated}
        className={this.props.className}
      >
        {this.props.children}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nav: state.nav,
    auth: state.auth,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openNav: (data) => dispatch({ type: "UPDATE_NAV_OPEN", data }),
    closeNav: (data) => dispatch({ type: "UPDATE_NAV_CLOSED", data }),
    authUserLogout: (data) => dispatch({ type: "AUTH_USER_LOGOUT", data }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutWithNav)
);
