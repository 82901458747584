import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';
import { connect } from "react-redux";
import { Alert, Jumbotron, Button } from "react-bootstrap";

import { ConvertEthToWei }  from "../../../helpers/Eth";

import AtraAPI from '../../../apiv2';

import NotificationMethods from "../../../components/Notifications/methods";
import TriggerMethods from '../methods/create';
import EnvMethods from '../../../components/Environments/methods';

import { Steps, Action, Conditions, Price, NoTablesWarning, DeployTrigger } from "../components/create";
import { Container } from "../components";
import { Loading } from "../../../components/Loaders";
import { Info, Submit } from "../../../components/ServiceCreate";


export class TriggerCreate extends Component {
  constructor(props){
    super(props);
    this.props.updateEnvironmentsEnabled({ enabled: false });
  }
  componentDidMount() {
    this.props.reset();
    this.alertBox = null;
    let envId = window.localStorage.getItem('activeEnvironment');
    AtraAPI.GetTables(null, envId).then(tables => {
        let filteredTables = tables.filter(t => !t.error && t.status === "Live" && !t.archived);
        if(filteredTables.length){
          this.props.setTables({ tables: filteredTables, timezone: this.props.account.user.timezone});
        }else{
          this.props.setNoTables(true);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  componentDidUpdate() {
    if (this.props.trigger.action.set && !this.props.trigger.success) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    if (this.props.trigger.error) this.alertBox.scrollIntoView();
  }
  componentWillUnmount() {
    window.onbeforeunload = undefined;
    this.props.reset();
  }


  RenderSelectedView = () => {
    switch(this.props.trigger.steps.find(s => s.selected).id){
      case 'action':{
        return <Action/>
      }
      case 'conditions': {
        return <Conditions
          {...this.props.trigger}
          add={() => {
            this.props.addCondition({ timezone: this.props.account.user.timezone });
          }}
          remove={event => {
            this.props.removeCondition(event.target.value);
          }}
          descriptionChange={event => {
            this.props.conditionDescriptionChange({
              id: event.target.dataset["id"],
              value: event.target.value
            });
          }}
          baseChange={event => {
            this.props.conditionBaseChange({
              id: event.target.dataset["id"],
              value: event.target.value,
              group: event.target[
                event.target.selectedIndex
              ].getAttribute("data-group")
            });
          }}
          operatorChange={event => {
            this.props.conditionOperatorChange({
              id: event.target.dataset["id"],
              value: event.target.value
            });
          }}
          targetChange={event => {
            this.props.conditionTargetChange({
              id: event.target.dataset["id"],
              value: event.target.value,
              group: event.target[
                event.target.selectedIndex
              ].getAttribute("data-group"),
              static: event.target.dataset["static"] ? true : false
            });
          }}
          targetTableChange={event => {
            this.props.conditionTargetTableChange({
              id: event.target.dataset["id"],
              value: event.target.value,
              type: event.target.id,
              group: event.target[
                event.target.selectedIndex
              ].getAttribute("data-group")
            });
          }}
          targetStaticChange={event => {
            this.props.conditionTargetChange({
              id: event.target.dataset["id"],
              value: event.target.value,
              static: event.target.dataset["static"] ? true : false
            });
          }}
        />
      }
      case 'payment': {
        return <Price
                {...this.props.trigger}
                enablePriceChange={event => {
                  this.props.priceEnabledChange({
                    value: event.target.value === "yes" ? true : false
                  });
                }}
                sourceTypeChange={event => {
                  this.props.priceSourceTypeChange({
                    value: event.target.value
                  });
                }}
                sourceValueChange={event => {
                  if(event.target.id === 'range'){
                    this.props.priceSourceValueChange({
                      range: event.target.dataset.range,
                      value: event.target.value,
                      type: event.target.id
                    });
                  }else{
                    this.props.priceSourceValueChange({
                      value: event.target.value,
                      type: event.target.id
                    });
                  }

                }}
                destinationTypeChange={event => {
                  this.props.priceDestinationTypeChange({
                    value: event.target.value
                  });
                }}
                destinationValueChange={event => {
                  this.props.priceDestinationValueChange({
                    value: event.target.value,
                    type: event.target.id
                  });
                }}
              />
      }
      case 'deploy': {
        return <DeployTrigger
          history={this.props.history}
          />
      }
    }
  }
  render() {
    const breadcrumbs = [];
    breadcrumbs.push({ text: 'Triggers', to: '/triggers', icon: "triggers"  });
    breadcrumbs.push({ text: 'Create', to: '/triggers/create' });


    if (this.props.trigger.tables.length && !this.props.trigger.noTables) {
      return (
        <Container className='triggers-create-container' breadcrumbs={breadcrumbs}>
          <Prompt
            when={this.props.trigger.action.set && !this.props.trigger.success}
            message="Are you sure you want to leave?"
          />
          <Steps />
          <div>
            {this.RenderSelectedView()}

            {this.props.trigger.error ? (
              <div
                ref={el => {
                  this.alertBox = el;
                }}
              >
                <Alert bsStyle="danger">{this.props.trigger.error}</Alert>
              </div>
            ) : (
              ""
            )}

          </div>

        </Container>
      )
    } else if(this.props.trigger.noTables) {
      return (
        <Container className='triggers-create-container' breadcrumbs={breadcrumbs}>
          <NoTablesWarning
              onClick={()=>this.props.history.push('/dtables')}
            />
        </Container>
      )
    } else {
      return (
        <Container className='triggers-create-container' breadcrumbs={breadcrumbs}>
          <Loading />
        </Container>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    trigger: state.triggersCreate,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...TriggerMethods({ dispatch }),
    ...NotificationMethods({ dispatch }),
    ...EnvMethods({dispatch})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TriggerCreate);
