import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from '../Layouts/LiteUIPublic';
import {Loading} from '../Loaders';

export default ({ component: Component, props: Props, ...rest }) => (
  <Route
    {...rest}
   render={(props) => {
     if(Props.isAuthenticated){
       return (
         <Layout>
           {Props.loadingUser ?
             <Loading />
             :<Component {...props} {...Props} />
           }
         </Layout>
       )
     }else{
       return (
         <Layout>
            <Component {...props} {...Props} />
         </Layout>
       )
     }
   }
   }
 />

);
