import React from 'react';
import { Link } from 'react-router-dom';

const Next = ({title, link}) => {
  return (
    <div className='docs-next-container'>
      <Link to={link}>Next - {title}</Link>
    </div>
  )
}


export  {
  Next
}
