import Actions from '../actions/create';
class DefaultState {
  constructor(){
    this.unsaved = false;
    this.success = false;
    this.isLoading = false;
    this.name = {
      value: '',
      status: {
        valid: false,
        message: ''
      }
    }
    this.description = {
      value: '',
      status: {
        valid: true,
        message: ''
      }
    };
    this.columns = [];
    this.pointerColumns = [];
    this.tables = [];
    this.descriptionErrors = "";
    this.columnErrors = "";
    this.noColumnErrors = "";
  }
}

export default (state = new DefaultState(), action) => {
  switch (action.type) {
    case Actions.RESET_STATE:
      return new DefaultState();
    case Actions.UPDATE_SUCCESS:
      return {
        ...state,
        success: action.data.value
      };
    case Actions.UPDATE_TABLES: {
      var newState = {...state};
      newState.tables = action.data.value;
      return newState;
    }
    case Actions.UPDATE_INPUT:{
      var newState = {...state};
      newState[action.data.value.id].value = action.data.value.value;
      newState.unsaved = true;
      return newState;
    }
    case Actions.UPDATE_COLUMNS:
      return {
        ...state,
        columns: action.data.value,
        unsaved: true
      };
    case Actions.UPDATE_POINTER_COLUMNS:
      return {
        ...state,
        pointerColumns: action.data.value,
        unsaved: true
      };
    case Actions.UPDATE_COLUMN_STATUS:{
      var newState = {...state};
      newState.columns[action.data.index].status = action.data.value;
      return newState;
    }
    case Actions.UPDATE_LOADING:
      return {
        ...state,
        isLoading: action.data.value
      };
    case Actions.UPDATE_NAME_STATUS:{
      let newState = {...state};
      newState.name.status = action.data.value;
      return newState;
    }
    case Actions.UPDATE_DESCRIPTION_STATUS:{
      let newState = {...state};
      newState.description.status = action.data.value;
      return newState;
    }
    case Actions.UPDATE_NO_COLUMN_ERROR:
      return {
        ...state,
        noColumnErrors: action.data.value
      };
    case Actions.UPDATE_COLUMN_ERROR:
      return {
        ...state,
        columnErrors: action.data.value
      };
    case Actions.REMOVE_ERRORS:
      return {
        ...state,
        columnErrors: "",
        nameErrors: "",
        noColumnErrors: ""
      };
    case Actions.REMOVE_COLUMN_ERROR:
      return {
        ...state,
        noColumnErrors: ""
      };
    default:
      return state;
  }
};
