import React from 'react';
import { Button } from "react-bootstrap";

const ActionHeader = ({
  title,
  onClick,
  actionButtonText
})=>{
  return (
    <div className={`dashboard-action-container`}>
      <h1>
        {title}
      </h1>
      <Button className='secondary-button' onClick={onClick}>
        {actionButtonText}
      </Button>
    </div>
  )
}

export default ActionHeader;
