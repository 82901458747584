import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import LoaderButton from '../../../components/Buttons/LoaderButton';

const AdminBar = ({
  id,
  mode,
  update,
  save,
  cancel,
  submitting,
  unsavedChanges
}) => {
  return (
    <div className='liteui-admin-bar-buttons'>
      <div className='button'>
        <Button onClick={cancel} className='white-button'>Cancel</Button>
      </div>
      {mode === 'create' ?
        <div className='button'>
          <LoaderButton
            block
            className="secondary-button"
            disabled={submitting || !unsavedChanges}
            onClick={save}
            isLoading={submitting}
            text={'Save'}
            loadingText={'Saving'}
          />
        </div>
        : null }
      {mode === 'edit' ?
        <div className='button'>
          <LoaderButton
            block
            className="secondary-button"
            disabled={submitting || !unsavedChanges}
            onClick={update}
            isLoading={submitting}
            text={'Update'}
            loadingText={'Updating'}
          />
        </div>
        : null }

    </div>
  );
}

export default class admin extends Component {

  render(){
    return (
      <AdminBar
        {...this.props}
      />
    )
  }
};
