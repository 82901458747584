import React from 'react';

const DashboardStat = ({
  title,
  value,
  loading
}) => {
  if(loading){
    return (
      <div className="stat-container">
        <div className="stat-value">
          -
        </div>
        <div className="stat-title">
          {title}
        </div>
      </div>
    )
  }else{
    return (
      <div className="stat-container">
        <div className="stat-value">
          {value}
        </div>
        <div className="stat-title">
          {title}
        </div>
      </div>
    )
  }

}

const DashboardStatsContainer = ({
  stats
}) => {
  return (
    <div className='stats-container'>
      {stats.map((stat, i)=>{
        return <DashboardStat
          key={i}
          loading={stat.loading}
          title={stat.title}
          value={stat.value}
         />
      })}
    </div>
  )
}

export { DashboardStat, DashboardStatsContainer };
