import TriggerCreateActions from '../actions/triggers-create';
const Methods = ({ dispatch }) => {
    return {
      updateSuccess: data => dispatch({ type: TriggerCreateActions.UPDATE_SUCCESS, data}),
      updateSelectedStep: data => dispatch({ type: TriggerCreateActions.UPDATE_SELECTED_STEP, data }),
      updateStepSet: data => dispatch({ type: TriggerCreateActions.UPDATE_STEP_SET, data }),
      updateConditionNext: data => dispatch({ type: TriggerCreateActions.UPDATE_CONDITION_NEXT, data}),
      setNoTables: data => dispatch({ type: TriggerCreateActions.TRIGGER_NO_TABLES, data }),
      setSubmitting: data => dispatch({ type: TriggerCreateActions.UPDATE_CONDITION_SUBMITTING, data }),
      updateMappingError: data => dispatch({ type: TriggerCreateActions.UPDATE_MAPPING_ERROR, data }),
      formError: data => dispatch({ type: TriggerCreateActions.ERROR, data }),
      infoChange: data => dispatch({ type: TriggerCreateActions.UPDATE_INFO, data }),
      save: () => dispatch({ type: TriggerCreateActions.SAVE_ACTION }),
      updateTable: data => dispatch({ type: TriggerCreateActions.UPDATE_ACTION_TABLE, data }),
      methodChange: data => dispatch({ type: TriggerCreateActions.UPDATE_ACTION_METHOD, data }),
      mappingValueChange: data =>
        dispatch({ type: TriggerCreateActions.UPDATE_ACTION_MAPPING_VALUE, data }),
      enableEdit: () => dispatch({ type: TriggerCreateActions.EDIT_ACTION }),
      cancelEdit: () => dispatch({ type: TriggerCreateActions.CANCEL_EDIT_ACTION }),
      addCondition: data => dispatch({ type: TriggerCreateActions.ADD_CONDITION, data }),
      removeCondition: index => dispatch({ type: TriggerCreateActions.REMOVE_CONDITION, index }),
      setTables: data => dispatch({ type: TriggerCreateActions.SET_TABLES, data }),
      reset: () => dispatch({ type: TriggerCreateActions.RESET_TRIGGER }),
      conditionDescriptionChange: data =>
        dispatch({ type: TriggerCreateActions.UPDATE_CONDITION_DESC, data }),
      conditionBaseChange: data =>
        dispatch({ type: TriggerCreateActions.UPDATE_CONDITION_BASE, data }),
      conditionOperatorChange: data =>
        dispatch({ type: TriggerCreateActions.UPDATE_CONDITION_OPERATOER, data }),
      conditionTargetChange: data =>
        dispatch({ type: TriggerCreateActions.UPDATE_CONDITION_TARGET, data }),
      conditionTargetTableChange: data =>
        dispatch({ type: TriggerCreateActions.UPDATE_CONDITION_TARGET_TABLE, data }),
      priceEnabledChange: data =>
        dispatch({ type: TriggerCreateActions.UPDATE_PRICE_ENABLED, data }),
      priceSourceTypeChange: data =>
        dispatch({ type: TriggerCreateActions.UPDATE_PRICE_SOURCE_TYPE, data }),
      priceSourceValueChange: data =>
        dispatch({ type: TriggerCreateActions.UPDATE_PRICE_SOURCE_VALUE, data }),
      priceDestinationTypeChange: data =>
        dispatch({ type: TriggerCreateActions.UPDATE_PRICE_DESTINATION_TYPE, data }),
      priceDestinationValueChange: data =>
        dispatch({ type: TriggerCreateActions.UPDATE_PRICE_DESTINATION_VALUE, data }),
    }
}

export default Methods;
