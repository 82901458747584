import React, { Component } from "react";
import { connect } from "react-redux";
import SectionContainer from "./section-container";
import { Button } from "react-bootstrap";
import { FormatDate } from "../../../helpers/Date";
import {
  SubscriptionPicker,
  DowngradeSubscriptionWarningModal,
  CancelSubscriptionWarningModal,
} from "../../../components/Subscription";
import {
  OrderSummary,
  BillingSummary,
  BillingModal,
  CheckoutCompleteModal,
} from "../../../components/Billing";
import { Billing } from "./";
import AtraAPI from "../../../apiv2";
import AccountMethods from "../methods";

class ManageSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ux: {
        loadingBilling: true,
        loadingPlans: true,
        submittingCancel: false,
        showDowngradeModal: false,
        showBillingModal: false,
        submittingCheckout: false,
        showCheckoutCompleteModal: false,
      },
      selectedPlan: this.props.account.user.subscription,
      plans: [],
      billing: {},
    };
  }

  start = () => {
    this.getPlans();
    this.getBilling();
  };

  componentDidMount = () => {
    this.start();
  };

  //API Methods
  getBilling = () => {
    AtraAPI.GetBilling()
      .then((res) => {
        this.setState({
          billing: res,
          ux: { ...this.state.ux, loadingBilling: false },
        });
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPlans = () => {
    AtraAPI.GetPlans()
      .then((res) => {
        this.setState({
          plans: res.plans,
          ux: { ...this.state.ux, loadingPlans: false },
        });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  checkout = ({ paymentMethod = null, orderPlanId }) => {
    if (!orderPlanId) {
      console.log("orderPlanId-requried");
      return;
    }
    const checkoutData = {
      paymentMethodId: paymentMethod && paymentMethod.id,
      orderPlanId: orderPlanId,
    };

    this.setState({
      ux: { ...this.state.ux, submittingCheckout: true },
    });

    AtraAPI.Checkout({ checkoutData })
      .then((res) => {
        this.setState({
          ux: {
            ...this.state.ux,
            showBillingModal: false,
            submittingCheckout: false,
            showCheckoutCompleteModal: true,
          },
        });

        //Subscribe Event
        const { price, transaction_id, orderPlanId, action } = res.data;
        if (price > 0) {
          //If the customer is not downgrading log as a purchase
          window.dataLayer.push({
            event: "subscribe",
            value: price,
            transaction_id,
            items: [
              {
                item_id: orderPlanId,
              },
            ],
          });
        }

        AtraAPI.GetUser().then((user) => {
          //update user
          this.props.updateUser({ value: user });
          this.props.updateLoadingUser({ value: false });
        });

        // console.log("Plan successfully updated");
        // window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Event Handlers
  handlePlanSelect = ({ orderPlanId }) => {
    console.log(orderPlanId, this.state.plans);
    const plan = this.state.plans.find((p) => p.orderPlanId == orderPlanId);
    this.setState({
      selectedPlan: plan,
    });
  };

  handlePaymentMethodUpdate = ({ paymentMethod }) => {
    console.log("new payment method", paymentMethod);
    AtraAPI.UpdateBilling({ paymentMethodId: paymentMethod.id })
      .then((res) => {
        // window.location.reload();
      })
      .catch((err) => {
        this.setState({
          ux: { ...this.state.ux, submittingCancel: false },
        });
        console.log(err);
      });
  };

  handleBillingModalSubmit = ({ paymentMethod }) => {
    this.checkout({
      paymentMethod,
      orderPlanId: this.state.selectedPlan.orderPlanId,
    });
  };

  handleDowngradeSubmit = () => {
    this.checkout({
      paymentMethod: null,
      orderPlanId: this.state.selectedPlan.orderPlanId,
    });
  };

  handleCheckout = ({ orderPlanId }) => {
    const plan = this.state.plans.find((p) => p.orderPlanId == orderPlanId);
    const pendingDowngradeOrderPlanId = this.props.account.user.subscription
      .pendingDowngradeOrderPlanId;

    if (this.props.account.user.subscription.tier > plan.tier) {
      console.log("show downgrade modal");
      this.setState({
        ux: { ...this.state.ux, showDowngradeModal: true },
      });
    } else if (
      this.props.account.user.subscription.tier < plan.tier ||
      (pendingDowngradeOrderPlanId &&
        this.state.selectedPlan.orderPlanId ==
          this.props.account.user.subscription.orderPlanId)
    ) {
      //if use has card on file process order
      if (this.state.billing.card) {
        this.checkout({ orderPlanId: this.state.selectedPlan.orderPlanId });
      } else {
        //if no card open modal
        this.setState({
          ux: { ...this.state.ux, showBillingModal: true },
        });
      }
    } else {
      //
      console.log("no-change-in-plan");
      return;
    }
  };

  handleCloseAllModals = () => {
    this.setState({
      ux: {
        ...this.state.ux,
        showDowngradeModal: false,
        showCancelSubscriptionModal: false,
        showBillingModal: false,
        showCheckoutCompleteModal: false,
      },
    });
  };

  handleCloseCheckoutCompleteModal = () => {
    this.handleCloseAllModals();
    //Ger the user again
    this.start();
  };

  render() {
    if (this.state.ux.loadingPlans || this.state.ux.loadingBilling) {
      return <div>Loading Subscription</div>;
    }

    const isCurrentPlan =
      this.props.account.user.subscription.orderPlanId ==
      this.state.selectedPlan.orderPlanId;

    console.log("isCurrentPlan", isCurrentPlan);

    const isScheduledPlan =
      this.props.account.user.subscription.pendingDowngradeOrderPlanId ==
      this.state.selectedPlan.orderPlanId;

    console.log("isScheduledPlan", isScheduledPlan);

    const isUpgrade =
      this.props.account.user.subscription.tier < this.state.selectedPlan.tier;

    console.log("isUpgrade", isUpgrade);

    const isDowngrade =
      !isScheduledPlan &&
      this.props.account.user.subscription.tier > this.state.selectedPlan.tier;

    console.log("isDowngrade", isDowngrade);

    const hasCardOnFile = this.state.billing.card ? true : false;

    console.log("hasCardOnFile", hasCardOnFile);

    const isReactivate = this.props.account.user.subscription
      .pendingDowngradeOrderPlanId
      ? true
      : false &&
        this.state.selectedPlan.orderPlanId ==
          this.props.account.user.subscription.orderPlanId;

    console.log("isReactivate", isReactivate);

    let submitEnabled = true;
    let submitText = "Upgrade Now";
    let helperText = "";
    if (this.state.billing.card) {
      helperText =
        "Your card on file will be charged a prorated amount for the upgrade immediately";
    }
    //enable checkout button for downgraded plans so user can go back
    if (
      this.props.account.user.subscription.pendingDowngradeOrderPlanId ==
      this.state.selectedPlan.orderPlanId
    ) {
      submitEnabled = false;
      submitText = "Current Plan";
      helperText = "Select another plan to update your subscription level";
    } else if (
      this.props.account.user.subscription.pendingDowngradeOrderPlanId &&
      this.state.selectedPlan.orderPlanId ==
        this.props.account.user.subscription.orderPlanId
    ) {
      submitEnabled = true;
      submitText = "Reactivate";
      helperText = "Cancel scheduled changes to plan immediately";
    } else if (
      this.state.selectedPlan.orderPlanId ==
      this.props.account.user.subscription.orderPlanId
    ) {
      submitText = "Current Plan";
      submitEnabled = false;
      helperText = "Select another plan to update your subscription level";
    } else {
      submitText = "Switch Plan";
      if (isDowngrade) {
        helperText = `Your plan will be switched at the end of the current billing cycle ${FormatDate(
          this.state.billing.upcomingInvoice.next_payment_attempt * 1000
        )}`;
      } else {
        submitText = "Upgrade Now";
      }
    }

    return (
      <div className="manage-subscription-container">
        <DowngradeSubscriptionWarningModal
          show={this.state.ux.showDowngradeModal}
          handleCancel={this.handleCloseAllModals}
          handleConfirm={this.handleDowngradeSubmit}
          isLoading={this.state.ux.submittingCheckout}
        />
        <BillingModal
          show={this.state.ux.showBillingModal}
          handleCancel={this.handleCloseAllModals}
          handleSubmit={this.handleBillingModalSubmit}
          isLoading={this.state.ux.submittingCheckout}
        />
        <CheckoutCompleteModal
          show={this.state.ux.showCheckoutCompleteModal}
          handleClose={this.handleCloseCheckoutCompleteModal}
        />
        <div className="manage-subscription-container-content">
          <SubscriptionPicker
            subscription={this.props.account.user.subscription}
            plans={this.state.plans}
            handlePlanSelect={this.handlePlanSelect}
          />
          <OrderSummary
            plan={this.state.selectedPlan}
            submitEnabled={submitEnabled}
            submitText={submitText}
            handleSubmit={this.handleCheckout}
            isLoading={this.state.ux.submittingCheckout}
            helperText={helperText}
          />
        </div>

        <BillingSummary
          billing={this.state.billing}
          handlePaymentMethodUpdate={this.handlePaymentMethodUpdate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...AccountMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSubscription);
