import React, { Component } from "react";
import { Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import AtraAPI from "../../../apiv2";
import Methods from "../methods/view";
import EnvMethods from "../../../components/Environments/methods";

import { Tabs } from "../components/Tabs";

import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { WalletDetails } from "../../../components/ServiceView";

import LoaderButton from "../../../components/Buttons/LoaderButton";

import { ConvertWeiToEth, ConvertEthToWei } from "../../../helpers/Eth";

import QRCode from "qrcode.react";

class LiteWalletView extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    this.props.updateEnvironmentsEnabled({ enabled: false });
  }

  componentDidMount = () => {
    this.start();
  };

  componentWillUnmount = () => {
    this._mounted = false;
    this.props.resetState();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.start();
    }
  }

  start = () => {
    this._mounted = true;
    this.props.resetState();

    //const tableId = this.props.match.params[0];
    const liteWalletId = this.props.match.params.id;
    this.props.updateLiteWalletId({ value: liteWalletId });
    AtraAPI.GetLiteWallet(liteWalletId).then(
      (liteWallet) => {
        liteWallet.initBalance = ConvertWeiToEth(
          liteWallet.amountPerWallet * liteWallet.numberOfWallets
        );

        liteWallet.amountPerWallet = ConvertWeiToEth(
          liteWallet.amountPerWallet
        );

        this.props.updateLiteWallet({ value: liteWallet });
        this.props.updateLoadingTab({ value: false });
        this.props.updateLoadingLiteWallet({ value: false });
      },
      (err) => {
        this.props.updateLoadingLiteWallet({ value: false });
        console.log(err);
      }
    );
  };

  handleBack = (event) => {
    this.props.history.push("/");
  };

  render() {
    const breadcrumbs = [];
    breadcrumbs.push({ text: "aWallet", to: "/awallet", icon: "litewallet" });
    breadcrumbs.push({
      text: this.props.view.liteWallet.name,
      to: `/awallet/view/${this.props.view.liteWallet.id}`,
    });

    return (
      <div className="dtables-view-container container">
        <BreadCrumbs links={breadcrumbs} />

        <WalletDetails
          loading={this.props.view.loading.liteWallet}
          instance={this.props.view.liteWallet}
          liteui={this.props.view.liteui}
        />

        <Tabs />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    view: state.liteWalletView,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...Methods({ dispatch }),
    ...EnvMethods({ dispatch }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiteWalletView)
);
