import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from "react-bootstrap";
import { connect } from "react-redux";

import Methods from '../methods/view';
import AtraAPI from '../../../apiv2';
import NotificationMethods from "../../../components/Notifications/methods";

import { IsAddress, CheckAddressChecksum } from '../../../helpers/Eth';

import LoaderButton from "../../../components/Buttons/LoaderButton";
import AddressInput from '../../../components/AddressInput/AddressInput';

class AddPermissionForm extends Component {

  componentWillUnmount = () => {
      this.props.updateShowNewPermissionForm({ value: false });
  }

  handleAddressChange = event => {
    this.props.updateNewPermissionFormAddress({ value: event.target.value });
    this.validateAddress(event.target.value);
  };

  handleSubmitPermission = () => {
    const tableId = this.props.dTablesView.table.id;
    const newAddress = this.props.dTablesView.newPermission.address;
    this.props.updateNewPermissionFormSubmitting({ value: true });

    AtraAPI.AddPermission(tableId, newAddress).then(res=>{
      this.props.updateNewPermissionFormSubmitting({ value: false });
      this.props.notificationsAddPopupMessage({
        message: 'Success! - Permission Saved',
        remove: (id)=>{this.props.notificationsRemovePopupMessage({ value: id })}
      });
      //refetch permissions
      this.props.onSubmit().then(()=>{
        this.handleCancelForm();
      });
    },err=>{

    })
  }

  handleCancelForm = () => {
    this.props.updateNewPermissionFormError({ Value: '' });
    this.props.updateNewPermissionFormAddress({ value: '' });
    this.props.updateShowNewPermissionForm({ value: false });
  };

  validateAddress = (address) => {
    if(!address.length){
      this.props.updateNewPermissionFormValidationMessage({ value: '' });
    }else if(!IsAddress(address)){
      this.props.updateNewPermissionFormValidationMessage({ value: 'Address Not Valid' });
    }else if(!CheckAddressChecksum(address)){
      this.props.updateNewPermissionFormValidationMessage({ value: 'Address Checksum Not Valid'});
    }else{
      this.props.updateNewPermissionFormValidationMessage({ value: '' });
    }

  }


  render() {
    return (
      <div className="new-permission-form-container">
        <form>
          <h3>Grant Address Permission to Modify this dTable</h3>
          <FormGroup
            controlId="newUserAddress"
            validationState={this.props.dTablesView.newPermission.validationMessage ? 'error' : null }
          >
            <ControlLabel>New Address</ControlLabel>
            <AddressInput
              value={this.props.dTablesView.newPermission.address}
              onChange={this.handleAddressChange}
               />
            <HelpBlock>
              {this.props.dTablesView.newPermission.validationMessage
                ? this.props.dTablesView.newPermission.validationMessage
                : ""}
            </HelpBlock>
          </FormGroup>
          <div className='form-actions'>
            <Link to='#' onClick={this.handleCancelForm}>
              Cancel
            </Link>
            <LoaderButton
              isLoading={this.props.dTablesView.newPermission.submitting}
              loadingText={'Saving'}
              disabled={
                this.props.dTablesView.newPermission.validationMessage
                || !this.props.dTablesView.newPermission.address ? true : false }
              className='secondary-button'
              onClick={this.handleSubmitPermission}
              text={'Grant Permission'}
            />
          </div>

        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dTablesView: state.dTablesView
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...Methods({dispatch}),
    ...NotificationMethods({dispatch})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPermissionForm);
