import React from 'react';
import {
  OverlayTrigger,
  Tooltip,
  Glyphicon
} from "react-bootstrap";
import Icon from './tooltip.svg';

const ToolTip = ({
  id,
  tip
})=>{
  return (
    <div className='atra-tooltip'>
      <OverlayTrigger
         rootClose
         trigger={['click','hover','focus']}
         placement={'bottom'}
         overlay={
           <Tooltip id={`tooltip-bottom`}>
             {tip}
           </Tooltip>
         }
       >
        <img src={Icon} />
       </OverlayTrigger>
    </div>
  )
}

const ToolTipContainer = ({
  tooltip,
  children
}) => {
  return (
    <div className='section-title atra-tooltip-container'>
      {children}
      <ToolTip
        tip={tooltip}
      />
    </div>
  )

}


export default ToolTip;
export { ToolTipContainer };
