import Amplify, { Auth } from "aws-amplify";

const config = {
  s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://api.atra.io/prod/",
    dtables: "https://api.atra.io/prod/dtables/",
    dtablesRecords: "https://api.atra.io/prod/dtables/records/",
    dtablesPermissions: "https://api.atra.io/prod/dtables/permissions/",
    api: "https://api.atra.io/prod/api/",
    triggers: "https://api.atra.io/prod/triggers/",
    contracts: "https://api.atra.io/prod/contracts/",
    liteui: "https://api.atra.io/prod/liteui/",
    liteuiNotification: "https://api.atra.io/prod/liteui/notification/",
    user: "https://api.atra.io/prod/user",
    userSubscription: "https://api.atra.io/prod/user/subscription/",
    servicesArchive: "https://api.atra.io/prod/services/archive/",
    liteWallet: "https://api.atra.io/prod/litewallet/",
    liteWalletPublic: "https://api.atra.io/prod/litewallet/public/",
    liteWalletPublicActivate:
      "https://api.atra.io/prod/litewallet/public/activate/",
    environments: "https://api.atra.io/prod/environments/",
    billingSubscription: "https://api.atra.io/prod/billing/subscription/",
    billing: "https://api.atra.io/prod/billing/",
    billingCheckout: "https://api.atra.io/prod/billing/checkout",
    subscriptionPlans: "https://api.atra.io/prod/subscription/plans/",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_Ki295cHAx",
    APP_CLIENT_ID: "6c9gkejsr7580f3if0se6o4fci",
    IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID",
  },
};

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "user",
        endpoint: config.apiGateway.user,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
            AccessToken: session.accessToken.jwtToken,
          };
        },
      },
      {
        name: "user/subscription",
        endpoint: config.apiGateway.userSubscription,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
            // AccessToken: session.accessToken.jwtToken
          };
        },
      },
      {
        name: "dtables/tables",
        endpoint: config.apiGateway.dtables,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
            AccessToken: session.accessToken.jwtToken,
          };
        },
      },
      {
        name: "dtables/records",
        endpoint: config.apiGateway.dtablesRecords,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
            AccessToken: session.accessToken.jwtToken,
          };
        },
      },
      {
        name: "dtables/permissions",
        endpoint: config.apiGateway.dtablesPermissions,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
            AccessToken: session.accessToken.jwtToken,
          };
        },
      },
      {
        name: "api",
        endpoint: config.apiGateway.api,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
            AccessToken: session.accessToken.jwtToken,
          };
        },
      },
      {
        name: "triggers",
        endpoint: config.apiGateway.triggers,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
            AccessToken: session.accessToken.jwtToken,
          };
        },
      },
      {
        name: "contracts",
        endpoint: config.apiGateway.contracts,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
            AccessToken: session.accessToken.jwtToken,
          };
        },
      },
      {
        name: "liteui",
        endpoint: config.apiGateway.liteui,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
            AccessToken: session.accessToken.jwtToken,
          };
        },
      },
      {
        name: "liteui/notification",
        endpoint: config.apiGateway.liteuiNotification,
        region: config.apiGateway.REGION,
        // custom_header: async () => {
        //   let session = await Auth.currentSession();
        //   return {
        //     Authorization: session.idToken.jwtToken,
        //     AccessToken: session.accessToken.jwtToken
        //   }
        // }
      },
      {
        name: "services/archive",
        endpoint: config.apiGateway.servicesArchive,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
            AccessToken: session.accessToken.jwtToken,
          };
        },
      },
      {
        name: "litewallet",
        endpoint: config.apiGateway.liteWallet,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
            AccessToken: session.accessToken.jwtToken,
          };
        },
      },
      {
        name: "litewallet/public",
        endpoint: config.apiGateway.liteWalletPublic,
        region: config.apiGateway.REGION,
        // custom_header: async () => {
        //   let session = await Auth.currentSession();
        //   return {
        //     Authorization: session.idToken.jwtToken,
        //     AccessToken: session.accessToken.jwtToken
        //   }
        // }
      },
      {
        name: "litewallet/public/activate",
        endpoint: config.apiGateway.liteWalletPublicActivate,
        region: config.apiGateway.REGION,
        // custom_header: async () => {
        //   let session = await Auth.currentSession();
        //   return {
        //     Authorization: session.idToken.jwtToken,
        //     AccessToken: session.accessToken.jwtToken
        //   }
        // }
      },
      {
        name: "environments",
        endpoint: config.apiGateway.environments,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
            AccessToken: session.accessToken.jwtToken,
          };
        },
      },
      {
        name: "billing/subscription",
        endpoint: config.apiGateway.billingSubscription,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "billing",
        endpoint: config.apiGateway.billing,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "billing/checkout",
        endpoint: config.apiGateway.billingCheckout,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "subscription/plans",
        endpoint: config.apiGateway.subscriptionPlans,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
    ],
  },
});
