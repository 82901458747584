import React from 'react';
import { Glyphicon } from "react-bootstrap";

const LoadingTab = ({
  title
}) => {
  return (
    <div className="loading-tab-container">
      <Glyphicon glyph="refresh" className="spinning" /> Loading {title}
    </div>
  )
}

export default LoadingTab;
