import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const LoggedInBox = ({ handleAction }) => {
  return (
    <div className="signup-box-container">
      <div className="box-header">The beta is FREE. Start building now.</div>
      <div className="signup-container">
        <Button onClick={handleAction} className="secondary-button">
          Getting Started Guide
        </Button>
      </div>
      <div className="login-container">
        <Link to="/doc/api">Or explore our API</Link>
      </div>
      <div className="foot-note-container">
        The Atra Beta is currently live on the Goerli test network. All
        transactions are free and no payment info required to use the beta.
      </div>
    </div>
  );
};

export default LoggedInBox;
