import React from 'react';
import LoaderButton from '../../../../components/Buttons/LoaderButton';
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

const Submit = ({
  text,
  loadingText,
  onClick,
  isLoading,
  disabled,
  cancelPath,
  handlePrev
}) => {
  return (
    <div className="submit-buttons">
      <Link to={cancelPath}>Cancel</Link>
      <Button
        className='black-button'
        onClick={handlePrev}
      >
        Previous
      </Button>
      <LoaderButton
          className='secondary-button'
          disabled={disabled}
          onClick={onClick}
          isLoading={isLoading}
          text={text}
          loadingText={loadingText}
        />
    </div>
  )
}

export default Submit;
