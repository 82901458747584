import React from 'react';
import { Button } from 'react-bootstrap';

const EditPageActionHeader = ({
  handlers,
  handleAddElement,
  selectedView,
  isDetailView
}) => {
  if(selectedView === false){
    return null;
  }
  return (
    <div className='view-bread-crumb-container'>
      <div className='crumbs'>

      </div>
      <div className='actions'>
        {isDetailView ? null
        :<Button className='white-button' id={selectedView} onClick={()=>handlers.ShowEditModal(selectedView)}>Edit Page</Button>
        }
        <Button className='black-button' onClick={handleAddElement}>Add UI Element</Button>
      </div>
    </div>
  )

}

export default EditPageActionHeader;
