import { CreateWeb3Object } from '../../../../components/Web3';

const GetWeb3Instance = (liteWallet, _ethereum) => {
  if(liteWallet.enabled && !liteWallet.disabled){
    const web3 = CreateWeb3Object();
    const account = web3.eth.accounts.privateKeyToAccount(liteWallet.pk);
    web3.eth.accounts.wallet.add(account);
    web3.eth.defaultAccount = account.address;
    return web3;
  }else{
   return new CreateWeb3Object(_ethereum);
  }
}

export { GetWeb3Instance }
