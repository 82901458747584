import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { LiteElementHeader } from "./index";
import { GetWeb3Instance } from "../Eth";
import PriceInput from "../../../../components/PriceInput/PriceInput";
import DateInput from "../../../../components/DateInput/DateInput";
import AddressInput from "../../../../components/AddressInput/AddressInput";
import {
  ConvertWeiToEth,
  ConvertEthToWei,
  IsAddress,
  ToHex,
  IsBigNumber,
  FromBigNumber,
} from "../../../../helpers/Eth";
import { GetRecordById } from "../../../../sdk/dtables";
import { UpdateBalance } from "../LiteWallet";
import UIModals from "./";

import LiteUIMethods from "../../methods";
import NotificationMethods from "../../../../components/Notifications/methods";

import AtraAPI from "../../../../apiv2";

const RenderPaymentInput = ({ payment, value, onChange }) => {
  if (payment.enabled) {
    switch (payment.source.type) {
      case "static":
        return (
          <div>
            <label>Cost: {ConvertWeiToEth(payment.source.value)} ETH</label>
          </div>
        );
        break;
      case "dtable":
        return (
          <div>
            {!value ? (
              <label>Cost: Loading...</label>
            ) : (
              <label>Cost: {value}</label>
            )}
          </div>
        );
      case "unlimited":
        return (
          <div>
            <label>Cost: Unlimited</label>
            <RenderInputField
              value={value}
              handleChange={onChange}
              type={"price"}
            />
          </div>
        );
        break;
      case "range":
        return (
          <div>
            <label>
              Cost: Range between {ConvertWeiToEth(payment.source.value.min)} -{" "}
              {ConvertWeiToEth(payment.source.value.max)} ETH
            </label>
            <RenderInputField
              value={value}
              handleChange={onChange}
              type={"price"}
            />
          </div>
        );
        break;
    }
  }
  return (
    <div>
      <label>Cost: Free</label>
    </div>
  );
};

const RenderInputField = ({ type, value, index, handleChange }) => {
  const childProps = {
    "data-index": index,
    "data-valuetype": type,
  };

  let dataSets = [];
  dataSets["valueType"] = type;
  dataSets["index"] = index;

  switch (type) {
    case "price":
      return (
        <div className="input-price-wrapper">
          <PriceInput
            value={value}
            onChange={handleChange}
            childProps={childProps}
          />
        </div>
      );
      break;
    case "date":
      return (
        <div className="input-date-wrapper">
          <DateInput
            datasets={dataSets}
            value={value}
            onChange={handleChange}
          />
        </div>
      );
      break;
    case "address":
      return (
        <div className="input-address-wrapper">
          <AddressInput
            childProps={childProps}
            value={value}
            onChange={handleChange}
            datasets={dataSets}
          />
        </div>
      );
      break;
    default:
      return (
        <div className="input-text-wrapper">
          <FormControl
            type="text"
            value={value}
            data-valuetype={type}
            data-index={index}
            placeholder={type}
            onChange={handleChange}
          />
        </div>
      );
      break;
  }
};

const RenderElement = ({
  trigger,
  invoke,
  handleRecordAddressChange,
  handleInputChange,
  handlePaymentChange,
}) => {
  return (
    <div>
      {invoke.inputs.map((input, i) => {
        if (input.input) {
          return (
            <div className="form-input-container" key={i}>
              <label>{input.name}</label>
              <RenderInputField
                value={input.value}
                index={i}
                handleChange={(event) =>
                  handleInputChange(i, event.target.value)
                }
                type={input.type}
              />
            </div>
          );
        }
      })}

      <RenderPaymentInput
        payment={trigger.input.payment}
        value={invoke.eth}
        onChange={handlePaymentChange}
      />
    </div>
  );
};

class InvokeTrigger extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    this.state = {
      show: true,
      table: this.props.table,
      trigger: this.props.trigger,
      action: this.props.action,
      recordAddress: this.props.recordAddress,
      record: this.props.record,
      isSubmitting: false,
      invoke: {
        eth: 0,
        index: this.props.recordAddress,
        inputs: [],
        isSubmitting: false,
        metamask: {
          loading: true,
          error: false,
          code: null,
        },
      },
    };

    this.setTriggerPaymentState(this.state.trigger, (cost) => {
      this.state.invoke.eth = cost;
    });

    this.state.invoke.inputs = this.state.trigger.input.action.mappings
      ? this.state.trigger.input.action.mappings.map((input, i) => {
          let newInput = {
            input: input.value === "input",
            value: this.state.record.record[i],
            type: input.valueType,
            name: input.name,
            index: i,
          };

          if (!input.input) {
            switch (input.valueType) {
              case "date":
                newInput.value = new Date();
                break;
              case "price":
                newInput.value = 0;
                break;
              case "number":
                  newInput.value = 0;
                  break;
              case "address":
                newInput.value = newInput.input
                  ? ""
                  : "0x0000000000000000000000000000000000000000";
                break;
              case "pointer":
                newInput.value = newInput.input
                  ? ""
                  : "0x0000000000000000000000000000000000000000";
                break;
            }
          }

          return newInput;
        })
      : [];

    //check if we need to update the price field
    if (
      this.state.trigger.input.payment.enabled &&
      this.state.trigger.input.payment.source.type === "dtable"
    ) {
      const tableId = this.state.trigger.input.action.tableId;
      const column = this.state.trigger.input.payment.source.value;

      //get table ABI and make request to the table to get the price of record
      //if valid record address
      if (IsAddress(this.state.recordAddress)) {
        GetRecordById(this.state.recordAddress, this.state.table).then(
          (res) => {
            const price = ConvertWeiToEth(res.record[`A_${column}`]);
            this.setState({ invoke: { ...this.state.invoke, eth: price } });
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
  }

  componentDidMount = () => {
    this._mounted = true;
    this.setState({ show: this.props.show });
  };

  async componentWillUnmount() {
    this._mounted = false;
    this.setState({ show: false });
  }

  sendNotification = async (liteuiId, triggerId, txHash) => {
    try {
      const input = {
        liteuiId,
        triggerId,
        txHash,
      };
      const res = await AtraAPI.SendNotification({ input });
    } catch (err) {
      console.log(err);
    }
  };

  setTriggerPaymentState = (trigger, updateCost) => {
    if (trigger.input.payment.enabled) {
      switch (trigger.input.payment.source.type) {
        case "static":
          updateCost(trigger.input.payment.source.value);
          break;
        case "unlimited":
          break;
        case "range":
          break;
        case "dtable":
          break;
      }
    }
  };

  handlePaymentChange = (event) => {
    if (event.target.value) {
      this.setState({
        invoke: { ...this.state.invoke, eth: event.target.value },
      });
    } else {
      this.setState({ invoke: { ...this.state.invoke, eth: 0 } });
    }
  };

  handleInputChange = (index, value) => {
    let inputs = this.state.invoke.inputs;
    inputs[index].value = value;
    this.setState({ inputs: inputs });
  };

  handleClose = () => {
    this.setState({ show: false });
    this.props.hide();
  };

  invokeWithMeataMask = async (event) => {
    const readOnlyMode = this.props.metamask.status.code !== 200;

    if (readOnlyMode) {
      console.log("read only mode");
      this.props.updateShowReadOnlyWarning({ value: true });
      return;
    }

    const web3React = GetWeb3Instance(
      this.props.liteui.liteWallet,
      window.ethereum
    );

    const trigger = this.state.trigger;
    const invoke = this.state.invoke;
    const recordAddress = this.state.recordAddress;

    this.setState({ isSubmitting: true });

    let account = "";

    if (
      this.props.liteui.liteWallet.enabled &&
      !this.props.liteui.liteWallet.disabled
    ) {
      account = this.props.liteui.liteWallet.address;
    } else {
      const res = await web3React.eth.getAccounts();
      account = res[0];
    }

    let contractInstance = new web3React.eth.Contract(
      JSON.parse(trigger.abi),
      trigger.goerli
    );

    let sendData = {
      from: account,
    };

    //format inputs
    const inputs = invoke.inputs.map((input, i) => {
      switch (input.type) {
        case "date":
          return input.value.getTime();
        case "price":
          return ConvertEthToWei(input.value);
        default:
          return input.value;
      }
    });

    //if trigger uses value
    const payment = trigger.input.payment;
    if (payment.enabled) {
      switch (payment.source.type) {
        case "static": {
          const value = ToHex(payment.source.value);
          sendData.value = value;
          break;
        }
        case "range": {
          const value = ToHex(ConvertEthToWei(invoke.eth));
          sendData.value = value;
          break;
        }
        case "unlimited": {
          const value = ToHex(ConvertEthToWei(invoke.eth));
          sendData.value = value;
          break;
        }
        case "dtable": {
          const value = ToHex(ConvertEthToWei(invoke.eth));
          sendData.value = value;
          break;
        }
      }
    }

    //make request to contract
    sendData = {
      ...sendData,
      // gasPrice: "3000000000", //price per unit in Wei
    };

    if (inputs.length) {
      sendData = {
        ...sendData,
        // gas: await contractInstance.methods
        //   .invoke(recordAddress, [...inputs])
        //   .estimateGas(sendData),
      };
    } else {
      sendData = {
        ...sendData,
        // gas: await contractInstance.methods
        //   .invoke(recordAddress)
        //   .estimateGas(sendData),
      };
    }

    try {
      if (trigger.input.action.method === "update") {
        let invokeWithIndex = contractInstance.methods
          .invoke(recordAddress, [...inputs])
          .send(sendData)
          .on("transactionHash", (hash) => {
            this.setState({ isSubmitting: false });
            this.props.notificationsAddPopupMessage({
              message: "Success! - Transaction Sent",
              remove: (id) => {
                this.props.notificationsRemovePopupMessage({ value: id });
              },
            });
            //Add to pending list
            this.props.addPendingTransaction({
              txHash: hash,
              contractAddress: trigger.goerli,
              date: new Date(),
              data: `${this.state.action.title}`,
              gas: 0,
              complete: false,
            });
            this.handleClose();
            //Send out notification if public mode
            if (this.props.liteui.mode === "public") {
              this.sendNotification(this.props.liteui.id, trigger.id, hash);
            }
          })
          .on("confirmation", (confirmationNumber, receipt) => {
            if (confirmationNumber === 1) {
              //Remove from pending list
              this.props.removePendingTransaction({
                txHash: receipt.transactionHash,
              });
              UpdateBalance(
                this.props.liteui.liteWallet,
                this.props.updateLiteWallet
              );

              //Popup notification
              this.props.notificationsAddPopupMessage({
                message: "Success! - Transaction Confirmed",
                remove: (id) => {
                  this.props.notificationsRemovePopupMessage({ value: id });
                },
              });
            }
          })
          .on("receipt", (receipt) => {})
          .on("error", (err) => {
            console.log(err);
            this.setState({ isSubmitting: false });
            this.props.notificationsAddPopupMessage({
              type: "warning",
              message: "Failure! - Transaction Failed",
              remove: (id) => {
                this.props.notificationsRemovePopupMessage({ value: id });
              },
            });
          });
      } else if (trigger.input.action.method === "delete") {
        let invokeWithIndex = contractInstance.methods
          .invoke(recordAddress)
          .send(sendData)
          .on("transactionHash", (hash) => {
            this.setState({ isSubmitting: false });
            this.props.notificationsAddPopupMessage({
              message: "Success! - Transaction Sent",
              remove: (id) => {
                this.props.notificationsRemovePopupMessage({ value: id });
              },
            });
            //Add to pending list
            this.props.addPendingTransaction({
              txHash: hash,
              contractAddress: trigger.goerli,
              date: new Date(),
              data: `${this.state.action.title}`,
              gas: 0,
              complete: false,
            });
            this.handleClose();
            //Send out notification if public mode
            if (this.props.liteui.mode === "public") {
              this.sendNotification(this.props.liteui.id, trigger.id, hash);
            }
          })
          .on("confirmation", (confirmationNumber, receipt) => {
            if (confirmationNumber === 1) {
              //Remove from pending list
              this.props.removePendingTransaction({
                txHash: receipt.transactionHash,
              });
              UpdateBalance(
                this.props.liteui.liteWallet,
                this.props.updateLiteWallet
              );

              //Popup notification
              this.props.notificationsAddPopupMessage({
                message: "Success! - Transaction Confirmed",
                remove: (id) => {
                  this.props.notificationsRemovePopupMessage({ value: id });
                },
              });
            }
          })
          .on("receipt", (receipt) => {})
          .on("error", (err) => {
            console.log(err);
            this.setState({ isSubmitting: false });
            this.props.notificationsAddPopupMessage({
              type: "warning",
              message: "Failure! - Transaction Failed",
              remove: (id) => {
                this.props.notificationsRemovePopupMessage({ value: id });
              },
            });
          });
      } else if (trigger.input.action.method === "insert") {
        let invoke = contractInstance.methods
          .invoke([...inputs])
          .send(sendData)
          .on("transactionHash", (hash) => {
            this.setState({ isSubmitting: false });
            this.props.notificationsAddPopupMessage({
              message: "Success! - Transaction Sent",
              remove: (id) => {
                this.props.notificationsRemovePopupMessage({ value: id });
              },
            });
            //Add to pending list
            this.props.addPendingTransaction({
              txHash: hash,
              contractAddress: trigger.goerli,
              date: new Date(),
              data: `${this.state.action.title}`,
              gas: 0,
              complete: false,
            });
            this.handleClose();
            //Send out notification if public mode
            if (this.props.liteui.mode === "public") {
              this.sendNotification(this.props.liteui.id, trigger.id, hash);
            }
          })
          .on("confirmation", (confirmationNumber, receipt) => {
            if (confirmationNumber === 1) {
              //Remove from pending list
              this.props.removePendingTransaction({
                txHash: receipt.transactionHash,
              });
              UpdateBalance(
                this.props.liteui.liteWallet,
                this.props.updateLiteWallet
              );

              //Popup notification
              this.props.notificationsAddPopupMessage({
                message: "Success! - Transaction Confirmed",
                remove: (id) => {
                  this.props.notificationsRemovePopupMessage({ value: id });
                },
              });
            }
          })
          .on("receipt", (receipt) => {})
          .on("error", (err) => {
            console.log(err);
            this.setState({ isSubmitting: false });
            this.props.notificationsAddPopupMessage({
              type: "warning",
              message: "Failure! - Transaction Failed",
              remove: (id) => {
                this.props.notificationsRemovePopupMessage({ value: id });
              },
            });
          });
      }
    } catch (err) {
      this.setState({ isSubmitting: false });
      this.props.notificationsAddPopupMessage({
        type: "warning",
        message: "Failure! - Transaction Failed",
        remove: (id) => {
          this.props.notificationsRemovePopupMessage({ value: id });
        },
      });
    }
  };

  render() {
    const action = this.state.action;

    const trigger = this.state.trigger;

    return (
      <UIModals.ModalContainer
        title={action.title}
        handleClose={this.handleClose}
        isLoading={this.state.isSubmitting}
        saveButtonText={action.title}
        handleSave={this.invokeWithMeataMask}
        show={this.state.show}
      >
        <div className="lite-form liteui-element">
          <RenderElement
            trigger={trigger}
            invoke={this.state.invoke}
            handleRecordAddressChange={this.handleRecordAddressChange}
            handleInputChange={this.handleInputChange}
            handlePaymentChange={this.handlePaymentChange}
          />
        </div>
      </UIModals.ModalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    liteui: state.liteui,
    notifications: state.notifications,
    metamask: state.metamask,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...LiteUIMethods({ dispatch }),
    ...NotificationMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvokeTrigger);
