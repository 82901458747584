import Actions from "../actions/create";

class DefaultState {
  constructor() {
    this.liteuis = [];
    this.loading = {
      liteuis: true,
      submitting: false,
    };
    this.submitError = "";
    this.ux = {
      inputs: [
        {
          input: "name",
          status: {
            valid: false,
            message: "",
          },
        },
        {
          input: "description",
          status: {
            valid: true,
            message: "",
          },
        },
        {
          input: "amountPerWallet",
          status: {
            valid: true,
            message: "",
          },
        },
      ],
    };
    this.options = [
      {
        type: "AccessPass",
        selected: true,
        disabled: false,
        amountPerWallet: 0.001,
        numberOfWallets: 1,
        wallets: [],
        actionType: "privateKey",
        liteui: "",
        name: "",
        description: "",
        tooltip: `AccessPass wallets allow you to generate, fund, and distribute bulk wallets. The generated wallets are great for events, dapp testing, and onboarding new users.`,
      },
      {
        type: "User - coming soon",
        selected: false,
        disabled: true,
      },
    ];
  }
}

export default (state = new DefaultState(), action) => {
  switch (action.type) {
    case Actions.UPDATE_LOADING:
      return {
        ...state,
        loading: action.data.loading,
      };
    case Actions.UPDATE_SUBMIT_ERROR:
      return {
        ...state,
        submitError: action.data.error,
      };
    case Actions.UPDATE_LITEUIS:
      return {
        ...state,
        liteuis: action.data.liteuis,
      };
    case Actions.UPDATE_OPTIONS: {
      let newState = { ...state };
      let index = newState.options.findIndex((o) => o.selected);
      newState.options[index] = action.data.options;
      return newState;
    }
    case Actions.UPDATE_INPUT_STATUS: {
      let newState = { ...state };
      let input = newState.ux.inputs.find((i) => i.input === action.data.input);
      input.status = action.data.status;
      return newState;
    }

    case Actions.CLEAR_STATE:
      return new DefaultState();
    default:
      return state;
  }
};
