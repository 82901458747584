import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Jumbotron, Button } from "react-bootstrap";
import { Container, AdminBar } from "../components";
import { Layout } from "../components/UIElements";
import { Loading } from "../../../components/Loaders";
import AtraAPI from "../../../apiv2";
import LiteUIMethods from "../methods";
import NotificationMethods from "../../../components/Notifications/methods";
import EnvMethods from "../../../components/Environments/methods";
import AccountMethods from "../../account/methods";

import { Prompt } from "react-router";

export class CreateLiteUi extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    //reset url
    this.props.history.replace("/liteui/create");
    this.state = {
      tables: [],
      triggers: [],
      ready: false,
      baseUrl: "/liteui/create/",
    };
    this.props.updateEnvironmentsEnabled({ enabled: false });
  }
  componentDidMount() {
    document.body.classList.add("white");
    this._mounted = true;
    let promiseList = [];
    this.props.updateLoadingTables({ value: true });
    this.props.updateLoadingTriggers({ value: true });

    let envId = window.localStorage.getItem("activeEnvironment");

    const getTrigger = AtraAPI.GetTriggersFullReturn(null, envId).then(
      (triggers) => {
        if (triggers.length) {
          const formattedTriggers = triggers
            .filter((t) => t.status === "Live" && !t.archived)
            .map((trigger) => {
              return {
                ...trigger,
                input: JSON.parse(trigger.input),
              };
            });
          this.props.updateLoadingTriggers({ value: false });
          this.setState({
            triggers: formattedTriggers.sort((a, b) => b.created - a.created),
          });
        }
      }
    );

    const getTable = AtraAPI.GetTablesFullReturn(null, envId).then((tables) => {
      this.props.updateLoadingTables({ value: false });

      if (tables.length) {
        this.setState({
          tables: tables
            .filter((t) => t.status === "Live" && !t.archived)
            .sort((a, b) => b.created - a.created),
        });
      }
    });

    promiseList.push(getTrigger);
    promiseList.push(getTable);
    Promise.all(promiseList).then((res) => {
      if (this.state.tables.length || this.state.triggers.length) {
        this.props.updateCanCreate(true);
      }
      this.setState({ ready: true });
    });

    //Setimezone
    this.props.updateTimezone({ value: this.props.account.user.timezone });
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.liteui.ux.unsavedChanges && !this.props.liteui.ux.success) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };
  componentWillUnmount() {
    this._mounted = false;
    window.onbeforeunload = undefined;
    this.props.resetState();
  }

  save = async () => {
    let input = {
      name: this.props.liteui.name,
      subheader: this.props.liteui.subheader,
      views: this.props.liteui.views,
      detailViewElements: this.props.liteui.detailViewElements,
      explorePageEnabled: this.props.liteui.explorePageEnabled,
    };

    let image = null;
    if (this.props.liteui.ux.logo.set && !this.props.liteui.ux.logo.tooLarge) {
      input = { ...input, logo: this.props.liteui.ux.logo.base64 };
    }

    let activeEnv = this.props.environments.environments.find((e) => e.active);
    let environmentId = null;
    if (activeEnv) {
      environmentId = activeEnv.id;
    }

    if (environmentId) {
      input.environmentId = environmentId;
    }

    this.props.updateSubmitting({ value: true });

    AtraAPI.CreateLiteUI({ input }).then(
      (res) => {
        this.props.updateSubmitting({ value: false });
        this.props.notificationsAddPopupMessage({
          message: "Success! - LiteUI Saved",
          remove: (id) => {
            this.props.notificationsRemovePopupMessage({ value: id });
          },
        });
        this.props.updateSuccess({ value: true });
        this.props.history.push(`/liteui/edit/${res.data.liteui}`);
      },
      (err) => {
        console.log(err);
        if (err == "limit-reached") {
          this.props.updateSuccess({ value: true });
          this.props.updateShowUsageAlertModal({ value: true });
        } else {
          this.props.updateError({ value: err });
        }
        this.props.updateSuccess({ value: false });
        this.props.updateSubmitting({ value: false });
      }
    );
  };

  cancel = () => {
    this.props.history.push("/liteui");
  };

  render() {
    document.body.classList.add("white");

    const breadcrumbs = [];
    breadcrumbs.push({ text: "LiteUI", to: "/liteui", icon: "liteui" });
    breadcrumbs.push({ text: "Create", to: "/liteui/create" });

    const rightContent = (
      <AdminBar
        success={this.props.liteui.ux.success}
        id={this.props.id}
        submitting={this.props.liteui.submitting}
        mode={this.props.liteui.mode}
        update={() => {}}
        save={this.save}
        unsavedChanges={this.props.liteui.ux.unsavedChanges}
        cancel={this.cancel}
        history={this.props.history}
      />
    );

    if (this.state.ready) {
      if (this.props.liteui.canCreate) {
        return (
          <Container
            className="liteui-container"
            breadcrumbs={breadcrumbs}
            rightContent={rightContent}
            border={true}
          >
            <Prompt
              when={
                this.props.liteui.ux.unsavedChanges &&
                !this.props.liteui.ux.success
              }
              message="Are you sure you want to leave before saving?"
            />

            <Layout
              mode="create"
              tables={this.state.tables}
              triggers={this.state.triggers}
              history={this.props.history}
              baseUrl={this.state.baseUrl}
            />
          </Container>
        );
      } else {
        return (
          <Container className="liteui-container" breadcrumbs={breadcrumbs}>
            <Jumbotron>
              <h1>We found no live dTables or Triggers on your Account</h1>
              <p>Creating a LiteUI requires at least one dTable or Trigger</p>
              <p>
                <Button
                  onClick={() => this.props.history.push("/dtables")}
                  bsStyle="primary"
                >
                  Go Create a dTable
                </Button>
              </p>
            </Jumbotron>
          </Container>
        );
      }
    } else {
      return (
        <Container className="liteui-container" breadcrumbs={breadcrumbs}>
          <Loading />
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    liteui: state.liteui,
    account: state.account,
    environments: state.environments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...LiteUIMethods({ dispatch }),
    ...NotificationMethods({ dispatch }),
    ...EnvMethods({ dispatch }),
    ...AccountMethods({ dispatch }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateLiteUi)
);
