import React, { Component } from 'react';
import {
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

const Input = ({
  onChange,
  value,
  childProps,
  handleAutoFill,
  autoFillEnabled,
  hideQuickFill
}) => {
  return (
    <FormGroup className='address-input-container'>
      <FormControl
        {...childProps}
        type='text'
        onChange={onChange}
        value={value}
        placeholder={'address'}
       />
     {hideQuickFill ? null
       :
       <div className='address-input-quickfill-container'>
         <span>Quickfill: </span>
         {autoFillEnabled ? <span onClick={handleAutoFill} className='link'>Use My Wallet Address</span> :
           <span>Set Address in <Link to='/account'>My Account</Link> to activate quickfill </span>
         }
       </div>
     }
    </FormGroup>
  )
}


class AddressInput extends Component {

  handleAutoFill = event => {
    const tempEvent = {
      target: {
         value: this.props.account.user.address,
         dataset: this.props.datasets,
         id: this.props.id
       }
    }

    this.props.onChange(tempEvent);
  }


  render(){
    return <Input
      childProps={this.props.childProps}
      value={this.props.value}
      onChange={this.props.onChange}
      handleAutoFill={this.handleAutoFill}
      autoFillEnabled={this.props.account.user.address ? true : false}
      hideQuickFill={this.props.hideQuickFill ? true : false}
      />
  }
}

const mapStateToProps = state => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return {

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressInput);
