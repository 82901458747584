import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import RecordsView from "./RecordsView";
import PermissionsView from "./PermissionsView";
import ContractView from "./ContractView";

class PageTabs extends Component {

  constructor(props){
    super(props);
    this._mounted = false;
  }

  componentDidMount(){
    this._mounted = true;
  }

  componentWillUnmount(){
    this._mounted = false;
  }

  handleTabSelect = key => {
    if(key != this.props.dTablesView.activeTab){
      this.props.updateLoadingTab({ value: true });
      this.props.setActiveTab({ value: key });
    }
  };

  render() {
    const loading = this.props.dTablesView.loadingInfo;
    if(this.props.dTablesView.table.status === 'Live'){
      return (
        <div className="tabs-container">
          <Tabs
            className='tab-headers'
            id='tabs_component'
            activeKey={this.props.dTablesView.activeTab}
            onSelect={this.handleTabSelect}
          >
            <Tab eventKey="records" title="Records">
              <div className="tab-content">
                {this.props.dTablesView.activeTab === 'records' && !loading ? <RecordsView /> : null}
              </div>
            </Tab>
            <Tab eventKey="permissions" title="Permissions">
              <div className="tab-content">
                {this.props.dTablesView.activeTab === 'permissions' && !loading ? <PermissionsView /> : null }
              </div>
            </Tab>
            <Tab eventKey="contract" title="Contract">
              <div className="tab-content">
                {this.props.dTablesView.activeTab === 'contract' && !loading ? <ContractView /> : null }
              </div>
            </Tab>
          </Tabs>
        </div>
      );
    }else{
      return (
        <div className="tabs-container">
          <Tabs
            className='tab-headers'
            id='tabs_component'
          >
            <Tab eventKey="records" title="Records">
            </Tab>
            <Tab eventKey="permissions" title="Permissions">
            </Tab>
            <Tab eventKey="contract" title="Contract">
            </Tab>
          </Tabs>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    dTablesView: state.dTablesView
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setActiveTab: data => dispatch({ type: "DTABLES_VIEW_SET_ACTIVE_TAB", data }),
    updateLoadingTab: data => dispatch({ type: "DTABLES_VIEW_UPDATE_LOADING_TAB", data })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageTabs);
