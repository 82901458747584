import moment from "moment-timezone";

const FormatDate = (date, timezone) => {
  if (!timezone) {
    return moment(new Date(date)).tz(moment.tz.guess()).format("MM/DD/YYYY");
  } else {
    return moment(new Date(date)).tz(timezone).format("MM/DD/YYYY");
  }
};

const FormatDateTime = (date, timezone) => {
  let tmp = new Date(date);
  if (!timezone) {
    return moment(new Date(date))
      .tz(moment.tz.guess())
      .format("MM/DD/YYYY hh:mm A");
  } else {
    return moment(new Date(date)).tz(timezone).format("MM/DD/YYYY hh:mm A");
  }
};

const GetEpochFromTimeZoneDate = (date, timezone = moment.tz.guess()) => {
  const localOffset = moment(new Date()).utcOffset();
  const timeZoneOffset = moment(new Date()).tz(timezone).utcOffset();
  const offsetFromLocalOffset = localOffset - timeZoneOffset;
  return moment(date).add(offsetFromLocalOffset, "minutes").valueOf();
};

const GetNewDateWithTimeZoneOffset = (timezone = moment.tz.guess()) => {
  return new Date(
    moment(new Date()).tz(timezone).format("MM/DD/YYYY hh:mm:ss A")
  );
};

export {
  FormatDate,
  FormatDateTime,
  GetEpochFromTimeZoneDate,
  GetNewDateWithTimeZoneOffset,
};
