import React, { Component } from "react";
import {connect} from 'react-redux';

import TriggerMethods from '../../methods/create';

class Steps extends Component {
  handleStepClick = (event) => {
    if(this.props.trigger.steps.find(s => s.id == event.target.id).clickable){
      this.props.updateSelectedStep({ value: event.target.id });
      switch(event.target.id){
        case 'action': {
          //if action is set and user navigates back to the step, they are now editing the action
          if(this.props.trigger.action.set) {
            //set editing to true
            this.props.enableEdit();
          }
        }
      }
    }
  }
  render() {
    return (
      <div className='trigger-create-steps-container'>
        {this.props.trigger.steps.map((step,i)=>{
          return <div key={i} className={step.clickable ? 'clickable' : ''}>
            <div className='step-label noselect '>{step.step}</div>
            <div className={`step-link noselect ${step.selected ? 'selected' : ''} ${step.clickable ? 'clickable' : ''}`} id={step.id} onClick={this.handleStepClick}>{step.title}</div>
          </div>
        })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    trigger: state.triggersCreate
  };
};

const mapDispatchToProps = dispatch => {
  return TriggerMethods({ dispatch })
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Steps);
