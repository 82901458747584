import React from 'react';
import {Link} from 'react-router-dom';
import Note from '../helpers/Note';
import Direction from '../helpers/Direction';
import ScreenShot from './ScreenShot';

const liteui = ({

}) => {
  return (
  <div className='liteui-section-container'>
    <h3>LiteUI</h3>
    <p><b>Create a User Interface</b></p>

    <p>Now we will combine everything we just created into one simple user interface. Building a LiteUI is a little different than the previous two services. The output of the LiteUI service is a website, not a smart contract, like we were creating in dTable and Triggers. LiteUI will bring together our dTables and Triggers, and give us a public website we can share with our users.</p>

    <Direction>
      <p>Select the LiteUI option from the Services dropdown in the navigation.</p>
    </Direction>

    <p>Let’s jump right in and create a new LiteUI</p>

    <Direction>
      <p>Click the Plus button in the upper right of the dashboard</p>
    </Direction>

    <p>First we are going to name our dApp.</p>

    <Direction>
      <p>Enter ‘BlockTalk’ in the text box at the top of the page. </p>
    </Direction>

    <p>Next we can add our first page which will serve as our home page.</p>

    <Direction>
      <p>Click the ‘Add Page’ button in the purple header</p>
      <p>Type ‘Home’ and hit enter or click ‘Save’ to add the page to our LiteUI</p>
    </Direction>

    <p>We’ve named our application and created the first page, it’s time to add a UI Element to the home page. </p>

    <Direction>
      <p>Click ‘Add UI Element’</p>
      <p>Select the ‘Text’ type element</p>
      <p>Click ‘Add’</p>
    </Direction>

    <p>We need to configure the newly added Text Element</p>
    <Direction>
      <p>Click the three dots in the upper right corner of the element.</p>
      <p>Select ‘Configure’ from the dropdown</p>
    </Direction>

    <ScreenShot name='liteui_configure'/>

    <Direction>
      <p>In the Configure Text modal</p>
      <p>Set the header to "Welcome to BlockTalk"</p>
      <p>Set the body to “A decentralized message board for your friends”.</p>
    </Direction>

    <p>Now that we have context to what our application does, we will display the MessageBoard dTable in a list element below the text element.</p>

    <Direction>
      <p>Click the ‘Add UI Element’</p>
      <p>Select the ‘List’ element</p>
      <p>In Select UI Element Data Source set the dtables dropdown to ‘MessageBoard’’</p>
      <p>Click ‘Add’.</p>
    </Direction>

    <p>We are now displaying the message board to our users - this is where we mentioned earlier that the dTable you create would be seen in your interface, in slightly different format.</p>

    <p>Now, we are going to add a new page where users can post to the message board.</p>

    <Direction>
      <p>Click ‘Add Page’ from inside the purple header</p>
      <p>Set the Name field to ‘Post’</p>
      <p>Click ‘Save’ or hit enter</p>
    </Direction>

    <p>Let’s use the trigger we created that allows users to insert a record, or create a new post.</p>

    <Direction>
      <p>Click ‘Add UI Element’</p>
      <p>Select the ‘Form’ element type</p>
      <p>In Select UI Element Data Source set the triggers dropdown to ‘InsertMessage’</p>
      <p>Click ‘Add’.</p>
    </Direction>

    <p>Configure the element and change the name.</p>

    <Direction>
      <p>Click the three dots in the upper corner of the InsertMessage form element</p>
      <p>Set the Name to ‘Post a Message’</p>
      <p>Click ‘Save’</p>
    </Direction>

    <p>We are done done building our UI for now, let’s save this version.</p>

    <Direction>
      <p>Click ‘Save’ in the upper right corner of the page</p>
    </Direction>

    <p>Your LiteUI is saved, let’s go view the public version of the website you just created.</p>

    <Direction>
      <p>Click back into the BlockTalk LiteUI from the dashboard</p>
      <p>Click the 'Open Public URL' link under the Save button</p>
      <p>This is your public dApp.</p>
    </Direction>

    <p>Your dApp is ready for primetime! Interact with it yourself, or send the URL to friends so they can interact too! The URL is accessible to anyone, however the user will need Metamask installed and have testnet Ether to post messages to the message board.</p>


  </div>
  )

}

export default liteui;
