import Actions from '../actions/index';
const Methods = ({ dispatch }) => {
    return {
      updateActiveEnvironment: data => dispatch({ type: Actions.UPDATE_ACTIVE, data }),
      updateEnvironments: data => dispatch({ type: Actions.UPDATE_ENVIRONMENTS, data }),
      updateEnvironmentsEnabled: data => dispatch({ type: Actions.UPDATE_ENABLED, data })
    }
}

export default Methods;
