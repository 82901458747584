import React, { Component } from "react";
import { connect } from "react-redux";

import AtraAPI from "../../../apiv2";
import DashboardMethods from "../methods/dashboard";
import EnvMethods from "../../../components/Environments/methods";
import { FormatDate } from "../../../helpers/Date";
import { RefreshMiliseconds } from "../../../helpers/Timers";
import { Container } from "../components";
import {
  DashboardStatsContainer,
  DashboardActionHeader,
  DashboardGrid,
  DashboardFooter,
  DashboardGridNoContent,
  DashboardFilter,
} from "../../../components/ServiceDashboard";

class TriggersDashboard extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    this._noContent = (
      <DashboardGridNoContent
        title={"Add a Trigger"}
        description={`Create a trigger for every scenario where a dTable's record is created or modified.
        Enforce your application's business logic in the form of a trigger.`}
        buttonText={"Create Trigger"}
        onClick={() => this.props.history.push("/triggers/create")}
        learnMoreClick={() =>
          this.props.history.push("/docs/user-guides/triggers")
        }
      />
    );
    this.props.updateEnvironmentsEnabled({ enabled: true });
  }

  async componentDidMount() {
    this._mounted = true;
    this.pollTriggers();
  }

  async componentWillUnmount() {
    this._mounted = false;
    this.props.clearState();
  }

  async pollTriggers() {
    if (this._mounted) {
      await this.getTriggers().then(async (tables) => {
        setTimeout(async () => {
          await this.pollTriggers();
        }, RefreshMiliseconds);
      });
    }
  }

  getTriggers = () => {
    return new Promise((resolve, reject) => {
      let envId = window.localStorage.getItem("activeEnvironment");
      AtraAPI.GetTriggersFullReturn(
        this.props.account.user.timezone,
        envId
      ).then(
        (triggers) => {
          if (this._mounted) {
            let formattedTriggers = triggers.map((trigger) => {
              return {
                ...trigger,
                input: JSON.parse(trigger.input),
              };
            });
            this.props.updateTriggers({ value: formattedTriggers });
            this.props.loadingTriggers({ value: false });
            resolve();
          } else {
            reject();
          }
        },
        (err) => {
          this.props.loadingTriggers({ value: false });
          reject();
        }
      );
    });
  };

  handleFilterChange = (event) => {
    this.props.toggleFilter({ value: event.target.id });
  };

  render() {
    const breadcrumbs = [];
    breadcrumbs.push({ text: "Triggers", to: "/triggers", icon: "triggers" });

    const statsList = [];
    statsList.push({
      title: "Total Triggers",
      value: this.props.triggersDashboard.stats.total_triggers,
      loading: this.props.triggersDashboard.loadingTriggers,
    });
    statsList.push({
      title: "Triggers Live",
      value: this.props.triggersDashboard.stats.total_live_triggers,
      loading: this.props.triggersDashboard.loadingTriggers,
    });
    statsList.push({
      title: "Triggers Deploying",
      value: this.props.triggersDashboard.stats.total_deploying_triggers,
      loading: this.props.triggersDashboard.loadingTriggers,
    });

    const triggers = this.props.triggersDashboard.triggers.filter(
      (t) => t.archived === this.props.triggersDashboard.filter.showArchived
    );

    return (
      <Container
        className="service-dashboard-container"
        breadcrumbs={breadcrumbs}
      >
        <DashboardStatsContainer stats={statsList} />

        <DashboardActionHeader
          title={"Your Triggers"}
          onClick={() => this.props.history.push("/triggers/create")}
          actionButtonText={"Create Trigger"}
        />

        <DashboardFilter
          filter={this.props.triggersDashboard.filter}
          onChange={this.handleFilterChange}
        />

        <DashboardGrid
          type={"trigger"}
          source={triggers.sort((a, b) => b.created - a.created)}
          loading={
            !this.props.triggersDashboard.triggers.length &&
            this.props.triggersDashboard.loadingTriggers
          }
          noContent={this._noContent}
        />

        <DashboardFooter
          title={"Triggers"}
          description={`
           Triggers are used to enforce standards in your application.
           Create a trigger for every scenario where a dTable's record is created or modified.
           Enforce your application's business logic in the form of a trigger.
           Triggers are invoked by users of your application and their browser wallet.
         `}
          link={"/docs/user-guides/triggers"}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    triggersDashboard: state.triggersDashboard,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...DashboardMethods({ dispatch }),
    ...EnvMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TriggersDashboard);
