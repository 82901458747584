import React from "react";
import { Link } from "react-router-dom";

const Box = ({
  title,
  subTitle,
  supTitle,
  image,
  url,
  footNotes,
  customClass,
  comingSoon
}) => {
  const FootNotes = ({ footNotes }) => {
    if (footNotes.length) {
      return (
        <div className={`box-foot-notes-container ${customClass}`}>
          <ul>
            {footNotes.map((note, i) => {
              return (
                <li key={i} className="foot-note">
                  {note}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };
  return (
    <div className="home-page-box-container">
      <div className="box-content-wrapper">
        {comingSoon ? <div className="comingSoon" /> : null}
        <Link to={url} className={`link-container ${customClass}`}>
          <div className="box-sup-title">{supTitle}</div>
          <div className="box-image-container">
            {image ? (
              <img src={require(`../../../components/Branding/${image}`)} />
            ) : null}
          </div>
          <div className="title-container">
            <div className="box-title">{title}</div>
            <div className="box-sub-title">{subTitle}</div>
          </div>
        </Link>
        <FootNotes footNotes={footNotes} />
      </div>
    </div>
  );
};

export default Box;
