import React, { Component } from "react";
import { connect } from "react-redux";

import EnvMethods from "../../../components/Environments/methods";
import Methods from "../methods";

import FeatureFlags from "../../../components/FeatureFlags";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faFileInvoice,
  faSync,
  faWallet,
  faKey,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

class AccountTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      billingTabEnabled: !!this.props.account.user.stripeCustomerId,
    };
  }

  Handlers = {
    handleTabClick: (tab) => {
      this.props.history.push(`/account/${tab}`);
      this.props.updateActiveTab({ value: tab });
    },
  };

  render() {
    const activeTab = this.props.account.ux.activeTab;
    return (
      <div className="account-tabs-container">
        <div className="tabs-menu">
          <div
            onClick={() => this.Handlers.handleTabClick("info")}
            className={`menu-item-container ${
              activeTab == "info" ? "active" : ""
            }`}
          >
            <FontAwesomeIcon icon={faUser} />
            <span>Account</span>
          </div>

          {false && FeatureFlags.Paywall && this.state.billingTabEnabled ? (
            <div
              onClick={() => this.Handlers.handleTabClick("billing")}
              className={`menu-item-container ${
                activeTab == "billing" ? "active" : ""
              }`}
            >
              <FontAwesomeIcon icon={faFileInvoice} />
              <span>Billing</span>
            </div>
          ) : null}

          {FeatureFlags.Paywall ? (
            <div
              onClick={() => this.Handlers.handleTabClick("subscription")}
              className={`menu-item-container ${
                activeTab == "subscription" ? "active" : ""
              }`}
            >
              <FontAwesomeIcon icon={faFileInvoice} />
              <span>Subscription</span>
            </div>
          ) : null}

          <div
            onClick={() => this.Handlers.handleTabClick("wallet")}
            className={`menu-item-container ${
              activeTab == "wallet" ? "active" : ""
            }`}
          >
            <FontAwesomeIcon icon={faWallet} />
            <span>ETH Wallet</span>
          </div>
          <div
            onClick={() => this.Handlers.handleTabClick("api")}
            className={`menu-item-container ${
              activeTab == "api" ? "active" : ""
            }`}
          >
            <FontAwesomeIcon icon={faKey} />
            <span>API Key</span>
          </div>

          {false ? (
            <div
              onClick={() => this.Handlers.handleTabClick("help")}
              className={`menu-item-container ${
                activeTab == "help" ? "active" : ""
              }`}
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
              <span>Help</span>
            </div>
          ) : null}
        </div>
        <div className="account-tabs-content-container">
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...Methods({ dispatch }),
    ...EnvMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountTabs);
