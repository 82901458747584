import Actions from "../actions/triggers-view";
class DefaultState {
  constructor() {
    this.activeTab = "details";
    this.archiving = false;
    this.loading = {
      tab: true,
      details: false,
      contract: false,
      invoke: false,
    };
    this.hasPermission = false;
    this.submittingPermission = false;
    this.pendingPermission = false;
    this.contract = "";
    this.trigger = {};
    this.invoke = {
      eth: 0,
      index: 0,
      inputs: [],
      isSubmitting: false,
      metamask: {
        loading: true,
        error: false,
        code: null,
      },
    };
  }
}
class Trigger {
  constructor(id) {
    this.id = id;
  }
}

export default (state = new DefaultState(), action) => {
  switch (action.type) {
    //Loading
    case Actions.UPDATE_ARCHIVING:
      return {
        ...state,
        archiving: action.data.value,
      };
    case Actions.TRIGGERS_VIEW_UPDATE_LOADING_TAB:
      var newState = { ...state };
      newState.loading.tab = action.data.value;
      return newState;
    case Actions.TRIGGERS_VIEW_UPDATE_LOADING_DETAILS:
      var newState = { ...state };
      newState.loading.details = action.data.value;
      return newState;
    case Actions.TRIGGERS_VIEW_UPDATE_LOADING_CONTRACT:
      var newState = { ...state };
      newState.loading.contract = action.data.value;
      return newState;
    case Actions.TRIGGERS_VIEW_UPDATE_LOADING_INVOKE:
      var newState = { ...state };
      newState.loading.invoke = action.data.value;
      return newState;
    //Tabs
    case Actions.TRIGGERS_VIEW_SET_ACTIVE_TAB:
      return { ...state, activeTab: action.data.value };
    //Trigger
    case Actions.UPDATE_TRIGGER_DTABLE_PERMISSION:
      return { ...state, hasPermission: action.data.value };
    case Actions.UPDATE_TRIGGER_DTABLE_PERMISSION_SUBMITTING:
      return { ...state, submittingPermission: action.data.value };

    case Actions.UPDATE_TRIGGER_DTABLE_PERMISSION_PENDING:
      return { ...state, pendingPermission: action.data.value };
    case Actions.TRIGGERS_VIEW_NEW_TRIGGER:
      return { ...state, trigger: new Trigger(action.data.value) };
    case Actions.TRIGGERS_VIEW_UPDATE_TRIGGER:
      var newState = { ...state };
      newState.trigger = { ...newState.trigger, ...action.data.value };
      newState.invoke.inputs = newState.trigger.input.action.mappings
        ? newState.trigger.input.action.mappings.map((input, i) => {
            let newInput = {
              input: input.value === "input",
              value: "",
              type: input.valueType,
              name: input.name,
              index: i,
            };
            switch (input.valueType) {
              case "date":
                newInput.value = new Date();
                break;
              case "price":
                newInput.value = 0;
                break;
              case "address":
                newInput.value = newInput.input
                  ? ""
                  : "0x0000000000000000000000000000000000000000";
                break;
              case "pointer":
                newInput.value = newInput.input
                  ? ""
                  : "0x0000000000000000000000000000000000000000";
                break;
            }

            return newInput;
          })
        : [];
      return newState;
    //Contract
    case Actions.TRIGGERS_VIEW_UPDATE_CONTRACT:
      return { ...state, contract: action.data.value };

    //Invoke
    case Actions.TRIGGERS_VIEW_UPDATE_INVOKE_FIELD:
      var newState = { ...state };
      newState.invoke.inputs[action.data.index].value = action.data.value;
      return newState;
    case Actions.TRIGGERS_VIEW_UPDATE_INVOKE_INDEX:
      var newState = { ...state };
      newState.invoke.index = action.data.value;
      return newState;
    case Actions.TRIGGERS_VIEW_UPDATE_INVOKE_ETH:
      var newState = { ...state };
      newState.invoke.eth = action.data.value;
      return newState;
    case Actions.TRIGGERS_VIEW_UPDATE_INVOKE_METAMASK:
      var newState = { ...state };
      newState.invoke.metamask = action.data.value;
      return newState;
    //Reset state
    case Actions.TRIGGERS_VIEW_RESET_STATE:
      return new DefaultState();
    default:
      return state;
  }
};
