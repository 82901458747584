import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Records from "../Records";

import Methods from '../../methods/view';
import { RefreshMiliseconds } from '../../../../helpers/Timers';
import AtraAPI from '../../../../apiv2';

import DashboardGridNoContent from '../../../../components/ServiceDashboard/DashboardGridNoContent';
import LoadingTab from '../LoadingTab';

class RecordsView extends Component {

  constructor(props){
    super(props);
    this._mounted = false;
  }

  componentDidMount(){
    this._mounted = true;
    this.pollRecords();
  }

  componentWillUnmount(){
    this._mounted = false;
  }

  async pollRecords() {
    if (this._mounted) {
      await this.getRecords().then(() => {
        setTimeout(async () => {
          await this.pollRecords();
        }, RefreshMiliseconds);
      },err=>{
        //catch error
      });
    }
  }

  getRecords = () => {
    return new Promise((resolve, reject)=>{
      AtraAPI.GetRecords(this.props.dTablesView.table.id).then((records) => {
        if (this._mounted) {
          this.props.updateRecords({ value: records });
          this.props.updateLoadingTab({ value: false });
          resolve();
        }else{
          reject();
        }
      },(err)=>{
        resolve();
      });
    });
  }

  render() {
    if(this.props.dTablesView.loadingTab || this.props.dTablesView.loadingInfo){
      return <LoadingTab title={'Records'} />;
    }else{
      return (
        <div className="tabs-content">
          {this.props.dTablesView.table.records.live.length === 0 && this.props.dTablesView.table.records.pending.length === 0 ? (
            <DashboardGridNoContent
                title={'Start Adding Data'}
                description=<div>
                  dTable's store data in the form of records. Each record has a cell for every column.

                  <h3>Three ways to create new records:</h3>
                  <ol>
                    <li>Right now by clicking 'New Record'</li>
                    <li>Connect to the <Link to='/docs/api/dtables'>Atra API</Link></li>
                    <li>Create a <Link to='/triggers'>Trigger</Link> to enfoce business logic while creating a record.</li>
                  </ol>
                </div>
                buttonText={'New Record'}
                onClick={() =>this.props.showNewRecordModal({ value: true, timezone: this.props.account.user.timezone })}
               />
          ) : (
            <Records
              columns={this.props.dTablesView.table.columns}
              records={this.props.dTablesView.table.records}
              timezone={this.props.account.user.timezone}
            />
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    dTablesView: state.dTablesView,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return Methods({dispatch});
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordsView);
