import React, { Component } from 'react';
import { connect } from "react-redux";

import { Modal, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import UIModals from './';
import LiteUIMethods from "../../methods";
import NotificationMethods from "../../../../components/Notifications/methods";
import { GetBase64 } from "../../../../helpers/Image";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

const ImageUploader = ({
  handleChange,
  logo,
  handleRemove
}) => {
  const handleClick = event => {
    document.getElementById('liteui_logo_image').click();
  }
  return (
    <div className='liteui-image-logo-uploader'>
      <div>
        <input
          onChange={handleChange}
          type='file'
          id='liteui_logo_image'
          accept=".png,.jpg"
        />
        {logo.set ?
          <div className="image-populated-container">
            <img src={logo.base64} />
          </div>
        :
          <div className='image-dropzone' onClick={handleClick}>
            <FontAwesomeIcon icon={faCamera} />
            <div>Click here to add image</div>
          </div>
        }
        {logo.tooLarge ?
          <div>{logo.message}</div>
           : null}

        {logo.set && !logo.tooLarge   ?
          <div className='remove-image-container'>
            <button className='red-button-small' onClick={handleRemove}>Remove Image</button>
          </div>
        :null}
      </div>
    </div>
  )

}

const ConfigureHeaderModal = ({
  show,
  name,
  subheader,
  title,
  logo,
  handlers
}) => {

  return (
    <UIModals.ModalContainer
      title={title}
      handleClose={handlers.Close}
      handleSave={handlers.Save}
      show={show}
      >
      <FormGroup controlId={'title'}>
        <ControlLabel>Site Name:</ControlLabel>
        <FormControl
          type='text'
          onChange={handlers.HeaderChange}
          defaultValue={name}
          />
      </FormGroup>

      <div className='add-logo-container'>
        <ControlLabel>Add a Logo <span className='small-label'>(image must be less than 1MB)</span></ControlLabel>
        <ImageUploader
        handleRemove={handlers.LogoRemove}
        handleChange={handlers.LogoChange}
        logo={logo}
        />
      </div>

      <FormGroup controlId={'subheader'}>
        <ControlLabel>Site Tagline:</ControlLabel>
        <FormControl
          type='text'
          onChange={handlers.SubheaderChange}
          defaultValue={subheader}
          />
      </FormGroup>

    </UIModals.ModalContainer>
  );
}

class ConfigurePage extends Component {

  Handlers = {
    Close: () => {
      this.props.updateShowHeaderModal({
        value: false
      })
    },
    Save: () => {
      this.props.saveHeaderModalEdits();
      this.props.updateShowHeaderModal({
        value: false
      })
    },
    SubheaderChange: (event) => {
      this.props.updateEditSubHeader({
        value: event.target.value
      });
    },
    HeaderChange: (event) => {
      this.props.updateEditHeader({
        value: event.target.value
      });
    },
    LogoChange: async event => {
      const image = document.getElementById('liteui_logo_image').files[0];
      if(image){
        var logo = window.URL.createObjectURL(image);
        // console.log(image);
        const base64 = await GetBase64(image);
        // console.log(base64);
        this.props.updateEditLogo({ value: logo, size: image.size, base64 });
      }
    },
    LogoRemove: event => {
      //remove
      document.getElementById('liteui_logo_image').value = null;
      this.props.updateEditLogo();
    }
  }

  render(){
    if(this.props.liteui.ux.showEditHeaderModal.show){
      return (
        <div>
          <ConfigureHeaderModal
            title='Add a Header'
            show={this.props.liteui.ux.showEditHeaderModal.show}
            name={this.props.liteui.ux.showEditHeaderModal.name}
            subheader={this.props.liteui.ux.showEditHeaderModal.subheader}
            logo={this.props.liteui.ux.showEditHeaderModal.logo}
            handlers={this.Handlers}
          />
          <UIModals.ModalContainerOK
            show={this.props.liteui.ux.showEditHeaderModal.logo.message.length > 0}
            title={'Image Warning'}
            handleClose={this.Handlers.LogoRemove}
          >
            {this.props.liteui.ux.showEditHeaderModal.logo.message}

          </UIModals.ModalContainerOK>
        </div>

      )
    }else{
      return null;
    }

  }
}

const mapStateToProps = state => {
  return {
    liteui: state.liteui,
    notifications: state.notifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...LiteUIMethods({dispatch}),
    ...NotificationMethods({dispatch})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurePage);
