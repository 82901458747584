import React from 'react';
import SectionContainer from './section-container';
import { Link } from 'react-router-dom';
import { Button } from "react-bootstrap";
import LoaderButton from '../../../components/Buttons/LoaderButton';

const APIKeysSection = ({
  url,
  handleCreateAPIKey,
  apiKey,
  loading,
  isSubmitting
}) => {


  let content = null;
  if(loading){
    content = 'loading...';
  }else{
    if(!apiKey){
      content = <LoaderButton
          isLoading={isSubmitting}
          text='Create API Key'
          loadingText='Creating Key'
          className='secondary-button'
          onClick={handleCreateAPIKey}
        />
    }else{
      content = <div>
          <div>API Url: {url}</div>
          <div>API Key: {apiKey}</div>
        </div>;
    }
  }

  const container = <div>
    <div>
      API Keys are used to access your services through the Atra API. Read the <Link to='/docs/api/dtables'>API Docs</Link> for more information.
    </div>
    <div className='section-content'>
      {content}
    </div>

  </div>;

  return <SectionContainer header={'API Key'} content={container} />;

}

export default APIKeysSection;
