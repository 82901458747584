import { API } from "aws-amplify";
import { FormatDate, FormatDateTime } from "../helpers/Date";

const GetTables = (timezone, environmentId = null) => {
  return new Promise((resolve, reject) => {
    try {
      const data = {
        response: true,
        queryStringParameters: {

        }
      };
      if(environmentId){
        data.queryStringParameters.environmentId = environmentId;
      }
      let code = null;
      if(timezone && timezone.code){
        code = timezone.code;
      }
      API.get("dtables/tables", "", data).then(res => {
        const tables = res.data.map(table => {
          return {
            ...table,
            date: FormatDateTime(table.created,code),
            link: `/dtables/view/${table.id}`,
            archived: table.archived || false
          }
        });
        resolve(tables);
      }).catch(err => {
        reject(err);
      });
    }catch(err){
      reject(err);
    }
  });
};

const GetTablesFullReturn = (timezone, environmentId = null) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        queryStringParameters: {
          full: true
        }
      };
      if(environmentId){
        data.queryStringParameters.environmentId = environmentId;
      }
      let code = null;
      if(timezone && timezone.code){
        code = timezone.code;
      }
      API.get("dtables/tables", "", data).then(res => {
        const tables = res.data.map(table => {
          return {
            ...table,
            date: FormatDateTime(table.created,code),
            link: `/dtables/view/${table.id}`,
            archived: table.archived || false
          }
        });
        resolve(tables);
      }).catch(err => {
        reject(err);
      });
    }catch(err){
      reject(err);
    }
  });
};

const GetTable = (tableId) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        queryStringParameters: {
          tableId: tableId
        }
      };
      API.get("dtables/tables", "", data).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    } catch (err) {
      reject(err);
    }
  });
}

const GetRecords = (tableId, metadata = false) => {
  return new Promise((resolve, reject) => {
    try {
      const data = {
        queryStringParameters: {
          tableId: tableId,
          metadata: metadata
        }
      };
      API.get("dtables/records", "", data).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    } catch (err) {
      reject(err);
    }
  });
}

const InsertRecord = (tableId, record) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        body: {
          record: record,
          tableId: tableId
        }
      };
      API.post("dtables/records", "", data).then(res => {
        resolve(res);
      }).catch(err => {
        if (err && err.response && err.response.data && err.response.data.error) {
          reject(err.response.data.error);
        }else{
          reject('error');
        }
      });
    } catch (err) {
      reject(err);
    }
  });
}

const GetPermissions = (tableId) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        queryStringParameters: {
          tableId: tableId
        }
      };
      API.get("dtables/permissions", "", data).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    } catch (err) {
      reject(err);
    }
  });
}

const AddPermission = (tableId, address) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: {
          address: address,
          tableId: tableId
        }
      };
      API.post("dtables/permissions", "", data).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

const RevokePermission = (tableId, address) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: {
          address: address,
          tableId: tableId
        }
      };
      API.del("dtables/permissions", "", data).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
    }catch(err){
      reject(err);
    }
  });
};

const CreateTable = (table) => {
  return new Promise((resolve, reject)=>{
    try {
      let data = {
        response: true,
        body: table
      };

      API.post("dtables/tables", "", data).then(res => {
        resolve(res);
      }).catch(err => {
        if (err && err.response && err.response.data && err.response.data.error) {
          reject(err.response.data.error);
        }else{
          reject('error');
        }
      });

    }catch(err){
      reject(err);
    }
  })
}

export default { GetTables, GetTable, GetTablesFullReturn, CreateTable, GetRecords, InsertRecord, GetPermissions, AddPermission, RevokePermission };
