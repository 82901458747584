import React from 'react';
import { Modal, Glyphicon, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import { GetIcon } from '../UIElements';

const AddUIElement = ({
  show,
  element,
  handlers,
  view
}) => {
  return (
    <Modal backdrop='static' show={show} onHide={handlers.CloseAddModal}>
      <Modal.Body>
        <div className="liteui-add-element-modal-container">
          <h3>Select UI Element Type</h3>
          <div className='select-element-container'>
            {element.options.map((option,i)=>{
              const classString =  `element-container ${option.selected ? 'selected' : null}`;
              return (
                <div key={i} onClick={() => handlers.ChangeAddElementType(option.type)} className={classString}>
                  <div className='slected-container'>
                    <Glyphicon glyph="ok-circle" />
                  </div>
                  <GetIcon icon={option.icon} />
                  <h4>{option.title}</h4>
                  <p>{option.description}</p>
                </div>
              )
            })}

          </div>

            {element.source ?
              <div className='select-source-container'>
                <h3>Select UI Element Data Source</h3>
                <FormGroup>
                  <ControlLabel>{element.source.type}</ControlLabel>
                  <FormControl data-type={element.source.type} value={element.source.options.find(o => o.selected).id} onChange={handlers.ChangeAddElementSource} componentClass="select">
                    {element.source.options.map((option,i)=>{
                      return <option key={i} value={option.id}>{option.name}</option>
                    })}
                  </FormControl>
                </FormGroup>
              </div>
            : null }

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className='white-button' onClick={handlers.CloseAddModal}>Cancel</Button>
        <Button className='secondary-button' onClick={handlers.Add}>Add</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default { AddUIElement };
