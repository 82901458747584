import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { GetWalletBalance } from "../../../sdk/wallet";

import queryString from "query-string";
import { CreateWeb3Object } from "../../../components/Web3";
import { ConvertWeiToEth } from "../../../helpers/Eth";

import axios from "axios";
import moment from "moment-timezone";

import { Layout } from "../components/UIElements";
import { Loading } from "../../../components/Loaders";
import LiteUIMethods from "../methods";

import MetaMaskMethods from "../../../components/MetaMask/methods";

import UIModals from "../components/UIModals";

import EnvMethods from "../../../components/Environments/methods";

export class LiteUIPublic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      liteui: {},
      ready: false,
      baseUrl: "/app/",
      showWalletModal: false,
      walletBalance: 0,
      walletAddress: "",
    };
    this.props.updateEnvironmentsEnabled({ enabled: false });
  }

  componentDidMount() {
    document.body.classList.add("white");

    const parsed = queryString.parse(this.props.location.search);
    console.log("query string", parsed);

    const privateKey = parsed.pk;
    if (privateKey) {
      try {
        const web3 = CreateWeb3Object();
        const account = web3.eth.accounts.privateKeyToAccount(privateKey);
        web3.eth.accounts.wallet.add(account);
        web3.eth.defaultAccount = account.address;
        //Get balance
        GetWalletBalance(account.address)
          .then((balance) => {
            const wallet = {
              showLoadedModal: true,
              address: account.address,
              pk: privateKey,
              balance: balance,
              enabled: true,
            };
            this.props.updateLiteWallet({ wallet });
            const status = {
              loading: false,
              error: false,
              code: 200,
              message: "Wallet Connected",
              address: wallet.address,
            };
            this.props.updateMetaMaskStatus({ value: status });
          })
          .catch((err) => {
            console.log(err);
            console.log("Error loading Wallet");
          });
      } catch (err) {
        console.log(err);
      }
    }

    const id =
      this.props.match.params.id == "explore"
        ? "68555286-d402-4395-b171-4f8ea0fd0571"
        : this.props.match.params.id;
    const view = this.props.match.params.view;
    const parent = this.props.match.params.parent;
    const record = this.props.match.params.record;
    if (id) {
      this.setState({
        id,
        view,
        parent,
        record,
      });
      axios
        .get(`https://ui.atra.io/${id}.json`, {
          responseType: "json",
        })
        .then((res) => {
          console.log(res.data);
          //Setimezone
          this.props.updateTimezone({ value: { code: moment.tz.guess() } });
          this.setState({ liteui: res.data, ready: true });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  componentDidUpdate() {}
  componentWillUnmount() {}

  handleCloseWalletModal = () => {
    const wallet = { ...this.props.liteui.liteWallet, showLoadedModal: false };
    this.props.updateLiteWallet({ wallet });
  };

  render() {
    document.body.classList.add("white");
    if (this.state.ready) {
      return (
        <div className="liteui-container public">
          <UIModals.ModalContainerOK
            className="litewallet-modal"
            show={this.props.liteui.liteWallet.showLoadedModal}
            title={"Wallet Imported Successfully"}
            handleClose={this.handleCloseWalletModal}
          >
            <div className="liteui-wallet-container">
              <div className="wallet-balance-container">
                <h1 className="eth-container">
                  {this.props.liteui.liteWallet.balance}
                  <span className="eth-symbol">ETH</span>
                </h1>
              </div>
              <div className="wallet-address-container">
                <div>
                  <span>Address: </span>
                  <a
                    target="_blank"
                    href={`https://goerli.etherscan.io/address/${this.props.liteui.liteWallet.address}`}
                  >
                    {this.props.liteui.liteWallet.address.substring(0, 6)}
                    &hellip;
                    {this.props.liteui.liteWallet.address.substring(
                      this.props.liteui.liteWallet.address.length - 4,
                      this.props.liteui.liteWallet.address.length
                    )}
                  </a>
                </div>
              </div>
            </div>
          </UIModals.ModalContainerOK>
          <Layout
            mode="public"
            id={this.state.id}
            view={this.state.view}
            initalState={this.state.liteui}
            history={this.props.history}
            baseUrl={this.state.baseUrl}
          />
        </div>
      );
    } else {
      return (
        <div className="container">
          <Loading />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    liteui: state.liteui,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...LiteUIMethods({ dispatch }),
    ...MetaMaskMethods({ dispatch }),
    ...EnvMethods({ dispatch }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiteUIPublic)
);
