import Actions from '../actions/view';
const Methods = ({ dispatch }) => {
  return {
    updateArchiving: data => dispatch({ type: Actions.UPDATE_ARCHIVING, data}),
    updateLoadingInfo: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_LOADING_INFO, data }),
    updateLoadingTab: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_LOADING_TAB, data }),
    setActiveTab: data => dispatch({ type: Actions.DTABLES_VIEW_SET_ACTIVE_TAB, data }),
    updateTableId: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_TABLEID, data }),
    updateTable: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_TABLE, data }),

    showNewRecordModal: data => dispatch({ type: Actions.DTABLES_VIEW_SHOW_NEW_RECORD, data }),
    updateRecords: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_RECORDS, data }),
    clearState: () => dispatch({ type: Actions.DTABLES_VIEW_CLEAR_STATE }),

    updateNewRecord: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_NEW_RECORD, data }),
    updateNewRecordModalError: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_NEW_RECORD_ERROR, data }),
    updateNewRecordValueStatus: data => dispatch({ type: Actions.UPDATE_NEW_RECORD_VALUE_STATUS, data }),
    updateLoadingNewRecordSubmit: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_LOADING_SUBMITTING_NEW_RECORD , data}),

    addPendingTransaction: data => dispatch({ type: Actions.ADD_PENDING_TRANSACTION, data}),
    removePendingTransaction: data => dispatch({ type: Actions.REMOVE_PENDING_TRANSACTION, data}),

    updateLoadingTab: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_LOADING_TAB, data }),
    updatePermissions: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_PERMISSIONS, data }),

    updateRevokingPermission: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_REVOKING_PERMISSION, data }),

    updateNewPermissionFormAddress: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_NEW_PERMISSION_ADDRESS , data}),
    updateNewPermissionFormError: data => dispatch({ type: Actions.DTABLES_VIEW_NEW_PERMISSION_ERROR , data}),
    updateShowNewPermissionForm: data => dispatch({ type: Actions.DTABLES_VIEW_SHOW_NEW_PERMISSION_FORM, data }),
    updateNewPermissionFormValidationMessage: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_NEW_PERMISSION_VALIDATION_MESSAGE, data}),
    updateNewPermissionFormSubmitting: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_NEW_PERMISSION_SUBMITTING, data}),

    updateContract: data => dispatch({ type: Actions.DTABLES_VIEW_UPDATE_CONTRACT, data })


  }
}
export default Methods;
