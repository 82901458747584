import React, { Component } from "react";
import { connect } from "react-redux";
import { Submit, Info, Review } from "./";

import { ConvertEthToWei } from "../../../../helpers/Eth";
import { GetEpochFromTimeZoneDate } from "../../../../helpers/Date";

import AtraAPI from "../../../../apiv2";

import NotificationMethods from "../../../../components/Notifications/methods";
import TriggerMethods from "../../methods/create";
import AccountMethods from "../../../account/methods";

class DeployTrigger extends Component {
  HandleInfoChange = (event) => {
    this.props.infoChange({
      type: event.target.id,
      value: event.target.value,
    });
  };
  handlePrev = () => {
    this.props.updateSelectedStep({ value: "payment" });
  };
  IsCreateFormValid = () => {
    if (this.props.trigger.name.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  //Used for creating json dumps of triggers for testing
  CreateScenarios = () => {
    try {
      if (!window.triggers) {
        window.triggers = [];
      }
      const triggerData = this.GetCreateTriggerBody();
      window.triggers.push(JSON.stringify(triggerData));
      console.log(window.triggers);
    } catch (err) {
      console.log(err);
    }
  };
  Create = () => {
    try {
      this.props.formError({ value: "" });
      this.props.setSubmitting({ value: true });

      let trigger = this.GetCreateTriggerBody();

      let activeEnv = this.props.environments.environments.find(
        (e) => e.active
      );
      let environmentId = null;
      if (activeEnv) {
        environmentId = activeEnv.id;
      }

      if (environmentId) {
        trigger.environmentId = environmentId;
      }

      AtraAPI.CreateTrigger(trigger)
        .then((res) => {
          this.props.setSubmitting({ value: false });
          this.props.notificationsAddPopupMessage({
            message: "Success! - Trigger Created",
            remove: (id) => {
              this.props.notificationsRemovePopupMessage({ value: id });
            },
          });
          this.props.updateSuccess({ value: true });
          this.props.history.push("/triggers");
        })
        .catch((err) => {
          console.log(err);
          if (err == "limit-reached") {
            this.props.updateSuccess({ value: true });
            this.props.updateShowUsageAlertModal({ value: true });
          } else {
            this.props.updateSuccess({ value: false });
            this.props.formError({ value: err });
            this.props.setSubmitting({ value: false });
          }
        });
    } catch (err) {
      this.props.setSubmitting({ value: false });
      console.log(err);
    }
  };
  // This method will take the current state and prune it into a smaller object for the API request
  GetCreateTriggerBody = () => {
    const trigger = { ...this.props.trigger };

    const actionTable = { ...trigger.tables.find((t) => t.selectedByAction) };

    const info = {
      name: trigger.name,
      description: trigger.description,
    };

    const mappings = actionTable.mappings.map((map) => {
      if (map.value === "static" && map.valueType === "price") {
        return { ...map, rawValue: ConvertEthToWei(map.rawValue) };
      } else if (map.value === "static" && map.valueType === "date") {
        return {
          ...map,
          rawValue: GetEpochFromTimeZoneDate(
            map.rawValue,
            this.props.account.user.timezone.code
          ),
        };
      } else {
        return map;
      }
    });

    let action = {
      tableId: actionTable.id,
      mappings: mappings,
      method: actionTable.actionMethod,
    };

    //remove mappings prop
    if (actionTable.actionMethod === "delete") {
      action = {
        tableId: actionTable.id,
        method: actionTable.actionMethod,
      };
    }

    const conditions = trigger.conditions.map((condition, i) => {
      let tempCondition = { ...condition };
      const tempTarget = { ...tempCondition.target };

      if (
        tempCondition.target.static &&
        tempCondition.target.valueType === "price"
      ) {
        tempCondition.target.rawValue = ConvertEthToWei(
          tempCondition.target.rawValue
        );
      } else if (
        tempCondition.target.static &&
        tempCondition.target.valueType === "date"
      ) {
        tempTarget.rawValue = GetEpochFromTimeZoneDate(
          tempTarget.rawValue,
          this.props.account.user.timezone.code
        );
      }
      return {
        description: tempCondition.description,
        base: tempCondition.base,
        operator: tempCondition.operator.value,
        target: tempTarget,
      };
    });

    let payment = {
      enabled: trigger.price.enabled,
    };
    if (payment.enabled) {
      // format value
      let paymentSourceValue = null;
      switch (trigger.price.source.selectedOption.value) {
        case "dtable":
          paymentSourceValue =
            trigger.price.source.selectedOption.selectedColumn;
          break;
        case "static":
          paymentSourceValue = ConvertEthToWei(
            trigger.price.source.selectedOption.rawValue
          );
          break;
        case "range":
          paymentSourceValue = {
            min: ConvertEthToWei(
              trigger.price.source.selectedOption.rawValue.min
            ),
            max: ConvertEthToWei(
              trigger.price.source.selectedOption.rawValue.max
            ),
          };
          break;
        case "unlimited":
          paymentSourceValue = trigger.price.source.selectedOption.rawValue;
          break;
      }

      payment = {
        ...payment,
        source: {
          type: trigger.price.source.selectedOption.value,
          value: paymentSourceValue,
        },
        destination: {
          type: trigger.price.destination.selectedOption.value,
          value:
            trigger.price.destination.selectedOption.value === "dtable"
              ? trigger.price.destination.selectedOption.selectedColumn
              : trigger.price.destination.selectedOption.rawValue,
        },
      };
    }

    return {
      info,
      action,
      conditions,
      payment,
    };
  };

  render() {
    return (
      <div className={`deploy-container section-container`}>
        <h3 className="section-title">Deploy Trigger</h3>
        <Info
          name={this.props.trigger.name}
          nameErrors={null}
          descriptionErrors={null}
          description={this.props.trigger.description}
          onChange={this.HandleInfoChange}
          service={"Trigger"}
          tooltip={`Trigger Info helps you identify your trigger after it's created so be descriptive.`}
        />
        <div className="section-content">
          <div className="header-container">
            <h4>Review</h4>
            <p>
              Make sure all aspects of your trigger are correct before
              deploying. Once deployed, a trigger cannot be editied
            </p>
          </div>
          <Review />
        </div>

        <Submit
          onClick={this.Create}
          isLoading={this.props.trigger.submitting}
          disabled={!this.IsCreateFormValid()}
          text={"Deploy"}
          loadingText={"Deploying"}
          handlePrev={this.handlePrev}
          cancelPath={"/triggers"}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trigger: state.triggersCreate,
    account: state.account,
    environments: state.environments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...TriggerMethods({ dispatch }),
    ...NotificationMethods({ dispatch }),
    ...AccountMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeployTrigger);
