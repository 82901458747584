import { CreateWeb3Object } from './web3';
import { ConvertWeiToEth } from './ethHelpers';

const GetWalletBalance = (walletAddress) => {
  return new Promise(async(resolve, reject)=>{
    try {
      const web3react = CreateWeb3Object();
      const balance = await web3react.eth.getBalance(walletAddress);
      resolve(parseFloat(ConvertWeiToEth(balance)).toFixed(6));
    } catch (err) {
      console.log(err);
      reject(err);
    }
  })
}

export { GetWalletBalance }
