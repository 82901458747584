import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AuthRoute from "./components/Routes/AuthRoute";
import HomeRoute from "./components/Routes/HomeRoute";
import PrivateRoute from "./components/Routes/PrivateRoute";
import PublicRoute from "./components/Routes/PublicRoute";
import LiteUIPublicRoute from "./components/Routes/LiteUIPublic";

import NotFound from "./containers/NotFound";
import Login from "./containers/auth/Login";
import Signup from "./containers/auth/Signup";
import Account from "./containers/account/containers/Account";
import ForgotPassword from "./containers/auth/ForgotPassword";

import Checkout from "./containers/checkout/containers/Checkout";

import Home from "./containers/home/containers/home";
import dTablesDashboard from "./containers/dtables/containers/dTables-Dashboard";
import dTablesCreate from "./containers/dtables/containers/dTables-Create";
import dTablesView from "./containers/dtables/containers/dTables-View";

import TriggersCreate from "./containers/triggers/containers/triggers-Create";
import TriggersView from "./containers/triggers/containers/triggers-View";
import TriggersDashboard from "./containers/triggers/containers/triggers-Dashboard";

import LiteUICreate from "./containers/liteui/containers/liteui-Create";
import LiteUIEdit from "./containers/liteui/containers/liteui-Edit";
import LiteUIDashboard from "./containers/liteui/containers/liteui-Dashboard";
import LiteUIPublic from "./containers/liteui/containers/liteui-Public";

//Overview
import Docs_Overview_About from "./containers/docs/containers/Sections/Overview/About";
import Docs_Overview_GettingStarted from "./containers/docs/containers/Sections/Overview/GettingStarted";

//Tutorials
import Docs_Tutorials_MyFirstDapp from "./containers/docs/containers/Sections/Tutorials/MyFirstDapp";
import Docs_Tutorials_AccountSetup from "./containers/docs/containers/Sections/Tutorials/AccountSetup";
import Docs_Tutorials_DecentralizedOrganization from "./containers/docs/containers/Sections/Tutorials/DecentralizedOrganization";
import Docs_Tutorials_SupplyChainTraceability from "./containers/docs/containers/Sections/Tutorials/SupplyChainTraceability";
import Docs_Tutorials_AccessPassWallets from "./containers/docs/containers/Sections/Tutorials/AccessPassWallets";
import Docs_Tutorials_BuySellItems from "./containers/docs/containers/Sections/Tutorials/BuySellItems";

//User Guides
import Docs_UserGuides_dTables from "./containers/docs/containers/Sections/UserGuides/dTables";
import Docs_UserGuides_Triggers from "./containers/docs/containers/Sections/UserGuides/Triggers";
import Docs_UserGuides_LiteUI from "./containers/docs/containers/Sections/UserGuides/LiteUI";
import Docs_UserGuides_LiteWallet from "./containers/docs/containers/Sections/UserGuides/LiteWallet";

//API Reference
import Docs_API_dTables from "./containers/docs/containers/Sections/APIReferences/dTables";
import Docs_API_Triggers from "./containers/docs/containers/Sections/APIReferences/Triggers";
import Docs_API_LiteUI from "./containers/docs/containers/Sections/APIReferences/LiteUI";

//Support
import Docs_Support_FAQ from "./containers/docs/containers/Sections/Support/FAQ";
import Docs_Support_Feedback from "./containers/docs/containers/Sections/Support/Feedback";
import Docs_Support_ReportIssue from "./containers/docs/containers/Sections/Support/ReportIssue";

//Wallets
import LiteWallet_Create from "./containers/litewallet/containers/wallets-create";
import LiteWallet_View from "./containers/litewallet/containers/wallets-view";
import LiteWallet_Dashboard from "./containers/litewallet/containers/wallets-dashboard";
import LiteWallet_Activate from "./containers/litewallet/containers/activate-wallet";

const RouteMaster = ({ childProps }) => {
  return (
    <Switch>
      <HomeRoute path="/" exact component={Home} props={childProps} />
      <PrivateRoute
        path="/account"
        exact
        component={Account}
        props={childProps}
      />
      <PrivateRoute
        path="/account/:tab"
        component={Account}
        props={childProps}
      />

      <PrivateRoute
        path="/subscription/checkout/:plan"
        exact
        component={Checkout}
        props={childProps}
        showNav={false}
      />

      <PrivateRoute
        path="/dtables"
        exact
        component={dTablesDashboard}
        props={childProps}
      />
      <PrivateRoute
        path="/dtables/create"
        exact
        component={dTablesCreate}
        props={childProps}
      />
      <PrivateRoute
        path="/dtables/view/:id"
        component={dTablesView}
        props={childProps}
      />

      <PrivateRoute
        path="/awallet/create"
        exact
        component={LiteWallet_Create}
        props={childProps}
      />
      <PrivateRoute
        path="/awallet/view/:id"
        exact
        component={LiteWallet_View}
        props={childProps}
      />
      <PrivateRoute
        path="/awallet"
        exact
        component={LiteWallet_Dashboard}
        props={childProps}
      />
      <Redirect exact from="/litewallet" to="/awallet" />
      <HomeRoute
        path="/activate/:wallet/:code"
        className="blue-bg"
        exact
        component={LiteWallet_Activate}
        props={childProps}
      />

      <PrivateRoute
        path="/triggers"
        exact
        component={TriggersDashboard}
        props={childProps}
      />
      <PrivateRoute
        path="/triggers/create"
        exact
        component={TriggersCreate}
        props={childProps}
      />
      <PrivateRoute
        path="/triggers/view/*"
        exact
        component={TriggersView}
        props={childProps}
      />

      <PrivateRoute
        path="/liteui"
        exact
        component={LiteUIDashboard}
        props={childProps}
      />
      <PrivateRoute
        path="/liteui/edit/:id/:view?/:parent?/:record?"
        component={LiteUIEdit}
        props={childProps}
      />
      <PrivateRoute
        path="/liteui/create/:view?/:parent?/:record?"
        component={LiteUICreate}
        props={childProps}
      />

      <LiteUIPublicRoute
        path="/app/:id/:view?/:parent?/:record?"
        component={LiteUIPublic}
        props={childProps}
      />

      <AuthRoute path="/login" exact component={Login} props={childProps} />
      <AuthRoute path="/signup" exact component={Signup} props={childProps} />
      <AuthRoute
        path="/forgotpassword"
        exact
        component={ForgotPassword}
        props={childProps}
      />

      <HomeRoute
        path="/docs"
        exact
        sidebar={true}
        component={Docs_Overview_About}
        props={childProps}
      />

      <HomeRoute
        path="/docs/overview/about"
        sidebar={true}
        component={Docs_Overview_About}
        props={childProps}
      />
      <HomeRoute
        path="/docs/overview/getting-started"
        sidebar={true}
        component={Docs_Overview_GettingStarted}
        props={childProps}
      />

      <HomeRoute
        path="/docs/tutorials/my-first-dapp"
        sidebar={true}
        component={Docs_Tutorials_MyFirstDapp}
        props={childProps}
      />
      <HomeRoute
        path="/docs/tutorials/account-setup"
        sidebar={true}
        component={Docs_Tutorials_AccountSetup}
        props={childProps}
      />
      <HomeRoute
        path="/docs/tutorials/decentralized-organization"
        sidebar={true}
        component={Docs_Tutorials_DecentralizedOrganization}
        props={childProps}
      />
      <HomeRoute
        path="/docs/tutorials/supply-chain-traceability"
        sidebar={true}
        component={Docs_Tutorials_SupplyChainTraceability}
        props={childProps}
      />
      <HomeRoute
        path="/docs/tutorials/create-accesspass-wallets"
        sidebar={true}
        component={Docs_Tutorials_AccessPassWallets}
        props={childProps}
      />
      <HomeRoute
        path="/docs/tutorials/buy-and-sell-items"
        sidebar={true}
        component={Docs_Tutorials_BuySellItems}
        props={childProps}
      />

      <HomeRoute
        path="/docs/user-guides/dtables"
        sidebar={true}
        component={Docs_UserGuides_dTables}
        props={childProps}
      />
      <HomeRoute
        path="/docs/user-guides/triggers"
        sidebar={true}
        component={Docs_UserGuides_Triggers}
        props={childProps}
      />
      <HomeRoute
        path="/docs/user-guides/liteui"
        sidebar={true}
        component={Docs_UserGuides_LiteUI}
        props={childProps}
      />
      <HomeRoute
        path="/docs/user-guides/awallet"
        sidebar={true}
        component={Docs_UserGuides_LiteWallet}
        props={childProps}
      />

      <HomeRoute
        path="/docs/api/dtables"
        sidebar={true}
        component={Docs_API_dTables}
        props={childProps}
      />
      <HomeRoute
        path="/docs/api/triggers"
        sidebar={true}
        component={Docs_API_Triggers}
        props={childProps}
      />
      <HomeRoute
        path="/docs/api/liteui"
        sidebar={true}
        component={Docs_API_LiteUI}
        props={childProps}
      />

      <HomeRoute
        path="/docs/support/faq"
        sidebar={true}
        component={Docs_Support_FAQ}
        props={childProps}
      />
      <HomeRoute
        path="/docs/support/feedback"
        sidebar={true}
        component={Docs_Support_Feedback}
        props={childProps}
      />
      <HomeRoute
        path="/docs/support/report-issue"
        sidebar={true}
        component={Docs_Support_ReportIssue}
        props={childProps}
      />

      <Route props={childProps} component={NotFound} />
    </Switch>
  );
};

class Routes extends Component {
  render() {
    document.body.classList.remove("white");
    const childProps = {
      isAuthenticated: this.props.auth.isAuthenticated,
      history: this.props.history,
      loadingUser: this.props.account.loading.user,
    };
    return <RouteMaster childProps={childProps} />;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
