import React from 'react';
import { Link } from 'react-router-dom';
export default () => {
  return (
    <div>
      <section>
        <h4>Watch a video on how to setup your account. (3 mins)</h4>
        <div className='video-content'>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/IAb5j4dFvjQ?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </section>

      <section>
        <h3>Continue to the My First dApp tutorial</h3>
        <p>Once you've completed the tasks in the video, you are now ready to start creating your first decentralized application! Start with the <Link to='/docs/tutorials/my-first-dapp'>My First dApp</Link> tutorial.</p>
      </section>
    </div>
  )
}
