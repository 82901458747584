import React from 'react';
import { Intro } from './AccessPassWallets/';
import { Next } from '../Utils';

const AccessPassWallets = ({

}) => {
  return (
    <div className='doc-section-content-container docs-tutorial-container '>
      <h2>Create AccessPass Wallets</h2>

      <Intro />

      <Next
        title={'Buy and Sell Items'}
        link={'/docs/tutorials/buy-and-sell-items'}
         />


    </div>
  )
}

export default AccessPassWallets;
