import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import DetailsView from "./DetailsView";
import InvokeView from "./InvokeView";
import ContractView from "./ContractView";
import Actions from '../../../actions/triggers-view';

class PageTabs extends Component {

  constructor(props){
    super(props);
    this._mounted = false;
  }

  componentDidMount(){
    this._mounted = true;
  }

  componentWillUnmount(){
    this._mounted = false;
  }

  handleTabSelect = key => {
    if(key != this.props.triggersView.activeTab){
      this.props.updateLoadingTab({ value: true });
      this.props.setActiveTab({ value: key });
    }
  };

  render() {
    const loading = this.props.triggersView.loading.details;
    if(this.props.triggersView.trigger.status === 'Live'){
      return (
        <div className="tabs-container">
          <Tabs
            className='tab-headers'
            id='tabs_component'
            activeKey={this.props.triggersView.activeTab}
            onSelect={this.handleTabSelect}
          >
            <Tab eventKey="details" title="Details">
              <div className="tab-content">
                {this.props.triggersView.activeTab === 'details' && !loading ? <DetailsView /> : null}
              </div>
            </Tab>
            <Tab eventKey="invoke" title="Invoke">
              <div className="tab-content">
                {this.props.triggersView.activeTab === 'invoke' && !loading ? <InvokeView /> : null }
              </div>
            </Tab>
            <Tab eventKey="contract" title="Contract">
              <div className="tab-content">
                {this.props.triggersView.activeTab === 'contract' && !loading ? <ContractView /> : null }
              </div>
            </Tab>
          </Tabs>
        </div>
      );
    }else{
      return (
        <div className="tabs-container">
          <Tabs
            className='tab-headers'
            id='tabs_component'
          >
            <Tab eventKey="records" title="Details">
            </Tab>
            <Tab eventKey="permissions" title="Invoke">
            </Tab>
            <Tab eventKey="contract" title="Contract">
            </Tab>
          </Tabs>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    triggersView: state.triggersView
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setActiveTab: data => dispatch({ type: Actions.TRIGGERS_VIEW_SET_ACTIVE_TAB, data }),
    updateLoadingTab: data => dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_LOADING_TAB, data })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageTabs);
