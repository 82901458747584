import React from 'react';
import KeyFeatures from './KeyFeatures';
import HelpingPoints from './HelpingPoints';

const MainBlurb = ({
  notLoggedIn,
  keyFeatures
}) => {

  return (
    <div className='main-blurb-container'>

      <div className='blurb-header'>
        {notLoggedIn.header}
      </div>

      <div className='blurb-sub-header'>
        {notLoggedIn.subHeader}
      </div>

      <KeyFeatures keyFeatures={keyFeatures} customClass={'desktop'} />
      <HelpingPoints customClass={'desktop'} />

    </div>
  )
}

export default MainBlurb;
