import React, { Component } from "react";
import {connect} from 'react-redux';
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";

import {Link} from 'react-router-dom';
import PriceInput from "../../../../components/PriceInput/PriceInput";
import DateInput from '../../../../components/DateInput/DateInput';
import AddressInput from '../../../../components/AddressInput/AddressInput';

import NotificationMethods from "../../../../components/Notifications/methods";
import TriggerMethods from '../../methods/create';

import { IsAddress } from '../../../../helpers/Eth';

const SelectTable = ({ tables, selected, tableChange }) => {
  return (
    <div className='table-select-contianer'>
      <FormGroup controlId="table">
        <ControlLabel>Select the dTable you want to modify</ControlLabel>
        <FormControl
          value={selected}
          onChange={tableChange}
          componentClass="select"
        >
          {tables.map((table, i) => {
            return (
              <option key={i} value={table.id}>
                {table.name}
              </option>
            );
          })}
        </FormControl>
      </FormGroup>
    </div>
  );
};

const SelectMethod = ({ methods, selected, methodChange }) => {

  return (
    <div className={`method-select-container`}>
      <div className='method-selections-container'>
        {methods.map((method, i)=>{
          return (
            <div key={i} onClick={()=>methodChange(method.value)} className={`method-container ${selected === method.value ? 'selected' : ''}`}>
              <div className='method-title'>{method.short}</div>
              <div className='method-sub-title'>
                Record
              </div>
            </div>
          )
        })}
      </div>
      <div className='method-description-container'>
        <h4>{methods.find(m => m.value === selected).label}</h4>
        <p>{methods.find(m => m.value === selected).description}</p>
      </div>
    </div>
  );
};

const RenderPointerColumnSelect = ({
  map,
  change,
  columns
}) => {
  return (
    <div>
      <FormGroup controlId={map.name}>
        <FormControl
          data-static={true}
          value={map.rawValue}
          onChange={change}
          componentClass="select"
        >
          {columns.map((column, i)=>{
            return <option key={i} value={column.name}>{column.name}</option>;
          })}
        </FormControl>
      </FormGroup>
    </div>
  )
}

const RenderStaticValueInput = ({
  map,
  change
}) => {
  const childProps = {
    "id": map.name,
    "data-static": true,
    "data-valuetype": map.valueType
  }
  let dataSets = [];
  dataSets['static'] = true;
  dataSets['valuetype'] = map.valueType;

  switch(map.valueType){
    case 'price':
      return <PriceInput
        value={map.rawValue}
        data-valuetype={map.valueType}
        onChange={change}
        childProps={childProps}
        />
    break;
    case 'date':
      return <DateInput
        id={map.name}
        datasets={dataSets}
        value={map.rawValue}
        onChange={change}
      />
    break;
    case 'address':
      return <AddressInput
        id={map.name}
        value={map.rawValue}
        onChange={change}
        datasets={dataSets}
        childProps={childProps}
        />
    default:
      return (
        <FormGroup controlId={map.name}>
          <FormControl
            value={map.rawValue}
            data-valuetype={map.valueType}
            data-static={true}
            onChange={change}
            type="text"
            autoComplete="off"
            placeholder={map.valueType}
          />
        </FormGroup>
      )
    break;
  }
}

const MappingValues = ({
  map,
  availableMappings,
  mappingValueChange,
  pointedTableColumns
}) => {
  return (
    <div>
      <FormGroup controlId={map.name}>
        <FormControl
          value={map.value}
          onChange={mappingValueChange}
          componentClass="select"
        >
          {availableMappings
            .filter(m => m.valueType === map.valueType || m.valueType === "any")
            .map((mappingType, i) => {
              return (
                <option key={i} value={mappingType.value}>
                  {mappingType.label}
                </option>
              );
            })}
        </FormControl>
      </FormGroup>
      {map.value === "static" ? (
        <RenderStaticValueInput
          map={map}
          change={mappingValueChange}
           />
      ) : null}
      {map.value === "automap" ? (
        <RenderPointerColumnSelect
          map={map}
          change={mappingValueChange}
          columns={pointedTableColumns}
         />
      ) : null}
    </div>
  );
};

const Mapping = ({
  map,
  availableMappings,
  mappingValueChange,
  pointedTableColumns
}) => {
  return (
    <tr>
      <td>
        <ControlLabel>
          {map.name} ({map.valueType})
        </ControlLabel>
      </td>
      <td>
        <MappingValues
          map={map}
          availableMappings={availableMappings}
          mappingValueChange={mappingValueChange}
          pointedTableColumns={pointedTableColumns}
        />
      {map.error ? <div className='error'>{map.error}</div> : null}
      </td>
    </tr>
  );
};

const ValueMapping = ({
  method,
  mappings,
  availableMappings,
  mappingValueChange,
  actionType,
  pointedTableColumns
}) => {
  if (availableMappings.length) {
    return (
      <div className='value-mapping-container'>
        <ControlLabel>Configure how each record value will map to user input.</ControlLabel>
        <table>
          <tbody>
            {mappings.map((map, i) => {
              return (
                <Mapping
                  key={i}
                  map={map}
                  availableMappings={availableMappings}
                  mappingValueChange={mappingValueChange}
                  pointedTableColumns={pointedTableColumns}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return null;
  }
};

class Action extends Component {

  componentDidUpdate = () => {

  }

  isValid = () => {
    let valid = true;
    //search action mapping for errors
    const selectedTable = this.props.trigger.tables.find(t => t.selectedByAction);
    //check value mappin for static values
    selectedTable.mappings.map((map, i)=>{
      if(map.error){
        valid = false;
      }
    });
    return valid;
  }

  handleNext = () => {
    this.props.save();
  }

  handlers = {
    tableChange: event => {
      this.props.updateTable({
        type: event.target.id,
        value: event.target.value
      });
    },
    methodChange: value => {
      this.props.methodChange({value, timezone: this.props.account.user.timezone});
    },
    mappingValueChange: event => {
      const type = event.target.id;
      const isStatic = event.target.dataset["static"] ? true : false;
      const value = event.target.value;
      //validate before updating value, if there is an error write it to the mapping
      this.props.mappingValueChange({
        type: type,
        static: isStatic,
        value: value,
        error: false
      });
    }

  }
  render() {
    let tables = this.props.trigger.tables;
    const selectedTable = tables.find(t => t.selectedByAction);
    const availableMappings = this.props.trigger.methods.find(
      m => m.value === selectedTable.actionMethod
    ).mappingTypes;

    //if pointer is available
    const pointerMapping = selectedTable.mappings.find( m => m.valueType === 'pointer');
    let pointedTableColumns = [];
    if(pointerMapping){
      pointedTableColumns = tables.find(t => t.id === selectedTable.columns.find(c => c.name === pointerMapping.name ).tableId).columns.filter( c => c.type === 'address');
    }

    return (
      <div className={`action-container section-container`}>
        <form autoComplete="off">
          <h3 className="section-title">Select Action</h3>
          <div className='section-content'>
            <div className='header-container'>
              <h4>Action Options</h4>
              <p>Set the outcome of the trigger</p>
            </div>
            <fieldset>
              <SelectMethod
                methods={this.props.trigger.methods}
                selected={selectedTable.actionMethod}
                methodChange={this.handlers.methodChange}
              />
              <SelectTable
                tables={tables}
                selected={selectedTable.id}
                tableChange={this.handlers.tableChange}
              />
              <ValueMapping
                method={selectedTable.actionMethod}
                mappings={selectedTable.mappings}
                availableMappings={availableMappings}
                mappingValueChange={this.handlers.mappingValueChange}
                pointedTableColumns={pointedTableColumns}
              />
            </fieldset>
          </div>
          <div className="submit-buttons">
            <Link to='/triggers'>Cancel</Link>
            <Button
              className='secondary-button'
              onClick={this.handleNext}
              disabled={!this.isValid()}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    trigger: state.triggersCreate,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...TriggerMethods({ dispatch }),
    ...NotificationMethods({ dispatch })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Action);
