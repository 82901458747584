import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import timezones from './timezones';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

const DateInput = ({
  datasets,
  value,
  onChange,
  id,
  inline = false,
  timezone
}) => {
  const DateChangeFunc = date => {
    const tempEvent = {
      target: {
         value: date,
         dataset: datasets,
         id: id
       }
    }
    onChange(tempEvent);
  }
  if(!value){
    value = new Date()
  }
  return <div>
    <DatePicker
      inline={inline}
      popperClassName="datepicker-modal"
      className="form-control datepicker-input"
      selected={value}
      onChange={DateChangeFunc}
      showTimeSelect
      timeIntervals={15}
      dateFormat="MMMM d, yyyy h:mm aa"
      timeCaption="time"
      popperModifiers={{
        flip: {behavior: ['top-bottom']},
        preventOverflow: { enabled: false },
        hide: { enabled: false }
      }}
    />
  <div><Link to='/account'>Time Zone: {timezone}</Link></div>
  </div>
}
class DatePickerComp extends Component {

  render(){
    let timezone = Object.entries(timezones).find( t => t[1] === this.props.account.user.timezone.code )[0];
    if(!this.props.auth.isAuthenticated){
      timezone = Object.entries(timezones).find( t => t[1] === moment.tz.guess() )[0];
    }
    return (
      <DateInput
        timezone={timezone}
        {...this.props}
      />
    )
  }
}
const mapStateToProps = state => {
  return {
    account: state.account,
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatePickerComp);
