import React from 'react';

import JSONPretty from 'react-json-pretty';
import Content from './';
import { Next } from '../Utils';

const Triggers = ({

}) => {

  //Code samples
  const getTriggerResponse = [
    {
        "goerli": "0x8d8aB041eF262ecDCdA2e0757881b0FA4CFEEa2C",
        "status": "Live",
        "created": 1553188385712,
        "description": "allow users to post",
        "id": "cd6534d7-7358-49e0-886e-cc930e22d2d5",
        "name": "Social_Post",
        "transactionHash": "0x786001393f39bc7ba0b5f37dc1698eb735c1667bb399b9ddba67c7f1ac51d3d4"
    },
    {
        "status": "Processing",
        "created": 1554790255553,
        "message": "\"[{\\\"component\\\":\\\"general\\\",\\\"formattedMessage\\\":\\\"trigger:3:1: Warning: Experimental features are turned on. Do not use experimental features on live deployments.\\\\npragma experimental ABIEncoderV2;\\\\n^-------------------------------^\\\\n\\\",\\\"message\\\":\\\"Experimental features are turned on. Do not use experimental features on live deployments.\\\",\\\"severity\\\":\\\"warning\\\",\\\"sourceLocation\\\":{\\\"end\\\":58,\\\"file\\\":\\\"trigger\\\",\\\"start\\\":25},\\\"type\\\":\\\"Warning\\\"},{\\\"component\\\":\\\"general\\\",\\\"formattedMessage\\\":\\\"trigger:145:38: DeclarationError: Identifier already declared.\\\\n  function Condition0(uint _msgValue,uint _msgValue) private pure {\\\\n                                     ^------------^\\\\ntrigger:145:23: The previous declaration is here:\\\\n  function Condition0(uint _msgValue,uint _msgValue) private pure {\\\\n                      ^------------^\\\\n\\\",\\\"message\\\":\\\"Identifier already declared.\\\",\\\"severity\\\":\\\"error\\\",\\\"sourceLocation\\\":{\\\"end\\\":4764,\\\"file\\\":\\\"trigger\\\",\\\"start\\\":4750},\\\"type\\\":\\\"DeclarationError\\\"}]\"",
        "description": "set trigger description",
        "id": "86187653-bf1c-4e8c-9bcd-0e9a15952bb1",
        "name": "DoubleCon",
        "error": "Compiling Contract Failed"
    }
  ]

  const createTriggerRequest = {
    "info": {
      "name": "UpPostDownPost_Endorse",
      "description": "allow user to endorse"
    },
    "action": {
      "tableId": "140074ba-ccc7-4cee-a5d1-9f0fd09a5b86",
      "method": "update",
      "mappings": [{
        "name": "Post",
        "valueType": "text",
        "value": "self",
        "rawValue": ""
      }, {
        "name": "PriceToRemove",
        "valueType": "price",
        "value": "msg.value",
        "rawValue": "0"
      }, {
        "name": "CurrentEndorser",
        "valueType": "address",
        "value": "msg.sender",
        "rawValue": ""
      }, {
        "name": "OriginalPoster",
        "valueType": "address",
        "value": "self",
        "rawValue": ""
      }]
    },
    "conditions": [{
      "description": "is higher",
      "base": {
        "label": "PriceToRemove",
        "value": "PriceToRemove",
        "valueType": "price",
        "groupLabel": "Action dTable",
        "groupId": "actionTable"
      },
      "operator": "less",
      "target": {
        "label": "ETH Sent by User",
        "value": "msg.value",
        "valueType": "price",
        "disabled": false,
        "groupLabel": "User",
        "groupId": "user"
      }
    }],
    "payment": {
      "enabled": true,
      "source": {
        "type": "unlimited"
      },
      "destination": {
        "type": "dtable",
        "value": "CurrentEndorser"
      }
    }
  }

  const createTriggerResponse = {
    "triggerId": "689244ca-5f0c-49cf-a84d-d0e869af7a97"
  }

  return (
    <div className='doc-section-content-container'>
      <h2>Triggers</h2>
      <Content.Headers/>

        <section>
          <h5>Get All Triggers</h5>
          <h2>GET /triggers</h2>
          <div>Returns all Triggers for your account</div>

          <h5>Response JSON (Array)</h5>
          <ul>
            <li><b>name</b> - (string): </li>
            <li><b>id</b> - (string): </li>
            <li><b>description</b> - (string): </li>
            <li><b>status</b> - (string): </li>
            <li><b>goerli</b> - (string): </li>
            <li><b>transactionHash</b> - (string): </li>
            <li><b>created</b> - (number): </li>
            <li><b>error</b> - (string): </li>
            <li><b>message</b> - (string): </li>
          </ul>

          <h5>Example Response</h5>
          <JSONPretty id="json-pretty" data={getTriggerResponse}></JSONPretty>

        </section>

        <section>
          <h5>Create new Trigger</h5>
          <h2>POST /triggers</h2>
          <div>Create a new Trigger with your account. We recommend starting with the console triggers page to build your triggers. This endpoint can be used to automate the process of creating new triggers and is an advanced feature of the API.</div>

          <h5>Request JSON (Object)</h5>
          <ul>
            {/* Info */}
            <li><b>info</b> - (object): Atra stored info</li>
            <ul>
              <li><b>name</b> - (string): The name of the trigger</li>
              <li><b>description</b> - (string): The description of the trigger</li>
            </ul>

            {/* Action */}
            <li><b>action</b> - (Object): The action is the outcome of the trigger</li>
            <ul>
              <li><b>tableId</b> - (string): A dTable ID from your account</li>
              <li><b>method</b> - (string): The type of action method (insert, update, delete)</li>
              <li><b>mappings</b> - (array): Mappings are required for method types (insert, update)</li>
              <ul>
                <li><b>value</b> - (string): The type of mapping (input, static, self, msg.value, msg.sender)</li>
                <li><b>name</b> - (string): The name of the dTable column to map</li>
                <li><b>valueType</b> - (string): Required for Static value types. Set this to the Atra type of the column</li>
                <li><b>rawValue</b> - (string): Required for Static value types. Set this to the static value</li>
              </ul>
            </ul>

            {/* Conditions */}
            <li><b>conditions</b> - (array): </li>
            <ul>
              <li><b>description</b> - (string): </li>
              <li><b>base</b> - (object): </li>
              <ul>
                <li><b>groupId</b> - (string): Type type of base value provided (actionTable, inputs, user)</li>
                <li><b>valueType</b> - (string): The Atra value type for the base value</li>
                <li><b>value</b> - (string): The value for the specified group type. actionTable ([the name of the column]). input ([name of the column]), user (msg.sender, msg.value)</li>
              </ul>
              <li><b>operator</b> - (string): The type of comparison operator used for the condition (equal, notEqual, greater, greaterEqual, less, lessEqual, in, notIn)</li>
              <li><b>target</b> - (object): </li>
                <ul>
                  <li><b>groupId</b> - (string): Type type of base value provided (inputs, list, static, user)</li>
                  <li><b>valueType</b> - (string): The Atra value type for the target value</li>
                  <li><b>value</b> - (string): The value for the specified group type. input ([name of the column]), list (), user (msg.sender, msg.value)</li>
                  <li><b>tableId</b> - (string):  Required for (list) type. The dTable ID to search.</li>
                  <li><b>column</b> - (string):  Required for (list) type. The dTable column to compare against.</li>
              </ul>
            </ul>

            {/* Payments */}
            <li><b>payment</b> - (object): </li>
            <ul>
              <li><b>enabled</b> - (boolean): Set to true if the trigger will charge the user ETH to invoke</li>
              <li><b>source</b> - (object): The source of the price to charge a user</li>
              <ul>
                <li><b>type</b> - (string): The type of payment source (static, range, unlimited, dtable)</li>
                <li><b>value</b> - (string): The value of payment source</li>
                <li><b>value</b> - (object): When type is range provide an object</li>
                  <ul>
                    <li><b>min</b> - (number): Amount in Wei</li>
                    <li><b>max</b> - (number): Amount in Wei</li>
                  </ul>
              </ul>
              <li><b>destination</b> - (object): The destination address where to transfer the funds</li>
              <ul>
                <li><b>type</b> - (string): The type of destination (static, dtable)</li>
                <li><b>value</b> - (string): The value of destination type. static (eth address), dtable (column name)</li>
              </ul>
            </ul>
          </ul>

          <h5>Example Request</h5>
          <JSONPretty id="json-pretty" data={createTriggerRequest}></JSONPretty>


          <h5>Response JSON (Array)</h5>
          <ul>
            <li><b>triggerId</b> - (string): The triggers id in the Atra system</li>
          </ul>

          <h5>Example Response</h5>
          <JSONPretty id="json-pretty" data={createTriggerResponse}></JSONPretty>

        </section>

        <Next
          title={'LiteUI API'}
          link={'/docs/api/liteui'}
           />

    </div>
  )
}

export default Triggers;
