import React, { Component } from "react";
import { connect } from 'react-redux';

import AtraAPI from '../../../apiv2';
import EnvMethods from '../../../components/Environments/methods';

import { Container } from '../components';

import { FormatDate, FormatDateTime } from "../../../helpers/Date";
import {
  DashboardStatsContainer,
  DashboardActionHeader,
  DashboardGrid,
  DashboardFooter,
  DashboardGridNoContent
} from "../../../components/ServiceDashboard";

class LiteUIDashboard extends Component {

  constructor(props) {
    super(props);
    this._mounted = false;
    this._noContent = <DashboardGridNoContent
        title={'Add a LiteUI'}
        description={'Turn your dTables and Triggers into a website for everyone to use.'}
        buttonText={'Create LiteUI'}
        onClick={()=>this.props.history.push('/liteui/create')}
        learnMoreClick={()=>this.props.history.push('/docs/user-guides/liteui')}
       />
    this.state = {
      loading: true,
      liteuis: [],
    };
    this.props.updateEnvironmentsEnabled({ enabled: true });
  }

  async componentDidMount() {
    document.body.classList.add("white");
    this._mounted = true;
    this.pollUIs();
  };

  async componentWillUnmount() {
    this._mounted = false;
  }

  async pollUIs() {
    if(this._mounted){
      await this.getLiteUIs().then(()=>{
        setTimeout(async ()=>{
          await this.pollUIs();
        },5000);
      });
    }
  }

  getLiteUIs = () => {
    return new Promise((resolve, reject)=>{
      let envId = window.localStorage.getItem('activeEnvironment');
      AtraAPI.GetLiteUIs(envId).then(res=>{
        if(this._mounted === true) {

          const liteuis = res.data.map(ui => {
            return {
              ...ui,
              date: FormatDateTime(ui.created, this.props.account.user.timezone.code),
              link: `/liteui/edit/${ui.id}`
            }
          });

          this.setState({ liteuis: liteuis, loading: false  });
          resolve();
        }
      },err=>{
        console.log(err);
        this.setState({ loading: false });
        resolve();
      })
    });
  }

  createLiteUI = event => {
    this.props.history.push("/liteui/create");
  }

  render() {
    document.body.classList.add("white");
    const breadcrumbs = [];
    breadcrumbs.push({ text: 'LiteUI', to: '/liteui', icon: "liteui"  });

    const statsList = [];
    statsList.push({
      title: 'Total UIs',
      value: this.state.liteuis.length
    });

    statsList.push({
      title: 'Total Users',
      value: 0
    });

    statsList.push({
      title: 'Activity',
      value: 0
    });

    const rightContent = <div className='action-icon'>
      <img
        onClick={()=>this.props.history.push('/liteui/create')}
        src={require('../components/Icons/blue_plus.svg')}
      />
    </div>;

    return (
      <Container
        className='service-dashboard-container'
        breadcrumbs={breadcrumbs}
        rightContent={rightContent}
      >

       <DashboardGrid
         type={'liteui'}
         source={this.state.liteuis.sort((a,b)=> b.created - a.created)}
         loading={!this.state.liteuis.length && this.state.loading}
         noContent={this._noContent}
          />

       <DashboardFooter
         title={'LiteUI'}
         description={`Point and click to build a user interface for your application. Connect dTables and Triggers together to create UI elements in the LiteUI Builder.`}
         link={'/docs/user-guides/liteui'}
          />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.account,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...EnvMethods({dispatch})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiteUIDashboard);
