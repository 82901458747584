import React from 'react';
import SectionContainer from './section-container';
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from '../../../components/Buttons/LoaderButton';


const UpdateAddressContainer = ({
  newAddress,
  error,
  isSubmitting,
  disabled,
  handleNewAddressSubmit,
  handleUpdateNewAddressInputChange,
  handleCancelNewAddress
}) => {
  return (
    <div className='update-address-container'>
      <FormGroup
        controlId='account_address'
        validationState={newAddress.validationMessage ? 'error' : null}
        >
        <FormControl
          placeholder='enter your ETH wallet address'
          type='text'
          value={newAddress.address}
          onChange={handleUpdateNewAddressInputChange}
        />
        {error}
      </FormGroup>
      <Button
        className='black-button'
        onClick={handleCancelNewAddress}
      >
        Cancel
      </Button>
      <LoaderButton
        disabled={disabled}
        isLoading={isSubmitting}
        loadingText='Updating'
        className='secondary-button'
        onClick={handleNewAddressSubmit}
        text='Update'
      />
    </div>
  )
}

const SetAddressContainer = ({
  newAddress,
  error,
  disabled,
  isSubmitting,
  handleNewAddressSubmit,
  handleUpdateNewAddressInputChange
}) => {
  return (
    <div className='update-address-container'>
      <FormGroup controlId='account_address'>
        <h4>Set Your Wallet Address</h4>
        <FormControl
          placeholder='enter your ETH wallet address'
          type='text'
          value={newAddress.address}
          onChange={handleUpdateNewAddressInputChange}
        />
        {error}
      </FormGroup>
      <LoaderButton
        disabled={disabled}
        isLoading={isSubmitting}
        loadingText='Saving'
        className='secondary-button'
        onClick={handleNewAddressSubmit}
        text='Save'
      />
    </div>
  )
}

const UserAddressSection = ({
  address,
  newAddress,
  errorMessage,
  showNewAddressForm,
  loading,
  isSubmitting,
  submitNewAddressButtonDisabled,
  handleUpdateNewAddressInputChange,
  handleNewAddressSubmit,
  handleShowChangeAddressForm,
  handleCancelNewAddress
}) => {
  let content = null;
  const description = "Your wallet address is used only by you throughout the console to help you quick fill address input boxes. Setting a wallet address is completely optional.";

  if(loading){
    content = 'loading...';
  }else{
    if(!address){
      content = <SetAddressContainer
        error={errorMessage}
        newAddress={newAddress}
        disabled={submitNewAddressButtonDisabled}
        isSubmitting={isSubmitting}
        handleUpdateNewAddressInputChange={handleUpdateNewAddressInputChange}
        handleNewAddressSubmit={handleNewAddressSubmit}
        />;
    }else if(showNewAddressForm){
      content = <UpdateAddressContainer
        error={errorMessage}
        handleCancelNewAddress={handleCancelNewAddress}
        newAddress={newAddress}
        disabled={submitNewAddressButtonDisabled}
        isSubmitting={isSubmitting}
        handleUpdateNewAddressInputChange={handleUpdateNewAddressInputChange}
        handleNewAddressSubmit={handleNewAddressSubmit}
      />;
    }else{
      content = <div className='update-address-container'>
        <div className='address'>Wallet Address: {address}</div>
        <Button className='secondary-button' onClick={handleShowChangeAddressForm}>Change Address</Button>
      </div>;
    }
  }

  return <SectionContainer
            header={'Ethereum Wallet'}
            description={description}
            content={content}
          />;
}

export default UserAddressSection;
