let defaultState = {
  eth: {
    price: '',
    lastUpdated: null
  }
}

const priceInput = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case "UPDATE_ETHUSD_PRICE":
      var newState = {...state};
      //allow update every 30 seconds
      const newDateTime = new Date().getTime();
      newState.eth.price = action.data.price;
      newState.eth.lastUpdated = newDateTime;

      return newState;
    default:
      return state;
  }
};

export default priceInput;
