import { API } from "aws-amplify";

const GetContract = (service, id) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        queryStringParameters: {
          service: service,
          id: id
        }
      };
      API.get("contracts", "", data).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export default { GetContract };
