import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFont, faComments, faKeyboard, faThList, faHeart } from '@fortawesome/free-solid-svg-icons'


const GetIcon = ({
  icon
}) => {
  switch(icon){
    case 'faThList':
      return <div className='element-icon list'><FontAwesomeIcon icon={faThList} /></div>;
    break;
    case 'faFont':
      return <div className='element-icon text'><FontAwesomeIcon icon={faFont} /></div>;
    break;
    case 'faKeyboard':
      return <div className='element-icon form'><FontAwesomeIcon icon={faKeyboard} /></div>;
    break;
    case 'faComments':
      return <div className='element-icon chat'><FontAwesomeIcon icon={faComments} /></div>;
    break;
    default:
      return null;
  }
}

export default GetIcon;
