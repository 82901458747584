import Actions from "../actions/liteui";
import FeatureFlags from '../../../components/FeatureFlags';

class ElementOptions {
  constructor(sourceType){
    switch(sourceType){
      case 'dtables':
        this.type = 'list';
        this.title = 'List';
        this.selected = true;
        this.icon = 'faThList';
        this.sourceType = 'dtables';
        this.description = 'A List displays a table with pagination.';
        this.config = {
          // Bind Actions to Element
          actions:[
            {
              source: {
                type: 'etherscan'
              },
              title: 'View on Etherscan',
              locked: true
            }
          ],
          // Render and filter columns
          renderMapping: [],
          viewDetails: {
            title: 'Details'
          },
          pointerTables: [],
          filter: {
            recordId: false
          }
        };

      break;
      case 'triggers':
        this.type = 'form';
        this.title = 'Form';
        this.selected = false;
        this.icon = 'faKeyboard';
        this.sourceType = 'triggers';
        this.description = 'A Form displays input boxes and a submit button.';
        this.config = {
          autofillPointer: false
        };
      break;
      case 'textarea':
        this.type = 'text';
        this.title = 'Text';
        this.selected = false;
        this.icon = 'faFont';
        this.sourceType = 'textarea';
        this.description = 'A Text element displays descriptive text for your users.';
        this.config = {};
      break;
      case 'chat':
        this.type = 'chat';
        this.title = 'Chat';
        this.selected = false;
        this.icon = 'faComments';
        this.sourceType = 'chat';
        this.description = 'A Chat element displays a realtime chat for users.';
        this.config = {};
      break;
      default:
        this.config = {};
      break;
    }
  }

}
const AddElementOptions = [
  {
    type: 'list',
    title: 'List',
    selected: true,
    icon: 'faThList',
    sourceType: 'dtables',
    description: 'A List displays a table with pagination.',
    config: {
      // Bind Actions to Element
      actions:[{
        source: {
          type: 'etherscan'
        },
        title: 'View on Etherscan',
        locked: true
      }],
      // Render and filter columns
      renderMapping: {

      }
    }
  },
  {
    type: 'form',
    title: 'Form',
    selected: false,
    icon: 'faKeyboard',
    sourceType: 'triggers',
    description: 'A Form displays input boxes and a submit button.'
  },
  {
    type: 'text',
    title: 'Text',
    selected: false,
    icon: 'faFont',
    sourceType: 'textarea',
    description: 'A Text element displays descriptive text for your users.'
  }

];
if(FeatureFlags.Chat){
  AddElementOptions.push({
    type: 'chat',
    title: 'Chat',
    selected: false,
    icon: 'faComments',
    sourceType: 'chat',
    description: 'A Chat element displays descriptive text for your users.',
    config: {
      // Bind Actions to Element
      address: null
    }
  });
}
class View {
  constructor(name, elements) {
    this.id = new Date().getTime();
    this.name = name;
    this.elements = elements ? elements : [];
  }
}
class DetailView {
  constructor(){
    this.show = false;
    this.recordId = null;
    this.parentElementId = null;
    this.isParentInsideDetailView = false;
  }
}
class ConfigAction {
  constructor(title,locked){
    this.source = {
      type: 'trigger',
      id: null
    };
    this.title = title;
    this.locked = locked;
  }
}
class AddElement {
  constructor(title, type, source, options) {
    this.id = new Date().getTime();
    this.title = title;
    this.type = type;
    this.source = source;
    this.options = options;
  }
}
class Element {
  constructor(title, type, source, config = {}) {
    this.id = new Date().getTime();
    this.title = title;
    this.type = type;
    this.source = source;
    this.config = config;
  }
}
class Transaction {
  constructor(txHash, contractAddress, date, data, gas, complete){
    this.txHash = txHash;
    this.contractAddress = contractAddress;
    this.date = date;
    this.data = data;
    this.gas = gas;
    this.complete = complete;
  }
}
class Logo {
  constructor(base64 = '', set = false){
    this.tooLarge = false;
    this.set = set;
    this.base64 = base64;
    this.message = '';
  }
}
class UX {
  constructor() {
    this.ux = {
      timezone: {
        code: 'Europe/London'
      },
      logo: new Logo(),
      detailView: new DetailView(),
      success: false,
      unsavedChanges: false,
      selectedView: false,
      showTransactionsView: false,
      showAppNameModal: false,
      pendingTransactions: [],
      readOnlyWarning: {
        show: false
      },
      showViewNameModal: {
        show: false,
        view: {},
        id: null
      },
      showAddPageModal: {
        show: false,
        name: '',
        error: ''
      },
      showEditPageModal: {
        show: false,
        name: '',
        pageId: '',
        error: ''
      },
      showAddElementModal: {
        show: false,
        view: {},
        id: null
      },
      showEditElementTitleModal: {
        show: false,
        view: {},
        element: new Element(),
        id: null
      },
      showEditHeaderModal: {
        show: false,
        name: '',
        subheader: '',
        logo: new Logo()
      },
      showEditTextModal: {
        show: false,
        id: null,
        view: {},
        element: new Element()
      },
      showEditListModal: {
        show: false,
        id: null,
        view: {},
        element: new Element(),
        editingAction: new ConfigAction('',false)
      },
      showEditFormModal : {
        show: false,
        id: null,
        view: {},
        element: new Element(),
        autofillCompatible: false
      }
    }
  }
}
class LiteWallet {
  constructor(){
    this.enabled = false;
    this.showLoadedModal = false;
    this.address = '';
    this.pk = '';
    this.balance = '';
    this.disabled = false;
  }
}
class DefaultState {
  constructor() {
    this.loading = {
      triggers: true,
      tables: true,
      liteui: true
    }
    this.explorePageEnabled = true;
    this.submitting = false;
    this.error = '';
    this.canCreate = false;
    this.name = '';
    this.subheader = '';
    this.logo = '';
    this.views = [];
    this.detailViewElements = [];
    this.editing = {
      uielement: new AddElement(null,null,null, [...AddElementOptions])
    }
    this.mode = 'public';
    let uxObj = new UX();
    this.ux = uxObj.ux;
    this.sources = {
      dtables: [],
      triggers: []
    }
    this.liteWallet = new LiteWallet();
  }
}

const liteui = (state = new DefaultState(), action) => {
  switch (action.type) {
    //Timezone
    case Actions.UPDATE_TIMEZONE: {
      let newState = {...state};
      newState.ux.timezone = action.data.value;
      return newState;
    }

    //Error
    case Actions.UPDATE_ERROR:
      return {...state, error: action.data.value };

    //Scuccess
    case Actions.UPDATE_SUCCESS: {
      let newState = {...state};
      newState.ux.success = action.data.value;
      return newState;
    }

    //Warning
    case Actions.UPDATE_SHOW_READ_ONLY_WARNING:{
      var newState = {...state};
      newState.ux.readOnlyWarning.show = action.data.value;
      return newState;
    }
    //Loading
    case Actions.UPDATE_LOADING_TABLES:
      return {...state, loading: { ...state.loading, tables: action.data.value }};
    case Actions.UPDATE_LOADING_TRIGGERS:
      return {...state, loading: { ...state.loading, triggers: action.data.value }};
    case Actions.UPDATE_LOADING_LITEUI:
      return {...state, loading: { ...state.loading, liteui: action.data.value }};

    //Submit Loader
    case Actions.UPDATE_SUBMITTING:
      return {...state, submitting: action.data.value};

    //State
    case Actions.RESET_STATE:
      var newState = {...state};
      newState.views = [];
      newState.name = '';
      newState.subheader = '';
      newState.logo = '';
      newState.explorePageEnabled = true;
      let uxObj = new UX();
      newState.ux = {...uxObj.ux, success: newState.ux.success, unsavedChanges: newState.ux.unsavedChanges};
      return newState;
    case Actions.UPDATE_STATE:
      var newState = {...state, ...action.data};
      //check for logo and set logo state for editing
      if(newState.logo){
        newState.ux.logo = new Logo(newState.logo, true);
      }
      if(!action.data.hasOwnProperty('explorePageEnabled')){
        newState.explorePageEnabled = false;
      }
      //Set element configs to all have updated values
      newState.detailViewElements = newState.detailViewElements.map((element,i)=>{
        const options = new ElementOptions(element.source.type);
        element.config = {...options.config, ...element.config};
        return element;
      });

      newState.views = newState.views.map((view, i)=>{

        view.elements = view.elements.map((element,i)=>{
          const options = new ElementOptions(element.source.type);
          element.config = {...options.config, ...element.config};
          return element;
        })
        return view;

      })

      return newState;

    //LiteUI Display mode
    case Actions.UPDATE_MODE:
      var newState = {...state};
      newState.mode = action.data;
      return newState;

    //Data Sources
    case Actions.SET_DTABLES:
      var newState = {...state};
      newState.sources.dtables = action.data.dtables;
      return newState;
    case Actions.SET_TRIGGERS: {
      var newState = {...state};
      newState.sources.triggers = action.data.triggers;
      return newState
    }

    //User State
    case Actions.UPDATE_CAN_CREATE:
      return {...state, canCreate: action.data};

    //Explore Page Enabled
    case Actions.UPDATE_EXPLORE_PAGE_ENABLED: {
      var newState = {...state};
      newState.explorePageEnabled = action.data.value;
      return newState;
    }

    //Transactions
    case Actions.UPDATE_SHOW_TX_VIEW: {
      let newState = {...state};
      newState.ux.showTransactionsView = action.data.value;
      return newState;
    }
    case Actions.ADD_PENDING_TX: {
      let newState = {...state};
      let newTx = new Transaction(
        action.data.txHash,
        action.data.contractAddress,
        action.data.date,
        action.data.data,
        action.data.gas,
        action.data.complete
      );
      newState.ux.pendingTransactions.push(newTx);
      return newState;
    }
    case Actions.REMOVE_PENDING_TX: {
      let newState = {...state};
      let index = newState.ux.pendingTransactions.findIndex(t => t.txHash === action.data.txHash);
      newState.ux.pendingTransactions.splice(index, 1);
      return newState;
    }

    //Detail View
    case Actions.UPDATE_DETAIL_VIEW: {
      let newState = {...state};
      newState.ux.detailView = action.data.value;
      return newState;
    }

    //Views
    case Actions.UPDATE_SELECTED_VIEW:
      var newState = {...state};
      newState.ux.showTransactionsView = false;
      newState.ux.selectedView = action.data.value;
      newState.ux.detailView = new DetailView();

      return newState;
    case Actions.ADD_VIEW:
      var newState = {...state};
      newState.ux.unsavedChanges = true;
      newState.views.push(new View(`View${newState.views.length + 1}`, []));
      return newState;
    case Actions.DELETE_VIEW:
      var newState = {...state};
      newState.ux.unsavedChanges = true;
      newState.views.splice(action.data.view,1);
      newState.ux.selectedView = false;
      return newState;

    //Edit Show Header Modal
    case Actions.UPDATE_SHOW_EDIT_HEADER_MODAL: {
      var newState = {...state};
      newState.ux.showEditHeaderModal.show = action.data.value;
      if(action.data.value === true){
        //set the startig state
        newState.ux.showEditHeaderModal.name = newState.name;
        newState.ux.showEditHeaderModal.subheader = newState.subheader;
        newState.ux.showEditHeaderModal.logo = {...newState.ux.logo};
      }else{
        newState.ux.showEditHeaderModal.header = '';
        newState.ux.showEditHeaderModal.subheader = '';
        newState.ux.showEditHeaderModal.logo = new Logo();
      }
      return newState;
    }
    case Actions.UPDATE_EDIT_HEADER: {
      var newState = {...state};
      newState.ux.showEditHeaderModal.name = action.data.value;
      return newState;
    }
    case Actions.UPDATE_EDIT_SUBHEADER: {
      var newState = {...state};
      newState.ux.showEditHeaderModal.subheader = action.data.value;
      return newState;
    }
    case Actions.UPDATE_EDIT_LOGO: {
      var newState = {...state};
      //API Gateway has a max payload of 10mb
      //if we are thinking about a limit it should be much lower
      const maxsize = 1000000; //1mb
      if(!action.data){
        newState.ux.showEditHeaderModal.logo = new Logo();
        return newState;
      }

      if(action.data.size > maxsize){
        newState.ux.showEditHeaderModal.logo.tooLarge = true;
        newState.ux.showEditHeaderModal.logo.message = 'Image exceeds 1MB max size';
      }else{
        newState.ux.showEditHeaderModal.logo.tooLarge = false;
        newState.ux.showEditHeaderModal.logo.message = '';
        newState.ux.showEditHeaderModal.logo.base64 = action.data.base64;
      }
      newState.ux.showEditHeaderModal.logo.set = true;
      return newState;
    }
    case Actions.SAVE_HEADER_MODAL_EDITS: {
      var newState = {...state};
      newState.name = newState.ux.showEditHeaderModal.name;
      newState.subheader = newState.ux.showEditHeaderModal.subheader;
      newState.ux.logo = newState.ux.showEditHeaderModal.logo;
      newState.ux.unsavedChanges = true;
      return newState;
    }

    //View Name
    case Actions.UPDATE_SHOW_VIEW_NAME:
      var newState = {...state};
      newState.ux.showViewNameModal.show = action.data.show;
      newState.ux.showViewNameModal.view = {...newState.views[action.data.view]};
      newState.ux.showViewNameModal.id = action.data.view;
      return newState;
    case Actions.UPDATE_VIEW_NAME:
      var newState = {...state};
      newState.ux.unsavedChanges = true;
      newState.views[action.data.view].name = action.data.name;
      return newState;

    //Page
    case Actions.UPDATE_SHOW_ADD_PAGE_MODAL_SHOW:{
      var newState = {...state};
      newState.ux.showAddPageModal.show = action.data.value;
      return newState;
    }
    case Actions.UPDATE_SHOW_ADD_PAGE_MODAL_ERROR:{
      var newState = {...state};
      newState.ux.showAddPageModal.error = action.data.value;
      return newState;
    }
    case Actions.UPDATE_SHOW_ADD_PAGE_MODAL_NAME: {
      var newState = {...state};
      newState.ux.showAddPageModal.name = action.data.value;
      return newState;
    }
    case Actions.ADD_PAGE:{
      var newState = {...state};
      newState.ux.unsavedChanges = true;
      newState.views.push(new View(newState.ux.showAddPageModal.name, []));
      newState.ux.showAddPageModal.name = '';
      newState.ux.showAddPageModal.error = '';
      newState.ux.selectedView = newState.views.length - 1;
      return newState;
    }
    case Actions.DELETE_PAGE: {
      var newState = {...state};
      newState.ux.unsavedChanges = true;
      //remove all elements from view
      const elementIds = newState.views[newState.ux.selectedView].elements.map(e => e.id);
      elementIds.forEach(id=>{
        newState = DeleteElement(newState, newState.ux.selectedView, id, false);
      });

      //remove view
      newState.views.splice(newState.ux.selectedView,1);
      //if any views left use the first view else set selected view to nothing
      newState.ux.selectedView = newState.views.length ? 0 : false;
      newState.ux.detailView.show = false;
      return newState;
    }
    case Actions.UPDATE_PAGE_NAME: {
      var newState = {...state};
      newState.ux.unsavedChanges = true;
      newState.views[newState.ux.selectedView].name = action.data.value;
      return newState;
    }

    //Page Edit Configure Modal
    case Actions.UPDATE_SHOW_EDIT_PAGE_MODAL:{
      var newState = {...state};
      newState.ux.showEditPageModal.show = action.data.show;
      //if showing the modal fill it's state
      if(action.data.show){
        var page = newState.views[action.data.page];
        newState.ux.showEditPageModal.name = page.name;
        newState.ux.showEditPageModal.error = '';
        newState.ux.showEditPageModal.pageId = page.id;

      }else{
        newState.ux.showEditPageModal.name = '';
        newState.ux.showEditPageModal.error = '';
        newState.ux.showEditPageModal.pageId = '';
      }
      return newState;
    }
    case Actions.UPDATE_EDIT_PAGE_NAME:{
      var newState = {...state};
      newState.ux.showEditPageModal.name = action.data.value;
      return newState;
    }
    case Actions.SAVE_PAGE_EDITS:{
      var newState = {...state};
      newState.ux.unsavedChanges = true;
      let page = newState.views.find(v => v.id === newState.ux.showEditPageModal.pageId);
      page.name = newState.ux.showEditPageModal.name;
      return newState;
    }

    //UI Elements
    case Actions.ADD_ELEMENT: {
      var newState = {...state};
      newState.ux.unsavedChanges = true;
      var selectedOption = newState.editing.uielement.options.find(o => o.selected);

      if(newState.editing.uielement.source){
        const selectedSource = newState.editing.uielement.source.options.find( o => o.selected);
        let options = new ElementOptions(newState.editing.uielement.source.type);

        if(options.config && options.type === 'list'){
          options.config.renderMapping = selectedSource.columns.map(column=>{
            return {
              column: column.name,
              render: 'text',
              defaultsort: false,
              sortHighToLow: true
            }
          })
          options.config.renderMapping[0].defaultsort = true;

          //append pointer pointerColumns
          const pointerColumns = selectedSource.columns.filter( c => c.type === 'pointer' );

          pointerColumns.forEach(pointer=>{
            //get the table for the pointer and attach the columns
            const table = newState.sources.dtables.find(t => t.id === pointer.tableId);
            let pointerColumnMappings = table.columns.map(column=>{
              return {
                column: column.name,
                render: 'text',
                defaultsort: false,
                sortHighToLow: true
              }
            });

            options.config.renderMapping = [...options.config.renderMapping,...pointerColumnMappings];

            // We need to store the source of the pointer columns with table as well
            //since the data sources won't be there in the public mode
            options.config.pointerTables.push(table);

          });
        }


        var newElement = new Element(
          selectedSource.name,
          selectedOption.type,
          {
            type: newState.editing.uielement.source.type,
            option: selectedSource
          },
          options.config
        );

        //are we editing the detail view?
        if(newState.ux.detailView.show){
          const parentElementId = newState.ux.detailView.parentElementId;
          newElement.parentElementId = parentElementId;
          newState.detailViewElements.push(newElement);
        }else{
          newState.views[action.data.view].elements.push(newElement);
        }

        return newState;
      }else{

        var newElement = new Element(
          'Header',
          selectedOption.type,
          'Place your text here. Click three dots in the corner and select configure.'
        );

        //are we editing the detail view?
        if(newState.ux.detailView.show){
          const parentElementId = newState.ux.detailView.parentElementId;
          newElement.parentElementId = parentElementId;
          newState.detailViewElements.push(newElement);
        }else{
          newState.views[action.data.view].elements.push(newElement);
        }
        return newState;
      }

    }
    case Actions.MOVE_ELEMENT: {
      var newState = {...state};

      if(action.data.isDetailViewElement){
        const currentIndex = newState.detailViewElements.findIndex( e => e.id === action.data.elementId);
        //element
        const element = newState.detailViewElements[currentIndex];
        //remove the element
        newState.detailViewElements.splice(currentIndex,1);
        //add it back in new posistion
        newState.detailViewElements.splice(action.data.newIndex, 0, element);
      }else{
        const currentIndex = newState.views[action.data.view].elements.findIndex( e => e.id === action.data.elementId);
        //element
        const element = newState.views[action.data.view].elements[currentIndex];
        //remove the element
        newState.views[action.data.view].elements.splice(currentIndex,1);
        //add it back in new posistion
        newState.views[action.data.view].elements.splice(action.data.newIndex, 0, element);
      }

      newState.ux.unsavedChanges = true;

      return newState;
    }
    case Actions.DELETE_ELEMENT:
      var newState = {...state};
      newState.ux.unsavedChanges = true;
      newState = DeleteElement(newState, action.data.view, action.data.elementId, newState.ux.detailView.show);
      return newState;
    case Actions.START_ADD_ELEMENT:
        var newState = {...state};

        let elementTypesToUseForModal = [];
        elementTypesToUseForModal.push({...AddElementOptions.find( e => e.sourceType === 'textarea' )});
        if(FeatureFlags.Chat){
          elementTypesToUseForModal.push({...AddElementOptions.find( e => e.sourceType === 'chat' )});
        }
        if(newState.sources.dtables.length){
          elementTypesToUseForModal.push({...AddElementOptions.find( e => e.sourceType === 'dtables' )});
        }

        //if there is at least one insert type trigger show the form element type
        if(newState.sources.triggers.find( t => t.input.action.method === 'insert' )){
          elementTypesToUseForModal.push({...AddElementOptions.find( e => e.sourceType === 'triggers' )});
        }

        // Create new edit object
        newState.editing.uielement = new AddElement(null,null,null, [...elementTypesToUseForModal]);

        // find a compatble source and set the options
        var sourceType = newState.editing.uielement.options.find(o => o.selected).sourceType;
        var addElementOptions = newState.sources[sourceType].map((o,i)=>{
          return {...o,selected:false};
        })
        //if source type dtables and adding to a details view only use tables with pointers to parent
        // if(sourceType === 'dtables' && newState.ux.detailView.show){
        //   const tableId = newState.ux.detailView.parent.id;
        //   var addElementOptions = newState.sources.dtables.filter( t => t.columns.find( c => c.pointer && c.tableId === tableId)).map((o,i)=>{
        //     return {...o,selected:false};
        //   })
        //
        // }

        if(addElementOptions.length){
          addElementOptions[0].selected = true;
        }

        newState.editing.uielement.source = {
          type: sourceType,
          options: addElementOptions
        }
        return newState;
    case Actions.UPDATE_ADD_ELEMENT_SHOW:
      var newState = {...state};
      newState.ux.showAddElementModal.show = action.data.show;
      return newState;
    case Actions.UPDATE_ADD_ELEMENT_TYPE:
      var newState = {...state};
      var view = action.data.view;
      var type = action.data.type;
      newState.editing.uielement.options = newState.editing.uielement.options.map((option)=>{
        return {...option, selected: false}
      })
      var selectedOption = newState.editing.uielement.options.find(o => o.type === type);
      selectedOption.selected = true;

      //set sources for new type
      var sourceType = selectedOption.sourceType;
      if(sourceType === 'textarea' || sourceType === 'chat'){
        newState.editing.uielement.source = '';
        return newState;
      }else {
        let addElementOptions = newState.sources[sourceType].map((o,i)=>{
          return {...o,selected:false};
        })
        if(sourceType === 'triggers'){
          addElementOptions = newState.sources.triggers.filter( t => t.input.action.method === 'insert' ).map((o,i)=>{
            return {...o,selected:false};
          })
        }
        //if source type dtables and adding to a details view only use tables with pointers to parent
        // if(sourceType === 'dtables' && newState.ux.detailView.show){
        //   const tableId = newState.ux.detailView.parent.id;
        //   addElementOptions = newState.sources.dtables.filter( t => t.columns.find( c => c.pointer && c.tableId === tableId)).map((o,i)=>{
        //     return {...o,selected:false};
        //   })
        // }

        if(addElementOptions.length){
          addElementOptions[0].selected = true;
        }
        newState.editing.uielement.source = {
          type: sourceType,
          options: addElementOptions
        }
        return newState;
      }
    case Actions.UPDATE_ADD_ELEMENT_SOURCE:
      var newState = {...state};

      var addElementOptions = newState.sources[action.data.type].map((o,i)=>{
        return {...o, selected: action.data.id === o.id ? true : false };
      })

      if(action.data.type === 'triggers'){
        addElementOptions = newState.sources[action.data.type].filter( t => t.input.action.method === 'insert' ).map((o,i)=>{
          return {...o, selected: action.data.id === o.id ? true : false };
        })
      }

      // if(sourceType === 'dtables' && newState.ux.detailView.show){
      //   const tableId = newState.ux.detailView.parent.id;
      //   addElementOptions = newState.sources.dtables.filter( t => t.columns.find( c => c.pointer && c.tableId === tableId)).map((o,i)=>{
      //     return {...o, selected: action.data.id === o.id ? true : false };
      //   })
      // }

      newState.editing.uielement.source.options = addElementOptions;
      return newState

    //General Configure
    case Actions.UPDATE_SHOW_ELEMENT_TITLE_MODAL:
      var newState = {...state};
      newState.ux.showEditElementTitleModal.show = action.data.value;
      if(action.data.value){

        let view = newState.views[action.data.view];

        newState.ux.showEditElementTitleModal.view = view;
        newState.ux.showEditElementTitleModal.element = view.elements[action.data.elementId];
        newState.ux.showEditElementTitleModal.id = action.data.elementId;
      }
      return newState;
    case Actions.UPDATE_ELEMENT_TITLE:
      var newState = {...state};
      var view = newState.views[action.data.view];
      newState.ux.unsavedChanges = true;
      view.elements[action.data.elementId].title = action.data.title;
      return newState

    //LiteForm Element
    case Actions.UPDATE_SHOW_EDIT_FORM_MODAL:{
      let newState = {...state};
      newState.ux.showEditFormModal.show = action.data.value;
      if(action.data.value){
        var view = newState.views[action.data.view];
        newState.ux.showEditFormModal.view = view;
        newState.ux.showEditFormModal.id = action.data.elementId;
        if(newState.ux.detailView.show){
          newState.ux.showEditFormModal.element = JSON.parse(JSON.stringify(newState.detailViewElements.find( e => e.id === action.data.elementId)));

          const triggerAction = newState.ux.showEditFormModal.element.source.option.input.action;
          // get the table the form is using
          const columns = newState.sources.dtables.find( t => t.id === triggerAction.tableId).columns;

          const parentElement = newState.ux.detailView.isParentInsideDetailView
            ? newState.ux.detailViewElements.find(e => e.id == newState.ux.detailView.parentElementId)
            : newState.views[newState.ux.selectedView].elements.find(e => e.id == newState.ux.detailView.parentElementId);

          const pointer = columns.find( c => c.type === 'pointer' );

          // is the form pointer using the same table as the parent
          if(pointer && pointer.tableId === parentElement.source.option.id
            // and is there pointer in the action set to user input
            && triggerAction.mappings.find( m => m.valueType === 'pointer' && m.value === 'input')){
              newState.ux.showEditFormModal.autofillCompatible = true;
          }else{
            newState.ux.showEditFormModal.autofillCompatible = false;
          }



        }else{
          newState.ux.showEditFormModal.element = JSON.parse(JSON.stringify(view.elements.find( e => e.id ===action.data.elementId)));
          newState.ux.showEditFormModal.autofillCompatible = false;
        }

      }
      return newState;
    }
    case Actions.SAVE_FORM_MODAL_EDITS: {
      var newState = {...state};
      newState.ux.unsavedChanges = true;
      var view = newState.views[newState.ux.selectedView];
      //replace the element with the new edited one
      if(newState.ux.detailView.show){
        const detailIndex = newState.detailViewElements.findIndex( e => e.id === newState.ux.showEditFormModal.element.id );
        newState.detailViewElements[detailIndex] = {...newState.ux.showEditFormModal.element};
      }else{
        let elIndex = view.elements.findIndex( e => e.id === newState.ux.showEditFormModal.element.id );
        view.elements[elIndex] = {...newState.ux.showEditFormModal.element};
      }
      return newState;
    }
    case Actions.UPDATE_FORM_MODAL_NAME: {
      var newState = {...state};
      newState.ux.showEditFormModal.element.title = action.data.value;
      return newState;
    }
    case Actions.UPDATE_FORM_MODAL_AUTOFILL_POINTER: {
      var newState = {...state};
      newState.ux.showEditFormModal.element.config.autofillPointer = action.data.value;
      return newState;
    }

    //LiteList Element
    case Actions.UPDATE_SHOW_EDIT_LIST_MODAL:
      var newState = {...state};
      newState.ux.showEditListModal.show = action.data.value;

      //If showing modal
      if(action.data.value){
        var view = newState.views[action.data.view];
        newState.ux.showEditListModal.editingAction = new ConfigAction('',false);
        newState.ux.showEditListModal.view = view;
        // newState.ux.showEditListModal.element = JSON.parse(JSON.stringify(view.elements[action.data.elementId]));
        if(newState.ux.detailView.show){
          newState.ux.showEditListModal.element = JSON.parse(JSON.stringify(newState.detailViewElements.find( e => e.id === action.data.elementId)));
        }else{
          newState.ux.showEditListModal.element = JSON.parse(JSON.stringify(view.elements.find( e => e.id ===action.data.elementId)));
        }
        newState.ux.showEditListModal.id = action.data.elementId;
        // set the starting option for the Actions dropdown
        const tableId = newState.ux.showEditListModal.element.source.option.id;
        const compatibleTriggers = newState.sources.triggers.filter( t => t.input.action.method !== 'insert' && t.input.action.tableId === tableId);
        if(compatibleTriggers.length){
          newState.ux.showEditListModal.editingAction.source.id = compatibleTriggers[0].id;
        }else{
          newState.ux.showEditListModal.editingAction.source.id = null;
        }

        if(!newState.ux.showEditListModal.element.config.renderMapping){
          newState.ux.showEditListModal.element.config.renderMapping = newState.ux.showEditListModal.element.source.option.columns.map(column=>{
            return {
              column: column.name,
              render: 'text',
              defaultsort: false,
              sortHighToLow: true
            }
          })
          newState.ux.showEditListModal.element.config.renderMapping[0].defaultsort = true;
        }


      }
      return newState;
    case Actions.UPDATE_LIST_MODAL_NAME:{
      let newState = {...state};
      newState.ux.showEditListModal.element.title = action.data.value;
      return newState;
    }
    case Actions.ADD_LIST_MODAL_ACTION: {
      var newState = {...state};
      newState.ux.showEditListModal.element.config.actions.push({...newState.ux.showEditListModal.editingAction})
      newState.ux.showEditListModal.editingAction = new ConfigAction('',false);
      return newState;
    }
    case Actions.UPDATE_LIST_MODAL_ACTION_NAME: {
      var newState = {...state};
      newState.ux.showEditListModal.editingAction.title = action.data.value;
      return newState;
    }
    case Actions.UPDATE_LIST_MODAL_ACTION_SOURCE: {
      var newState = {...state};
      newState.ux.showEditListModal.editingAction.source.id = action.data.value;
      return newState;
    }
    case Actions.REMOVE_LIST_MODAL_ACTION: {
      var newState = {...state};
      newState.ux.showEditListModal.element.config.actions.splice(action.data.value, 1);
      return newState;
    }
    case Actions.UPDATE_LIST_MODAL_RENDER_MAPPING: {
      var newState = {...state};
      let renderMap = newState.ux.showEditListModal.element.config.renderMapping.find( r => r.column === action.data.column );
      renderMap.render = action.data.render;
      if(renderMap.render === 'hyperlink'){
        renderMap.linkColumn = action.data.column;
      }
      return newState;
    }
    case Actions.UPDATE_LIST_MODAL_RENDER_MAPPING_HYPERLINK: {
      var newState = {...state};
      let renderMap = newState.ux.showEditListModal.element.config.renderMapping.find( r => r.column === action.data.column );
      renderMap.linkColumn = action.data.linkColumn;
      return newState;
    }
    case Actions.UPDATE_LIST_MODAL_RENDER_MAPPING_HYPERLINK_CUSTOM: {
      var newState = {...state};
      let renderMap = newState.ux.showEditListModal.element.config.renderMapping.find( r => r.column === action.data.column );
      renderMap.linkCustom = action.data.linkCustom;
      return newState;
    }
    case Actions.UPDATE_LIST_MODAL_RENDER_MAPPING_DEFAULT_SORT: {
      var newState = {...state};
      newState.ux.showEditListModal.element.config.renderMapping = newState.ux.showEditListModal.element.config.renderMapping.map(map=>{
        return {...map, defaultsort: map.column === action.data.column ? true : false};
      })
      return newState;
    }
    case Actions.UPDATE_LIST_MODAL_RENDER_MAPPING_SORT_DIRECTION: {
      var newState = {...state};
      var defaultsort = newState.ux.showEditListModal.element.config.renderMapping.find( r => r.defaultsort === true );
      defaultsort.sortHighToLow = action.data.value === 'desc' ? true : false;
      return newState;
    }
    case Actions.UPDATE_LIST_MODAL_VIEW_DETAILS_TITLE: {
      var newState = {...state};
      newState.ux.showEditListModal.element.config.viewDetails.title = action.data.value;
      return newState;
    }
    case Actions.UPDATE_LIST_MODAL_VIEW_FILTER_ON_RECORD_ID: {
      var newState = {...state};
      newState.ux.showEditListModal.element.config.filter.recordId = action.data.value;
      return newState;
    }
    case Actions.SAVE_LIST_MODAL_EDITS: {
        var newState = {...state};
        var view = newState.views[newState.ux.selectedView];
        //go find the triggers id's and attach the full triger object
        newState.ux.showEditListModal.element.config.actions = newState.ux.showEditListModal.element.config.actions.map((action, i)=>{
          if(action.locked){
            return action;
          }else{
            action.source.data = newState.sources.triggers.find(t => t.id === action.source.id);
            return action;
          }
        });
        newState.ux.unsavedChanges = true;

        //replace the element with the new edited one
        if(newState.ux.detailView.show){
          const detailIndex = newState.detailViewElements.findIndex( e => e.id === newState.ux.showEditListModal.element.id );
          newState.detailViewElements[detailIndex] = {...newState.ux.showEditListModal.element};
        }else{
          let elIndex = view.elements.findIndex( e => e.id === newState.ux.showEditListModal.element.id );
          view.elements[elIndex] = {...newState.ux.showEditListModal.element};
        }
        return newState;
    }

    //LiteText Element
    case Actions.UPDATE_SHOW_EDIT_TEXT_MODAL:
      var newState = {...state};
      newState.ux.showEditTextModal.show = action.data.show;
      if(action.data.show){
        if(action.data.isDetailView){
          newState.ux.showEditTextModal.element = {...newState.detailViewElements.find( e => e.id === action.data.elementId)};
          newState.ux.showEditTextModal.id = action.data.elementId;
        }else{
          var view = newState.views[action.data.view];
          newState.ux.showEditTextModal.view = view;
          newState.ux.showEditTextModal.element = {...view.elements.find( e => e.id === action.data.elementId)};
          newState.ux.showEditTextModal.id = action.data.elementId;

        }
      }
      return newState;
    case Actions.UPDATE_EDIT_TEXT_MODAL_VALUE: {
      var newState = {...state};
      newState.ux.showEditTextModal.element[action.data.prop] = action.data.value;
      return newState;
    }
    case Actions.SAVE_TEXT_MODAL_EDITS: {
      var newState = {...state};
      newState.ux.unsavedChanges = true;
      //replace the element with the new edited one
      if(action.data.isDetailView){
        const detailElementIndex = newState.detailViewElements.findIndex( e => e.id === newState.ux.showEditTextModal.element.id);
        newState.detailViewElements[detailElementIndex] = {...newState.ux.showEditTextModal.element};
      }else{
        var view = newState.views[newState.ux.selectedView];
        const elementIndex = view.elements.findIndex(e => e.id === newState.ux.showEditTextModal.element.id);
        view.elements[elementIndex] = {...newState.ux.showEditTextModal.element};
      }

      return newState;
    }

    case Actions.UPDATE_CHAT_THREAD_ID: {
      var newState = {...state};
      var view = newState.views[newState.ux.selectedView];
      newState.ux.unsavedChanges = true;

      //replace the element with the new edited one
      if(newState.ux.detailView.show){
        const detailIndex = newState.detailViewElements.findIndex( e => e.id === action.data.elementId );
        newState.detailViewElements[detailIndex].config.address = action.data.address;
      }else{
        let elIndex = view.elements.findIndex( e => e.id === action.data.elementId );
        view.elements[elIndex].config.address = action.data.address;
      }
      return newState;
    }

    //LiteWallet
    case Actions.UPDATE_LITEWALLET: {
      let newState = {...state};
      newState.liteWallet = {...newState.liteWallet, ...action.data.wallet};
      return newState;
    }

    default:
      return state;
  }
};

const DeleteElement = (state, view, elementId, isDetailView) => {
  let newState = {...state};
  if(isDetailView){
    newState.detailViewElements.splice(newState.detailViewElements.findIndex( v => v.id === elementId),1);
  }else{
    newState.views[view].elements.splice(newState.views[view].elements.findIndex( e => e.id === elementId),1);
    newState = DeleteNestedElements(newState, elementId);
  }
  return newState;
}

const DeleteNestedElements = (state, elementId) => {
  let newState = {...state};
  const DependentElements = [...newState.detailViewElements.filter( e => e.parentElementId === elementId)];
  //remove DependentElements
  newState.detailViewElements = newState.detailViewElements.filter( e => e.parentElementId !== elementId);
  //delete nested
  DependentElements.forEach(element=>{
    newState = DeleteNestedElements(newState, element.id);
  })
  return newState;
}

export default liteui;
