import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { FormatDate, FormatDateTime } from "../../../helpers/Date";
import { ConvertWeiToEth, FromHex } from "../../../helpers/Eth";

const FormatRecordValue = ({
  type,
  value,
  timezone
}) => {
  switch(type){
    case 'price':
      return `${ConvertWeiToEth(value)} ETH`;
    break;
    case 'date':
      return FormatDateTime(parseInt(value), timezone);
    default:
      return value;
    break;
  }
}

const FormatPendingRecordValue = ({
  type,
  value,
  timezone
}) => {
  switch(type){
    case 'price':
      return `${ConvertWeiToEth(value)} ETH`;
    break;
    case 'date':
      return FormatDateTime(parseInt(value), timezone);
    case 'number':
      return FromHex(value);
    default:
      return value;
    break;
  }
}

export default class Records extends Component {

  render() {
    const records = this.props.records.live;
    const pending = this.props.records.pending;
    const columns = this.props.columns;
    const timezone = this.props.timezone;
    if (records.length || pending.length) {
      return (
        <Table responsive>
          <thead>
            <tr>
              <th>Record Id</th>
              {this.props.columns.map(column => (
                <th key={column.name}>{column.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {pending.map((pendingRecord, x) => (
              <tr key={x}>
                <td>{'pending'}</td>
                {columns.map((column, i) => (
                  <td key={`${x}${i}`}>
                    <FormatPendingRecordValue timezone={timezone.code} type={column.type} value={pendingRecord.data[i]} />
                  </td>
                ))}
              </tr>
            ))}
            {records.map((data, x) => (
              <tr key={x}>
                <td>{data.recordId}</td>
                {columns.map((column, i) => (
                  <td key={`${x}${i}`}>
                    <FormatRecordValue timezone={timezone.code} type={column.type} value={data.record[i]} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }
    return "";
  }
}
