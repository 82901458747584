import { Auth } from "aws-amplify";
import AtraAPI from "../../../apiv2";

const UserLogin = ({
  email,
  password,
  accountUpdateLoadingUser,
  accountUpdateUser,
  authUpdateIsAuthenticated,
  accountUpdateUserName,
  accountUpdateSubscription,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      await Auth.signIn(email, password);
      const session = await Auth.currentSession();
      if (session) {
        await UserHasAuthenticated({
          authenticated: true,
          email: session.idToken.payload.email,
          accountUpdateLoadingUser: accountUpdateLoadingUser,
          accountUpdateUser: accountUpdateUser,
          authUpdateIsAuthenticated: authUpdateIsAuthenticated,
          accountUpdateUserName: accountUpdateUserName,
          accountUpdateSubscription: accountUpdateSubscription,
        });
        resolve();
      }
      reject();
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};

const UserHasAuthenticated = ({
  authenticated,
  email,
  accountUpdateLoadingUser,
  accountUpdateUser,
  authUpdateIsAuthenticated,
  accountUpdateUserName,
  accountUpdateSubscription,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let username = null;
      if (authenticated && email) {
        username = email.split("@")[0];
      }
      accountUpdateLoadingUser({ value: true });
      AtraAPI.GetUser().then((user) => {
        accountUpdateUser({ value: user });
        accountUpdateLoadingUser({ value: false });
      });
      authUpdateIsAuthenticated({ value: true });
      accountUpdateUserName({ value: username });
      resolve();
    } catch (err) {
      reject(err);
    }
  });
};

export { UserLogin };
