import Actions from '../actions/triggers-view';
const Methods = ({ dispatch }) => {
    return {
      //LOADING
      updateArchiving: data => dispatch({ type: Actions.UPDATE_ARCHIVING, data }),
      updateLoadingDetails: data => dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_LOADING_DETAILS, data }),
      updateLoadingContract: data => dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_LOADING_CONTRACT, data }),
      updateLoadingInvoke: data => dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_LOADING_INVOKE, data }),
      updateLoadingTab: data=> dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_LOADING_TAB, data }),

      //Trigger
      newTrigger: data => dispatch({ type: Actions.TRIGGERS_VIEW_NEW_TRIGGER, data }),
      updateTrigger: data => dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_TRIGGER, data }),
      //Trigger Permissions
      updateTriggerDtablePermission: data => dispatch({ type: Actions.UPDATE_TRIGGER_DTABLE_PERMISSION, data }),
      updateTriggerDtablePermissionSubmitting: data => dispatch({ type: Actions.UPDATE_TRIGGER_DTABLE_PERMISSION_SUBMITTING, data }),
      updateTriggerDtablePermissionPending : data => dispatch({ type: Actions.UPDATE_TRIGGER_DTABLE_PERMISSION_PENDING, data }),


      //Contract
      updateContract: data => dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_CONTRACT, data }),

      //Reset
      resetState: data => dispatch({ type: Actions.TRIGGERS_VIEW_RESET_STATE, data })
    }
}

export default Methods;
