import { API } from "aws-amplify";


const GetAPIKey = () => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true
      };

      API.get("api", "", data).then( res => {
        resolve(res.data.apiKey);
      }).catch(err => {
        reject(err);
      });
    } catch (err) {
      reject(err);
    }
  });
};

const CreateKey = () => {
  return new Promise((resolve, reject) => {
    try {

      let data = {
        response: true
      }

      API.post('api', '', data).then(res => {
        resolve(res.data.key);
      }).catch(err => {
        reject(err);
      });

    } catch (err) {
      reject(err);
    }

  });
}

export default { GetAPIKey, CreateKey };
