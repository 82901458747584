import Actions from '../actions/dashboard';
const Methods = ({ dispatch }) => {
    return {
      toggleFilter: data  =>  dispatch({ type: Actions.TOGGLE_FILTER, data }),
      updateDTables: data => dispatch({ type: Actions.UPDATE_DTABLES, data }),
      loadingTables: data => dispatch({ type: Actions.UPDATE_LOADING_TABLES, data }),
      updateRecordCount: data => dispatch({ type: Actions.UPDATE_RECORD_COUNT, data }),
      updateLoadingRecordCount: data => dispatch({ type: Actions.UPDATE_LOADING_RECORDCOUNTS, data }),
      clearState: data => dispatch({ type: Actions.CLEAR_STATE, data })
    }
}
export default Methods;
