import React, { Component } from "react";
import { LiteElementHeader } from './index';

const Text = ({
  mode,
  source,
  edit
}) => {
  if(mode === 'public'){
    return (
      <div className='text-content-container'>
        {source}
      </div>
    )
  }else{
    return (
      <div onClick={edit} className='text-content-container'>
        {source}
      </div>
    )
  }
}

export default class LiteText extends Component {

  constructor(props){
    super(props);
    this._mounted = false;
  }

  async componentDidMount() {
    this._mounted = true;
  };

  async componentWillUnmount() {
    this._mounted = false;
  }

  render() {

    const element = this.props.element;

    return (
      <div className="lite-text-container liteui-element">
        <LiteElementHeader
          dragHandleProps={this.props.dragHandleProps}
          mode={this.props.mode}
          edit={()=>this.props.edit(element.id)}
          remove={()=>this.props.remove(element.id)}
          title={element.title}
          />
        <Text
          mode={this.props.mode}
          source={element.source}
        />
      </div>
    );

  }
}
