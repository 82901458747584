import React, { Component } from 'react';
import { connect } from "react-redux";

import { Modal, FormGroup, FormControl, ControlLabel, Button, Table } from 'react-bootstrap';
import UIModals from './';
import LiteUIMethods from "../../methods";
import NotificationMethods from "../../../../components/Notifications/methods";


const TableBody = ({
  transations
}) => {
  return(
    <tbody>
      {transations.map((tx, x)=>{
        const etherScanUrl = `https://goerli.etherscan.io/tx/${tx.txHash}`;
        return <tr key={x}>
          <td>{tx.data}</td>
          <td><a href={etherScanUrl} target='_blank'>View</a></td>
        </tr>;
      })}
    </tbody>
  )
}
const TableHeader = ({
  columns
}) => {
  return (
    <thead>
      <tr>
        <th>Transaction</th>
        <th>Link</th>
      </tr>
    </thead>
  )
}


class PendingTransactions extends Component {

  handlers = {
    close: () => {
      this.props.updateShowTransactionView({ value: false });
    }
  }
  render(){
    if(this.props.liteui.ux.showTransactionsView){
      return (
        <UIModals.ModalContainerOK
          title={'Goerli Test Network'}
          handleClose={this.handlers.close}
          show={true}
          >
          <div className='liteui-transactions-container'>
            <div>{this.props.liteui.liteWallet.address}</div>
            <div className='wallet-balance'>
              <h1>{this.props.liteui.liteWallet.balance} ETH</h1>
            </div>
            <h3>History</h3>
            <Table striped condensed responsive>
              <TableHeader />
              <TableBody
                transations={this.props.liteui.ux.pendingTransactions}
                />
            </Table>
            {!this.props.liteui.ux.pendingTransactions.length ?
              <div className='no-pending'>No pending transactions at this time</div>
               : null}
          </div>
        </UIModals.ModalContainerOK>
      )
    }else{
      return null;
    }

  }
}

const mapStateToProps = state => {
  return {
    liteui: state.liteui,
    notifications: state.notifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...LiteUIMethods({dispatch}),
    ...NotificationMethods({dispatch})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingTransactions);
