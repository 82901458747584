import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  Alert,
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import LoaderButton from "../../components/Buttons/LoaderButton";
import Logo from "../../components/Branding/Logo";

import { signup, login } from "./actions/actionTypes";
import NotificationActions from "../../components/Notifications/actions/notifications";
import AccountActions from "../../containers/account/actions/account-actions";

import pwdErrMssgs from "./data/passwordErrorMessages";
import passwordvalidator from "./validation/password-validator";
import { UserLogin } from "./helpers/login";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.props.resetState();
  }

  /* form state validation */
  validationStatePassword() {
    if (
      this.props.signup.passwordError.length === 0 &&
      this.props.signup.password.length > 0
    ) {
      return "success";
    }
    return this.props.signup.passwordError.length > 0 ? "error" : null;
  }

  validationStateEmail(err) {
    console.log(this.props.signup.emailError);
    if (this.props.signup.emailError) {
      return "error";
    }
    // const reg = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    // return reg.exec(this.props.signup.email) ? "success" : "";
  }

  validationStateConfirmPassword() {
    if (
      this.props.signup.confirmPassword.length === 0 &&
      this.props.signup.password.length === 0
    ) {
      return null;
    }
    return this.props.signup.confirmPassword === this.props.signup.password &&
      this.props.signup.confirmPassword.length > 0
      ? "success"
      : "error";
  }

  validationStateConfirmationCode() {
    return this.props.signup.confirmationCodeError &&
      this.props.signup.confirmationCodeError.length > 0
      ? "error"
      : null;
  }

  /* Password validation and matching */
  validatePassword(password) {
    Object.entries(pwdErrMssgs.rules).forEach((rule) => {
      passwordvalidator[rule[0]](password.split(""))
        ? this.props.removePasswordError({ value: rule[1] })
        : this.props.updatePasswordError({ value: rule[1] });
    });
  }

  validatePasswordsMatch(value, originInputField) {
    if (value === originInputField) {
      this.props.updateConfirmPasswordError({ value: "" });
    } else if (value.length > 0) {
      this.props.updateConfirmPasswordError({
        value: pwdErrMssgs.match,
      });
    }
  }

  /* handle form changes */
  handleEmailChange = (event) => {
    this.props.updateEmailError({ value: "" });
    this.props.updateEmail({ value: event.target.value });
  };

  handlePasswordChange = (event) => {
    this.props.clearPasswordError();
    this.props.updatePassword({ value: event.target.value });
    this.validatePassword(event.target.value);
    this.validatePasswordsMatch(
      event.target.value,
      this.props.signup.confirmPassword
    );
  };

  handleConfirmPasswordChange = (event) => {
    this.props.updateConfirmPassword({ value: event.target.value });
    this.validatePasswordsMatch(event.target.value, this.props.signup.password);
  };

  handleConfirmationChange = (event) => {
    this.props.updateConfirmationCode({ value: event.target.value });
  };

  handleResendCode = (event) => {
    Auth.resendSignUp(this.props.signup.email)
      .then(() => {
        console.log("a verification code is sent");
      })
      .catch((e) => {
        console.log("failed with error", e);
      });
  };

  /* submit buttons */
  handleSubmit = async (event) => {
    event.preventDefault();
    if (
      this.props.signup.confirmPassword === this.props.signup.password &&
      !this.props.signup.passwordError.length &&
      !this.props.signup.confirmPasswordError
    ) {
      try {
        this.props.updateLoading({ value: true });
        if (this.props.signup.email.length) {
          await Auth.signUp({
            username: this.props.signup.email.toLowerCase(),
            password: this.props.signup.password,
          });
          this.props.updateLoading({ value: false });
          this.props.updateNewUser({ value: "exists" });
        } else {
          this.props.updateLoading({ value: false });
          this.props.updateNewUserError({ value: "Email required" });
        }
      } catch (err) {
        if (err.code === "UsernameExistsException") {
          this.props.updateLoading({ value: false });
          this.props.updateNewUserError({
            value: "Account with that Email exists.",
          });
        } else {
          this.props.updateLoading({ value: false });
          this.props.updateNewUserError({ value: err });
        }
      }
    }
    this.props.updateLoading({ value: false });
  };

  handleConfirmationCodeSubmit = async (event) => {
    event.preventDefault();

    this.props.updateLoading({ value: true });

    try {
      await Auth.confirmSignUp(
        this.props.signup.email.toLowerCase(),
        this.props.signup.confirmationCode
      );

      window.dataLayer.push({
        event: "sign_up",
        method: "email",
      });
    } catch (err) {
      this.props.updateConfirmationCodeError({
        value: `There was an issue sending the confirmation code: ${err.message}.`,
      });
    }

    try {
      //Log user in after confimation of email
      UserLogin({
        email: this.props.signup.email.toLowerCase(),
        password: this.props.signup.password,
        accountUpdateLoadingUser: this.props.accountUpdateLoadingUser,
        accountUpdateUser: this.props.accountUpdateUser,
        authUpdateIsAuthenticated: this.props.authUpdateIsAuthenticated,
        accountUpdateUserName: this.props.accountUpdateUserName,
      }).then(
        (res) => {
          this.props.updateLoading({ value: false });
          this.props.clearSignupState();
          //Set pop notification
          this.props.notificationsAddPopupMessage({
            message: "Success! - Account Created",
            remove: (id) => {
              this.props.notificationsRemovePopupMessage({ value: id });
            },
          });
          this.props.history.push("/");
        },
        (err) => {
          this.props.updateLoading({ value: false });
          this.props.updateConfirmationCodeError({
            value: `There was an issue logging in: ${err.message}.`,
          });
        }
      );
    } catch (e) {
      this.props.updateConfirmationCodeError({
        value: `There was an issue logging in: ${e.message}.`,
      });
    }
  };

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h3>Create an Account</h3>

        <FormGroup
          controlId="email"
          bsSize="large"
          validationState={this.validationStateEmail()}
        >
          <ControlLabel className="label">Email</ControlLabel>
          <FormControl
            className="input"
            type="email"
            placeholder="enter email"
            value={this.props.signup.email}
            onChange={this.handleEmailChange}
            autoComplete="email"
          />
          <FormControl.Feedback />
          <HelpBlock key={1} bsStyle="danger">
            {this.props.signup.emailError || this.props.signup.newUserError}
          </HelpBlock>
        </FormGroup>
        <FormGroup
          controlId="password"
          bsSize="large"
          validationState={this.validationStatePassword()}
        >
          <ControlLabel className="label">Password</ControlLabel>
          <FormControl
            className="input"
            type="password"
            placeholder="enter password"
            value={this.props.signup.password}
            onChange={this.handlePasswordChange}
          />
          <FormControl.Feedback />
          <ul>
            {this.props.signup.passwordError.map((error, index) => {
              return (
                <HelpBlock bsStyle="danger" key={index}>
                  {error}
                </HelpBlock>
              );
            })}
          </ul>
        </FormGroup>
        <FormGroup
          controlId="confirmPassword"
          bsSize="large"
          validationState={this.validationStateConfirmPassword()}
        >
          <ControlLabel className="label">Confirm Password</ControlLabel>
          <FormControl
            className="input"
            type="password"
            placeholder="confirm password"
            value={this.props.signup.confirmPassword}
            onChange={this.handleConfirmPasswordChange}
          />
          <FormControl.Feedback />
          <HelpBlock key={1} bsStyle="danger">
            {this.props.signup.confirmPasswordError}
          </HelpBlock>
        </FormGroup>
        <div className="flex-center-container">
          <LoaderButton
            block
            className="button"
            bsSize="large"
            disabled={false}
            type="submit"
            isLoading={this.props.signup.isLoading}
            text="Create Account"
            loadingText="Creating Account"
          />
        </div>
        <div className="terms-container">
          By creating an account you agree to our{" "}
          <a target="_blank" href="https://atra.io/terms">
            Terms of Service
          </a>{" "}
          and{" "}
          <a target="_blank" href="https://atra.io/privacy">
            Privacy Policy
          </a>
        </div>
      </form>
    );
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationCodeSubmit}>
        <h3>Confirmation Email</h3>
        <FormGroup
          controlId="confirmationCode"
          bsSize="large"
          validationState={this.validationStateConfirmationCode()}
        >
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            className="input"
            autoComplete="off"
            placeholder="enter code"
            value={this.props.signup.confirmationCode}
            onChange={this.handleConfirmationChange}
          />
          {/* <HelpBlock>Please check your email for the code.</HelpBlock> */}
          <HelpBlock key={1} bsStyle="danger">
            {this.props.signup.confirmationCodeError || (
              <div>
                Please check your email for the code.{" "}
                <a onClick={this.handleResendCode}>Resend Code</a>
              </div>
            )}
          </HelpBlock>
        </FormGroup>
        <div className="flex-center-container">
          <LoaderButton
            block
            className="button"
            bsSize="large"
            type="submit"
            isLoading={this.props.signup.isLoading}
            text="Verify"
            loadingText="Verifying"
          />
        </div>
      </form>
    );
  }

  render() {
    const alert = (
      <Alert bsStyle="success">{this.props.signup.alertMessage}</Alert>
    );
    return (
      <div className="auth">
        <div className="auth-container-wrapper">
          <Logo />

          {this.props.signup.alertMessage ? alert : null}
          <div className="auth-container">
            {this.props.signup.newUser === null
              ? this.renderForm()
              : this.renderConfirmationForm()}
            <div className="account-links-container">
              <Link to="login" className="purple-link">
                Return to Log In
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signup: state.signup,
    account: state.account,
    auth: state.auth,
    notifications: state.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // update forms
    resetState: () => dispatch({ type: signup.SIGNUP_DEFAULT_STATE }),
    updateEmail: (data) => dispatch({ type: signup.SIGNUP_UPDATE_EMAIL, data }),
    updatePassword: (data) =>
      dispatch({ type: signup.SIGNUP_UPDATE_PASSWORD, data }),
    updateConfirmationCode: (data) =>
      dispatch({ type: signup.SIGNUP_UPDATE_CONFIRMATION_CODE, data }),
    updateConfirmPassword: (data) =>
      dispatch({ type: signup.SIGNUP_UPDATE_CONFIRM_PASSWORD, data }),

    // errors
    updateEmailError: (data) =>
      dispatch({ type: signup.SIGNUP_UPDATE_EMAIL_ERROR, data }),
    updatePasswordError: (data) =>
      dispatch({ type: signup.SIGNUP_UPDATE_PASSWORD_ERROR, data }),
    updateConfirmPasswordError: (data) =>
      dispatch({ type: signup.SIGNUP_UPDATE_CONFIRM_PASSWORD_ERROR, data }),
    updateConfirmationCodeError: (data) =>
      dispatch({ type: signup.SIGNUP_UPDATE_CONFIRMATION_CODE_ERROR, data }),
    updateNewUserError: (data) =>
      dispatch({ type: signup.SIGNUP_UPDATE_NEW_USER_ERROR, data }),
    clearPasswordError: (data) =>
      dispatch({ type: signup.SIGNUP_CLEAR_PASSWORD_ERROR, data }),
    removePasswordError: (data) =>
      dispatch({ type: signup.SIGNUP_REMOVE_PASSWORD_ERROR, data }),

    // etc
    updateNewUser: (data) =>
      dispatch({ type: signup.SIGNUP_UPDATE_NEW_USER, data }),
    updateAlertMessage: (data) =>
      dispatch({ type: login.LOGIN_UPDATE_ALERT_MESSAGE, data }),
    updateLoading: (data) =>
      dispatch({ type: signup.SIGNUP_UPDATE_IS_LOADING, data }),
    clearSignupState: (data) =>
      dispatch({ type: signup.SIGNUP_CLEAR_SIGNUP_STATE, data }),

    // Auth Actions
    userLogout: (data) => dispatch({ type: "AUTH_USER_LOGOUT", data }),
    authUpdateIsAuthenticating: (data) =>
      dispatch({ type: "AUTH_UPDATE_IS_AUTHENTICATING", data }),
    authUpdateIsAuthenticated: (data) =>
      dispatch({ type: "AUTH_UPDATE_IS_AUTHENTICATED", data }),

    // Account Action
    accountUpdateLoadingUser: (data) =>
      dispatch({ type: AccountActions.UPDATE_LOADING_USER, data }),
    accountUpdateUserName: (data) =>
      dispatch({ type: AccountActions.UPDATE_USERNAME, data }),
    accountUpdateUser: (data) =>
      dispatch({ type: AccountActions.UPDATE_USER, data }),

    // Notifications
    notificationsAddPopupMessage: (data) =>
      dispatch({
        type: NotificationActions.NOTIFICATIONS_ADD_POPUP_MESSAGE,
        data,
      }),
    notificationsRemovePopupMessage: (data) =>
      dispatch({
        type: NotificationActions.NOTIFICATIONS_REMOVE_POPUP_MESSAGE,
        data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
