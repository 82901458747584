import React, { Component } from "react";

const HamburgerIcon = ({
  onClick
}) => {
  return (
    <div className="hamburger-container">
      <img onClick={onClick} alt="Atra" src={require("./hamburger_icon.svg")} />
    </div>
  )
}

export default HamburgerIcon;
