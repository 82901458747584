import React from 'react';
import { Navigation } from '../../../components/Docs/Navigation';

export default ({
  selected,
  children
}) => {
  document.body.classList.add("white");
  return (
    <div id='docs-container' className='docs-container'>
      <Navigation selected={selected} />
      <div className='docs-content-container'>
        {children}
      </div>
    </div>
  )
}
