import React, { Component } from "react";
import { Route, Switch, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import logo from "./components/Branding/atra_nobg.svg";
import Footer from "./components/Navigation/Footer";
import ScrollToTop from "./helpers/ScrollToTop";
import AtraAPI from "./apiv2";
import {
  Nav,
  Navbar,
  NavItem,
  MenuItem,
  NavDropdown,
  Glyphicon,
} from "react-bootstrap";

import AccountActions from "./containers/account/actions/account-actions";
import AuthActions from "./containers/auth/actions/actionTypes";

class App extends Component {
  constructor(props) {
    super(props);
    console.log(`application is running in ${process.env.NODE_ENV} mode`);
  }

  async componentDidMount() {
    //remove white them each page load
    try {
      this.props.authUpdateIsAuthenticating({ value: true });
      const session = await Auth.currentSession();
      if (session) {
        this.userHasAuthenticated(true, session.idToken.payload.email);
      }
    } catch (e) {
      console.log(e);
      if (e !== "No current user") {
        console.log(e);
      }
    }
    this.props.authUpdateIsAuthenticating({ value: false });
  }

  userHasAuthenticated = (authenticated, email) => {
    let username = null;
    if (authenticated && email) {
      username = email.split("@")[0];
    }
    this.props.accountUpdateLoadingUser({ value: true });
    AtraAPI.GetUser().then((user) => {
      this.props.accountUpdateUser({ value: user });
      this.props.accountUpdateLoadingUser({ value: false });
    });
    this.props.authUpdateIsAuthenticated({ value: true });
    this.props.accountUpdateUserName({ value: username });
  };

  render() {
    if (!this.props.auth.isAuthenticating) {
      //console.log(this.props.history.action);
      return (
        <ScrollToTop>
          <Routes history={this.props.history} />
        </ScrollToTop>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Auth Actions
    userLogout: (data) => dispatch({ type: "AUTH_USER_LOGOUT", data }),
    authUpdateIsAuthenticating: (data) =>
      dispatch({ type: "AUTH_UPDATE_IS_AUTHENTICATING", data }),
    authUpdateIsAuthenticated: (data) =>
      dispatch({ type: "AUTH_UPDATE_IS_AUTHENTICATED", data }),

    // Account Action
    accountUpdateLoadingUser: (data) =>
      dispatch({ type: AccountActions.UPDATE_LOADING_USER, data }),
    accountUpdateUserName: (data) =>
      dispatch({ type: AccountActions.UPDATE_USERNAME, data }),
    accountUpdateUser: (data) =>
      dispatch({ type: AccountActions.UPDATE_USER, data }),
    accountUpdateSubscription: (data) =>
      dispatch({ type: AccountActions.UPDATE_USER_SUBSCRIPTION, data }),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
