import React, { Component } from 'react';
import { connect } from 'react-redux';
import Methods from '../../methods/view';
import LoadingTab from './LoadingTab';

import { Table, FormControl, ControlLabel } from 'react-bootstrap';

import { CreateWeb3Object } from '../../../../components/Web3';

import { ModalContainer } from '../../../../components/Modals';

import AtraAPI from '../../../../apiv2';
import { ConvertWeiToEth, ConvertEthToWei } from '../../../../helpers/Eth';


class ManageView extends Component {

  constructor(props){
    super(props);
    this._mounted = false;
  }

  componentDidMount = () => {
    this._mounted = true;
    this.start();
  }

  componentWillUnmount = () => {
    this._mounted = false;
  }

  start = async () => {
    //find balances
    try {
      if(!this.props.view.loading.liteWallet){
        this.props.updateLoadingTab({ value: false });
        if(this.props.view.liteWallet.actionType === 'liteui'){
          await this.getLiteUI(this.props.view.liteWallet.liteui);
        }
        let balancePromises = [];
        for(let x = 0; x < this.props.view.liteWallet.wallets.slice(0,100).length; x++){
          if(this._mounted){
            balancePromises.push(this.getWalletBalance(this.props.view.liteWallet.wallets[x].address));
          }
        }
        Promise.all(balancePromises).then((balances)=>{
          this.props.updateBalances({ balances });
        })
      }
    }catch(err){
      console.log(err);
    }
  }

  refresh = () => {
    AtraAPI.GetLiteWallet(this.props.view.liteWallet.id).then((liteWallet) => {

      liteWallet.initBalance = ConvertWeiToEth(liteWallet.amountPerWallet * liteWallet.numberOfWallets );

      liteWallet.amountPerWallet = ConvertWeiToEth(liteWallet.amountPerWallet);

      this.props.updateLiteWallet({ value: liteWallet });

      let balancePromises = [];
      for(let x = 0; x < this.props.view.liteWallet.wallets.slice(0,100).length; x++){
        if(this._mounted){
          balancePromises.push(this.getWalletBalance(this.props.view.liteWallet.wallets[x].address));
        }
      }
      Promise.all(balancePromises).then((balances)=>{
        this.props.updateBalances({ balances });
      })

    },(err) => {
      console.log(err);
    });
  }

  getWalletBalance = (address) => {
    return new Promise(async(resolve, reject)=>{
      try {
        const web3react = CreateWeb3Object();
        const balance = await web3react.eth.getBalance(address);
        resolve({
          eth: ConvertWeiToEth(balance),
          wei: balance,
          address
        });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    })
  }

  getLiteUI = (id) => {
    return new Promise(async (resolve, reject)=>{
      try {
        const liteui = await AtraAPI.GetLiteUI(id);
        this.props.updateLiteUI({liteui});
        resolve();
      }catch(err){
        reject();
        console.log(err);
      }
    })

  }

  handleOpenAssignEmailModal = (walletIndex) => {
    this.props.updateAssignUserModal({ walletIndex, show: true });
  }

  handleSubmitAssignEmail = async (serial) => {
    if(document.getElementById('assign-email-form').reportValidity()){
      try {
        this.props.updateAssignUserModal({ error: '' });
        this.props.updateLoading({ value: 'isSubmittingAssignUser', loading: true });
        const res = await AtraAPI.UpdateWalletUser({
          walletId: this.props.view.liteWallet.id,
          serial,
          email: document.getElementById('assign-email').value
        })
        this.props.updateAssignUserModal({ show: false });
        this.props.updateLoading({ value: 'isSubmittingAssignUser', loading: false });
        this.refresh();
      }catch(err){
        console.log(err);
        this.props.updateLoading({ value: 'isSubmittingAssignUser', loading: false });
        if(err == 'error'){
          this.props.updateAssignUserModal({ error: `Error Assigning User` });
        }else{
          this.props.updateAssignUserModal({ error: `Error Assigning User: ${err}` });
        }
      }
    }
  }

  handleCloseAssignUserModal = (modal) => {
    this.props.updateAssignUserModal({ show: false });
  }

  handleToggleKey = (walletIndex) => {
    this.props.toggleKey({ index: walletIndex });
  }


  render(){
    if(this.props.view.loading.tab){
      return <LoadingTab title={'Manage'} />;
    }
    return (
      <div className='manage-wallet-list-container'>

        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>User</th>
              <th>Balance</th>
              {this.props.view.liteWallet.actionType === 'liteui' ? <th>LiteUI</th> : null}
              <th>Address</th>
              <th>Private Key</th>
            </tr>
          </thead>
          <tbody>
            {this.props.view.liteWallet.wallets.slice(0,100).map((wallet, x) => (
              <tr key={x}>
                <td>{x}</td>
                <td>{wallet.user ? wallet.user : <a onClick={()=>this.handleOpenAssignEmailModal(x)}>Assign Email</a>}</td>
                <td>{wallet.balance || 'loading' }
                </td>
                {this.props.view.liteWallet.actionType === 'liteui' ? <td><a href={`/app/${this.props.view.liteWallet.liteui}?pk=${wallet.privateKey}`} target="_blank">Access</a></td> : null}
                <td>{wallet.address}</td>
                <td>
                  <span>
                    <a onClick={()=>this.handleToggleKey(x)}>
                    {wallet.showKey ? 'Hide Key ' : 'Show Key '}
                    </a>
                  </span>
                  {wallet.showKey ?
                      <span className='key'>{wallet.privateKey}</span>
                    : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <ModalContainer
          title={'Assign Email'}
          handleClose={()=>this.handleCloseAssignUserModal('assignUser')}
          handleSave={()=>this.handleSubmitAssignEmail(this.props.view.ux.modals.assignUser.wallet.id)}
          show={this.props.view.ux.modals.assignUser.show}
          saveButtonText={'Send Wallet'}
          isLoading={this.props.view.loading.isSubmittingAssignUser}
          >
          {this.props.view.ux.modals.assignUser.show ?

            <div className='assign-email-modal-container'>
              <p>Assigning an email to a wallet will send the wallet in an email. If the wallet QR action navigates the user to a LiteUI the email will contain a link that does the same. If the wallet exposes the private key as the action, the private key will be sent in the email.</p>
              <div># {this.props.view.ux.modals.assignUser.wallet.id}</div>
              <div>Address: {this.props.view.ux.modals.assignUser.wallet.address}</div>
              <div>
                <form id="assign-email-form" onSubmit={(e)=>e.preventDefault()}>
                  <ControlLabel>User Email</ControlLabel>
                  <FormControl
                    id='assign-email'
                    required
                    type='email'
                    />
                </form>
              </div>
              <div className='error'>{this.props.view.ux.modals.assignUser.error}</div>
            </div>

             :null}


        </ModalContainer>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    view: state.liteWalletView,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return Methods({dispatch});
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageView);
