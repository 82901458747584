import Header from './header';
import MyFirstDapp from './myFirstDapp';
import AccountSetup from './accountSetup';
import DecentralizedOrganization from './decentralizedOrganization';
import SupplyChainTraceability from './supplyChainTraceability';
import AccessPassWallets from './accesspassWallets';
import BuySellItems from './buySellItems';

export default {
  Header,
  MyFirstDapp,
  AccountSetup,
  DecentralizedOrganization,
  SupplyChainTraceability,
  AccessPassWallets,
  BuySellItems
 }
