import { API } from "aws-amplify";
import { FormatDate } from "../helpers/Date";
import axios from 'axios';

const CreateLiteUI = ({
  input
}) => {
  return new Promise(async (resolve, reject)=>{
    try {

      let data = {
        response: true,
        body: input
      }

      API.post('liteui', '', data).then(res => {
        resolve(res);
      }).catch(err => {
        if (err && err.response && err.response.data && err.response.data.error) {
          reject(err.response.data.error);
        }else{
          reject('error');
        }
      });

    } catch (err) {
      reject(err);
    }
  })
}

const SendNotification = ({
  input
}) => {
  return new Promise(async (resolve, reject)=>{
    try {

      let data = {
        response: true,
        body: input
      }

      API.post('liteui/notification', '', data).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });

    } catch (err) {
      reject(err);
    }
  })
}

const UpdateLiteUI = ({
  input
}) => {
  return new Promise((resolve, reject)=>{
    try {
      let data = {
        response: true,
        body: input
      }

      API.put('liteui', '', data).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });

    } catch (err) {
      reject(err);
    }
  })
}

const GetLiteUIs = (environmentId = null) => {
  return new Promise((resolve, reject)=>{
    try {
      let data = {
        response: true,
        queryStringParameters: {}
      }
      if(environmentId){
        data.queryStringParameters.environmentId = environmentId;
      }
      API.get('liteui', '', data).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    }catch(err){
      reject(err);
    }
  });
}

const GetLiteUI = (id) => {
  return new Promise((resolve, reject)=>{
    try {
      let data = {
        response: true,
        queryStringParameters: { id }
      }
      API.get('liteui', '', data).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    }catch(err){
      reject(err);
    }
  });
}

export default { CreateLiteUI, UpdateLiteUI, GetLiteUIs, SendNotification, GetLiteUI }
