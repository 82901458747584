import Actions from '../actions/create';
const Methods = ({ dispatch }) => {
    return {
      updateSuccess: data => dispatch({ type: Actions.UPDATE_SUCCESS, data}),
      updateTables: data => dispatch({ type: Actions.UPDATE_TABLES, data }),
      updatePointersColumns: data => dispatch({ type: Actions.UPDATE_POINTER_COLUMNS, data }),
      updateInput: data => dispatch({ type: Actions.UPDATE_INPUT, data }),
      updateColumns: data => dispatch({ type: Actions.UPDATE_COLUMNS, data }),
      updateLoading: data => dispatch({ type: Actions.UPDATE_LOADING, data }),
      updateNameStatus: data => dispatch({ type: Actions.UPDATE_NAME_STATUS, data }),
      updateDescriptionStatus: data => dispatch({ type: Actions.UPDATE_DESCRIPTION_STATUS, data }),
      updateNoColumnError: data => dispatch({ type: Actions.UPDATE_NO_COLUMN_ERROR, data }),
      updateColumnError: data => dispatch({ type: Actions.UPDATE_COLUMN_ERROR, data }),
      updateColumnStatus: data => dispatch({ type: Actions.UPDATE_COLUMN_STATUS, data }),
      removeErrors: () => dispatch({ type: Actions.REMOVE_ERRORS }),
      removeColumnError: () => dispatch({ type: Actions.REMOVE_COLUMN_ERROR }),
      resetState: () => dispatch({ type: Actions.RESET_STATE })
    }
}
export default Methods;
