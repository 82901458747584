import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import DistributeView from './DistributeView';
import ManageView from './ManageView';
import Actions from '../../actions/view';
import Methods from '../../methods/view';

class PageTabs extends Component {

  constructor(props){
    super(props);
    this._mounted = false;
  }

  componentDidMount(){
    this._mounted = true;
  }

  componentWillUnmount(){
    this._mounted = false;
  }

  handleTabSelect = key => {
    if(key != this.props.view.activeTab){
      this.props.updateLoadingTab({ value: true });
      this.props.updateActiveTab({ value: key });
    }
  };

  render() {
    const loading = this.props.view.loading.liteWallet;
    return (
      <div className="tabs-container">
        <Tabs
          className='tab-headers'
          id='tabs_component'
          activeKey={this.props.view.activeTab}
          onSelect={this.handleTabSelect}
        >
          <Tab eventKey="manage" title="Manage">
            <div className="tab-content">
              {this.props.view.activeTab === 'manage' && !loading ? <ManageView /> : null}
            </div>
          </Tab>

          <Tab eventKey="wallets" title="Distribute">
            <div className="tab-content">
              {this.props.view.activeTab === 'wallets' && !loading ? <DistributeView /> : null}
            </div>
          </Tab>

        </Tabs>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    view: state.liteWalletView
  };
};

const mapDispatchToProps = dispatch => {
  return Methods({dispatch});
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageTabs);
