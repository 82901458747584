import React from "react";
import Box from "./Box";

const GettingStartedGuides = ({ guides }) => {
  return (
    <div className="section-container">
      <div className="section-header">Getting Started Guides</div>
      <div className="section-description">
        <div>
          Learn how to build various kinds of decentralized applications.
        </div>
        <div>
          Start with ‘My First dApp’ for an adventure into building your first
          decentralized application.
        </div>
      </div>
      <div className="boxes-container">
        {guides.map((guide, i) => {
          return <Box key={i} {...guide} />;
        })}
      </div>
    </div>
  );
};

export default GettingStartedGuides;
