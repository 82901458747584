import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LiteUIMethods from '../../methods';
import { GetWalletBalance } from '../../../../sdk/wallet';


const UpdateBalance = (liteWallet, update) => {
  if(liteWallet.enabled){
    GetWalletBalance(liteWallet.address).then(balance=>{
      const wallet = {
        balance: balance
      }
      update({wallet});
    }).catch(err=>{
      console.log(err);
      console.log('Error Getting Balance LiteWallet');
    });
  }
}

class LiteWallet extends Component {
  render(){
    if(this.props.liteui.liteWallet.enabled){
      if(this.props.liteui.liteWallet.disabled){
        return (
          <div className='litewallet-balance'>(disabled)</div>
        )
      }else{
        return (
          <div className='litewallet-balance'>(Eth: {this.props.liteui.liteWallet.balance})</div>
        )
      }
    }else{
      return null;
    }

  }
}

const mapStateToProps = state => {
  return {
    liteui: state.liteui
  };
};

const mapDispatchToProps = dispatch => {
  return LiteUIMethods({dispatch});
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LiteWallet));

export { UpdateBalance }
