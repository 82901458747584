import React, { Component } from 'react';
import Container from '../../Container';
import { UserGuides } from '../../../components/Content';
import Start from '../../Start';

export default class Documentation extends Component {
  render(){
    return (
      <Container selected={['user-guides','triggers']}>
        <Start />
        <UserGuides.Header />
        <UserGuides.Triggers />
      </Container>

    )
  }
}
