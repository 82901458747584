import React, { Component } from "react";
import {connect} from  'react-redux';
import {Link} from 'react-router-dom';
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import PriceInput from "../../../../components/PriceInput/PriceInput";
import DateInput from '../../../../components/DateInput/DateInput';
import AddressInput from '../../../../components/AddressInput/AddressInput';
import ToolTip from '../../../../components/Tooltips';

import { ActionSummary } from './';

import NotificationMethods from "../../../../components/Notifications/methods";
import TriggerMethods from '../../methods/create';

const DefaultPlaceHolder = () => {
  return (
    <div className="alert-message">
      Complete the Set Action step before proceeding
    </div>
  );
};

const ConditionDTableValue = ({
  id,
  tables,
  selectedTable,
  columns,
  selectColumn,
  change
}) => {
  return (
    <div>
      <FormGroup controlId="dtable">
        <ControlLabel>Select Table to Search</ControlLabel>
        <FormControl
          data-id={id}
          value={selectedTable}
          onChange={change}
          componentClass="select"
        >
          {tables.map((table, i) => {
            return (
              <option key={i} value={table.id}>
                {table.name}
              </option>
            );
          })}
        </FormControl>
      </FormGroup>
      <FormGroup controlId="dtable-column">
        <ControlLabel>
          Select Column
        </ControlLabel>
        <FormControl
          data-id={id}
          value={selectedTable}
          onChange={change}
          componentClass="select"
        >
          {columns.map((column, i) => {
            return (
              <option key={i} value={column.name}>
                {column.name}
              </option>
            );
          })}
        </FormControl>
      </FormGroup>
    </div>
  );
};

const RenderStaticValueInput = ({
  id,
  value,
  type,
  change
}) => {
  const childProps = {
    "id": "rawValue",
    "data-id": id,
    "data-static": true
  }
  let datasets = [];
  datasets["static"] = true;
  datasets["id"] = id;

  switch(type){
    case 'price':
      return <PriceInput
        value={value}
        onChange={change}
        childProps={childProps}
        />
    break;
    case 'date':
      return <DateInput
        id={"rawValue"}
        datasets={datasets}
        value={value}
        onChange={change}
        childProps={childProps}
        />
    break;
    case 'address':
      return <AddressInput
        id={"rawValue"}
        value={value}
        onChange={change}
        datasets={datasets}
        childProps={childProps}
        />
    default:
      return (
        <FormGroup controlId="rawValue">
          <ControlLabel>Static Value</ControlLabel>
          <FormControl
            data-id={id}
            onChange={change}
            data-static={true}
            type="text"
            value={value}
          />
        </FormGroup>
      )
    break;
  }
}

const ConditionBase = ({ id, base, conditionOptions, baseChange }) => {
  return (
    <div className='condition-base'>
      <FormGroup controlId="base">
        <ControlLabel>Base Value ({base.groupLabel})</ControlLabel>
        <FormControl
          data-id={id}
          onChange={baseChange}
          componentClass="select"
        >
          {conditionOptions.base
            .filter(c => !c.disabled)
            .map((group, i) => {
              return (
                <optgroup key={i} label={group.label}>
                  {group.options.map((option, j) => {
                    return (
                      <option
                        key={j}
                        selected={base.value === option.value && base.groupId === group.group ? true : false}
                        data-group={group.group}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    );
                  })}
                </optgroup>
              );
            })}
        </FormControl>
      </FormGroup>
    </div>
  );
};

const ConditionTarget = ({
  id,
  target,
  conditionOptions,
  targetChange,
  targetStaticChange,
  targetTableChange,
  tables
}) => {
  return (
    <div className='condition-target'>
      <FormGroup>
        <ControlLabel>Target Value: {target.groupLabel}</ControlLabel>
        <FormControl
          data-id={id}
          value={target.value}
          onChange={targetChange}
          componentClass="select"
        >
          {conditionOptions.target
            .filter(c => !c.disabled)
            .map((group, i) => {
              return (
                <optgroup key={i} label={group.label}>
                  {group.options
                    .filter(o => !o.disabled)
                    .map((option, j) => {
                      return (
                        <option
                          data-group={group.group}
                          key={j}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                </optgroup>
              );
            })}
        </FormControl>
      </FormGroup>

      {target.static ? (
        <div>
          <RenderStaticValueInput
            type={target.valueType}
            change={targetStaticChange}
            id={id}
            value={target.rawValue}
          />
          {target.error ? <div className='error'>{target.error}</div> : null}
        </div>
      ) : null}
      {target.value === "dtable" ? (
        <ConditionDTableValue
          columns={conditionOptions.target.columns}
          change={targetTableChange}
          id={id}
          tables={conditionOptions.target.tables}
        />
      ) : null}
    </div>
  );
};

const ConditionOperator = ({ id, operators, selected, operatorChange }) => {
  return (
    <div className='condition-operator'>
      <FormGroup controlId="operator">
        <ControlLabel>Comparison</ControlLabel>
        <FormControl
          data-id={id}
          value={selected.value}
          onChange={operatorChange}
          componentClass="select"
        >
          {operators
            .filter(o => !o.disabled)
            .map((operator, i) => {
              return (
                <option key={i} value={operator.value}>
                  {operator.label}
                </option>
              );
            })}
        </FormControl>
      </FormGroup>
    </div>
  );
};

const ConditionLabel = ({ index }) => {
  return (
    <ControlLabel>Condition {index}</ControlLabel>
  )
}

const ConditionDescription = ({ id, description, change }) => {
  return (
    <div className='condition-description'>
      <FormGroup controlId="description">
        <ControlLabel>Description</ControlLabel>
        <FormControl
          data-id={id}
          value={description}
          onChange={change}
          type="text"
        />
      </FormGroup>
    </div>
  );
};

const ConditionStatement = ({
  id,
  condition,
  descriptionChange,
  baseChange,
  operatorChange,
  targetChange,
  targetStaticChange,
  targetTableChange,
  tables
}) => {
  return (
    <div>
      <ConditionLabel
        index={id+1}
        />
      <ConditionDescription
        id={id}
        description={condition.description}
        change={descriptionChange}
      />
      <ConditionBase
        id={id}
        base={condition.base}
        conditionOptions={condition.options}
        baseChange={baseChange}
      />
      <ConditionOperator
        id={id}
        selected={condition.operator}
        operators={condition.options.operators}
        operatorChange={operatorChange}
      />
      <ConditionTarget
        id={id}
        target={condition.target}
        conditionOptions={condition.options}
        targetChange={targetChange}
        targetStaticChange={targetStaticChange}
        targetTableChange={targetTableChange}
        tables={tables}
      />
    </div>
  );
};

const ConditionsList = ({
  actionTable,
  tables,
  remove,
  descriptionChange,
  baseChange,
  operatorChange,
  targetChange,
  targetStaticChange,
  targetTableChange,
  conditions
}) => {
  return (
    <div>
      {conditions.map((condition, i) => {
        return (
          <div className="condition-statement" key={i}>
            <ConditionStatement
              id={i}
              condition={condition}
              descriptionChange={descriptionChange}
              baseChange={baseChange}
              operatorChange={operatorChange}
              targetChange={targetChange}
              targetStaticChange={targetStaticChange}
              targetTableChange={targetTableChange}
              tables={tables}
            />
            <div className='remove-container'>
              <Button
                className="black-button"
                value={i}
                onClick={remove}
              >
                remove
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

class Condition extends Component {
  handleNext = () => {
    this.props.updateSelectedStep({ value: 'payment' });
    this.props.updateConditionNext();
  }
  handlePrev = () => {
    this.props.updateSelectedStep({ value: 'action' });
    if(this.props.trigger.action.set) {
      //set editing to true for the action
      this.props.enableEdit();
    }
  }
  isValid = () => {
    let valid = true;
    this.props.conditions.map((condition, i)=>{
      if(condition.target.error){
        valid = false;
      }
    });
    return valid;
  }
  render() {
    const actionTable = this.props.tables.find(t => t.selectedByAction);
    return (
      <div className="conditions-container section-container">
        <form autoComplete="off">
          <h3 className="section-title">Set Conditions</h3>
          <ActionSummary
            table={actionTable}
             />
          <div className='section-content'>
            <div className='header-container'>
              <h4>Create Conditions</h4>
              <p>Set the rules of the trigger. Conditions are optional.</p>
            </div>


            <fieldset disabled={this.props.action.editing}>
            <ConditionsList actionTable={actionTable} {...this.props} />

            <div className='add-condition-button-container'>
              <Button
                onClick={event => {
                  event.preventDefault();
                  this.props.add();
                }}
              >
                New Condition
              </Button>
            </div>

          </fieldset>
          </div>
        </form>
        <div className="submit-buttons">
          <Link to='/triggers'>Cancel</Link>
          <Button
            className='black-button'
            onClick={this.handlePrev}
          >
            Previous
          </Button>
          <Button
            className='secondary-button'
            onClick={this.handleNext}
            disabled={!this.isValid()}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    trigger: state.triggersCreate,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...TriggerMethods({ dispatch }),
    ...NotificationMethods({ dispatch })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Condition);
