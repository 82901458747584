import React from 'react';
import {Link} from 'react-router-dom';
import Note from '../helpers/Note';
import Direction from '../helpers/Direction';
import ScreenShot from './ScreenShot';

const Intro = ({

}) => {
  return (
    <div className='intro-section-container'>

      <section>
        <div className='reqs-container'>
          <h3>Overview</h3>
            <div className='req-container'>
              <div className='req-label'>Skill Level:</div>
              <div className='req'>Beginner -  No Coding</div>
            </div>

            <div className='req-container'>
              <div className='req-label'>System Requirements:</div>
              <div className='req'>Desktop Browser (Chrome, Brave, Firefox) with Metamask Extension</div>
            </div>

            <div className='req-container'>
              <div className='req-label'>Time:</div>
              <div className='req'>30 min</div>
            </div>

            <div className='req-container'>
              <div className='req-label'>Color Key</div>
            </div>

            <div className='colors-container'>
              <div className='color-container direction'>
                Directions - Don't skip these
              </div>
              <div className='color-container note'>
                Important Notes
              </div>
            </div>
        </div>
      </section>


      <h4>Intro</h4>
      <p>In this tutorial we will be creating a simple message board dApp to get familiar with the atra platform. We will cover the essentials of all 3 beta services - dTables, Triggers and LiteUI. By the end of this tutorial, you’ll have a message board dApp deployed to the Ethereum Goerli Testnet that you can share with friends.</p>

      <p>The dApp we are building is called BlockTalk - it’s a fast and simple way to explore the unique mechanics decentralized applications have to offer. </p>

      <p><a target="_blank" href="https://console.atra.io/app/44d48da9-8451-4da4-80ec-d453e719a210">Here</a> is an example of the BlockTalk application made and ready to use, for your reference.</p>

      <p>The Ethereum Goerli network is a free network for testing Ethereum dApps that are in development. The test net acts exactly as the main net would, but without making paid transactions to the Ethereum blockchain. The Ether used on this network and in this demo has no real value - it is for test purposes only, yet acts as Ether would on the main net where it does have value.</p>

      <h4>Installing Metamask</h4>

      <p><b>If you already have Metamask installed and testnet Ether, skip to the dTables section below.</b></p>

      <p>After you’ve viewed the pre-built version of the dApp, you’ll notice you can’t post or contribute unless you have a wallet and Ether. If you don’t yet have a browser wallet installed, keep reading for instructions on how to install Metamask and add test Ether to it.</p>

      <p>A wallet allows you to store your Ether and is the connection between you and the Ethereum application. In this tutorial, we will use <a target='_blank' href="https://metamask.io/">Metamask</a> - a widely popular browser wallet. (make sure you’re on a desktop browser - Chrome, Brave, and Firefox are compatible) Follow the instructions to download, install and setup the Metamask browser extension.</p>

      <Note>
        <p><b>Note:</b> Once Metamask is installed and open, please change the network dropdown to <b>Goerli Test Network</b> in your Metamask extension. It’s at the very top of the extension popup.</p>
      </Note>

      <ScreenShot name='metamask'/>

      <p>
        Once Metamask is installed, you’ll need to deposit test Ether into your wallet so you can participate with our Ethereum application. We are going to use the <a target='_blank' href='https://goerli-faucet.pk910.de/'>Goerli POW Faucet</a>. In order to receive test Ether (GoETH) from the faucet, you will need to insert your metamask wallet address and follow the prompts. You can find your wallet address at the top of the Metamask extension popup by clicking on your account name. Make sure to calim your GoETH reward when you're done.
      </p>
      <p>
        Don’t worry - no one can steal your Ether from you posting your public wallet address! Worst case someone may send you some Ether unexpectedly. Once the transaction is complete in the facet, you’re all set. Ether is now in your wallet.
      </p>


      <p>Congrats! You now have the basic requirements for building a decentralized application on Atra. To recap,</p>

      <ul>
        <li>You should be on a <b>supported</b> desktop browser with the Metamask extension installed</li>
        <li>Set your Metamask wallet to the Goerli Test Network</li>
        <li>Make sure test Ether is in your wallet</li>
      </ul>

      <p><b>Let’s start building our dApp - BlockTalk</b></p>

    </div>
  )

}

export default Intro;
