import Actions from '../actions/dashboard';

class DefaultState {
  constructor(){
    this.wallets = [];

    this.loadingWallets = true;

    this.stats = {
      total_wallets: 0,
      total_batches: 0
    };
    this.filter = {
      showArchived: false
    }
  }
}

export default (state = new DefaultState, action) => {
  switch (action.type) {
    case Actions.TOGGLE_FILTER :{
      let newState = {...state};
      newState.filter[action.data.value] = !newState.filter[action.data.value];
      return newState;
    }
    case Actions.UPDATE_WALLETS: {
      let newState = {...state};
      newState.wallets = action.data.value;
      newState.stats.total_batches = newState.wallets.length;
      newState.stats.total_wallets = newState.wallets.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.wallets.length;
      },0);
      // newState.stats.total_deploying_triggers = newState.triggers.filter( t => t.status !== 'Live' ).length;
      return newState;
    }
    case Actions.UPDATE_LOADING_WALLETS:
      return {
        ...state,
        loadingWallets: action.data.value
      };
    case Actions.CLEAR_STATE:
      return new DefaultState();
    default:
      return state;
  }
};
