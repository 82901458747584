import React from 'react';

import JSONPretty from 'react-json-pretty';
import Content from './';
import { Next } from '../Utils';

const dTables = ({

}) => {

  //Code Samples

  const header = "x-api-key: [Atra API key]";

  const allDtablesReturn = [
      {
      "columns": [
          {
              "name": "Title",
              "type": "text",
              "solType": "string"
          },
          {
              "name": "Price",
              "type": "price",
              "solType": "uint"
          },
          {
              "name": "OriginalArtist",
              "type": "address",
              "solType": "address"
          },
          {
              "name": "Owner",
              "type": "address",
              "solType": "address"
          },
          {
              "name": "OriginalArtistName",
              "type": "text",
              "solType": "string"
          }
      ],
      "goerli": "0x5ddeF787DdbE5437d718277A59B2c1380d79073C",
      "status": "Live",
      "created": 1554054946357,
      "description": "Sell rare digital art online",
      "id": "3e60fdfe-1614-4e7a-897a-0debcb4aaaf8",
      "name": "ArtGallery",
      "transactionHash": "0x0b7f52b8e75b91da613027d0a82efef0f7a3d5141a42868a8c98d515ce13ead7"
      }
    ];

  const singleDtableReturn = {
    "columns": [
        {
            "name": "Message",
            "type": "text",
            "solType": "string"
        }
    ],
    "goerli": "0x3CF3b5c742Bb9b018F609e36C3De7f3d8b55A142",
    "abi": "[{\"constant\":true,:\"event\"},{\"anonymous\":false,\"inputs\":[{\"indexed\":false,\"name\":\"_address\",\"type\":\"address\"}],\"name\":\"PermissionRevoked\",\"type\":\"event\"}]",
    "status": "Live",
    "created": 1555623270389,
    "id": "51bfa887-f285-40cd-b336-887555a50301",
    "description": "set table description",
    "name": "Hello_world",
    "transactionHash": "0x6ec2c2511689cab94d899105b8badeb5c5e53a3c217f27a2f840656c3f68a7ab"
  }

  const createDtable = {
  	"name": "ExampleTable",
  	"columns": [{
    		"name": "Username",
    		"type": "text"
    	},{
    		"name": "Age",
    		"type": "number"
    	}]
  }

  const createDtableResponse = {
    "tableId": "0eea757c-c702-4054-9ee4-7d59c110d2d5"
  }

  const getAllRecordsReponse = {
        "live": [
            {
                "0": "0xFb8Dff41Ba7cc8BAb43C4D42bEAb50F44bB25fA2",
                "1": [
                    "message to the aliens"
                ],
                "recordId": "0xFb8Dff41Ba7cc8BAb43C4D42bEAb50F44bB25fA2",
                "record": [
                    "message to the aliens"
                ]
            }
        ],
        "pending": [
            {
                "tableId": "51bfa887-f285-40cd-b336-887555a50301",
                "date": 1555786532884,
                "id": "8e1cb33e-132d-46ba-a252-5094599d0262",
                "data": [
                    "hello world!"
                ],
                "network": "goerli",
                "status": "Processing"
            }
        ]
    }

  const getRecordCountResponse = {
      "count": "3"
  }

  const insertRecordRequest = {
  	"tableId": "ef334a7a-ae41-421a-92e8-e44fab71df02",
  	"record": ["first column value",2,'third column value']
  }

  const insertRecordResponse = {
    "recordId": "5b758c81-579b-4f57-bfad-22e3d19fc413"
  }

  return (
    <div className='doc-section-content-container'>
      <h2>dTables</h2>
      <Content.Headers/>

      <section>
        <h5>Get All dTables</h5>
        <h2>GET /dtables</h2>
        <div>Returns all dTables for your account</div>

        <h5>Response JSON (Array)</h5>
        <ul>
          <li><b>name</b> - (string): The name of the dTable</li>
          <li><b>description</b> - (string): The user given description of the dTable</li>
          <li><b>id</b> - (string): The identifier created by Atra</li>
          <li><b>status</b> - (string): The status of the dTable deployment (Processing, Pending, Live, Error)</li>
          <li><b>goerli</b> - (string): The Goerli Ethereum Test Net Address of the dTable smart contract</li>
          <li><b>columns</b> - (array): The list of columns objects that represent the dTable data structure</li>
          <ul>
            <li><b>name</b> - (string): The user given name of the column</li>
            <li><b>type</b> - (string): The Atra column type (address, date, number, text, price)</li>
            <li><b>solType</b> - (string): The underlying solidity type</li>
          </ul>
          <li><b>created</b> - (number): Unix timestamp (UTC-0)</li>
          <li><b>transactionHash</b> - (string): The deploy smart contract transaction hash</li>
        </ul>

        <h5>Example Response</h5>
        <JSONPretty id="json-pretty" data={allDtablesReturn}></JSONPretty>

      </section>

      <section>
        <h5>Get dTable</h5>
        <h2>GET /dtables?tableId</h2>
        <div>Returns a single dTable from your account</div>

        <h5>Request Query String Parameters</h5>
        <ul>
          <li><b>tableId</b> - (string): The ID of the Atra dTable</li>
        </ul>

        <h5>Example Request with Query String</h5>
        <JSONPretty id="json-pretty" data={'https://api.atra.io/prod/v1/dtables?tableId=035a84ae-e8e4-4643-b984-54f34beb9655'}></JSONPretty>

        <h5>Response JSON (Object)</h5>
        <ul>
          <li><b>name</b> - (string): The name of the dTable</li>
          <li><b>description</b> - (string): The user given description of the dTable</li>
          <li><b>id</b> - (string): The identifier created by Atra</li>
          <li><b>status</b> - (string): The status of the dTable deployment (Processing, Pending, Live, Error)</li>
          <li><b>goerli</b> - (string): The Goerli Ethereum Test Net Address of the dTable smart contract</li>
          <li><b>abi</b> - (string): The solidity smart contract abi json string</li>
          <li><b>columns</b> - (array): The list of columns objects that represent the dTable data structure</li>
          <ul>
            <li><b>name</b> - (string): The user given name of the column</li>
            <li><b>type</b> - (string): The Atra column type (address, date, number, text, price)</li>
            <li><b>solType</b> - (string): The underlying solidity type</li>
          </ul>
          <li><b>created</b> - (number): Unix timestamp (UTC-0)</li>
          <li><b>transactionHash</b> - (string): The deploy smart contract transaction hash</li>
        </ul>

        <h5>Example Response</h5>
        <JSONPretty id="json-pretty" data={singleDtableReturn}></JSONPretty>

      </section>

      <section>
        <h5>Create dTable</h5>
        <h2>POST /dtables</h2>
        <div>Creates a new dTable on your account</div>

        <h5>Request JSON (object)</h5>
        <ul>
          <li><b>name</b> - (string): The user given name of the dTable they are creating</li>
          <li><b>columns</b> - (array): The list of columns that will represent the dTable</li>
          <ul>
            <li><b>name</b> - (string): The user given name of the column</li>
          	<li><b>type</b> - (string): The Atra type of column (address, date, number, text, price)</li>
          </ul>
        </ul>

        <h5>Example Request</h5>
        <JSONPretty id="json-pretty" data={createDtable}></JSONPretty>

        <h5>Response JSON (object)</h5>
        <ul>
          <li><b>tableId</b> - (string): The ID of the Atra dTable</li>
        </ul>

        <h5>Example Response</h5>
        <JSONPretty id="json-pretty" data={createDtableResponse}></JSONPretty>

      </section>

      <section>
        <h5>Get All Records</h5>
        <h2>GET /dtables/records?tableId</h2>
        <div>Returns all records for a dTable instance.</div>

        <h5>Request Query String Parameters</h5>
        <ul>
          <li><b>tableId</b> - (string): The ID of the Atra dTable</li>
        </ul>

        <h5>Example Request with Query String</h5>
        <JSONPretty id="json-pretty" data={'https://api.atra.io/prod/v1/dtables/records?tableId=0eea757c-c702-4054-9ee4-7d59c110d2d5'}></JSONPretty>

        <h5>Response JSON (Object)</h5>
        <ul>
          <li><b>live</b> - (array): Records pulled directly from Ethereum</li>
          <ul>
            <li><b>recordId</b> - (string): The recordId is used to identify records in the smart contract. Used when connecting dTables contract to other contarcts.</li>
            <li><b>record</b> - (array): The list of values inside a record in the same order the columns are in</li>
          </ul>
          <li><b>pending</b> - (array): Records account owner has submitted from the API or Console that are still pending on Ethereum</li>
          <ul>
            <li><b>tableId</b> - (string): The dTable ID from which the record is being inserted into</li>
            <li><b>date</b> - (number): Unix timestamp (UTC-0)</li>
            <li><b>id</b> - (string): The Atra record Id</li>
            <li><b>data</b> - (array): An array the of data sent through Atra for the new record</li>
            <li><b>network</b> - (string): The Ethereum network</li>
            <li><b>status</b> - (string): The status of the record (Processing, Pending, Live, Error)</li>
          </ul>
        </ul>

        <h5>Example Response</h5>
        <JSONPretty id="json-pretty" data={getAllRecordsReponse}></JSONPretty>
      </section>

      {/*
        <section>
        <h5>Get Record</h5>
        <h2>GET /dtables/records?tableId&recordId</h2>
        <div>Returns a single record from a dTable instance.</div>

        <h5>Request Query String Parameters</h5>

        <h5>Example Request with Query String</h5>

        <h5>Response JSON (Object)</h5>

        <h5>Example Response</h5>
        </section>
      */}

      <section>
        <h5>Get Record Count</h5>
        <h2>GET /dtables/records?tableId&metadata</h2>
        <div>Returns the amount of records in a dTable instance.</div>

        <h5>Request Query String Parameters</h5>
        <ul>
          <li><b>tableId</b> - (string): The ID of the Atra dTable</li>
          <li><b>metadata</b> - (boolean): When metadata is True records are not returned instead the record count for the dTable is returned</li>
        </ul>

        <h5>Example Request with Query String</h5>
        <JSONPretty id="json-pretty" data={'https://api.atra.io/prod/v1/dtables/records?tableId=bcf99873-5994-42dd-b2cd-2e0e7882528f&metadata=true'}></JSONPretty>

        <h5>Response JSON (Object)</h5>
        <ul>
          <li><b>count</b> - (string): The amount of live records in the dTable contract on Ethereum</li>
        </ul>

        <h5>Example Response</h5>
        <JSONPretty id="json-pretty" data={getRecordCountResponse}></JSONPretty>
      </section>

      <section>
        <h5>Insert Record</h5>
        <h2>POST /dtables/records</h2>
        <div>Inserts a record into a dTable smart contract using your account.</div>

        <h5>Request JSON (Object)</h5>
        <ul>
          <li><b>tableId</b> - (string): The dTable ID you want to insert a new record into</li>
          <li><b>record</b> - (array): An array of value for your new record, one value for each column in the record</li>
        </ul>

        <h5>Example Request</h5>
        <JSONPretty id="json-pretty" data={insertRecordRequest}></JSONPretty>

        <h5>Response JSON (Object)</h5>
        <ul>
          <li><b>recordId</b> - (string): The Atra recordId, this is not the same as the recordId returned from the smart contract</li>
        </ul>

        <h5>Example Response</h5>
        <JSONPretty id="json-pretty" data={insertRecordResponse}></JSONPretty>
      </section>

      <Next
        title={'Triggers API'}
        link={'/docs/api/triggers'}
         />

    </div>
  )
}

export default dTables;
