import React from "react";
import Box from "./Box";
import {Link} from 'react-router-dom';

const Services = ({ services }) => {
  return (
    <div className="section-container">
      <div className="section-header">Services</div>
      <div className="section-description">
        <div>
          Use Atra services to help you build decentralized infrastructure to
          run your application.
        </div>
        <div>
          We offer a getting started guide{" "}
          <Link to="/docs/overview/getting-started">here</Link> or click on a service below to
          explore.
        </div>
      </div>
      <div className="boxes-container">
        {services.map((service, i) => {
          return <Box key={i} {...service} />;
        })}
      </div>
    </div>
  );
};

export default Services;
