import React, { useState } from "react";
import { ModalContainerOK } from "../Modals";
import PaymentMethod from "./PaymentMethod";

const CheckoutCompleteModal = ({ show, handleClose }) => {
  return (
    <ModalContainerOK show={show} title={"Success"} handleClose={handleClose}>
      You're order has been processed successfully
    </ModalContainerOK>
  );
};

export default CheckoutCompleteModal;
