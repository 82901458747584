import React, { useState } from "react";
import { PaymentMethod } from "./";
import { FormatDate } from "../../helpers/Date";

const NoCardInfo = () => {
  return (
    <div>
      <i>
        None on file. Your payment information will be displayed here when you
        upgrade your plan.
      </i>
    </div>
  );
};

const NoInvoiceInfo = () => {
  return <div></div>;
};

const InvoiceSchedule = ({ invoice }) => {
  const nextPayment = FormatDate(invoice.next_payment_attempt * 1000);
  const chargeAmount = invoice.total / 100;
  return (
    <div className="next-invoice-container">
      Next Invoice {nextPayment} for ${chargeAmount}
    </div>
  );
};

const BillingSummary = ({ billing = {}, handlePaymentMethodUpdate }) => {
  return (
    <div className="billing-summary-container">
      <h3>Billing Information</h3>
      {billing.card ? (
        <div>
          <PaymentMethod
            card={billing.card}
            handlePaymentMethodUpdate={handlePaymentMethodUpdate}
          />
        </div>
      ) : (
        <NoCardInfo />
      )}

      {billing.upcomingInvoice ? (
        <InvoiceSchedule invoice={billing.upcomingInvoice} />
      ) : (
        <NoInvoiceInfo />
      )}
    </div>
  );
};

export default BillingSummary;
