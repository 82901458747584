class box {
  constructor(
    title,
    subTitle,
    supTitle,
    image,
    url,
    footNotes,
    customClass,
    comingSoon
  ) {
    this.title = title;
    this.subTitle = subTitle;
    this.supTitle = supTitle;
    this.image = image;
    this.url = url;
    this.footNotes = footNotes;
    this.customClass = customClass;
    this.comingSoon = comingSoon;
  }
}

export default box;
