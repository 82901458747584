import Actions from "../actions/view";
import moment from 'moment-timezone';
class DefaultState {
  constructor(){
    this.loadingTab = true;
    this.loadingInfo = true;
    this.archiving = false;
    this.activeTab = "records";
    this.pendingTransactions = [];
    this.table = {
      id: null,
      columns: [],
      pointerColumns: [],
      records: {
        live: [],
        pending: []
      },
      permissions: {
        pending: [],
        live: []
      },
      contract: null
    };
    this.newRecord = {
      show: false,
      record: [],
      submitting: false,
      error: null,
      loading: false
    };
    this.newPermission = {
      show: false,
      address: '',
      error: null,
      submitting: false,
      validationMessage: ''
    };
  }
};

export default (state = new DefaultState, action) => {
  switch (action.type) {
    //Loading
    case Actions.UPDATE_ARCHIVING:
      return {
        ...state,
        archiving: action.data.value
      };
    case Actions.DTABLES_VIEW_UPDATE_LOADING_INFO:
      return {
        ...state,
        loadingInfo: action.data.value
      };
    case Actions.DTABLES_VIEW_UPDATE_LOADING_TAB:
      return {
        ...state,
        loadingTab: action.data.value
      };
    case Actions.DTABLES_VIEW_UPDATE_LOADING_SUBMITTING_NEW_RECORD:
      var newState = {...state};
      newState.newRecord.loading = action.data.value;
      return newState;

    //Tab
    case Actions.DTABLES_VIEW_SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.data.value
      };
    //Table Info
    case Actions.DTABLES_VIEW_UPDATE_TABLEID:
      return {
        ...state,
        table: {...state.table, id: action.data.value}
      };
    case Actions.DTABLES_VIEW_UPDATE_TABLE:
      var newState = {...state};
      newState.table = {...state.table, ...action.data.value};
      return newState;
    //Contract View
    case Actions.DTABLES_VIEW_UPDATE_CONTRACT:
      return {
        ...state,
        table: {...state.table, contract: action.data.value}
      };
    //Records View
    case Actions.DTABLES_VIEW_UPDATE_RECORDS:
      return {
        ...state,
        table: {...state.table, records: action.data.value }
      };
    case Actions.DTABLES_VIEW_ERASE_RECORDS:
      return {
        ...state,
        records: []
      };
    case Actions.DTABLES_VIEW_SHOW_NEW_RECORD:
      var newState = {...state};
      newState.newRecord.show = action.data.value;
      newState.newRecord.record = newState.table.columns.map(column=>{
        let value = null;
        let status = {
          valid: false,
          message: ''
        }
        switch(column.type){
          case 'address':
            value = '';
            status.valid = false;
          break;
          case 'price':
            value = 0;
            status.valid = true;
          break;
          case 'date':
            value =  new Date(moment(new Date()).tz(action.data.timezone.code).format("MM/DD/YYYY hh:mm:ss A"));
            status.valid = true;
          break;
          case 'number':
            value = 0;
            status.valid = true;
          break;
          default:
            value = null;
          break;
        }

        return {
          value,
          status
        }
      });
      return newState;
    //New Record Dialog
    case Actions.DTABLES_VIEW_UPDATE_NEW_RECORD:
      var newState = {...state};
      newState.newRecord.record = action.data.value;
      return newState;
    case Actions.DTABLES_VIEW_UPDATE_NEW_RECORD_ERROR:
      var newState = {...state};
      newState.newRecord.error = action.data.value;
      return newState;

    case Actions.UPDATE_NEW_RECORD_VALUE_STATUS: {
      let newState = {...state};
      newState.newRecord.record[action.data.index].status = {
        valid: action.data.valid,
        message: action.data.message
      }
      return newState;
    }

    //Transactions
    case Actions.ADD_PENDING_TRANSACTION: {
      let newState = {...state};
      newState.pendingTransactions.push(action.data.value);
      return newState;
    }
    case Actions.REMOVE_PENDING_TRANSACTION: {
      let newState = {...state};
      const index = newState.pendingTransactions.findIndex(action.data.value);
      newState.pendingTransactions.splice(index, 1);
      return newState;
    }

    //Permissions View
    case Actions.DTABLES_VIEW_UPDATE_PERMISSIONS:
      var livePermissions = action.data.value.live.map((permission)=>{
        return {
          address: permission,
          revoking: false,
          pending: false
        }
      })
      var pendingPermissions = action.data.value.data.filter( p => p.status === 'Pending' || p.status === 'Processing').map((permission)=>{
        return {
          address: permission.data.address,
          revoking: false,
          pending: true
        }
      })
      return {
        ...state,
        table: {
          ...state.table,
          permissions: {
            live: livePermissions,
            pending: pendingPermissions
          }
        }
      };
    //Permission Revoking
    case Actions.DTABLES_VIEW_UPDATE_REVOKING_PERMISSION:
      var newState = {...state};
      newState.table.permissions.live.find( p => p.address === action.data.value ).revoking = true;
      return newState;
    //Permission Adding
    case Actions.DTABLES_VIEW_UPDATE_NEW_PERMISSION_ADDRESS:
      return {
        ...state,
        newPermission: {...state.newPermission, address: action.data.value }
      };
    case Actions.DTABLES_VIEW_SHOW_NEW_PERMISSION_FORM:
      return {
        ...state,
        newPermission: {...state.newPermission, show: action.data.value }
      };
    case Actions.DTABLES_VIEW_NEW_PERMISSION_ERROR:
      return {
        ...state,
        newPermission: {...state.newPermission, error: action.data.value }
      };
    case Actions.DTABLES_VIEW_UPDATE_NEW_PERMISSION_VALIDATION_MESSAGE:
      return {
        ...state,
        newPermission: {...state.newPermission, validationMessage: action.data.value }
      };
    case Actions.DTABLES_VIEW_UPDATE_NEW_PERMISSION_SUBMITTING:
      return {
        ...state,
        newPermission: {...state.newPermission, submitting: action.data.value }
      };

    case Actions.DTABLES_VIEW_CLEAR_STATE:
      return new DefaultState;
    default:
      return state;
  }
};
