import Actions from '../actions/create';
const Methods = ({ dispatch }) => {
    return {
      updateLoading: data => dispatch({ type: Actions.UPDATE_LOADING, data}),
      updateLiteUIs: data => dispatch({ type: Actions.UPDATE_LITEUIS, data }),
      updateOptions: data => dispatch({ type: Actions.UPDATE_OPTIONS, data }),
      updateInputStatus: data => dispatch({ type: Actions.UPDATE_INPUT_STATUS, data }),
      updateSubmitError: data => dispatch({ type: Actions.UPDATE_SUBMIT_ERROR, data }),
      clearState: data => dispatch({ type: Actions.CLEAR_STATE, data })
    }
}
export default Methods;
