import React from 'react';
import { Link } from  'react-router-dom';

const HelpingPoints = ({ customClass }) => {
  const styles = `helping-points-container ${customClass}`;
  return (
    <div className={styles}>
      <div className='helping-point'>
        <div className='point-header'>
          First time on the console?
        </div>
        <div className='point-content'>
          View the <Link to='/docs/overview/getting-started'>Getting Started</Link> page.
        </div>
      </div>
      <div className='helping-point'>
        <div className='point-header'>
          Ready to build something?
        </div>
        <div className='point-content'>
          Complete the <Link to='/docs/tutorials/my-first-dapp'>My First dApp</Link> tutorial.
        </div>
      </div>
      <div className='helping-point'>
        <div className='point-header'>
          Interested in learning about our API?
        </div>
        <div className='point-content'>
          <Link to='/docs/api/dtables' >API Docs</Link>.
        </div>
      </div>
      <div className='helping-point'>
        <div className='point-header'>
          Confused?
        </div>
        <div className='point-content'>
          <Link to='/docs/overview/about' >Learn More</Link>.
        </div>
      </div>
    </div>
  )
}

export default HelpingPoints;
