import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import LoaderButton from "../../../../components/Buttons/LoaderButton";

const ModalContainerOK = ({
  show,
  title,
  handleClose,
  children,
  className
}) => {
  return (
    <Modal backdrop='static' className={`liteui-modal ${className}`} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button className='white-button' onClick={handleClose}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

const ModalContainer = ({
  show,
  title,
  handleClose,
  handleSave,
  children,
  saveButtonText,
  isLoading
}) => {
  return (
    <Modal backdrop='static' className='liteui-modal' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button className='white-button' onClick={handleClose}>Cancel</Button>
        <LoaderButton
          className='secondary-button'
          isLoading={isLoading}
          loadingText={'Pending'}
          text={saveButtonText ? saveButtonText : 'Save'}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
}



export default { ModalContainer, ModalContainerOK };
