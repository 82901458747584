import React from 'react';
import { Intro } from './BuySellItems/';
import { Next } from '../Utils';

const BuySellItems = ({

}) => {
  return (
    <div className='doc-section-content-container docs-tutorial-container '>
      <h2>Buy and Sell Items</h2>

      <Intro />

      <Next
        title={'dTables User Guide'}
        link={'/docs/user-guides/dtables'}
         />


    </div>
  )
}

export default BuySellItems;
