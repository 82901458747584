import { API } from "aws-amplify";

const ArchiveResource = (
  service,
  id,
  archive
) => {
  return new Promise((resolve, reject) => {
    try {
      const data = {
        response: true,
        body: {
          service: service,
          id: id,
          archive: archive
        }
      };
      API.post("services/archive", "", data).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    }catch(err){
      reject(err);
    }
  });
};

export default { ArchiveResource }
