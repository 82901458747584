import Actions from '../actions/liteui-dashboard';

class DefaultState {
  constructor(){
    this.uis = [];
    this.loading = {
      uis: true
    }
  }
}

export default ( state = new DefaultState(), action) => {
  switch(action.type){
    case Actions.UPDATE_LOADING_UIS:
      return {...state, loading: action.data.value};
    default:
      return state;
  }
}
