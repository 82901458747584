import React from 'react';

const ActionSummary = ({
  table
}) => {
  return <div className='action-summary-container'>
    <div><b>Action Summary: </b>
      <span>Modifying the <b>{table.name}</b> dTable with the <b>{ table.actionMethod[0].toUpperCase() + table.actionMethod.substring(1)}</b> action</span>
    </div>
  </div>
}

export default ActionSummary;
