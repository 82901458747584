import Actions from '../actions/index';
class DefaultState {
  constructor(){
    this.status = {
      loading: true,
      error: false,
      code: null
    }
  }
}

export default (state = new DefaultState(), action) => {
  switch(action.type){
    case Actions.UPDATE_STATUS:
      return {...state, status: action.data.value};
    default:
      return {...state};
  }
}
