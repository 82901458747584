import { signup } from "../actions/actionTypes";

let defaultState = {
  isLoading: false,
  email: "",
  password: "",
  confirmPassword: "",
  confirmationCode: "",
  confirmationCodeError: "",
  emailError: "",
  passwordError: [],
  confirmPasswordError: "",
  newUser: null,
  alertMessage: "",
  confirmValid: false,
  resetState: true
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case signup.SIGNUP_DEFAULT_STATE:
      return state.resetState ? defaultState : state;
    case signup.SIGNUP_UPDATE_EMAIL:
      return {
        ...state,
        email: action.data.value
      };
    case signup.SIGNUP_UPDATE_PASSWORD:
      return {
        ...state,
        password: action.data.value
      };
    case signup.SIGNUP_UPDATE_CONFIRMATION_CODE:
      return {
        ...state,
        confirmationCode: action.data.value
      };
    case signup.SIGNUP_UPDATE_CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword: action.data.value
      };
    case signup.SIGNUP_UPDATE_CONFIRMATION_CODE_ERROR:
      return {
        ...state,
        confirmationCodeError: action.data.value
      };
    case signup.SIGNUP_UPDATE_EMAIL_ERROR:
      return {
        ...state,
        emailError: action.data.value
      };
    case signup.SIGNUP_UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        passwordError: [action.data.value, ...state.passwordError]
      };
    case signup.SIGNUP_CLEAR_PASSWORD_ERROR:
      return {
        ...state,
        passwordError: []
      };
    case signup.SIGNUP_REMOVE_PASSWORD_ERROR:
      return {
        ...state,
        passwordError: state.passwordError.filter(
          error => error !== action.data.value
        )
      };
    case signup.SIGNUP_UPDATE_CONFIRM_PASSWORD_ERROR:
      return {
        ...state,
        confirmPasswordError: action.data.value
      };
    case signup.SIGNUP_UPDATE_IS_LOADING:
      return {
        ...state,
        isLoading: action.data.value
      };
    case signup.SIGNUP_UPDATE_NEW_USER:
      return {
        ...state,
        newUser: action.data.value
      };
    case signup.SIGNUP_LOGIN_NOT_CONFIRMED:
      return {
        ...state,
        newUser: action.data.email,
        email: action.data.email,
        alertMessage: action.data.message,
        resetState: false
      };
    case signup.SIGNUP_UPDATE_NEW_USER_ERROR:
      return {
        ...state,
        emailError: action.data.value
      };
    case signup.SIGNUP_CLEAR_SIGNUP_STATE:
      {
        state = defaultState;
      }
      return state;
    default:
      return state;
  }
};
