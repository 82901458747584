import React from "react";
import Routes from "../../Routes";
import Footer from "../Navigation/Footer";
import PopupNotifications from "../Notifications/components/popup";

const PublicLayout = ({ isAuthenticated, header, children, hideFooter = false }) => {
  const childProps = {
    isAuthenticated: isAuthenticated
  };
  let headerClass = !header ? 'no-header' : '';
  return (
    <div>
      <PopupNotifications />
      <div className='App container'>
        <div className={`content-wrapper  ${headerClass}`}>
            {children}
        </div>
      </div>
      {hideFooter?
        null
        :
        <Footer />
      }
    </div>

  );
};

export default PublicLayout;
