import React from 'react';

const DashboardFooter = ({
  title,
  description,
  link
}) => {
  return (
    <div className="dashboard-footer-container">
      <h1>
        {title}
      </h1>
      <p className="">
        {description}
      </p>
      <a href={link} className="">
        LEARN MORE
      </a>
    </div>
  )
}

export default DashboardFooter;
