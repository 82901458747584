import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Jumbotron, OverlayTrigger, Popover, Glyphicon, Nav, NavItem, Navbar, Modal, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import Methods from "../../methods";
import { RenderUIElements, GetIcon, DetailView, AppViewRouter, LiteNav, EditPageActionHeader, ResourceStrings, PendingTransactions, Wallet } from "./index";
import LoaderButton from '../../../../components/Buttons/LoaderButton';
import UIModals from '../UIModals';
import MetaMask from "../../../../components/MetaMask";
import ToolTip from "../../../../components/Tooltips";
import { GetBase64 } from "../../../../helpers/Image";
import { Banner } from "../Public";
import { StatusBanner } from '../';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

import AtraAPI from '../../../../apiv2';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { bootstrapUtils } from 'react-bootstrap/lib/utils';
bootstrapUtils.addStyle(Button, 'liteui-nav-info');

const OpenPublicLiteUI = ({
  id,
  mode
}) => {
  if(mode === 'edit'){
    const url = `/app/${id}`;
    return (
      <div className='open-public-container'>
        <a href={url} target='_blank'>Open Public URL</a>
      </div>
    )
  }else{
    return null;
  }
}

const AppView = ({
  mode,
  view,
  UIElementHandlers,
  ResourceStrings,
  detailView,
  history,
  location
}) => {

  const defaultNoPages = (name) => (
    <div>
      <Jumbotron>
        <h2>{ResourceStrings.BlankPage.Header}</h2>
        <p>
          {ResourceStrings.BlankPage.Description}
        </p>
        {ResourceStrings.ElementDescriptions.map((element,i)=>{
          return <div key={i} className='default-elements-container'>
            <h3>{i+1}.{element.Title}</h3>
            <div className='element-container'>
              <p>{element.Description}</p>
              <GetIcon icon={element.Icon} />
            </div>
          </div>
        })}
      </Jumbotron>
    </div>
  );

  const defaultView = (name) => (
    <div>
      <Jumbotron>
        <h2>{ResourceStrings.NoElements.Header}</h2>
        {ResourceStrings.ElementDescriptions.map((element,i)=>{
          return <div key={i} className='default-elements-container'>
            <h3>{i+1}.{element.Title}</h3>
            <div className='element-container'>
              <p>{element.Description}</p>
              <GetIcon icon={element.Icon} />
            </div>
          </div>
        })}
      </Jumbotron>
    </div>
  );

  if(!view){
    return <div className='liteui-view-container'>
      {defaultNoPages()}
    </div>
  }

  if(mode === 'create' || mode === 'edit'){
    if(detailView.show){
      return (
        <div className='liteui-view-container'>
          <DetailView
            key={detailView.recordId}
            mode={mode}
            UIElementHandlers={UIElementHandlers}
            view={view}
            detailView={detailView}
            history={history}
          />
        </div>
      );
    }else{
      if(view.elements.length){
        return (
          <div className='liteui-view-container'>
            <RenderUIElements mode={mode} history={history} location={location} UIElementHandlers={UIElementHandlers} view={view} />
          </div>
        );
      }else{
        return (
          <div className='liteui-view-container'>
            {defaultView()}
          </div>
        );
      }
    }
  }else if(mode === 'public'){
    if(detailView.show){
      return (
        <div className='liteui-view-container'>
          <DetailView
            key={detailView.recordId}
            mode={mode}
            UIElementHandlers={UIElementHandlers}
            view={view}
            detailView={detailView}
            history={history}
          />
        </div>
      );
    }else{
      if(view.elements.length){
        return (
          <div className='liteui-view-container'>
            <RenderUIElements mode={mode} history={history} location={location} UIElementHandlers={UIElementHandlers} view={view} />
          </div>
        );
      }else{
        return (
          <div className='liteui-view-container'>
          </div>
        );
      }
    }
  }
}

const HeaderNamePlaceHolder = ({
  name
}) => {
  return (
    <div className='header placeholder-container'>
        {name ?
        <h1>{name}</h1>
        :
        <h1>Site Name</h1>
        }
    </div>
  )
}

const LogoPlaceHolder = ({
  logo
}) =>{
  return (
    <div className='liteui-logo placeholder-container'>
      {logo.set ?
        <img src={logo.base64} />
      :
        <FontAwesomeIcon icon={faCamera} />
      }
    </div>
  )
}

const SubheaderPlaceHolder = ({
  subheader
}) => {
  return (
    <div className='subheader placeholder-container'>
      {subheader ?
      <h4>{subheader}</h4>
      :
      <h4>Site tagline</h4>
      }
    </div>
  )
}

const Header = ({
  mode,
  name,
  subheader,
  logo,
  handleChange,
  handleLogoChange,
  handleShowEditDialog
}) => {
  if(mode === 'public'){
    return (
      <div className='app-header-container'>
        <div className='container'>
          <div>
            <div className='left-container public-header'>
              <div>
                <h1>{name}</h1>
                {logo.set ?
                  <img className='logo' src={logo.base64} />
                : null}
              </div>
              <div>
                <h3>{subheader}</h3>
              </div>
            </div>
            <div className='right-container'>

            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className='app-header-container'>
        <div>
            <div className='left-container'>

              <div onClick={handleShowEditDialog} className='header-content-container'>
                <div>
                  <HeaderNamePlaceHolder
                    name={name}
                   />
                  <LogoPlaceHolder
                    logo={logo}
                     />
                </div>
                <SubheaderPlaceHolder
                  subheader={subheader}
                   />
              </div>

            </div>
          </div>
      </div>
    )
  }
}


class Layout extends Component {

  constructor(props){
    super(props);
    this.start();
  }
  componentDidUpdate = (prevProps) => {
    // console.log('New View:', this.props.match.params.view);
    if(this.props.mode === 'public'){
      if (prevProps.match.params.view !== this.props.match.params.view) {
        const liteui = this.props.match.params.id;
        const view = this.props.match.params.view || 0;
        this.props.updateSelectedPage({ value: view });
      }
      if(prevProps.match.params.parent !== this.props.match.params.parent || prevProps.match.params.record !== this.props.match.params.record){
        const parent = this.props.match.params.parent;
        const record = this.props.match.params.record;
        if(parent && record){

          let isParentInsideDetailView = false;
          if(this.props.liteui.detailViewElements.find(e => e.id == parent)){
            isParentInsideDetailView = true;
          }

          let detailView = {
            ...this.props.liteui.ux.detailView,
            show: true,
            recordId: record,
            parentElementId: parent,
            isParentInsideDetailView
          };
          this.props.updateDetailView({ value: detailView });
        }else{
          this.props.updateDetailView({ value: {...this.props.liteui.ux.detailView, show: false} });
        }
      }
    }

  }

  start = () => {
    //set the mode
    this.props.setMode(this.props.mode);
    //set the state based on the mode
    const id = this.props.match.params.id;
    const view = this.props.match.params.view;
    const parent = this.props.match.params.parent;
    const record = this.props.match.params.record;
    switch(this.props.mode){
      case 'edit':
        this.props.setdTables({ dtables: this.props.tables });
        this.props.setTriggers({ triggers: this.props.triggers });
        this.props.setState({ ...this.props.initalState, id , baseUrl: this.props.baseUrl });
      break;
      case 'public':
        this.props.setState({ ...this.props.initalState, id, baseUrl: this.props.baseUrl });
        //set selected view
        if(view){
          this.props.updateSelectedPage({ value: view });
        }else{
          this.props.updateSelectedPage({ value: 0 });
        }
        //set detail view
        var isParentInsideDetailView = false;
        if(parent && record){
          if(this.props.initalState.detailViewElements.find(e => e.id == parent)){
            isParentInsideDetailView = true;
          }
          let detailView = {
            ...this.props.liteui.ux.detailView,
            show: true,
            recordId: record,
            parentElementId: parent,
            isParentInsideDetailView
          };
          this.props.updateDetailView({ value: detailView });
        }
      break;
      case 'create':
        this.props.resetState();
        this.props.setState({ baseUrl: this.props.baseUrl, explorePageEnabled: true });
        this.props.setdTables({ dtables: this.props.tables });
        this.props.setTriggers({ triggers: this.props.triggers });
      break;
    }
  }

  componentDidMount = () => {

  }

  AppHandlers = (()=>{
    const mode = this.props.mode;
    return {
      ShowEditHeaderModal: () => {
        this.props.updateShowHeaderModal({
          value: true
        })
      },
      ExploreOptionChange: (event) => {
        this.props.updateExplorePageEnabled({
          value: event.target.checked
        })
      }
    }
  })();

  PageHandlers = {
    ShowEditModal: (page) => {
      this.props.updateShowEditPageModal({ show: true, page });
    },
    ViewTransactions: () => {
      this.props.updateShowTransactionView({ value: true });
    }
  }

  UIElementHandlers = {
    Element:{
      Add: () => {
        const selectedView = this.props.liteui.ux.selectedView;
        this.props.addEditingElementToView({
          view: selectedView
        });
        this.props.updateShowAddElementModal({ show: false });
      },
      Delete: (elementId) => {
        const selectedView = this.props.liteui.ux.selectedView;
        this.props.deleteElementFromView({
          view: selectedView,
          elementId: elementId
        });
      },
      SaveEditTitle: (event) => {
        const selectedView = this.props.liteui.ux.selectedView;
        this.props.updateElementTitle({
          view: selectedView,
          elementId: event.target.dataset['id'],
          title: document.getElementById('ElementTitle').value
        });
        this.props.updateShowElementTitleModal({
          value: false
        });
      },
      ShowAddModal: (event) => {
        this.props.startAddElement();
        this.props.updateShowAddElementModal({ show: true, view: event.target.dataset['id'] });
      },
      CloseAddModal: (event) => {
        this.props.updateShowAddElementModal({ show: false });
      },
      ChangeAddElementType: (type) => {
        const selectedView = this.props.liteui.ux.selectedView;
        this.props.updateAddElementType({ view: selectedView, type: type });
      },
      ChangeAddElementSource: (event) => {
        this.props.updateAddElementSource({ type: event.target.dataset.type, id: event.target.value});
      }
    },
    LiteList: {
      Configure: (elementId)=>{
        const selectedView = this.props.liteui.ux.selectedView;
        this.props.updateShowEditListModal({
          value: true,
          elementId: elementId,
          view: selectedView,
          detailView: this.props.liteui.ux.detailView.show
        });
      }
    },
    LiteForm: {
      Configure: (elementId) => {
        const selectedView = this.props.liteui.ux.selectedView;
        this.props.updateShowEditFormModal({
          value: true,
          elementId: elementId,
          view: selectedView,
          isDetailView: this.props.liteui.ux.detailView.show
        });
      }
    },
    LiteChat: {
      Configure: (elementId) => {
        // const selectedView = this.props.liteui.ux.selectedView;
        // this.props.updateShowEditChatModal({
        //   value: true,
        //   elementId: elementId,
        //   view: selectedView,
        //   isDetailView: this.props.liteui.ux.detailView.show
        // });
      }
    },
    LiteText: {
      Configure: (elementId) => {
        const selectedView = this.props.liteui.ux.selectedView;
        this.props.updateShowEditTextModal({
          show: true,
          elementId: elementId,
          view: selectedView,
          isDetailView: this.props.liteui.ux.detailView.show
        });
      },
      HandleEditTextModalChange: event => {
        const value = event.target.value;
        const prop = event.target.id;
        this.props.updateEditTextModalValue({
          value,
          prop
        });
      },
      SaveEditTextModal: (event) => {
        const selectedView = this.props.liteui.ux.selectedView;
        this.props.saveTextModalEdits({
          view: selectedView,
          isDetailView: this.props.liteui.ux.detailView.show
        });
        this.props.updateShowEditTextModal({
          value: false
        });
      },
      CloseEditTextModal: event => {
        this.props.updateShowEditTextModal({
          value: false
        });
      }
    }
  }

  onBeforeDragStart = () => {
    /*...*/
  };

  onDragStart = () => {
    /*...*/
  };
  onDragUpdate = () => {
    /*...*/
  };
  onDragEnd = event => {
    if(!event.destination){
      return;
    }
    const elementId = event.draggableId;
    const newIndex = event.destination.index;
    const view = this.props.liteui.ux.selectedView;
    const isDetailViewElement = this.props.liteui.ux.detailView.show;
    if(newIndex !== event.source.index){
      this.props.moveElement({
        elementId,
        newIndex,
        view,
        isDetailViewElement
      })
    }
  };


  render() {

    const selectedView = this.props.liteui.ux.selectedView;
    let atRoot = true;
    let view = null;
    if(selectedView !== false){
      view = this.props.liteui.views[selectedView];
      atRoot = false;
    }

    if(this.props.mode === 'create' || this.props.mode === 'edit'){
      return (
        <div className="liteui-container">
          <MetaMask/>

          <OpenPublicLiteUI
            id={this.props.liteui.id}
            mode={this.props.mode}
          />

          <StatusBanner
            mode={this.props.liteui.mode}
            status={this.props.metamask.status}
            pending={this.props.liteui.ux.pendingTransactions.length ? true : false}
            onClick={this.PageHandlers.ViewTransactions}
            explorePageChange={this.AppHandlers.ExploreOptionChange}
            explorePageEnabled={this.props.liteui.explorePageEnabled}
          />

          <Header
            name={this.props.liteui.name}
            subheader={this.props.liteui.subheader}
            logo={this.props.liteui.ux.logo}
            handleShowEditDialog={this.AppHandlers.ShowEditHeaderModal}
          />

          <LiteNav
            {...this.props}
            />

          <EditPageActionHeader
           handlers={this.PageHandlers}
           handleAddElement={this.UIElementHandlers.Element.ShowAddModal}
           selectedView={selectedView}
           isDetailView={this.props.liteui.ux.detailView.show}
           />

           <DragDropContext
             onBeforeDragStart={this.onBeforeDragStart}
             onDragStart={this.onDragStart}
             onDragUpdate={this.onDragUpdate}
             onDragEnd={this.onDragEnd}
           >
           <Droppable
             droppableId="droppable-1"
            >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
               <AppView
                 mode={this.props.liteui.mode}
                 view={view}
                 UIElementHandlers={this.UIElementHandlers}
                 ResourceStrings={ResourceStrings}
                 detailView={this.props.liteui.ux.detailView}
                 history={this.props.history}
                 location={this.props.location}
                 />
                {provided.placeholder}
              </div>
              )}
           </Droppable>
          </DragDropContext>

            {/* Error Modal */}
            <UIModals.Error
              error={this.props.liteui.error}
              show={this.props.liteui.error ? true : false}
              handleClose={()=>{
                this.props.updateError({ value: '' });
              }}
            />

            {/* Ready Only Warning */}
            <UIModals.ReadOnlyWarning />

            {/* Show pending transaction modal */}
            <UIModals.Wallet />

            {/* Edit Header  */}
            <UIModals.ConfigureHeader />

            {/* Edit Page  */}
            <UIModals.ConfigurePage
              history={this.props.history}
               />

            {/* Configure List  */}
            <UIModals.ConfigureLiteList />

            {/* Configure Form  */}
            <UIModals.ConfigureLiteForm />

            {/* Configure Text  */}
            <UIModals.ConfigureLiteText
              title='Configure Text'
              name={this.props.liteui.ux.showEditTextModal.element.title}
              text={this.props.liteui.ux.showEditTextModal.element.source}
              show={this.props.liteui.ux.showEditTextModal.show}
              handleClose={this.UIElementHandlers.LiteText.CloseEditTextModal}
              handleSave={this.UIElementHandlers.LiteText.SaveEditTextModal}
              handleChange={this.UIElementHandlers.LiteText.HandleEditTextModalChange}
              id={this.props.liteui.ux.showEditTextModal.id}
              />

             <UIModals.AddUIElement
               element={this.props.liteui.editing.uielement}
               show={this.props.liteui.ux.showAddElementModal.show}
               view={view}
               handlers={this.UIElementHandlers.Element}
               />
        </div>
      );
    }else if(this.props.mode === 'public'){
      return (
        <div className="liteui-container public">
          <MetaMask
            key={this.props.liteui.liteWallet.disabled}
             />
          {/* Ready Only Warning */}
          <UIModals.ReadOnlyWarning />

          {/* Show pending transaction modal */}
          <UIModals.Wallet />

          <Banner />

          <StatusBanner
            mode={this.props.liteui.mode}
            status={this.props.metamask.status}
            pending={this.props.liteui.ux.pendingTransactions.length ? true : false}
            onClick={this.PageHandlers.ViewTransactions}
          />
          {/*
            <LiteNav
              mode={this.props.liteui.mode}
              {...this.props}
              />
            */}

          <Header
            logo={this.props.liteui.ux.logo}
            mode={this.props.liteui.mode}
            name={this.props.liteui.name}
            subheader={this.props.liteui.subheader}
            />

           <div className='container'>


               <LiteNav
                 mode={this.props.liteui.mode}
                 {...this.props}
                 />



           <AppView
             mode={this.props.liteui.mode}
             view={view}
             UIElementHandlers={this.UIElementHandlers}
             ResourceStrings={ResourceStrings}
             detailView={this.props.liteui.ux.detailView}
             history={this.props.history}
             location={this.props.location}
             />


         </div>

        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    liteui: state.liteui,
    metamask: state.metamask
  };
};

const mapDispatchToProps = dispatch => {
  return Methods({ dispatch });
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout));
