import React, { Component } from "react";
import { Button, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';

import AtraAPI from '../../../apiv2';
import { Loading } from '../../../components/Loaders';
import LoaderButton  from '../../../components/Buttons/LoaderButton';
import EnvMethods from '../../../components/Environments/methods';

class ActivateLiteWallet extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    this.state = {
      loadingLiteWallet: true,
      loadingSubmit: false,
      message: '',
      success: false,
      email: '',
      walletId: '',
      activationCode: '',
      liteWallet: {}
    }
    this.props.updateEnvironmentsEnabled({ enabled: false });
  }

  componentDidMount = () => {
    this.start();
  }

  componentWillUnmount = () => {
    this._mounted = false;
    //this.props.resetState();
  }

  componentDidUpdate(prevProps) {

  }

  start = () => {
    this._mounted = true;
    //this.props.resetState();
    const walletId = this.props.match.params.wallet;
    const activationCode = this.props.match.params.code;
    this.setState({ walletId, activationCode });

    //get wallet public info, required activation code
    AtraAPI.GetLiteWalletPublic(walletId, activationCode).then((liteWallet) => {
      this.setState({ liteWallet, loadingLiteWallet: false });
      console.log(liteWallet);
    },(err) => {
      this.setState({ loadingLiteWallet: false });
      console.log(err);
    });
  }

  submitEmail = (event) => {
    event.preventDefault();
    if(document.getElementById('litewallet-activate-form').reportValidity()){
      const email = document.getElementById('user-email').value;
      const body = {
        walletId: this.state.walletId,
        activationCode: this.state.activationCode,
        email
      }
      this.setState({ loadingSubmit: true });
      AtraAPI.LiteWalletPublicActivate(body).then((res) => {
        let message = 'Success, Check Your Email!';
        if(res.data === 'only-one'){
          message = 'Email Already Active';
        }else if(res.data === 'empty'){
          message = 'Sorry, No Wallets Left';
        }
        this.setState({ success: true, message, loadingSubmit: false });
        console.log(res);
      },(err) => {
        this.setState({ success: true, message: 'Failed. refresh and try again', loadingSubmit: false });
        console.log(err);
      });
    }
  }


  render() {
    if(this.state.loadingLiteWallet){
      return <Loading />
    }
    if(!this.state.liteWallet.open){
      return (
        <div className="activate-litewallet-container container">
          <form id='litewallet-activate-form'>
            <div className='activate-form-container'>
              <div className='side-pipe-points'>
                <div className='point'></div>
                <div className='line'></div>
                <div className='point'></div>
              </div>
              <div className='elements-container'>
                <div>
                  <h3>Claim Your AccessPass!</h3>
                </div>
                <div>
                  <p>Enter your email to receive your AccessPass to</p>
                </div>
                <div>
                  <h4>{this.state.liteWallet.name}</h4>
                </div>
                <h3>Sorry, No Wallets Left!</h3>
              </div>
            </div>
          </form>
        </div>
      );
    }
    return (
      <div className="activate-litewallet-container container">
        <form id='litewallet-activate-form' onSubmit={this.submitEmail}>
          <div className='activate-form-container'>
            <div className='side-pipe-points'>
              <div className='point'></div>
              <div className='line'></div>
              <div className='point'></div>
            </div>
            <div className='elements-container'>
              <div>
                <h3>Claim Your AccessPass!</h3>
              </div>
              <div>
                <p>Enter your email to receive your AccessPass to</p>
              </div>
              <div>
                <h4>{this.state.liteWallet.name}</h4>
              </div>
              {this.state.success ? <h3>{this.state.message}</h3> : <div><FormControl id='user-email' name='email' placeholder='Enter Email' type='email'required /></div>}
            </div>
          </div>
          {this.state.success ?
            null
           :
           <div className='submit-activate-container'>
             <LoaderButton
               isLoading={this.state.loadingSubmit}
               text={'Submit'}
               loadingText={'Submitting'}
               onClick={this.submitEmail}
               className='baby-blue-button'
               />
           </div>
         }
        </form>



      </div>
    );

  }
}

const mapStateToProps = state => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...EnvMethods({dispatch})
  }
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateLiteWallet));
