import React, { Component } from 'react';
import Container from '../../Container';
import { Tutorials } from '../../../components/Content';
import Start from '../../Start';

export default class Documentation extends Component {
  render(){
    return (
      <Container selected={['tutorials','supply-chain-Traceability']}>
        <Start />
        <Tutorials.Header />
        <Tutorials.SupplyChainTraceability />
      </Container>
    )
  }
}
