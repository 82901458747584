import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Table,
  Glyphicon,
  FormGroup,
  FormControl,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";
import LoaderButton from "../../../../components/Buttons/LoaderButton";
import { API, Auth } from "aws-amplify";
import Modals from "../UIModals";
import { FormatDate, FormatDateTime } from "../../../../helpers/Date";
import { ConvertWeiToEth, FromBigNumber } from "../../../../helpers/Eth";
import { CreateWeb3Object } from "../../../../components/Web3";
import { Loading } from "../../../../components/Loaders";

import LiteUIMethods from "../../methods";
import NotificationMethods from "../../../../components/Notifications/methods";

const ListHeader = ({
  dragHandleProps,
  mode,
  edit,
  remove,
  title,
  children,
}) => {
  if (mode === "create" || mode === "edit") {
    return (
      <div>
        <div className="action-bar">
          <div {...dragHandleProps} className="header-drag-handle"></div>
          <DropdownButton id="action" title="">
            <MenuItem onClick={edit}>Configure</MenuItem>
            <MenuItem onClick={remove}>Delete</MenuItem>
          </DropdownButton>
        </div>
        <div className="lite-list-titlebar">
          <div>
            <h3>{title}</h3>
          </div>
          <div>{children}</div>
        </div>
      </div>
    );
  } else if (mode === "public") {
    return (
      <div className="lite-list-titlebar">
        <div>
          <h3>{title}</h3>
        </div>
        <div>{children}</div>
      </div>
    );
  }
};

const ActionsDropDown = ({
  actions,
  handleActionClick,
  table,
  selectedRecord,
}) => {
  const disabled = selectedRecord && actions.length ? false : true;
  if (disabled) {
    return (
      <div className="actions-dropdown-container">
        <DropdownButton
          disabled={true}
          id="list-actions"
          title="Action"
        ></DropdownButton>
      </div>
    );
  } else {
    return (
      <div className="actions-dropdown-container">
        <DropdownButton id="list-actions" title="Action">
          {actions.map((action, i) => {
            return (
              <RenderActionLink
                selectedRecord={selectedRecord}
                handleActionClick={handleActionClick}
                key={i}
                table={table}
                index={i}
                action={action}
              />
            );
          })}
        </DropdownButton>
      </div>
    );
  }
};

const RenderActionLink = ({
  index,
  action,
  table,
  handleActionClick,
  selectedRecord,
}) => {
  switch (action.source.type) {
    case "etherscan":
      const url = `https://goerli.etherscan.io/tx/${selectedRecord.event.transactionHash}`;
      return (
        <MenuItem href={url} target={"_blank"}>
          {action.title}
        </MenuItem>
      );
      break;
    case "trigger":
      return (
        <MenuItem
          onClick={() => {
            handleActionClick(action);
          }}
        >
          {action.title}
        </MenuItem>
      );
      break;
  }
};

const TableHeader = ({ columns, renderMapping }) => {
  return (
    <thead>
      <tr>
        <th></th>
        <th>View</th>
        {columns.map((column, i) => {
          if (renderMapping) {
            const renderMap = renderMapping.find(
              (r) => r.column === column.name
            );
            if (!renderMap || renderMap.render !== "none") {
              return <th key={i}>{column.name}</th>;
            }
          } else {
            return <th key={i}>{column.name}</th>;
          }
        })}
      </tr>
    </thead>
  );
};

const FormatRecordValue = ({ type, value, timezone }) => {
  //console.log(type,value);
  switch (type) {
    case "price":
      return `${ConvertWeiToEth(value)} ETH`;
      break;
    case "date":
      return FormatDateTime(parseInt(value), timezone);
    case "number":
      return FromBigNumber(value);
    default:
      return value;
      break;
  }
};

const TableBody = ({
  records,
  columns,
  renderMapping,
  selectedRecord,
  handleShowDetails,
  handleRecordSelect,
  loading,
  timezone,
  viewDetails,
}) => {
  if (loading) {
    return <tbody></tbody>;
  }
  if (renderMapping) {
    const defaultsort = renderMapping.find((r) => r.defaultsort === true);
    if (defaultsort) {
      const index = columns.findIndex((c) => c.name === defaultsort.column);
      records.sort(function (a, b) {
        var a = a.record[index];
        var b = b.record[index];
        if (typeof a === "string") {
          var a = a.toUpperCase();
          var b = b.toUpperCase();
          if (defaultsort.sortHighToLow) {
            return a > b ? -1 : a < b ? 1 : 0;
          } else {
            return a < b ? -1 : a > b ? 1 : 0;
          }
        }
        if (defaultsort.sortHighToLow) {
          return b - a;
        } else {
          return a - b;
        }
      });
    }
  }

  return (
    <tbody>
      {records.map((record, x) => (
        <tr
          className={
            selectedRecord && selectedRecord.recordId == record.recordId
              ? "selected"
              : ""
          }
          key={x}
        >
          <td>
            <input
              checked={
                selectedRecord && selectedRecord.recordId == record.recordId
                  ? true
                  : false
              }
              onChange={() => handleRecordSelect(x)}
              type="checkbox"
            />
          </td>
          <td>
            <a
              onClick={() => {
                handleShowDetails(x);
              }}
            >
              {viewDetails.title}
            </a>
          </td>
          {columns.map((column, i) => {
            const key = `${x}${i}`;
            if (renderMapping) {
              const renderMap = renderMapping.find(
                (r) => r.column === column.name
              );
              if (!renderMap || renderMap.render !== "none") {
                if (renderMap && renderMap.render === "hyperlink") {
                  let url = "";

                  if (renderMap.linkColumn == "custom_base_url") {
                    url = `${renderMap.linkCustom}${record.record[i]}`;
                  } else {
                    url = renderMap.linkColumn
                      ? record.record[
                          columns.findIndex(
                            (c) => c.name === renderMap.linkColumn
                          )
                        ]
                      : record.record[i];
                  }

                  return (
                    <td key={key}>
                      <a href={url}>
                        <FormatRecordValue
                          type={column.type}
                          value={record.record[i]}
                          timezone={timezone}
                        />
                      </a>
                    </td>
                  );
                } else if (renderMap && renderMap.render === "text") {
                  return (
                    <td key={key}>
                      <FormatRecordValue
                        type={column.type}
                        value={record.record[i]}
                        timezone={timezone}
                      />
                    </td>
                  );
                } else {
                  return (
                    <td key={key}>
                      <FormatRecordValue
                        type={column.type}
                        value={record.record[i]}
                        timezone={timezone}
                      />
                    </td>
                  );
                }
              }
            } else {
              return (
                <td>
                  <FormatRecordValue
                    type={column.type}
                    value={record.record[i]}
                    timezone={timezone}
                  />
                </td>
              );
            }
          })}
        </tr>
      ))}
    </tbody>
  );
};

class LiteList extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    this.state = {
      invokeTrigger: {
        show: false,
      },
      loading: true,
      records: [],
      selectedRecord: null,
    };
  }

  async componentDidMount() {
    this._mounted = true;
    this.pollRecords();
  }

  async componentWillUnmount() {
    this._mounted = false;
  }

  async pollRecords() {
    if (this._mounted) {
      await this.GetRecordsFromContract().then(() => {
        // setTimeout(async ()=>{
        //   if(this._mounted){
        //     await this.pollRecords();
        //   }
        // },5000);
      });
    }
  }

  GetRecordsFromContract = () => {
    return new Promise(async (resolve, reject) => {
      try {
        var web3react = CreateWeb3Object();

        //console.log('web3react from litelist', web3react);
        const table = this.props.element.source.option;
        const network = "goerli";
        const startTime = new Date().getTime();

        const length = await this.GetRecordsLength(web3react, table, network);
        //console.log('Records Length: ', length);
        let recordPromises = [];

        for (let x = 0; x < length; x++) {
          let recordPromise = this.GetRecordFromContract(
            web3react,
            table,
            x,
            network
          );
          recordPromises.push(recordPromise);
        }

        Promise.all(recordPromises).then((recordsList) => {
          //console.log('Promise.all recordList', recordsList);

          //Get past events list for all records
          recordsList = recordsList.filter((r) => r);
          const recordIds = recordsList.map((r) => r.recordId);
          var contract = new web3react.eth.Contract(
            JSON.parse(table.abi),
            table[network]
          );
          const ttl = (new Date().getTime() - startTime) / 1000;
          //console.log('TTL List:', ttl);
          contract.getPastEvents(
            "Inserted",
            {
              filter: { _recordId: recordIds },
              fromBlock: 0,
              toBlock: "latest",
            },
            (error, events) => {
              if (error) {
                console.log("Error", error);
              } else {
                //console.log('Events', events);
                //match up events with records
                recordsList = recordsList.map((record) => {
                  //find event
                  const event = events.find(
                    (e) => e.returnValues._recordId === record.recordId
                  );
                  return { ...record, event };
                });

                if (this._mounted) {
                  const ttlpe = (new Date().getTime() - startTime) / 1000;
                  //console.log('TTL List + Events:', ttlpe);
                  //console.log('recordList', recordsList);
                  this.setState({ records: recordsList, loading: false });
                }
              }
            }
          );
          resolve();
        });
      } catch (err) {
        console.log(err);
        //alert(err);
        reject(err);
      }
    });
  };

  GetRecordsLength = (web3react, table, network) => {
    return new Promise(function (resolve, reject) {
      try {
        var contract = new web3react.eth.Contract(
          JSON.parse(table.abi),
          table[network]
        );
        contract.methods
          .GetLength()
          .call()
          .then(
            (res) => {
              const length = res;
              //console.log('Length result',length);
              resolve(length);
            },
            (err) => {
              //alert(err);
              console.log(err);
              reject();
            }
          );
      } catch (err) {
        //alert(err);
        console.log(err);
        reject();
      }
    });
  };

  GetRecordFromContract = (web3react, table, index, network) => {
    return new Promise((resolve, reject) => {
      try {
        let contract = new web3react.eth.Contract(
          JSON.parse(table.abi),
          table[network]
        );
        console.log(index);
        contract.methods
          .GetByIndex(index)
          .call()
          .then(
            (res) => {
              //detailview filter on recordId to parentId
              if (
                this.props.liteui.ux.detailView.show &&
                this.props.element.config.filter.recordId
              ) {
                const detailRecordId = this.props.liteui.ux.detailView.recordId;
                const recordPointedId =
                  res.record[
                    `A_${table.columns.find((c) => c.type === "pointer").name}`
                  ];
                if (detailRecordId === recordPointedId) {
                  //concat record data from pointer
                  const pointersLength = table.columns.filter(
                    (c) => c.type === "pointer"
                  ).length;
                  for (let x = 0; x < pointersLength; x++) {
                    res.record = [...res.record, ...res[x + 2]];
                  }
                  resolve(res);
                } else {
                  resolve();
                }
              } else {
                //concat record data from pointer
                const pointersLength = table.columns.filter(
                  (c) => c.type === "pointer"
                ).length;
                for (let x = 0; x < pointersLength; x++) {
                  res.record = [...res.record, ...res[x + 2]];
                }
                resolve(res);
              }
            },
            (err) => {
              //alert(err);
              console.log(index);
              console.log(err);
              reject();
            }
          );
      } catch (err) {
        console.log(err);
        reject();
      }
    });
  };

  handleRecordSelect = (recordIndex) => {
    let selectedRecord = this.state.records[recordIndex];
    //Toggle
    if (
      this.state.selectedRecord &&
      selectedRecord.recordId === this.state.selectedRecord.recordId
    ) {
      this.setState({ selectedRecord: null });
    } else {
      this.setState({ selectedRecord });
    }
  };

  handleShowDetails = (recordIndex) => {
    const baseUrl = this.props.liteui.baseUrl;
    const selectedView = this.props.liteui.ux.selectedView;
    const parentElementId = this.props.element.id;
    const recordId = this.state.records[recordIndex].recordId;
    if (this.props.liteui.mode === "public") {
      const id = this.props.liteui.id;
      this.props.history.push({
        pathname: `${baseUrl}${id}/${selectedView}/${parentElementId}/${recordId}`,
        search: this.props.location.search,
      });
    } else {
      let isParentInsideDetailView = false;
      if (
        this.props.liteui.detailViewElements.find(
          (e) => e.id == parentElementId
        )
      ) {
        isParentInsideDetailView = true;
      }

      let detailView = {
        ...this.props.liteui.ux.detailView,
        show: true,
        recordId,
        parentElementId,
        isParentInsideDetailView,
      };
      this.props.updateDetailView({ value: detailView });
    }
  };

  handleActionClick = (action) => {
    //Go get the whole trigger object
    const trigger = action.source.data;
    const selectedRecordId = this.state.selectedRecord.recordId;
    //Render the Invoke Dialog using the trigger and the selected record
    this.setState({
      invokeTrigger: { show: true, trigger, action, selectedRecordId },
    });
  };

  handleRefreshClick = () => {
    this.pollRecords();
  };

  render() {
    let timezone = undefined;
    if (this.props.auth.isAuthenticated) {
      timezone = this.props.account.user.timezone.code;
    }

    const element = this.props.element;

    const table = element.source.option;

    let columns = table.columns;

    const pointerColumns = table.columns.filter((c) => c.type === "pointer");

    pointerColumns.forEach((pointer) => {
      //console.log(pointer);
      //get the table for the pointer and attach the columns
      const table = element.config.pointerTables.find(
        (t) => t.id === pointer.tableId
      );
      //console.log(table.columns);
      columns = [...columns, ...table.columns];
    });

    return (
      <div className="lite-list liteui-element">
        <ListHeader
          dragHandleProps={this.props.dragHandleProps}
          mode={this.props.mode}
          edit={() => this.props.ShowEditModal(element.id)}
          remove={() => this.props.remove(element.id)}
          title={element.title}
        >
          <LoaderButton
            isLoading={false}
            text={"Refresh"}
            loadingText={"Loading"}
            onClick={this.handleRefreshClick}
            className={"white-button"}
          />
          <ActionsDropDown
            selectedRecord={this.state.selectedRecord}
            actions={element.config.actions}
            handleActionClick={this.handleActionClick}
            table={table}
          />
        </ListHeader>

        <Table striped condensed responsive>
          <TableHeader
            columns={columns}
            renderMapping={element.config.renderMapping}
          />
          <TableBody
            timezone={timezone}
            loading={this.state.loading}
            selectedRecord={this.state.selectedRecord}
            handleShowDetails={this.handleShowDetails}
            handleRecordSelect={this.handleRecordSelect}
            records={this.state.records}
            columns={columns}
            renderMapping={element.config.renderMapping}
            viewDetails={element.config.viewDetails}
          />
        </Table>
        {this.state.loading ? (
          <div className="loading-list-container">
            <Loading />
          </div>
        ) : null}
        {!this.state.loading && this.state.records.length === 0 ? (
          <div className="table-no-records">no records</div>
        ) : null}

        {this.state.invokeTrigger.show ? (
          <Modals.InvokeTrigger
            hide={() => {
              this.setState({ invokeTrigger: { show: false } });
            }}
            show={this.state.invokeTrigger.show}
            key={`${this.state.invokeTrigger.show}${this.state.invokeTrigger.trigger.id}${this.state.invokeTrigger.action.id}${this.state.invokeTrigger.selectedRecordId}`}
            trigger={this.state.invokeTrigger.trigger}
            action={this.state.invokeTrigger.action}
            table={this.props.element.source.option}
            record={this.state.selectedRecord}
            recordAddress={this.state.invokeTrigger.selectedRecordId}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    liteui: state.liteui,
    notifications: state.notifications,
    metamask: state.metamask,
    account: state.account,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...LiteUIMethods({ dispatch }),
    ...NotificationMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiteList);
