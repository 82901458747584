import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import EnvMethods from "../../../components/Environments/methods";
import Methods from "../methods";
import AtraAPI from "../../../apiv2";
import {
  AccountTabs,
  ManageSubscription,
  Profile,
  Wallet,
  Api,
  TimeZone,
} from "../components";
import { Link, withRouter } from "react-router-dom";
import { IsAddress, CheckAddressChecksum } from "../../../helpers/Eth";
import { ModalContainerOK } from "../../../components/Modals";
import Checkout from "../../checkout/containers/Subscription-Checkout";

class Account extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    this.props.updateEnvironmentsEnabled({ enabled: true });
  }

  async componentDidMount() {
    this._mounted = true;
    this.ApiMethods.loadKeys();
    this.start();
  }

  async componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.tab !== this.props.match.params.tab) {
      this.start();
    }
  }

  start = () => {
    AtraAPI.GetUser().then((user) => {
      //update user
      this.props.updateUser({ value: user });
      this.props.updateLoadingUser({ value: false });
    });

    const activeTab = this.props.match.params.tab;
    if (activeTab) {
      this.props.updateActiveTab({ value: activeTab });
    } else {
      this.props.updateActiveTab({ value: "info" });
    }
  };

  BillingMethods = {
    showUpgradeModal: () => {
      this.props.history.push("/subscription/checkout");
      // this.props.updateShowUpgradeModal({ value: true });
    },
    hideUpgradeModal: () => {
      this.props.updateShowUpgradeModal({ value: false });
    },
  };

  ApiMethods = {
    loadKeys: () => {
      this.props.updateLoadingAPIKeys({ value: true });
      AtraAPI.GetAPIKey().then(
        (key) => {
          this.props.updateAPIKey({ value: key });
          this.props.updateLoadingAPIKeys({ value: false });
        },
        (err) => {
          console.log(err);
        }
      );
    },
    createAPIKey: () => {
      this.props.updateIsSubmittingAPIKey({ value: true });
      AtraAPI.CreateKey().then(
        (key) => {
          this.props.updateAPIKey({ value: key });
          this.props.updateIsSubmittingAPIKey({ value: false });
        },
        (err) => {
          console.log(err);
        }
      );
    },
  };

  WalletMethods = {
    handleNewAddressSubmit: (event) => {
      this.props.updateIsSubmittingWalletAddress({ value: true });
      const address = this.props.account.newAddress.address;
      if (address && !IsAddress(address)) {
        this.props.updateNewAddressFormValidationMessage({
          value: "address not valid",
        });
        this.props.updateIsSubmittingWalletAddress({ value: false });
      } else {
        this.props.updateNewAddressFormValidationMessage({ value: "" });
        AtraAPI.UpdateUserAddress(address).then(
          (res) => {
            //SUCCESS
            //Get the new address
            AtraAPI.GetUser().then((user) => {
              //Stop loader button
              this.props.updateIsSubmittingWalletAddress({ value: false });
              //Close Dialog
              this.props.updateShowChangeAddress({ value: false });
              //update user
              this.props.updateUser({ value: user });
              this.props.updateLoadingUser({ value: false });
            });
          },
          (err) => {
            this.props.updateNewAddressFormValidationMessage({ value: err });
            this.props.updateIsSubmittingWalletAddress({ value: false });
            console.log(err);
          }
        );
      }
    },
    handleCancelNewAddress: (event) => {
      this.props.updateShowChangeAddress({ value: false });
    },
    handleUpdateNewAddressInputChange: (event) => {
      const value = event.target.value;
      if (value && !IsAddress(value)) {
        this.props.updateNewAddressFormValidationMessage({
          value: "address not valid",
        });
      } else if (value && !CheckAddressChecksum(value)) {
        this.props.updateNewAddressFormValidationMessage({
          value: "address checksum not valid",
        });
      } else {
        this.props.updateNewAddressFormValidationMessage({ value: "" });
      }
      this.props.updateNewAddress({ value: event.target.value });
    },
    handleShowChangeAddressForm: (event) => {
      this.props.updateShowChangeAddress({ value: true });
    },
    isNewAddressButtonDisabled: () => {
      if (
        !this.props.account.newAddress.address.length ||
        this.props.account.newAddress.validationMessage
      ) {
        return true;
      } else {
        return false;
      }
    },
  };

  render() {
    const breadcrumbs = [];
    breadcrumbs.push({ text: "Account Settings", to: "/account" });

    let tab = (
      <Profile
        loading={this.props.account.loading.user}
        email={this.props.account.user.email}
      />
    );
    switch (this.props.account.ux.activeTab) {
      case "info":
        tab = (
          <div>
            <Profile
              loading={this.props.account.loading.user}
              email={this.props.account.user.email}
            />
            <TimeZone />
          </div>
        );
        break;
      case "subscription":
        tab = (
          <ManageSubscription
            history={this.props.history}
            showUpgradeModal={this.BillingMethods.showUpgradeModal}
          />
        );
        break;
      case "wallet":
        tab = (
          <Wallet
            address={this.props.account.user.address}
            newAddress={this.props.account.newAddress}
            loading={this.props.account.loading.user}
            errorMessage={this.props.account.newAddress.validationMessage}
            isSubmitting={this.props.account.isSubmitting.walletAddress}
            showNewAddressForm={this.props.account.newAddress.show}
            submitNewAddressButtonDisabled={this.WalletMethods.isNewAddressButtonDisabled()}
            handleCancelNewAddress={this.WalletMethods.handleCancelNewAddress}
            handleNewAddressSubmit={this.WalletMethods.handleNewAddressSubmit}
            handleUpdateNewAddressInputChange={
              this.WalletMethods.handleUpdateNewAddressInputChange
            }
            handleShowChangeAddressForm={
              this.WalletMethods.handleShowChangeAddressForm
            }
          />
        );
        break;
      case "api":
        tab = (
          <Api
            url={"https://api.atra.io/prod/v1/"}
            handleCreateAPIKey={this.ApiMethods.createAPIKey}
            apiKey={this.props.account.api.key}
            loading={this.props.account.loading.apiKey}
            isSubmitting={this.props.account.isSubmitting.apiKey}
          />
        );
        break;
      case "help":
        tab = <div>Help</div>;
        break;
      default:
        this.props.history.push("/account");
        this.props.updateActiveTab({ value: "info" });
        break;
    }

    return (
      <div className="account-container container">
        <BreadCrumbs links={breadcrumbs} />
        <div className="account-content-container">
          <AccountTabs history={this.props.history} activeTab={"info"}>
            {tab}
          </AccountTabs>
        </div>
        {this.props.account.ux.showUpgradeModal ? (
          <ModalContainerOK
            show={true}
            title={"checkout"}
            handleClose={this.BillingMethods.hideUpgradeModal}
          >
            <Checkout />
          </ModalContainerOK>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...Methods({ dispatch }),
    ...EnvMethods({ dispatch }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Account)
);
