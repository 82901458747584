import React, { Component } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  FormGroup,
  FormControl,
  HelpBlock,
  Glyphicon,
} from "react-bootstrap";

import AtraAPI from "../../../apiv2";
import Methods from "../methods/create";
import NotificationMethods from "../../../components/Notifications/methods";
import EnvMethods from "../../../components/Environments/methods";
import AccountMethods from "../../account/methods";

import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { Info, Submit } from "../../../components/ServiceCreate/";
import ToolTip from "../../../components/Tooltips/";

const PointerColumnsSection = ({
  pointercolumns,
  tables,
  onChange,
  onRemove,
}) => {
  return (
    <div className="pointer-columns-container">
      {pointercolumns.length ? <div>Select dTable</div> : null}
      <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
        {pointercolumns.map((pointer, i) => {
          return (
            <div key={i} className="column-container">
              <div className="column-content">
                <FormGroup controlId="type">
                  <FormControl
                    className={"table-selectbox"}
                    data-index={i}
                    value={pointer.id}
                    componentClass="select"
                    placeholder="select"
                    onChange={onChange}
                  >
                    {tables
                      .filter(
                        (t) =>
                          !pointercolumns.find((p) => p.id === t.id) ||
                          t.id === pointer.id
                      )
                      .map((table, i) => {
                        return (
                          <option key={i} value={table.id}>
                            {table.name}
                          </option>
                        );
                      })}
                  </FormControl>
                </FormGroup>
                <Button bsStyle="danger" value={i} onClick={onRemove}>
                  <Glyphicon glyph="trash" />
                </Button>
              </div>
            </div>
          );
        })}
      </form>
      <HelpBlock className="error"></HelpBlock>
    </div>
  );
};

const ColumnsSection = ({
  columns,
  columnErrors,
  onChange,
  removeColumn,
  validateColumn,
}) => {
  return (
    <div className="columns-container">
      <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
        {columns.map((column, i) => {
          return (
            <div key={i} className="column-container">
              <div className="column-content">
                <div className="count-label">{i + 1}.</div>
                <FormGroup controlId="type">
                  <FormControl
                    data-index={i}
                    value={column.type}
                    onChange={onChange}
                    componentClass="select"
                    placeholder="select"
                  >
                    <option value="text">Text</option>
                    <option value="price">Price</option>
                    <option value="number">Number</option>
                    <option value="address">Address</option>
                    <option value="date">Date</option>
                  </FormControl>
                </FormGroup>
                <FormGroup controlId="name">
                  <FormControl
                    data-index={i}
                    type="text"
                    placeholder={"Enter column name"}
                    value={column.name}
                    onChange={onChange}
                    onBlur={validateColumn}
                  />
                </FormGroup>
                <Button
                  bsStyle="danger"
                  value={i}
                  onClick={() => removeColumn(i)}
                >
                  <Glyphicon glyph="trash" />
                </Button>
              </div>
              {column.status && !column.status.valid ? (
                <div className="column-status">{column.status.message}</div>
              ) : null}
            </div>
          );
        })}
      </form>
      <HelpBlock className="error">{columnErrors}</HelpBlock>
    </div>
  );
};

class dTablesCreate extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    this.props.updateEnvironmentsEnabled({ enabled: false });
  }
  componentDidMount = () => {
    this._mounted = true;
    this.props.resetState();

    //Get dTables
    this.setTables();
  };
  componentDidUpdate() {
    if (this.props.dTablesCreate.unsaved && !this.props.dTablesCreate.success) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    //if (this.props.dTablesCreate.error) this.alertBox.scrollIntoView();
  }
  componentWillUnmount() {
    window.onbeforeunload = undefined;
    this.props.resetState();
  }

  setTables = () => {
    return new Promise((resolve, reject) => {
      let envId = window.localStorage.getItem("activeEnvironment");
      AtraAPI.GetTables(null, envId).then(
        (tables) => {
          if (this._mounted) {
            tables = tables
              .filter(
                (t) =>
                  t.status === "Live" &&
                  !t.archived &&
                  !t.columns.find((c) => c.type === "pointer")
              )
              .sort((a, b) => b.created - a.created);
            this.props.updateTables({ value: tables });
            resolve();
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  handleChange = (event) => {
    const { id, value } = event.target;
    this.props.updateInput({ value: { id, value } });
    //validate
    switch (id) {
      case "name": {
        this.validateName(event);
        break;
      }
      case "description": {
        this.validateDescription(event);
        break;
      }
    }
  };

  handleColumnChange = (event) => {
    this.props.removeErrors();
    const columns = this.props.dTablesCreate.columns.slice(); //copy the array
    let index = event.target.dataset["index"];
    columns[index][event.target.id] = event.target.value; //execute the manipulations
    this.props.updateColumns({ value: columns }); //set the new state
    this.validateColumn(event);
  };

  handleAddColumn = (event) => {
    this.props.removeColumnError();
    let columns = this.props.dTablesCreate.columns.slice();
    columns.push({
      name: "",
      type: "text",
      status: {
        valid: false,
        message: "",
      },
    });
    this.props.updateColumns({ value: columns });
  };

  handleAddColumnPointer = () => {
    var pointers = this.props.dTablesCreate.pointerColumns.slice();
    let filteredTables = this.props.dTablesCreate.tables.filter(
      (t) => !pointers.find((p) => p.id === t.id)
    );
    pointers.push({
      id: filteredTables[0].id,
      name: filteredTables[0].name,
    });
    this.props.updatePointersColumns({ value: pointers });
  };

  handlePointerChange = (event) => {
    var pointers = this.props.dTablesCreate.pointerColumns.slice();
    pointers[event.target.dataset.index].id = event.target.value;
    pointers[
      event.target.dataset.index
    ].name = this.props.dTablesCreate.tables.find(
      (t) => t.id === event.target.value
    ).name;
    this.props.updatePointersColumns({ value: pointers });
  };

  handlePointerRemove = (event) => {
    let pointers = [...this.props.dTablesCreate.pointerColumns];
    pointers.splice(event.target.value, 1);
    this.props.updatePointersColumns({ value: pointers });
  };

  removeColumn = (index) => {
    let columns = [...this.props.dTablesCreate.columns];
    columns.splice(index, 1);
    this.props.updateColumns({ value: columns });
  };

  validateForm = () => {
    return (
      this.props.dTablesCreate.columns.length > 0 &&
      !this.props.dTablesCreate.columns.find((c) => !c.status.valid) &&
      this.props.dTablesCreate.name.status.valid &&
      this.props.dTablesCreate.description.status.valid
    );
  };

  validateName = (event) => {
    let valid = true;
    let message = null;
    const value = event.target.value;
    if (value.length === 0) {
      valid = false;
      message = "Name Required";
    } else if (value.search(" ") > -1) {
      valid = false;
      message = "No Spaces in Name";
    } else if (!/^[a-zA-Z]*$/g.test(value)) {
      valid = false;
      message = "Invalid Character - Letters Only";
    } else if (value.length > 50) {
      valid = false;
      message = "Name Max Length 50 Characters";
    }
    const status = {
      valid,
      message,
    };
    this.props.updateNameStatus({ value: status });
  };
  validateDescription = (event) => {
    let valid = true;
    let message = null;
    const value = event.target.value;
    if (value.length > 124) {
      valid = false;
      message = "Description Max Length 124 Characters";
    }
    const status = {
      valid,
      message,
    };
    this.props.updateDescriptionStatus({ value: status });
  };

  validateColumn = (event) => {
    let valid = true;
    let message = null;
    const value = event.target.value;
    if (value.length === 0) {
      valid = false;
      message = "Column Name Required";
    } else if (value.length > 50) {
      valid = false;
      message = "Column Max Length 50 Characters";
    } else if (value.search(" ") > -1) {
      valid = false;
      message = "No Spaces in Column Name";
    } else if (!/^[a-zA-Z]*$/g.test(value)) {
      valid = false;
      message = "Invalid Character - Letters Only";
    }
    const status = {
      valid,
      message,
    };
    this.props.updateColumnStatus({
      value: status,
      index: event.target.dataset["index"],
      message,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.props.updateLoading({ value: true });

    this.props.removeErrors();

    const table = {
      name: this.props.dTablesCreate.name.value,
      description: this.props.dTablesCreate.description.value,
      columns: this.props.dTablesCreate.columns.map((column) => {
        return { name: column.name, type: column.type };
      }),
      pointerColumns: this.props.dTablesCreate.pointerColumns,
    };

    let activeEnv = this.props.environments.environments.find((e) => e.active);
    let environmentId = null;
    if (activeEnv) {
      environmentId = activeEnv.id;
    }

    if (environmentId) {
      table.environmentId = environmentId;
    }

    AtraAPI.CreateTable(table).then(
      (res) => {
        this.props.updateSuccess({ value: true });
        this.props.updateLoading({ value: false });
        this.props.notificationsAddPopupMessage({
          message: "Success! - dTable Created",
          remove: (id) => {
            this.props.notificationsRemovePopupMessage({ value: id });
          },
        });
        this.props.resetState();
        this.props.history.push("/dtables");
      },
      (err) => {
        this.props.updateLoading({ value: false });
        switch (err) {
          case "limit-reached":
            this.props.updateSuccess({ value: true });
            this.props.updateShowUsageAlertModal({ value: true });
            break;
          case "name property required":
            this.props.updateNameStatus({
              value: {
                valid: false,
                messgage: "Please Enter a Table Name",
              },
            });
            break;
          case "columns property array elements required":
            this.props.updateNoColumnError({
              value: "Please Include a Column",
            });
            break;
          case "no spaces allowed in name property":
            this.props.updateNameStatus({
              value: {
                valid: false,
                message: "Name Cannot Include Spaces",
              },
            });
            break;
          case "each column is required to have a name property":
            this.props.updateColumnError({
              value: "Each Column Must Include a Name",
            });
            break;
          default:
            this.props.updateNoColumnError({ value: err });
            break;
        }
      }
    );
  };

  render() {
    const breadcrumbs = [];
    breadcrumbs.push({ text: "dTables", to: "/dtables", icon: "dtables" });
    breadcrumbs.push({ text: "Create", to: "/dtables/create" });

    return (
      <div className="dtables-create-container container">
        <BreadCrumbs links={breadcrumbs} />
        <Prompt
          when={
            this.props.dTablesCreate.unsaved &&
            !this.props.dTablesCreate.success
          }
          message="Are you sure you want to leave?"
        />
        <div>
          <div className="create-table-wrapper">
            <Info
              name={this.props.dTablesCreate.name.value}
              nameError={this.props.dTablesCreate.name.status.message}
              descriptionError={
                this.props.dTablesCreate.description.status.message
              }
              description={this.props.dTablesCreate.description.value}
              onChange={this.handleChange}
              service={"dTable"}
              tooltip={`dTables info is used to identify your table after it's deployed`}
            />

            <div className="atra-tooltip-container">
              <h3 className="header">Set Columns</h3>
              <ToolTip
                tip={`Columns are just like the columns at the top of a table. Create a column for each peice of information you want to store. A record is all of the columns together.`}
              />
            </div>

            <ColumnsSection
              columns={this.props.dTablesCreate.columns}
              columnErrors={this.props.dTablesCreate.columnErrors}
              onChange={this.handleColumnChange}
              removeColumn={this.removeColumn}
              validateColumn={this.validateColumn}
            />

            <div className="add-column-button-container">
              <Button onClick={this.handleAddColumn}>Add Column</Button>
              <HelpBlock className="error">
                {this.props.dTablesCreate.noColumnErrors}
              </HelpBlock>
            </div>

            <div className="atra-tooltip-container">
              <h3 className="header">Add Pointer Column</h3>
              <ToolTip
                tip={`A Pointer Column will join a record from another table to a record in this table. Select the dTable you want to use. Valid dTables cannot have a pointer column. Example: Join an Employee record to an EmployeeReport`}
              />
            </div>

            <PointerColumnsSection
              tables={this.props.dTablesCreate.tables.filter(
                (t) => !t.archived
              )}
              pointercolumns={this.props.dTablesCreate.pointerColumns}
              onChange={this.handlePointerChange}
              onRemove={this.handlePointerRemove}
            />

            <div className="add-column-button-container">
              {this.props.dTablesCreate.pointerColumns.length ? null : (
                <Button
                  disabled={!this.props.dTablesCreate.tables.length}
                  onClick={this.handleAddColumnPointer}
                >
                  Add Pointer Column
                </Button>
              )}
              <HelpBlock className="error">
                {this.props.dTablesCreate.noColumnErrors}
              </HelpBlock>
            </div>

            <Submit
              onClick={this.handleSubmit}
              isLoading={this.props.dTablesCreate.isLoading}
              disabled={!this.validateForm()}
              text={"Create dTable"}
              loadingText={"Creating"}
              cancelPath={"/dtables"}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dTablesCreate: state.dTablesCreate,
    environments: state.environments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...Methods({ dispatch }),
    ...NotificationMethods({ dispatch }),
    ...EnvMethods({ dispatch }),
    ...AccountMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(dTablesCreate);
