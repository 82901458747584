import Actions from '../actions/index';
class DefaultState {
  constructor(){
    this.environments = [];
    this.enabled = true;
    this.loading = true;
  }
}

export default (state = new DefaultState(), action) => {
  switch(action.type){
    case Actions.UPDATE_ENVIRONMENTS:
      return {...state, environments: action.data.environments, loading: false};
    case Actions.UPDATE_ACTIVE:
      return {...state, active: action.data.value};
    case Actions.UPDATE_ENABLED:
      return {...state, enabled: action.data.enabled};
    default:
      return {...state};
  }
}
