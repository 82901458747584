import React from 'react';
import { Modal, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import UIModals from './';

const AddPageModal = ({
  name,
  show,
  handleClose,
  handleSave,
  handleChange
}) => {
  const onKeyPress = (event) => {
    if(event.which === 13) {
      handleSave();
    }
  }
  return (
    <UIModals.ModalContainer
      title={'Name Your Page'}
      handleClose={handleClose}
      handleSave={handleSave}
      show={show}
      >
      <form onSubmit={(e)=>e.preventDefault()} autoComplete='off'>
        <FormGroup controlId={'name'}>
          <ControlLabel>Name:</ControlLabel>
          <FormControl
            autoFocus
            type='text'
            onChange={handleChange}
            value={name}
            onKeyPress={onKeyPress}
            />
        </FormGroup>
      </form>
    </UIModals.ModalContainer>
  );
}

export default AddPageModal;
