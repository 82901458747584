import React, { Component } from "react";
import { connect } from "react-redux";
import Methods from "../../methods/view";
import QRCode from "qrcode.react";
import LoadingTab from "./LoadingTab";

import { Button } from "react-bootstrap";

import { CreateWeb3Object } from "../../../../components/Web3";
import AtraAPI from "../../../../apiv2";
import { ConvertWeiToEth, ConvertEthToWei } from "../../../../helpers/Eth";

import WalletBg from "../../images/walletbg.png";

const WalletCreditCardImage = ({ info, wallet, liteui, index }) => {
  return (
    <div className="print-show wallet-container">
      <div className="print-show bg-container">
        <img className="print-show" src={WalletBg} />
      </div>

      <div className="prit-show title">{info.name}</div>

      <div className="print-show balance">{wallet.balance}</div>
      <div className="print-show balance-shadow">{wallet.balance}</div>

      <div className="print-show private-qr">
        <QRCode
          renderAs={"svg"}
          size={84}
          bgColor={"white"}
          fgColor={"darkblue"}
          className="print-show "
          value={
            info.actionType == "liteui"
              ? `https://console.atra.io/app/${info.liteui}?pk=${wallet.privateKey}`
              : wallet.privateKey
          }
        />
      </div>

      <div className="print-show public-key-string">{wallet.address}</div>
      <div className="print-show public-key-string-shadow">
        {wallet.address}
      </div>

      <div className="print-show info">
        <span>Atra LiteWallet</span>
        <span>{index}</span>
      </div>

      <div className="print-show info-shadow">
        <span>Atra LiteWallet</span>
        <span>{index}</span>
      </div>

      <div className="print-show info-date">{info.shortDate}</div>

      <div className="print-show info-date-shadow">{info.shortDate}</div>

      <div className="print-show public-qr">
        <QRCode
          size={47}
          bgColor={"white"}
          fgColor={"darkblue"}
          className="print-show"
          value={wallet.address}
        />
      </div>
    </div>
  );
};

class WalletsView extends Component {
  componentDidMount = () => {
    if (!this.props.view.loading.liteWallet) {
      this.props.updateLoadingTab({ value: false });
      if (this.props.view.liteWallet.liteui) {
        this.getLiteUI(this.props.view.liteWallet.liteui);
      }
    }
  };

  getWalletBalance = (walletAddress) => {
    return new Promise(async (resolve, reject) => {
      try {
        const web3react = CreateWeb3Object();
        const balance = await web3react.eth.getBalance(walletAddress);
        resolve(balance);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };

  getLiteUI = async (id) => {
    try {
      const liteui = await AtraAPI.GetLiteUI(id);
      this.props.updateLiteUI({ liteui });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    if (this.props.view.loading.tab) {
      return <LoadingTab title={"Distribute"} />;
    }
    return (
      <div className="print-show wallet-list-container">
        <p>
          Distribute an activation QR code or link. An activation link when
          clicked will take the user to a page where they can enter their email
          to claim a spot in the wallet. Activation links can be shared on
          social or handed out at events. Only the amount of wallets in your
          aWallet can be activated, once all the spots are gone no more users
          can sign up.
        </p>
        <div>
          <a
            href={`/activate/${this.props.view.liteWallet.id}/${this.props.view.liteWallet.activationCode}`}
            target="_blank"
          >
            Activation Link
          </a>
        </div>
        <div>
          <QRCode
            includeMargin={true}
            size={200}
            value={`https://console.atra.io/activate/${this.props.view.liteWallet.id}/${this.props.view.liteWallet.activationCode}`}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    view: state.liteWalletView,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return Methods({ dispatch });
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletsView);
