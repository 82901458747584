import Actions from "../actions/triggers-create";
import moment from "moment-timezone";

import { IsAddress, CheckAddressChecksum } from "../../../helpers/Eth";
class DefaultState {
  constructor() {
    this.error = "";
    this.noTables = false;
    this.submitting = false;
    this.success = false;
    this.name = "";
    this.description = "";
    this.steps = [
      {
        step: "Step 1",
        title: "Select Action",
        id: "action",
        set: false,
        selected: true,
        clickable: false,
      },
      {
        step: "Step 2",
        title: "Set Conditions",
        id: "conditions",
        set: false,
        selected: false,
        clickable: false,
      },
      {
        step: "Step 3",
        title: "Configure Payment",
        id: "payment",
        set: false,
        selected: false,
        clickable: false,
      },
      {
        step: "Step 4",
        title: "Deploy Trigger",
        id: "deploy",
        set: false,
        selected: false,
        clickable: false,
      },
    ];
    this.action = {
      set: false,
      editing: false,
      edited: false,
    };
    this.tables = [];
    this.methods = [
      {
        label: "Insert a Record",
        short: "Insert",
        description: `"Insert Record" will allow the trigger to insert a new record into the dTable selected below. Upon meeting the conditions set on the next screen, the trigger will send a transaction to Ethereum and allow the data to be inserted into the table`,
        value: "insert",
        icon: "log-in",
        mappingTypes: [
          {
            label: "User Input",
            value: "input",
            valueType: "any",
          },
          {
            label: "Static Value",
            value: "static",
            valueType: "any",
          },
          {
            label: "User's Address",
            value: "msg.sender",
            valueType: "address",
          },
          {
            label: "ETH Sent by User",
            value: "msg.value",
            valueType: "price",
          },
          {
            label: "Automap User's Address",
            value: "automap",
            valueType: "pointer",
          },
          {
            label: "Current DateTime",
            value: "current",
            valueType: "date",
          },
        ],
      },
      {
        label: "Update a Record",
        short: "Update",
        description: `"Update Record" will allow the trigger to modify an existing record in the dTable selected
  below. Upon meeting the conditions set on the next screen, the trigger will update the
  given record. The new record will be reflected on the blockchain, along with the record
  of the data being changed.`,
        value: "update",
        icon: "edit",
        mappingTypes: [
          {
            label: "User Input",
            value: "input",
            valueType: "any",
          },
          {
            label: "Don't Update Value",
            value: "self",
            valueType: "any",
          },
          {
            label: "Static Value",
            value: "static",
            valueType: "any",
          },
          {
            label: "User's Address",
            value: "msg.sender",
            valueType: "address",
          },
          {
            label: "ETH Sent by User",
            value: "msg.value",
            valueType: "price",
          },
          {
            label: "Increase by 1",
            value: "add-one",
            valueType: "number",
          },
          {
            label: "Decrease by 1",
            value: "sub-one",
            valueType: "number",
          },
          {
            label: "Automap User's Address",
            value: "automap",
            valueType: "pointer",
          },
          {
            label: "Current DateTime",
            value: "current",
            valueType: "date",
          },
        ],
      },
      {
        label: "Delete a Record",
        short: "Delete",
        description: `"Delete Record" will allow the trigger to mark an existing record as deleted in the dTable selected
  below. Upon meeting the conditions set on the next screen, the trigger will delete the
  given record. The deleted record will remain in the dTable for audit reason but will not be returned with active data.`,
        value: "delete",
        icon: "remove",
        mappingTypes: [],
      },
    ];
    this.conditions = [];
    this.conditionOptions = {
      base: [
        {
          compatibleActionMethods: ["insert", "update"],
          disabled: true,
          label: "Inputs",
          group: "inputs",
          options: [],
        },
        {
          compatibleActionMethods: ["insert", "update", "delete"],
          disabled: true,
          label: "User",
          group: "user",
          options: [
            {
              label: "User's Address",
              value: "msg.sender",
              valueType: "address",
            },
            {
              label: "ETH Sent by User",
              value: "msg.value",
              valueType: "price",
            },
            {
              label: "Current DateTime",
              value: "now",
              valueType: "date",
            },
          ],
        },
        {
          compatibleActionMethods: ["update", "delete"],
          disabled: true,
          label: "Action dTable",
          group: "actionTable",
          options: [],
        },
      ],
      operators: [
        {
          label: "Equal To",
          value: "equal",
          compatibleValueTypes: ["all"],
          disabled: true,
        },
        {
          label: "Not Equal To",
          value: "notEqual",
          compatibleValueTypes: ["all"],
          disabled: true,
        },
        {
          label: "Greater Than",
          value: "greater",
          compatibleValueTypes: ["number", "price", "date"],
          disabled: true,
        },
        {
          label: "Greater Than or Equal To",
          value: "greaterEqual",
          compatibleValueTypes: ["number", "price", "date"],
          disabled: true,
        },
        {
          label: "Less Than",
          value: "less",
          compatibleValueTypes: ["number", "price", "date"],
          disabled: true,
        },
        {
          label: "Less Than or Equal To",
          value: "lessEqual",
          compatibleValueTypes: ["number", "price", "date"],
          disabled: true,
        },
        {
          label: "Is In",
          value: "in",
          compatibleValueTypes: ["all"],
          disabled: true,
        },
        {
          label: "Is Not In",
          value: "notIn",
          compatibleValueTypes: ["all"],
          disabled: true,
        },
      ],
      target: [
        {
          compatibleOperators: [
            "equal",
            "notEqual",
            "greater",
            "greaterEqual",
            "less",
            "lessEqual",
          ],
          disabled: true,
          label: "Static",
          group: "static",
          options: [
            {
              disabled: true,
              static: true,
              value: "address",
              label: "Address",
              valueType: "address",
              rawValue: "",
            },
            {
              disabled: true,
              static: true,
              value: "date",
              label: "Date",
              valueType: "date",
              rawValue: "",
            },
            {
              disabled: true,
              static: true,
              value: "price",
              label: "Price",
              valueType: "price",
              rawValue: "0",
            },
            {
              disabled: true,
              static: true,
              value: "number",
              label: "Number",
              valueType: "number",
              rawValue: 0,
            },
            {
              disabled: true,
              static: true,
              value: "text",
              label: "Text",
              valueType: "text",
              rawValue: "",
            },
          ],
        },
        {
          compatibleOperators: [
            "equal",
            "notEqual",
            "greater",
            "greaterEqual",
            "less",
            "lessEqual",
          ],
          disabled: true,
          label: "Inputs",
          group: "inputs",
          options: [],
        },
        {
          compatibleOperators: [
            "equal",
            "notEqual",
            "greater",
            "greaterEqual",
            "less",
            "lessEqual",
          ],
          disabled: true,
          label: "User",
          group: "user",
          options: [
            {
              label: "User's Address",
              value: "msg.sender",
              valueType: "address",
            },
            {
              label: "ETH Sent by User",
              value: "msg.value",
              valueType: "price",
            },
          ],
        },
        {
          compatibleOperators: ["in", "notIn"],
          disabled: true,
          label: "List",
          group: "list",
          options: [
            {
              label: "dTable",
              value: "dtable",
              valueType: "list",
            },
          ],
        },
      ],
    };
    this.price = {
      requiredByAction: false,
      requiredByCondition: false,
      enabled: false,
      source: {},
      destination: {},
    };
    this.priceOptions = {
      source: [
        {
          compatibleActionMethods: ["insert", "update", "delete"],
          disabled: true,
          label: "Static",
          value: "static",
          rawValue: "0",
        },
        {
          compatibleActionMethods: ["insert", "update", "delete"],
          disabled: true,
          label: "Range",
          value: "range",
          rawValue: { min: "0", max: "1" },
        },
        {
          compatibleActionMethods: ["insert", "update", "delete"],
          disabled: true,
          label: "Unlimited",
          value: "unlimited",
        },
        {
          compatibleActionMethods: ["update", "delete"],
          disabled: true,
          label: "Action dTable Record Value",
          value: "dtable",
        },
      ],
      destination: [
        {
          compatibleActionMethods: ["insert", "update", "delete"],
          disabled: true,
          label: "Static Ethereum Address",
          value: "static",
          rawValue: "",
        },
        {
          compatibleActionMethods: ["update", "delete"],
          disabled: true,
          label: "Action dTable Record Value",
          value: "dtable",
        },
      ],
    };
  }
}

const trigger = (state = new DefaultState(), action) => {
  let newState = {};
  let actionTable = {};
  let tables = [];
  switch (action.type) {
    case Actions.UPDATE_SUCCESS: {
      newState = { ...state };
      newState.success = action.data.value;
      return newState;
    }
    case Actions.ERROR: {
      let newState = { ...state };
      newState.error = action.data.value;
      return newState;
    }
    case Actions.UPDATE_SELECTED_STEP: {
      let newState = { ...state };
      const stepIndex = newState.steps.findIndex(
        (s) => s.id === action.data.value
      );

      newState.steps = newState.steps.map((step, i) => {
        return {
          ...step,
          selected: false,
          clickable: i < stepIndex ? true : false,
        };
      });

      newState.steps.find((s) => s.id === action.data.value).selected = true;
      return newState;
    }
    case Actions.UPDATE_STEP_SET: {
      let newState = { ...state };
      newState.steps.find((s) => s.id === action.data.id).set = action.data.set;
      return newState;
    }
    case Actions.TRIGGER_NO_TABLES:
      return { ...state, noTables: action.data };
    case Actions.UPDATE_CONDITION_SUBMITTING:
      return { ...state, submitting: action.data.value };
    case Actions.UPDATE_INFO:
      newState = { ...state };
      newState[action.data.type] = action.data.value;
      return newState;
    case Actions.SET_TABLES:
      // set the default state w/ tables
      newState = {
        ...state,
        tables: action.data.tables.sort((a, b) => b.created - a.created),
      };
      newState.tables[0].selectedByAction = true;

      // Set the Input Mappings for the table using the columns
      newState.tables = newState.tables.map((table) => {
        table.mappings = table.columns.map((column, i) => {
          let rawValue = "";
          switch (column.type) {
            case "price":
              rawValue = "0";
              break;
            case "number":
              rawValue = 0;
              break;
            case "date":
              rawValue = new Date(
                moment(new Date())
                  .tz(action.data.timezone.code)
                  .format("MM/DD/YYYY hh:mm:ss A")
              );
              break;
          }
          return {
            name: column.name,
            valueType: column.type,
            value: `input`,
            rawValue: rawValue,
          };
        });
        table.actionMethod = newState.methods[0].value;
        return table;
      });
      return newState;
    case Actions.SAVE_ACTION:
      newState = { ...state };
      newState.steps = newState.steps.map((step, i) => {
        return {
          ...step,
          clickable: i == 0 ? true : false,
          selected: i == 1 ? true : false,
        };
      });

      //if updated and nothing changed
      if (newState.action.set && !newState.action.edited) {
        newState.action.set = true;
        newState.action.editing = false;
        newState.action.edited = false;
        return newState;
      }

      newState.action.set = true;
      newState.action.editing = false;
      newState.action.edited = false;
      //reset conditions
      actionTable = newState.tables.find((t) => t.selectedByAction);
      newState.conditions = [];

      //reset price &
      if (actionTable.mappings.find((m) => m.value === "msg.value")) {
        //check if msg.value is used at all in the mappings, if so require price
        newState.price = {
          requiredByAction: true,
          enabled: true,
          source: {},
          destination: {},
        };
        newState = Price_Enabled_Default_State(newState);
      } else {
        newState.price = {
          requiredByAction: false,
          enabled: false,
          source: {},
          destination: {},
        };
      }

      // Set the actionTable Group options for the condition statements
      // Create an option for each column value
      let actionTableGroup = newState.conditionOptions.base.find(
        (o) => o.group === "actionTable"
      );

      actionTableGroup.options = actionTable.columns.map((column) => {
        return {
          label: column.name,
          value: column.name,
          valueType: column.type,
        };
      });

      // disable/enable condition options based on action method
      newState.conditionOptions.base = newState.conditionOptions.base.map(
        (option) => {
          let disabled = true;
          if (
            option.compatibleActionMethods.find(
              (m) => m === actionTable.actionMethod
            )
          ) {
            disabled = false;
          }
          return { ...option, disabled };
        }
      );

      // Get all mappings for the action that are type of input
      // and set them as options for the condition statements
      newState.conditionOptions.base.find(
        (c) => c.group === "inputs"
      ).options = actionTable.mappings
        .filter((m) => m.value === "input")
        .map((map) => {
          return {
            label: map.name,
            value: map.name,
            valueType: map.valueType,
          };
        });
      if (actionTable.actionMethod !== "delete") {
        newState.conditionOptions.target.find(
          (c) => c.group === "inputs"
        ).options = actionTable.mappings
          .filter((m) => m.value === "input")
          .map((map) => {
            return {
              label: map.name,
              value: map.name,
              valueType: map.valueType,
            };
          });
      } else {
        newState.conditionOptions.target.find(
          (c) => c.group === "inputs"
        ).options = [];
      }
      return newState;
    case Actions.EDIT_ACTION:
      newState = { ...state };
      newState.action.editing = true;
      return newState;
    case Actions.CANCEL_EDIT_ACTION:
      newState = { ...state };
      newState.action.editing = false;
      newState.action.edited = false;
      return newState;
    case Actions.RESET_TRIGGER:
      return new DefaultState();
    case Actions.SET_METHODS:
      return { ...state, methods: action.data };
    case Actions.UPDATE_ACTION_TABLE:
      newState = { ...state };
      tables = newState.tables;
      if (newState.action.editing) {
        newState.action.edited = true;
      }
      // loop through tables
      const actionMethod = tables.find((t) => t.selectedByAction).actionMethod;
      tables = tables.map((table) => {
        if (table.id === action.data.value) {
          table.selectedByAction = true;
          //set the method to the current one
          table.actionMethod = actionMethod;
        } else {
          table.selectedByAction = false;
        }
        return table;
      });
      newState = SetValidState(newState);
      return newState;
    case Actions.UPDATE_ACTION_METHOD:
      newState = { ...state };
      tables = newState.tables;
      if (newState.action.editing) {
        newState.action.edited = true;
      }
      let table = tables.find((t) => t.selectedByAction);
      table.actionMethod = action.data.value;
      //reset mapping values for new method since they are not all compatible
      if (table.actionMethod === "update" || table.actionMethod === "insert") {
        const mapResetValue = newState.methods.find(
          (m) => m.value === action.data.value
        ).mappingTypes[0].value;

        table.mappings = table.mappings.map((map, i) => {
          map.value = mapResetValue;
          map.error = false;
          let rawValue = "";
          switch (map.valueType) {
            case "price":
              rawValue = "0";
              break;
            case "number":
              rawValue = 0;
              break;
            case "date":
              rawValue = new Date(
                moment(new Date())
                  .tz(action.data.timezone.code)
                  .format("MM/DD/YYYY hh:mm:ss A")
              );
              break;
          }
          map.rawValue = rawValue;
          return map;
        });
      } else {
        table.mappings = table.mappings.map((map, i) => {
          map.value = "";
          map.error = false;
          map.rawValue = "";
          return map;
        });
      }
      newState = SetValidState(newState);
      return newState;
    case Actions.UPDATE_ACTION_MAPPING_VALUE: {
      newState = { ...state };
      tables = newState.tables;
      if (newState.action.editing) {
        newState.action.edited = true;
      }
      let table = tables.find((t) => t.selectedByAction);
      let mapping = table.mappings.find((m) => m.name === action.data.type);
      mapping.error = false;
      if (action.data.static) {
        mapping.rawValue = action.data.value;
        mapping.error = action.data.error;
      } else {
        mapping.value = action.data.value;
        //clear and set rawValue between options if pointer type
        if (mapping.valueType === "pointer") {
          if (mapping.value === "automap") {
            const tableId = table.columns.find((c) => c.name === mapping.name)
              .tableId;
            const column = tables
              .find((t) => t.id === tableId)
              .columns.find((c) => c.type === "address");
            if (column) {
              mapping.rawValue = column.name;
            } else {
              mapping.error = `Automap User's Address requires related dTable to have an address type column.`;
            }
          } else if (mapping.value === "static") {
            mapping.rawValue = "";
          }
        }
      }
      newState = SetValidState(newState);
      return newState;
    }
    case Actions.UPDATE_MAPPING_ERROR: {
      let newState = { ...state };
      newState.tables.find((t) => t.selectedByAction).mappings[
        action.data.index
      ].error = action.data.error;
      return newState;
    }
    case Actions.ADD_CONDITION:
      newState = { ...state };

      var conditionOptions = JSON.parse(
        JSON.stringify(newState.conditionOptions)
      );

      // find the first enabled base option for the condition statement
      var baseGroup = conditionOptions.base.find(
        (c) => !c.disabled && c.options.length
      );
      var baseOption = baseGroup.options[0];

      conditionOptions.base = [...conditionOptions.base];

      conditionOptions.operators = conditionOptions.operators.map((op) => {
        let disabled = true;
        if (
          op.compatibleValueTypes.find(
            (c) => c === baseOption.valueType || c === "all"
          )
        ) {
          disabled = false;
        }
        return { ...op, disabled };
      });

      //select the first compatible operator
      var operator = conditionOptions.operators.find((o) => !o.disabled);

      // find the first target value that is of the base type
      // set the disabled flag for the target types for the selected base type
      conditionOptions.target = conditionOptions.target.map((target) => {
        let disabled = true;
        target.options = target.options.map((option) => {
          if (option.valueType === baseOption.valueType) {
            option.disabled = false;
            disabled = false;
          } else {
            option.disabled = true;
          }
          if (option.valueType === "date") {
            option.rawValue = new Date(
              moment(new Date())
                .tz(action.data.timezone.code)
                .format("MM/DD/YYYY hh:mm:ss A")
            );
          }
          return option;
        });
        return { ...target, disabled };
      });

      var targetGroup = conditionOptions.target.find((c) => !c.disabled);
      var targetOption = targetGroup.options.find((o) => !o.disabled);

      newState.conditions.push({
        description: "",
        base: {
          ...baseOption,
          groupLabel: baseGroup.label,
          groupId: baseGroup.group,
        },
        operator,
        target: {
          ...targetOption,
          groupLabel: targetGroup.label,
          groupId: targetGroup.group,
        },
        options: { ...conditionOptions },
      });
      //newState = CheckIfPaymentIsRequiredByConditions(newState);

      newState = SetValidState(newState);
      return newState;
    case Actions.UPDATE_CONDITION_NEXT: {
      let newState = { ...state };
      newState.steps.find((s) => s.id === "conditions").clickable = true;
      return newState;
    }
    case Actions.REMOVE_CONDITION:
      newState = { ...state };
      newState.conditions.splice(action.index, 1);
      newState = SetValidState(newState);
      return newState;
    case Actions.UPDATE_CONDITION_DESC:
      newState = { ...state };
      newState.conditions[action.data.id].description = action.data.value;
      return newState;
    case Actions.UPDATE_CONDITION_BASE:
      newState = { ...state };

      var condition = newState.conditions[action.data.id];

      // get the new base object
      var baseGroup = condition.options.base.find(
        (b) => b.group === action.data.group
      );
      var baseOption = baseGroup.options.find(
        (o) => o.value === action.data.value
      );
      condition.base = {
        ...baseOption,
        groupLabel: baseGroup.label,
        groupId: baseGroup.group,
      };

      // Update the available operators
      condition.options.operators = condition.options.operators.map((op) => {
        let disabled = true;
        if (
          op.compatibleValueTypes.find(
            (c) => c === baseOption.valueType || c === "all"
          )
        ) {
          disabled = false;
        }
        return { ...op, disabled };
      });

      // Set to the first op compatible
      var operator = condition.options.operators.find((o) => !o.disabled);
      condition.operator = operator;

      // recalculate target options
      condition.options.target = condition.options.target.map((target) => {
        let disabled = true;
        target.options = target.options.map((op) => {
          if (op.valueType === baseOption.valueType) {
            op.disabled = false;
            disabled = false;
          } else {
            op.disabled = true;
          }
          return op;
        });
        return { ...target, disabled };
      });

      var targetGroup = condition.options.target.find((c) => !c.disabled);
      var targetOption = targetGroup.options.find((o) => !o.disabled);
      condition.target = {
        ...targetOption,
        groupLabel: targetGroup.label,
        groupId: targetGroup.group,
      };

      //newState = CheckIfPaymentIsRequiredByConditions(newState);
      newState = SetValidState(newState);
      return newState;
    case Actions.UPDATE_CONDITION_TARGET:
      newState = { ...state };
      //find target object
      var condition = newState.conditions[action.data.id];
      if (action.data.static) {
        condition.target.rawValue = action.data.value;
      } else {
        var targetGroup = condition.options.target.find(
          (b) => b.group === action.data.group
        );
        var targetOption = targetGroup.options.find(
          (o) => o.value === action.data.value
        );
        condition.target = {
          ...targetOption,
          groupLabel: targetGroup.label,
          groupId: targetGroup.group,
        };
      }

      newState = SetValidState(newState);

      return newState;
    case Actions.UPDATE_CONDITION_TARGET_TABLE:
      newState = { ...state };
      //find target object
      var condition = newState.conditions[action.data.id];
      if (action.data.type === "dtable") {
        condition.target.tableId = action.data.value;
        //Update the columns
        // based on the selected default get the columns
        condition.options.target.columns = condition.options.target.tables
          .find((t) => t.id === condition.target.tableId)
          .columns.filter((c) => c.type === condition.base.valueType);

        // set default column for target
        condition.target.column = condition.options.target.columns[0].name;
      } else if (action.data.type === "dtable-column") {
        condition.target.column = action.data.value;
      }
      newState = SetValidState(newState);
      return newState;
    case Actions.UPDATE_CONDITION_OPERATOER:
      newState = { ...state };
      var condition = newState.conditions[action.data.id];
      //find operator object

      var selectedOperator = condition.options.operators.find(
        (b) => b.value === action.data.value
      );
      condition.operator = selectedOperator;

      const baseValueType = condition.base.valueType;

      // recalculate target options
      condition.options.target = condition.options.target.map((target) => {
        //check compaible operators for the target against the operator
        let disabled = true;
        if (
          target.compatibleOperators.find((c) => c === selectedOperator.value)
        ) {
          //check for compatible target options based on base value
          target.options = target.options.map((op) => {
            if (op.valueType === baseValueType || op.valueType === "list") {
              op.disabled = false;
              disabled = false;
            } else {
              op.disabled = true;
            }
            return op;
          });
        } else {
          target.options = target.options.map((op) => {
            op.disabled = true;
            return op;
          });
        }

        return { ...target, disabled };
      });

      var targetGroup = condition.options.target.find((c) => !c.disabled);
      var targetOption = targetGroup.options.find((o) => !o.disabled);
      condition.target = {
        ...targetOption,
        groupLabel: targetGroup.label,
        groupId: targetGroup.group,
      };

      if (targetOption.value === "dtable") {
        // Target Tables must have valid columns inside of it to match with the base value
        condition.options.target.tables = newState.tables.filter((t) =>
          t.columns.find((c) => c.type === baseValueType)
        );
        // based on the new set of target tables set the selected target table and build a column List
        condition.target.tableId = condition.options.target.tables[0].id;
        // based on the selected default get the columns
        condition.options.target.columns = condition.options.target.tables[0].columns.filter(
          (c) => c.type === baseValueType
        );

        // set default column for target
        condition.target.column = condition.options.target.columns[0].name;
      }
      newState = SetValidState(newState);
      return newState;
    case Actions.UPDATE_PRICE_ENABLED:
      newState = { ...state };
      newState.price.enabled = action.data.value;

      if (newState.price.enabled) {
        newState = Price_Enabled_Default_State(newState);
      }
      newState = SetValidState(newState);
      return newState;
    case Actions.UPDATE_PRICE_SOURCE_TYPE:
      newState = { ...state };

      // Get the option for the new source
      newState.price.source.selectedOption = {
        ...newState.priceOptions.source.find(
          (s) => s.value === action.data.value
        ),
        error: null,
      };

      // does the new source need anymore info?
      switch (newState.price.source.selectedOption.value) {
        case "dtable":
          newState.price.source.selectedOption.columns = newState.tables
            .find((t) => t.selectedByAction)
            .columns.filter((c) => c.type === "price");
          newState.price.source.selectedOption.selectedColumn =
            newState.price.source.selectedOption.columns[0].name;
      }
      newState = SetValidState(newState);
      return newState;
    case Actions.UPDATE_PRICE_DESTINATION_TYPE:
      newState = { ...state };

      // Get the option for the new destination
      newState.price.destination.selectedOption = {
        ...newState.priceOptions.destination.find(
          (s) => s.value === action.data.value
        ),
        error: null,
      };

      //does the new type needs anymore info?
      switch (newState.price.destination.selectedOption.value) {
        case "dtable":
          newState.price.destination.selectedOption.columns = newState.tables
            .find((t) => t.selectedByAction)
            .columns.filter((c) => c.type === "address");
          newState.price.destination.selectedOption.selectedColumn =
            newState.price.destination.selectedOption.columns[0].name;
      }
      newState = SetValidState(newState);
      return newState;
    case Actions.UPDATE_PRICE_SOURCE_VALUE:
      newState = { ...state };

      if (action.data.type === "dtable-column") {
        newState.price.source.selectedOption.selectedColumn = action.data.value;
      } else if (action.data.type === "range") {
        newState.price.source.selectedOption.rawValue[action.data.range] =
          action.data.value;
      } else {
        newState.price.source.selectedOption.rawValue = action.data.value;
      }
      newState = SetValidState(newState);
      return newState;
    case Actions.UPDATE_PRICE_DESTINATION_VALUE:
      newState = { ...state };

      if (action.data.type === "dtable-column") {
        newState.price.destination.selectedOption.selectedColumn =
          action.data.value;
      } else {
        newState.price.destination.selectedOption.rawValue = action.data.value;
      }
      newState = SetValidState(newState);
      return newState;
    default:
      return state;
  }
};
function CheckIfPaymentIsRequiredByConditions(state) {
  let newState = { ...state };
  newState.price.requiredByCondition = false;
  //Is a base value using msg.value
  newState.conditions.forEach((condition) => {
    if (
      condition.base.value === "msg.value" ||
      (condition.target.value === "msg.value" &&
        !newState.price.requiredByCondition)
    ) {
      newState.price.requiredByCondition = true;
      // if not already in use enabled and set default
      if (!newState.price.enabled) {
        newState.price.enabled = true;
        newState = Price_Enabled_Default_State(newState);
      }
    }
  });

  return newState;
}
function Price_Enabled_Default_State(state) {
  let newState = { ...state };
  const actionTable = newState.tables.find((t) => t.selectedByAction);
  const actionMethod = actionTable.actionMethod;

  // set the active options based on action method
  newState.priceOptions.source = newState.priceOptions.source.map(
    (source, i) => {
      let disabled = true;
      if (source.compatibleActionMethods.find((o) => o === actionMethod)) {
        disabled = false;
        //check if dtable has columns to support the source uint
        if (
          source.value === "dtable" &&
          !actionTable.columns.find((c) => c.type === "price")
        ) {
          disabled = true;
        }
      }
      return { ...source, disabled };
    }
  );
  newState.priceOptions.destination = newState.priceOptions.destination.map(
    (destination, i) => {
      let disabled = true;
      if (destination.compatibleActionMethods.find((o) => o === actionMethod)) {
        disabled = false;
        //check if dtable has columns to support the source uint
        if (
          destination.value === "dtable" &&
          !actionTable.columns.find((c) => c.type === "address")
        ) {
          disabled = true;
        }
      }
      return { ...destination, disabled };
    }
  );

  // set default source and destination options based on the action method compatibility
  newState.price.source.selectedOption = {
    ...newState.priceOptions.source.find((s) => !s.disabled),
  };
  newState.price.destination.selectedOption = {
    ...newState.priceOptions.destination.find((s) => !s.disabled),
  };

  return newState;
}
function SetValidState(state) {
  //search action mapping for errors
  let selectedTable = state.tables.find((t) => t.selectedByAction);
  //check value mappin for static values
  selectedTable.mappings.map((map, i) => {
    if (map.value === "static") {
      const validMessage = ValidateStaticValue(map.valueType, map.rawValue);
      if (validMessage) {
        map.error = validMessage;
      } else {
        map.error = null;
      }
    }
  });
  //check condition target static values
  state.conditions.map((condition) => {
    if (condition.target.groupId === "static") {
      const validMessage = ValidateStaticValue(
        condition.target.valueType,
        condition.target.rawValue
      );
      if (validMessage) {
        condition.target.error = validMessage;
      } else {
        condition.target.error = null;
      }
    }
  });

  //check payment options for validation
  if (state.price.enabled) {
    //check for source static
    if (state.price.source.selectedOption.value === "static") {
      const validMessage = ValidateStaticValue(
        "price",
        state.price.source.selectedOption.rawValue
      );
      if (validMessage) {
        state.price.source.selectedOption.error = validMessage;
      } else {
        state.price.source.selectedOption.error = null;
      }
    } else if (state.price.source.selectedOption.value === "range") {
      //validate min
      const validMessageMin = ValidateStaticValue(
        "price",
        state.price.source.selectedOption.rawValue.min
      );
      if (validMessageMin) {
        state.price.source.selectedOption.min = {
          error: validMessageMin,
        };
      } else {
        state.price.source.selectedOption.min = {
          error: null,
        };
      }
      //validate max
      const validMessageMax = ValidateStaticValue(
        "price",
        state.price.source.selectedOption.rawValue.max
      );
      if (validMessageMax) {
        state.price.source.selectedOption.max = {
          error: validMessageMax,
        };
      } else {
        state.price.source.selectedOption.max = {
          error: null,
        };
      }
      if (
        parseFloat(state.price.source.selectedOption.rawValue.min) >=
        parseFloat(state.price.source.selectedOption.rawValue.max)
      ) {
        state.price.source.selectedOption.error =
          "Price Minimum must be lower than Maximum";
      } else {
        state.price.source.selectedOption.error = null;
      }
    }

    if (state.price.destination.selectedOption.value === "static") {
      const validMessage = ValidateStaticValue(
        "address",
        state.price.destination.selectedOption.rawValue
      );
      if (validMessage) {
        state.price.destination.selectedOption.error = validMessage;
      } else {
        state.price.destination.selectedOption.error = null;
      }
    }
  }

  return state;
}
function ValidateStaticValue(type, value) {
  switch (type) {
    case "address": {
      if (!IsAddress(value)) {
        return "Enter a valid Ethereum Address";
      } else if (!CheckAddressChecksum(value)) {
        return "Address Checksum Not Valid";
      } else {
        return false;
      }
      break;
    }
    case "number": {
      const errorMessage = "Enter a valid whole number";
      try {
        if (
          isNaN(value) ||
          value === "" ||
          value.toString().indexOf(".") > -1 ||
          value.toString().indexOf("-") > -1
        ) {
          return errorMessage;
        } else {
          const num = parseInt(value);
          if (!Number.isInteger(num)) {
            return errorMessage;
          } else {
            return false;
          }
        }
      } catch (err) {
        return errorMessage;
      }
      break;
    }
    case "text": {
      return false;
      break;
    }
    case "price": {
      const errorMessage = "Enter a valid price";
      try {
        if (
          isNaN(value) ||
          value === "" ||
          value.toString().indexOf("-") > -1
        ) {
          return errorMessage;
        } else {
          //if has a decimal
          if (value.toString().indexOf(".") > -1) {
            const nums = value.toString().split(".");
            nums.map((number) => {
              const num = parseFloat(number);
              if (!Number.isInteger(num)) {
                return errorMessage;
              }
            });
          } else {
            const num = parseFloat(value);
            if (!Number.isInteger(num)) {
              return errorMessage;
            } else {
              return false;
            }
          }
        }
      } catch (err) {
        return errorMessage;
      }
      break;
      break;
    }
  }
}
export default trigger;
