import Actions from '../actions/view';
class DefaultState {
  constructor(){
    this.activeTab = "manage";
    this.loading = {
      tab: true,
      liteWallet: true,
      isSubmittingAssignUser: false
    }
    this.liteWallet = {
      id: null,
      name: null,
      wallets: [],
      balance: 0,
      initBalance: 0
    }
    this.liteui = {
      name: 'loading'
    }
    this.ux = {
      modals: {
        assignUser: new Modal()
      }
    }
  }
}

class Modal{
  constructor(){
    this.show = false;
    this.error = ''
  }
}

export default (state = new DefaultState(), action) => {
  switch (action.type) {

    //Loading
    case Actions.UPDATE_LOADING_TAB:{
      let newState = {...state};
      newState.loading.tab = action.data.value;
      return newState;
    }
    case Actions.UPDATE_LOADING_WALLET:{
      let newState = {...state};
      newState.loading.liteWallet = action.data.value;
      return newState;
    }
    case Actions.UPDATE_LOADING: {
      let newState = {...state};
      newState.loading[action.data.value] = action.data.loading;
      return newState;
    }

    //tabs
    case Actions.UPDATE_ACTIVE_TAB:{
      let newState = {...state};
      newState.activeTab = action.data.value;
      return newState;
    }

    //Modals
    case Actions.UPDATE_ASSIGN_USER_MODAL: {
      let newState = {...state};
      newState.ux.modals.assignUser = {...newState.ux.modals.assignUser, ...action.data};
      if(action.data.walletIndex >= 0){
        newState.ux.modals.assignUser.wallet = newState.liteWallet.wallets[action.data.walletIndex];
        newState.ux.modals.assignUser.wallet.id = action.data.walletIndex;
      }
      if(action.data.show === false){
        newState.ux.modals.assignUser = new Modal();
      }
      return newState;
    }

    //Toggle private key
    case Actions.TOGGLE_KEY:{
      let newState = {...state};
      newState.liteWallet.wallets[action.data.index].showKey = !newState.liteWallet.wallets[action.data.index].showKey;
      return newState;
    }

    //LiteWallet
    case Actions.UPDATE_LITEWALLET_ID:{
      let newState = {...state};
      newState.liteWallet.id = action.data.value;
      return newState;
    }
    case Actions.UPDATE_LITEWALLET:{
      let newState = {...state};
      newState.liteWallet = {...newState.liteWallet, ...action.data.value};
      return newState;
    }
    case Actions.UPDATE_WALLET_BALANCE:{
      let newState = {...state};
      let wallet = newState.liteWallet.wallets.find( w => w.address === action.data.address);
      wallet.balance = action.data.eth;
      newState.liteWallet.balance += parseInt(action.data.wei);
      return newState;
    }
    case Actions.UPDATE_WALLET_BALANCES:{
      let newState = {...state};
      for( let wallet of newState.liteWallet.wallets.slice(0, 100) ){
        const balance = action.data.balances.find( b => b.address == wallet.address);
        wallet.balance = balance.eth;
        newState.liteWallet.balance += parseInt(balance.wei);
      }
      return newState;
    }

    //Liteui
    case Actions.UPDATE_LITEUI: {
      let newState = {...state};
      newState.liteui = action.data.liteui;
      return newState;
    }

    //Reset State
    case Actions.RESET_STATE:{
      return new DefaultState();
    }

    default:
      return state;
  }
};
