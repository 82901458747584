import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import EnvMethods from '../../../components/Environments/methods';

class Start extends Component {
  constructor(props){
    super(props);
    this.props.updateEnvironmentsEnabled({ enabled: true });
  }
  render(){
    return null;
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return EnvMethods({dispatch});
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Start));
