import Actions from '../actions/triggers-dashboard';

class DefaultState {
  constructor(){
    this.loadingTriggers = true;
    this.triggers = [];
    this.stats = {
      total_triggers: 0,
      total_live_triggers: 0,
      total_deploying_triggers: 0
    };
    this.filter = {
      showArchived: false
    }
  }
}

export default (state = new DefaultState, action) => {
  switch (action.type) {
    case Actions.TOGGLE_FILTER :{
      let newState = {...state};
      newState.filter[action.data.value] = !newState.filter[action.data.value];
      return newState;
    }
    case Actions.UPDATE_TRIGGERS:
      var newState = {...state};
      newState.triggers = action.data.value;
      newState.stats.total_triggers = newState.triggers.length;
      newState.stats.total_live_triggers = newState.triggers.filter( t => t.status === 'Live' ).length;
      newState.stats.total_deploying_triggers = newState.triggers.filter( t => t.status !== 'Live' ).length;
      return newState;
    case Actions.UPDATE_LOADING_TRIGGERS:
      return {
        ...state,
        loadingTriggers: action.data.value
      };
    case Actions.CLEAR_STATE:
      return new DefaultState();
    default:
      return state;
  }
};
