import { forgotPassword } from "../actions/actionTypes";

let defaultState = {
  email: "",
  emailError: "",
  newPassword: "",
  newPasswordErrors: [],
  confirmNewPassword: "",
  confirmNewPasswordError: "",
  confirmationCode: "",
  confirmationCodeError: "",
  showState: "enterEmail",
  isLoading: false,
  onSuccess: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case forgotPassword.FORGOTPASSWORD_DEFAULT_STATE:
      return defaultState;
    case forgotPassword.FORGOTPASSWORD_CLEAR_NEW_PASSWORD_ERRORS:
      return {
        ...state,
        newPasswordErrors: []
      };
    case forgotPassword.FORGOTPASSWORD_REMOVE_NEW_PASSWORD_ERRORS:
      return {
        ...state,
        newPasswordErrors: state.newPasswordErrors.filter(
          error => error !== action.data.value
        )
      };
    case forgotPassword.FORGOTPASSWORD_UPDATE_CONFIRMATION_CODE:
      return {
        ...state,
        confirmationCode: action.data.value
      };
    case forgotPassword.FORGOTPASSWORD_UPDATE_CONFIRMATION_CODE_ERROR:
      return {
        ...state,
        confirmationCodeError: action.data.value
      };
    case forgotPassword.FORGOTPASSWORD_UPDATE_CONFIRM_NEW_PASSWORD:
      return {
        ...state,
        confirmNewPassword: action.data.value
      };
    case forgotPassword.FORGOTPASSWORD_UPDATE_CONFIRM_NEW_PASSWORD_ERROR:
      return {
        ...state,
        confirmNewPasswordError: action.data.value
      };
    case forgotPassword.FORGOTPASSWORD_UPDATE_EMAIL:
      return {
        ...state,
        email: action.data.value
      };
    case forgotPassword.FORGOTPASSWORD_UPDATE_EMAIL_ERROR:
      return {
        ...state,
        emailError: action.data.value
      };
    case forgotPassword.FORGOTPASSWORD_UPDATE_IS_LOADING:
      return {
        ...state,
        isLoading: action.data.value
      };
    case forgotPassword.FORGOTPASSWORD_UPDATE_NEW_PASSWORD:
      return {
        ...state,
        newPassword: action.data.value
      };
    case forgotPassword.FORGOTPASSWORD_UPDATE_NEW_PASSWORD_ERRORS:
      return {
        ...state,
        newPasswordErrors: [action.data.value, ...state.newPasswordErrors]
      };
    case forgotPassword.FORGOTPASSWORD_UPDATE_SHOW_STATE:
      return {
        ...state,
        showState: action.data.value
      };
    case forgotPassword.FORGOTPASSWORD_CLEAR_FORGOTPASSWORD_STATE:
      {
        state = defaultState;
      }
      return state;
    case forgotPassword.FORGOTPASSWORD_ON_SUCCESS:
      return {
        ...state,
        onSuccess: action.data.value
      };
    default:
      return state;
  }
};
