import React, { Component } from "react";
import Highlight from "react-highlight";
import { connect } from "react-redux";

import Methods from '../../methods/view';
import AtraAPI from '../../../../apiv2';

import LoadingTab from '../LoadingTab';

const Contract = ({ contract, rendered }) => {
  if(contract){
    return <Highlight className="javascript">{contract}{rendered()}</Highlight>;
  }else{
    return null;
  }
};

class ContractView extends Component {
  constructor(props){
    super(props);
    this._mounted = false;
    this._rendered = false;
  }

  shouldComponentUpdate(){
    return !this._rendered;
  }

  componentDidMount = () => {
    this._mounted = true;
    if(!this.props.dTablesView.table.contract){
      AtraAPI.GetContract('dtables', this.props.dTablesView.table.id).then(contract=>{
        if(this._mounted){
          this.props.updateContract({ value: contract });
          this.props.updateLoadingTab({ value: false });
        }
      },err=>{

      })
    }else{
      this.props.updateLoadingTab({ value: false });
    }
  }

  componentWillUnmount = () => {
    this._mounted = false;
  }

  render() {
    if(this.props.dTablesView.loadingTab){
      return <LoadingTab title={'Contract'} />;
    }else{
      return <Contract
        contract={this.props.dTablesView.table.contract}
        rendered={()=>{this._rendered = true}}
       />;
    }
  }

}

const mapStateToProps = state => {
  return {
    dTablesView: state.dTablesView
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...Methods({dispatch})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractView);
