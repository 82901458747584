import React from 'react';
import { Modal, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import UIModals from './';

const Error = ({
  show,
  error,
  handleClose
}) => {

  return (
    <UIModals.ModalContainerOK
      title={'error'}
      handleClose={handleClose}
      handleSave={()=>{}}
      show={show}
      >
      {error}
    </UIModals.ModalContainerOK>
  );
}

export default Error;
