import React from 'react';
import { Intro, Tables, Triggers, LiteUI, Finish, Survey } from './MyFirstDapp/';
import { Next } from '../Utils';

const MyFirstDapp = ({

}) => {
  return (
    <div className='doc-section-content-container docs-tutorial-container '>
      <h2>My First dApp</h2>

      <Intro />
      <Tables />
      <Triggers />
      <LiteUI />

      <Survey />

      <Next
        title={'Supply Chain Traceability'}
        link={'/docs/tutorials/supply-chain-traceability'}
         />


    </div>
  )
}

export default MyFirstDapp;
