import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from '../Layouts/LayoutWithOutNav';

export default ({ component: Component, props: Props, ...rest }) =>{
  return (
    <Route
      {...rest}
     render={props =>
       !Props.isAuthenticated ? (
         <Layout>
           <Component {...Props} />
         </Layout>
       ) : (
         <Redirect
           to={{
             pathname: "/",
             state: { from: props.location }
           }}
         />
       )
     }
    />
  )
}
