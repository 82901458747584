import React from 'react';
import { Next } from '../Utils';
import { Link } from 'react-router-dom';

const GettingStarted = ({

}) => {
  return (
    <div className='doc-section-content-container'>
      <h2>Getting Started</h2>

      <section>
        <h3>Management Console Overview</h3>
        <p>The Atra Management Console is a web interface where you configure, launch, and manage Atra services. To automate some console interactions, developers may want to use the REST API. You can create an API Key in your <Link to='/account'>account page</Link>, and use the API References to start building.</p>

        <h4>Watch the Overview video (3 mins)</h4>
        <div className='video-content'>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/ze74-6IA9YQ?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

      </section>

      <Next
        title={'Account Setup (5 mins)'}
        link={'/docs/tutorials/account-setup'}
         />


    </div>
  )
}

export default GettingStarted;
