import React from 'react';
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export default ({
  className,
  breadcrumbs,
  children
}) => {
  return (
    <div className={`container ${className}`}>
      <BreadCrumbs
        links={breadcrumbs}
      />
    <div className='trigger-content'>
      {children}
    </div>
    </div>
  )
}
