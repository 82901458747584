import React from 'react';
import { Checkbox, ControlLabel } from 'react-bootstrap';

const DashboardFilter = ({
  filter,
  onChange
}) => {
  return (
    <div>
      <div className='dashboard-filter-container'>
        <Checkbox id={'showArchived'} checked={filter.showArchived} onChange={onChange} />
        <ControlLabel htmlFor={'showArchived'}>Show Archived</ControlLabel>
      </div>
    </div>
  )
}

export default DashboardFilter;
