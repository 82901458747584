import React, { Component } from "react";

import AtraAPI from '../../../apiv2';
import Methods from '../methods/dashboard';
import EnvMethods from '../../../components/Environments/methods';

import { connect } from "react-redux";
import { FormatDate } from "../../../helpers/Date";
import { FromBigNumber } from "../../../helpers/Eth";
import { RefreshMiliseconds } from '../../../helpers/Timers';
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import {
  DashboardStatsContainer,
  DashboardActionHeader,
  DashboardGrid,
  DashboardFooter,
  DashboardGridNoContent,
  DashboardFilter
} from "../../../components/ServiceDashboard";

import { Button, Checkbox, ControlLabel } from 'react-bootstrap';

import { GetRecordCount } from '../../../sdk/dtables';

class dTablesDashboard extends Component {

  constructor(props){
    super(props);
    this._mounted = false;
    this._noContent = <DashboardGridNoContent
        title={'Add a Table'}
        description={`Store your applications data in a dTable. dTable's store data in the form of records. Each record has a cell for every column.`}
        buttonText={'Create Table'}
        onClick={()=>this.props.history.push('/dtables/create')}
        learnMoreClick={()=>this.props.history.push('/docs/user-guides/dtables')}
       />
     this.props.updateEnvironmentsEnabled({ enabled: true });
  }

  async componentDidMount() {
    this._mounted = true;
    this.pollTables();
  }

  async componentWillUnmount() {
    this._mounted = false;
    this.props.clearState();
  }

  async pollTables() {
    if (this._mounted) {
      await this.getTables().then(async (tables) => {
        this.props.loadingTables({ loading: false });
        if (this._mounted) {
          await this.getTablesRecordCount(tables);
          setTimeout(async () => {
            if(this._mounted){
              await this.pollTables();
            }
          }, RefreshMiliseconds);
        }
      });
    }
  }

  getTablesRecordCount = tables => {
    return new Promise((resolve, reject) => {
      try {

        let promiseList = [];
        for ( var table of tables.filter( t => t.status === 'Live') ){
          const tableId = table.id;
          let getRecordCountPromise = GetRecordCount(table).then(res=>{
            if(this._mounted){
              let count = FromBigNumber(res);
              this.props.updateRecordCount({ recordCount: count, tableId });
            }
          },err=>{
            if(this._mounted){
              this.props.updateRecordCount({ recordCount: 0, tableId});
            }
          })
          promiseList.push(getRecordCountPromise);
        }

        Promise.all(promiseList).then((res)=>{
          if(this._mounted){
            this.props.updateLoadingRecordCount({ loading: false });
          }
          resolve();
        });

      } catch (err) {
        reject();
      }
    });
  };

  getTables = () => {
    return new Promise((resolve, reject) => {

      let envId = window.localStorage.getItem('activeEnvironment');

      AtraAPI.GetTablesFullReturn(this.props.account.user.timezone, envId).then(tables=>{
        if (this._mounted) {
          this.props.updateDTables({ value: tables });
          resolve(tables);
        }
      },err=>{
        reject(err);
      })
    });
  };

  handleFilterChange = (event) => {
    this.props.toggleFilter({ value: event.target.id });
  }

  render() {
    const stats = this.props.dTablesDashboard.stats;

    const breadcrumbs = [];
    breadcrumbs.push({ text: 'dTables', to: '/dtables', icon: "dtables" });

    const statsList = [];
    statsList.push({
      title: 'Total Tables',
      value: stats.total_tables,
      loading: this.props.dTablesDashboard.loadingTables
    });
    statsList.push({
      title: 'Tables Live',
      value: stats.tables_live,
      loading: this.props.dTablesDashboard.loadingTables
    });
    statsList.push({
      title: 'Tables In Progress',
      value: stats.tables_inprog,
      loading: this.props.dTablesDashboard.loadingTables
    });
    statsList.push({
      title: 'Total Records',
      value: stats.total_records,
      loading: this.props.dTablesDashboard.loadingRecordCounts
    });

    const tables = this.props.dTablesDashboard.tables.filter(t => t.archived === this.props.dTablesDashboard.filter.showArchived);

    return (
      <div className="service-dashboard-container container">

        <BreadCrumbs
          links={breadcrumbs}
          />

        <DashboardStatsContainer
          stats={statsList}
          />

        <DashboardActionHeader
          title={'Your dTables'}
          onClick={()=>this.props.history.push('/dtables/create')}
          actionButtonText={'Create Table'}
         />

       <DashboardFilter
         filter={this.props.dTablesDashboard.filter}
         onChange={this.handleFilterChange}
         />

       <DashboardGrid
         type={'dtable'}
         source={tables.sort((a,b)=> b.created - a.created)}
         loading={this.props.dTablesDashboard.loadingTables}
         noContent={this._noContent}
          />

       <DashboardFooter
         title={'dTables'}
         description={`Store your applications state in dTables. dTables store data in the form of records. Each record has a cell for every column. Connect your dTables to Triggers to enforce business logic. Connect your dTables to LiteUI for a user friendly interface.`}
         link={'/docs/user-guides/dtables'}
          />

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dTablesDashboard: state.dTablesDashboard,
    environments: state.environments,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...Methods({dispatch}),
    ...EnvMethods({dispatch})
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(dTablesDashboard);
