import React from 'react';
import { Next } from '../Utils';
import Logo from '../../../../../components/Branding/ServiceIcons/triggers.svg';


const Triggers = ({

}) => {
  return (
    <div className='doc-section-content-container'>
      <div className='service-branding-container'>
        <h2>Triggers</h2>
        <img src={Logo} />
      </div>

      <section>
        <h3>Overview</h3>
        <p>Triggers provide business logic for your decentralized applications. Triggers eliminate the need for typing lengthy Ethereum smart contracts to control how users interact with underlying data storage, as well as how payments are processed. Triggers are what sit between an application's user and the applications data storage (dTables). For example, if you create an decentralized application on Atra that allows users to change their profiles stored in a dTable, a Trigger must be created to enforce that the user is actually the owner of that record before being able to modify the record. It’s normal to have many triggers for a single application to control each individual interaction with the data. In our example application user’s would need to have a profile for them to edit, in this case a Trigger would also be used by users of application to create a profile while still having a separate trigger for editing the data. It is normal to have many triggers for a single dTable.</p>
      </section>

      <section>
        <h3>Quick Start</h3>
        <p>You need to have at least one dTable made to create a trigger. Once logged into the console, select the Triggers from the services dropdown in the navigation. The Triggers dashboard page is a hub to all your Triggers. You can click ‘Create Trigger’ to start prototyping.</p>
      </section>

      <section>
        <h3>Create a Trigger</h3>

        <p>From the Triggers dashboard select ‘Create Trigger’. A Trigger is made up of three distinct pieces:
        </p>

        <p>Action: You must set an action for your trigger to complete. These actions can either be to Insert a Record, Update a Record, or Delete a Record. If there are conditions or payment applicable, they must be met in order for the action to complete.</p>
        <p>Conditions: Set conditions on your trigger to ensure your requirements are met before the action is completed. I.e, the user must have an account, or the user cannot already exist in a dTable.
        </p>

        <p>Payment: If you require Ether to be sent by a user to complete a transaction, you will set that up here. I.e., you want to charge a user a small fee to participate in your message board when they post a message.</p>

        <p>First, you will be setting an action. Based on the goal of your trigger you will select one of the three options below:</p>

        <p>Insert Record: You will allow users to submit a brand new record to the blockchain</p>
        <p>Update Record: You will allow users to modify an existing record on the blockchain (the original record will still remain on the blockchain as it is immutable, however there will be logged changes which update the modified data)</p>
        <p>Delete Record: You will allow users to delete a record (the original record will still remain on the blockchain, but in your UI it will be deleted. Record of the deletion will show on the blockchain).</p>

        <p>Once you select your Action Option, you will select the dTable you want to modify. If you are inserting or updating a record, you will be asked to configure how each record value will map to user input. You have the power to either let users control the input, create static values, or have them not be able to update certain values. In the end, this will show as a form on your UI and users will either have the option to fill in their own value in the form, or not.
        For deleting a record, you need only to select the dTable you wish to modify.
        </p>

        <p>
        Click ‘Next’ to start on conditions. Here you can create requirements for your action to complete. If your trigger requires conditions, click ‘Add Condition’ and follow the prompt to name and describe your condition. Note: Condition functionality at this time may be limited, however we are actively working on building this feature out with more options. If there’s something you’d like to accomplish that our conditions cannot shape, let us know!
        </p>
        <p>After you’ve completed your conditions, click ‘Next’. Now we arrive to the Payment Configuration tab. This is where we will configure how the Ether sent on top of the gas cost in a transaction is handled. Will you require users to pay Ether to complete the action? If so, select ‘Yes’.
        You can set the payment in three different ways:
        </p>

        <p>Static Value: Users always pay the same price to execute the action</p>

        <p>Range: Users are allowed to decide how much they want to pay within a given range to execute the action</p>

        <p>Unlimited: Users determine how much they want to pay to execute the action. This may be helpful for something like a donation, where the trigger is allowing someone to donate funds to another, and they choose the amount they want to give.</p>

        <p>Set a wallet address for the payment destination. Where will the Ether go once the user has paid it? If you’re the owner of the dApp, it’s likely you will want the Ether to go to a wallet of yours. If so, paste your wallet address into the form. Click ‘Next’.</p>

        <p>The last step is to review and deploy the trigger. Give your trigger a name and description in the text fields. Then, review all the information in the summary outline and make sure it is correct. You can hit Previous to go back and edit and sections you wish to change.</p>

        <p>Once you are done on this screen, click ‘Deploy’. Your trigger is now being created into a smart contract and being deployed to the Ethereum network. Once it is live, you can review it’s contract within the trigger instance under the contract tab.</p>

        <p>Now you need to grant permission to your trigger so it can interact with the dTable it is going to modify. dTable smart contracts by default don’t allow other smart contracts to modify the data. Granting permission takes the smart contract address from the trigger and adds it to the list of permissioned address in the dTable. The trigger can now successfully complete the action.</p>

        <p>Once you’ve granted your trigger permission, you are ready to manually invoke it or hook it in to a LiteUI.
        </p>


      </section>

      <section>
        <h3>Invoke a Trigger</h3>
        <p>From a trigger’s instance page, select the ‘Invoke’ tab.</p>

        <p><b>Note:</b> Triggers must have permission in the dTable before being able to invoke it. If the trigger doesn’t have permission in the underlying dTable smart contact the trigger ‘Detail View’ tab will have a notice for you to grant the trigger permission to use the dTable.</p>

        <p><b>Note:</b> Invoking a trigger is always from the User’s point of view and is required to be executed with a browser wallet such as Metmask. The reason triggers can only be invoked using a wallet directly, not from the API or using the Atra console, is because triggers can have payments associated with them. While Atra covers the transaction fee, we exclude Triggers given they may have a value assigned to them. This is to protect the user and Atra - we don’t want to bill a user for accidentally sending a large transaction they can’t pay for and Atra does not want to front Ether for costs outside of transaction gas.</p>

        <p>The Invoke tab will display your wallet status to ensure you’re connected correctly. Your status should be “Eth Status: Wallet Connected” and displayed at the top of the Invoke tab.</p>

        <p>Once you’re connected and you have Ether in your wallet, you can now fill in the invoke form and click invoke. This will send a transaction to your Trigger on Ethereum for an official test of how users will be interacting with the trigger.</p>

        <p>To make sure your trigger executed as you expected, navigate to the dTable page and review the records.</p>


      </section>

      <section>
        <h3>View Contract</h3>
        <p>From the Triggers dashboard and click on a live trigger, these are annotated by green lightning bolts and have a status of Live. Once in the trigger’s specific page, click the ‘Contract’ tab. This view will show the solidity smart contract that is deployed on the Ethereum network and powering your Trigger.</p>
      </section>

      <section>
        <h3>Use Case Examples</h3>
        <h4>Access Management</h4>
        <p>Triggers can serve as an access management mechanism to dTables.</p>
        <h4>Business Logic</h4>
        <p>Triggers sit in front of your data source allowing you to control exactly how the data is modified.</p>
        <h4>Accepting Payments</h4>
        <p>A trigger can have ability to accept Ether from users for the execution of the trigger. This flow can be applied to buying items and making sure users pay the correct amount before completing the trigger.</p>
      </section>

      <Next
        title={'LiteUI User Guide'}
        link={'/docs/user-guides/liteui'}
         />
    </div>
  )
}

export default Triggers;
