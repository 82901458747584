import React from 'react';
import { Intro } from './SupplyChainTraceability/';
import { Next } from '../Utils';

const SupplyChainTraceability = ({

}) => {
  return (
    <div className='doc-section-content-container docs-tutorial-container '>
      <h2>Supply Chain Traceability</h2>

      <Intro />

       <Next
         title={'Decentralized Organization'}
         link={'/docs/tutorials/decentralized-organization'}
          />

    </div>
  )
}

export default SupplyChainTraceability;
