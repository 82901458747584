import React from 'react';
import { Next } from '../Utils';
import Logo from '../../../../../components/Branding/ServiceIcons/dtables.svg';

const dTables = ({

}) => {
  return (
    <div className='doc-section-content-container'>
      <div className='service-branding-container'>
        <h2>dTables</h2>
        <img src={Logo} />
      </div>

      <section>
        <h3>Overview</h3>
        <p>dTables provides immutable, decentralized public storage in the form of tables and records. dTables can be customized for almost any scenario that involves the storing data. Each dTable is a separately deployed smart contract on the Ethereum blockchain. User instances of smart contracts are not shared, you data is kept separate from other Atra deployed dTables.</p>

        <p>
          dTables are decentralized data tables your application will use to store and relay data. You can decide what kind of data you want to store based on the needs of your application.
        </p>
      </section>

      <section>
        <h3>Quick Start</h3>
        <p>Once logged into the console, select dTables from the services dropdown in the navigation. The dTables dashboard page is a hub to all your dTables. You can click ‘Create Table’ to start prototyping.</p>
      </section>

      <section>
        <h3>Create a Table</h3>
        <p>From the dTable dashboard select ‘Create Table’. dTables are made up of two distinct pieces, table info and table columns. The table info is simply identification information about your table instance. Table columns define what type of data will be stored in each table record.</p>

        <p>For Example: Say you’re creating a dTable to store phone numbers. You name your dTable ‘PhoneBook’ and you add the columns ‘FirstName’, ‘LastName’, and ‘PhoneNumber’. After you’ve created the PhoneBook table, each record you insert will always have a FirstName, LastName, and PhoneNumber. Your dTable would resemble what an actual phone book looks like.</p>

        <p>Selecting ‘Add’ column will allow you to add as many columns as you need. Columns must have a name of only letters, and can be one of five different data types.</p>

        <p>Column Types:</p>
        <ul>
          <li>Text: Can hold any type of data and length, will not be formatted when displayed</li>
          <li>Price: Price in Eth (Wei), will be formatted to ETH</li>
          <li>Number: Whole Number, no decimals, not formatted</li>
          <li>Date: Number, UTC+0 No timezone, will be formatted to UTC+0 Date</li>
          <li>Address: Ethereum address type, not formatted</li>
        </ul>

        <p>dTables are required to have a name, and at least one column to be created. Once your name and columns are set click the ‘Create dTable’. Your request to create a dTable will be processed and deployed to Ethereum.</p>

        <p>Back on the dTables dashboard you will notice your dTable instance displays a yellow loading icon. This indicates that the table is processing or pending. Once the transaction of the dTable Ethereum smart contract is complete you will notice a green lightning bolt take the place of the yellow loading icon. Your dTable is now live on the Ethereum network click on the dTable. You are ready to insert records into your table.</p>

        <p>
          When you hit “Create Table” the dTables services starts creating a smart contract based on your inputs. That smart contract is then deployed to the Ethereum network. The table will go through a pending stage while it is being deployed to the network. Once live, you can click within that table and view the contract that was just created in the ‘Contract’ tab. You can also confirm the blockchain transaction by clicking the address link under your table Status and ID. This will bring you to Etherscan.
        </p>

      </section>

      <section>
        <h3>Insert a Record</h3>
        <p>From the dTables dashboard click on a live table as annotated by a green lightning bolt.</p>
        <p>Once in the table’s specific page, click ‘New Record’ to bring up the insert record dialog. Fill out the necessary information for each column in the record.</p>
        <p>Clicking ‘Insert Record’ will send a transaction to the dTable smart contract on Ethereum to save that data.</p>
        <p>All transactions will follow the same pattern: processing, pending, live. Depending on the speed of the network, a record could stay pending for up to a minute or two be patient.</p>
      </section>

      <section>
        <h3>Manage Permissions</h3>
        <p>From a dTable instance page select the ‘Permissions’ tab. Here you’ll see any active permission currently associated with that dTable.</p>
        <p>To give an address permission to insert, edit, or delete from a table click ‘New Permission’ and enter the Ethereum address to either the wallet or the smart contract you want to give permission to.</p>
        <p>Click ‘Grant Permission’. A transaction will be sent to your dTable’s smart contract on the Ethereum network for that address to be added to the permissions list. All transactions will follow the same pattern: processing, pending, live.</p>
        <p>If you want to remove permission for any reason, select the revoke button next to the permissioned address and wait for the transaction to resolve.</p>
      </section>

      <section>
        <h3>View Smart Contract</h3>
        <p>From the dTables dashboard and click on a live table, these are annotated by green lightning bolts and have a status of Live. Once in the table’s specific page, click the ‘Contract’ tab. This view will show the solidity smart contract that is deployed on the Ethereum network and powering your dTable.</p>
      </section>

      <section>
        <h3>Key Concepts </h3>
        <h4>Transactions</h4>
        <p>All data that enters your dTable will be sent through a blockchain transaction. Blockchain (Ethereum) transactions cost cryptocurrency (Ether) to execute. When using the console or API to send data to your dTable, Atra will cover the cost of the transaction.</p>
        <p>All transactions sent to the Ethereum test network are free. Transaction costs only apply to the Ethereum main network.</p>


        <h4>User Driven Data</h4>
        <p>Public blockchains such as Ethereum main/test net promote the idea of users directly interacting with the blockchain without a middleman. dTables is designed for users of an application to insert records directly into Ethereum bypassing any centralized servers.</p>

        <h4>API Driven Data</h4>
        <p>Public blockchains such as Ethereum main/test net promote the idea of serving transparent data without using a middlemen such as a private API. dTables is designed for companies to use the Atra API to insert data into their dTables and allow them to provide their customers with transparent immutable data directly from the data source, Ethereum.</p>
      </section>

      <section>
        <h3>Use Case Examples</h3>

        <h4>Application State</h4>
        <p>dTables can be used to maintain a general state of any decentralized application. Tables can be used together to create a complex state for applications.</p>

        <h4>Immutable File Hashes</h4>
        <p>dTables is a great place to store hashes of your IPFS files to ensure your users they are receiving the correct file with 100% certainty.</p>

        <h4>Supply Chain Transparency</h4>
        <p>Record supply chain transactions with dTable to provide end customers with detailed data about their products.</p>

        <h4>Manage Ownership of Physical Assets</h4>
        <p>Provide a tamper proof record of ownership for physical assets such as real estate, automobiles, jewelry, and much more.</p>

        <h4>Proof of Certificate</h4>
        <p>Store records that allow customers to show proof of a certificate such as college degrees, insurance records, health conditions, and much more from anywhere in the world.</p>
      </section>

      <Next
        title={'Triggers User Guide'}
        link={'/docs/user-guides/triggers'}
         />

</div>
  )
}

export default dTables;
