import React, { Component } from 'react';
import { UserGuides } from '../../../components/Content';
import Container from '../../Container';
import Start from '../../Start';

export default class Documentation extends Component {
  render(){
    return (
      <Container selected={['user-guides','liteui']}>
        <Start />
        <UserGuides.Header />
        <UserGuides.LiteUI />
      </Container>


    )
  }
}
