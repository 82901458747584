import _web3react from 'web3';

const CreateWeb3Object = (instance = false) => {
  if(instance){
    return new _web3react(instance);
  }else{
    return new _web3react("https://goerli.infura.io/v3/7181e2ca04ad45fdbdd4cea905eede4c");
  }
}


export {
  CreateWeb3Object
}
