import Actions from '../actions/account-actions.json';
const Methods = ({ dispatch }) => {
    return {
      //Tabs
      updateActiveTab: data => dispatch({ type: Actions.UPDATE_ACTIVE_TAB, data }),

      //Loading
      updateLoadingUser: data => dispatch({ type: Actions.UPDATE_LOADING_USER, data }),
      updateLoadingAPIKeys: data => dispatch({ type: Actions.UPDATE_LOADING_KEYS, data }),

      //Submitting
      updateIsSubmittingAPIKey: data => dispatch({ type: Actions.UPDATE_IS_SUBMITTING_APIKEY, data }),
      updateIsSubmittingWalletAddress: data => dispatch({ type: Actions.UPDATE_IS_SUBMITTING_WALLET_ADDRESS, data }),

      //User Account
      updateUser: data => dispatch({ type: Actions.UPDATE_USER, data }),
      updateUsername: data => dispatch({ type: Actions.UPDATE_USERNAME, data }),

      //User subscription
      updateUserSubscription: data => dispatch({ type: Actions.UPDATE_USER_SUBSCRIPTION, data }),
      updateShowUpgradeModal: data => dispatch({ type: Actions.UPDATE_SHOW_UPGRADE_MODAL, data }),

      //Usage Alert
      updateShowUsageAlertModal: data => dispatch({ type: Actions.UPDATE_SHOW_USAGE_ALERT_MODAL, data }),

      //Api Key
      updateAPIKey: data => dispatch({ type: Actions.UPDATE_API_KEY, data }),

      //Wallet Address
      updateNewAddress: data => dispatch({ type: Actions.UPDATE_NEW_ADDRESS, data }),
      updateShowChangeAddress: data => dispatch({ type: Actions.UPDATE_SHOW_CHANGE_ADDRESS, data }),
      updateNewAddressFormValidationMessage: data => dispatch({ type: Actions.UPDATE_NEW_ADDRESS_FORM_VALIDATION_MESSAGE, data }),
    }
}

export default Methods;
