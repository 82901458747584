import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Modal, FormGroup, FormControl, ControlLabel, Button, Checkbox } from 'react-bootstrap';
import UIModals from './';
import Methods from "../../methods";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountDown, faSortAmountUp, faFilter, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const ConfigureLiteListModal = ({
  id,
  tableId,
  columns,
  renderMapping,
  title,
  name,
  actions,
  show,
  handlers,
  triggers,
  editingAction,
  viewDetails,
  isDetailView,
  filter,
  allowFilter
}) => {
  const compatibleTriggers = triggers.filter(t => t.input.action.tableId === tableId);
  return (
    <UIModals.ModalContainer
      title={title}
      handleClose={handlers.Close}
      handleSave={handlers.Save}
      show={show}
      >
      <section className='list-name-container'>
        <h3>List Details</h3>
        <FormGroup controlId={'title'}>
          <ControlLabel>Name:</ControlLabel>
          <FormControl
            type='text'
            onChange={handlers.Change}
            defaultValue={name}
            />
        </FormGroup>
      </section>
      <section>
        <h3>Record Actions</h3>
        <ControlLabel>Inline Actions:</ControlLabel>
          <div className='record-action-container'>
            <FormGroup>
              <FormControl disabled={true} componentClass="select">
                return <option>View Record Details</option>
              </FormControl>
            </FormGroup>
            <FormGroup>
              <FormControl
                onChange={handlers.ViewDetailsTitleChange}
                value={viewDetails.title}
                />
            </FormGroup>
            <div className='locked-text'>
              This action cannot be removed
            </div>
          </div>
        <ControlLabel>Actions Dropdown:</ControlLabel>
        {actions.filter( a => a.locked).map((action,i)=>{
            return (
              <div key={i} className='record-action-container'>
                <FormGroup>
                  <FormControl disabled={true} componentClass="select">
                    return <option>View on Etherscan</option>
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <FormControl
                    disabled={true}
                    value={'View'}
                    />
                </FormGroup>
                <div className='locked-text'>
                  This action cannot be removed
                </div>
              </div>
            )
          })}

          {actions.filter( a => a.source.type === 'trigger').map((action,i)=>{
            return (
              <div key={i} className='record-action-container'>
                <FormGroup>
                  <FormControl value={action.source.id} disabled componentClass="select">
                    {compatibleTriggers.map((trigger,i)=>{
                      return <option value={trigger.id} key={i}>{trigger.name}</option>
                    })}
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <FormControl
                    disabled={true}
                    value={action.title}
                    />
                </FormGroup>
                {/* add one to account for the first etherscan */}
                <Button onClick={()=>handlers.RemoveAction(i+1)}  className='black-button'>Remove</Button>
              </div>
            )
          })}

          {compatibleTriggers.length ?
            <div className='record-action-container'>
              <FormGroup>
                <FormControl onChange={handlers.ActionSourceChange} value={editingAction.source.id} componentClass="select">
                  {compatibleTriggers.map((trigger,i)=>{
                    return <option value={trigger.id} key={i}>{trigger.name}</option>
                  })}
                </FormControl>
              </FormGroup>
              <FormGroup>
                <FormControl
                  value={editingAction.title}
                  onChange={handlers.ActionNameChange}
                  placeholder='enter title'
                  />
              </FormGroup>
              <Button onClick={handlers.AddAction} className='secondary-button'>Add</Button>
            </div>
          :
            null
          }
          <Link className='create-new-trigger-link' to='/triggers/create'>
            Create New Trigger
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </Link>
      </section>
      <section>
        <h3>List View</h3>
        <div className='list-view-header-container'>
          <FontAwesomeIcon icon={faSortAmountDown} />
          <h4>Default Sorting:</h4>
        </div>
        <div className='defaultsort-container'>
          <div className='sort-label'>
            Sort List Element by
          </div>

          <FormControl value={renderMapping.find( r => r.defaultsort === true).column} onChange={handlers.DefaultSortChange} componentClass="select">
            {columns.map((column,x)=>{
              return (
                <option key={x} value={column.name}>{column.name}</option>
              )
            })}
          </FormControl>

          <button className='sort-buttons' disabled={renderMapping.find( r => r.defaultsort === true).sortHighToLow} onClick={()=>handlers.SortDirectionChange('desc')}>
            <FontAwesomeIcon onClick={handlers.SortDirectionChange} value={'desc'} icon={faSortAmountDown} />
          </button>
          <button className='sort-buttons' disabled={!renderMapping.find( r => r.defaultsort === true).sortHighToLow} onClick={()=>handlers.SortDirectionChange('asc')}>
            <FontAwesomeIcon icon={faSortAmountUp} />
          </button>
        </div>

        <div className='list-view-header-container'>
          <FontAwesomeIcon icon={faFilter} />
          <h4>Filter:</h4>
        </div>
        {isDetailView && allowFilter?
          <div className='filter-container'>
            <div>
              <Checkbox id='filterByRecordId' checked={filter.recordId} onChange={handlers.FilterOnRecordIdChange} />
              <ControlLabel for='filterByRecordId'>Filter Records on Record Id</ControlLabel>
            </div>
          </div>
        :null
        }
        <div className='record-columns-container'>
          <div>Column Name</div>
          <div>Render As</div>

          {renderMapping.map((mapping,i)=>{
            return ([
              <h3 key={'col'+i}>{mapping.column}</h3>,
              <div className='render-container' key={'render'+i}>
                <FormControl id={mapping.column} className='render-type' value={mapping.render} onChange={handlers.ColumnRenderChange} componentClass="select">
                  <option value={'none'}>None</option>
                  <option value={'text'}>Text</option>
                  <option value={'hyperlink'}>URL</option>
                </FormControl>

                {mapping.render === 'hyperlink' ?
                  <div className='hyperlink-container'>
                    <div>Use URL from:</div>
                    <FormControl id={mapping.column} value={mapping.linkColumn} onChange={handlers.ColumnRenderHyperLinkChange} componentClass="select">
                      <option value='custom_base_url'>Custom Base URL</option>
                      {columns.map((column,y)=>{
                        return (
                          <option key={y} value={column.name}>{column.name}</option>
                        )
                      })}
                    </FormControl>
                  </div>
                : null}

                {mapping.linkColumn === 'custom_base_url' ?

                  <FormControl id={mapping.column} value={mapping.linkCustom} onChange={handlers.ColumnRenderHyperLinkCustomBaseChange}
                    />

                   : null}
              </div>
            ])
          })}
        </div>


      </section>
    </UIModals.ModalContainer>
  );
}

class ConfigureLiteList extends Component {
  handlers = {
    ShowEditModal: (elementId) => {
      const selectedView = this.props.liteui.ux.selectedView;
      this.props.updateShowEditListModal({
        value: true,
        elementId: elementId,
        view: selectedView
      });
    },
    Change: event => {
      const value = event.target.value;
      const id = event.target.id;
      this.props.updateEditListName({
        value
      });
    },
    ActionNameChange: event => {
      this.props.updateEditActionName({
        value: event.target.value
      })
    },
    ActionSourceChange: event => {
      this.props.updateEditActionSource({
        value: event.target.value
      })
    },
    AddAction: action => {
      this.props.addEditingListAction();
    },
    RemoveAction: id => {
      this.props.removeListAction({
        value: id
      });
    },
    Save: (event) => {
      const selectedView = this.props.liteui.ux.selectedView;
      this.props.saveListModalEdits({
        view: selectedView,
        detailView: this.props.liteui.ux.detailView.show
      });
      this.props.updateShowEditListModal({
        value: false
      });
    },
    Close: event => {
      this.props.updateShowEditListModal({
        value: false
      });
    },
    ColumnRenderChange: event => {
      this.props.updateListModalRenderMapping({
        column: event.target.id,
        render: event.target.value
      })
    },
    ColumnRenderHyperLinkChange: event => {
      this.props.updateListModelRenderMappingHyperLink({
        column: event.target.id,
        linkColumn: event.target.value
      })
    },
    ColumnRenderHyperLinkCustomBaseChange: event => {
      this.props.updateListModelRenderMappingHyperLinkCustom({
        column: event.target.id,
        linkCustom: event.target.value
      })
    },
    DefaultSortChange: event => {
      this.props.updateListModelRenderMappingDefaultSort({
        column: event.target.value,
      })
    },
    SortDirectionChange: sort => {
      this.props.updateListModelRenderMappingSortDirection({
        value: sort,
      })
    },
    ViewDetailsTitleChange: event => {
      this.props.updateListModelViewDetailsTitle({
        value: event.target.value
      })
    },
    FilterOnRecordIdChange: event => {
      this.props.updateListModelViewFilterOnRecordId({
        value: !this.props.liteui.ux.showEditListModal.element.config.filter.recordId
      })
    }
  }

  render() {

    if(this.props.liteui.ux.showEditListModal.show){
      let allowFilter = false
      const detailView = this.props.liteui.ux.detailView;
      if(detailView.show){
        const parentElement = detailView.isParentInsideDetailView
          ? this.props.liteui.detailViewElements.find(e => e.id == detailView.parentElementId)
          : this.props.liteui.views[this.props.liteui.ux.selectedView].elements.find(e => e.id == detailView.parentElementId);
        const tableId = parentElement.source.option.id;
        allowFilter = this.props.liteui.ux.showEditListModal.element.source.option.columns.find( c => c.type === 'pointer' && c.tableId === tableId) ? true : false;
      }
      return (
        <ConfigureLiteListModal
          title='Configure List'
          renderMapping={this.props.liteui.ux.showEditListModal.element.config.renderMapping}
          columns={this.props.liteui.ux.showEditListModal.element.source.option.columns}
          tableId={this.props.liteui.ux.showEditListModal.element.source.option.id}
          name={this.props.liteui.ux.showEditListModal.element.title}
          actions={this.props.liteui.ux.showEditListModal.element.config.actions}
          editingAction={this.props.liteui.ux.showEditListModal.editingAction}
          show={this.props.liteui.ux.showEditListModal.show}
          handlers={this.handlers}
          id={this.props.liteui.ux.showEditListModal.id}
          triggers={this.props.liteui.sources.triggers.filter( t => t.input.action.method !== 'insert')}
          viewDetails={this.props.liteui.ux.showEditListModal.element.config.viewDetails}
          isDetailView={this.props.liteui.ux.detailView.show}
          allowFilter={allowFilter}
          filter={this.props.liteui.ux.showEditListModal.element.config.filter}
          />
      )
    }else{
      return null
    }
  }
}

const mapStateToProps = state => {
  return {
    liteui: state.liteui
  };
};

const mapDispatchToProps = dispatch => {
  return Methods({dispatch});
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigureLiteList);
