import React from 'react';
import { Link } from "react-router-dom";
import { Glyphicon } from "react-bootstrap";
import { Loading } from '../Loaders';

const StatusIcon = ({
  status,
  error
}) => {
  if(error){
    return (
      <Glyphicon className={'Failed'} glyph='warning-sign' />
    )
  }
  switch(status){
    case 'Live':
      return (
        <img className={`atra-icon ${status}`} src={require("./Icons/lighting_bolt.svg")} />
      )
    break;
    case 'Funded':
      return (
        <img className={`atra-icon ${status}`} src={require("./Icons/lighting_bolt.svg")} />
      )
    break;
    case 'Pending':
      return (
        <Glyphicon className={`${status} spinning`} glyph='refresh' />
      )
    break;
    case 'Queued':
      return (
        <Glyphicon className={`${status} spinning`} glyph='refresh' />
      )
    break;
    case 'Processing':
      return (
        <Glyphicon className={`${status} spinning`} glyph='refresh' />
      )
    break;
    case 'Failed':
      return (
        <Glyphicon className={status} glyph='refresh' />
      )
    break;
    default:
      return (
        <Glyphicon className={status} glyph='refresh' />
      )
    break;
  }
}

const StatusLabel = ({
  item
}) => {
  if(item.error){
    return "Failed";
  }else{
    return item.status;
  }
}

const DtableGridItem = ({
  item
}) => {
  return (
    <Link to={item.link} className='grid-item'>
      <div className='grid-header-container'>
        <div className='status-icon'><StatusIcon status={item.status} error={item.error} /></div>
        <div className='table-name'>{item.name}</div>
      </div>
      <div className='grid-info-container'>
        <div className='table-desc'>{item.description}</div>
        <div className='table-status'>Status: <StatusLabel item={item} /></div>
        <div className='table-date'>Date Created: {item.date}</div>
        <div className='table-record-count'>{ item.status !== 'Live' ? '-'  : item.record_count > -1 ? item.record_count : <Glyphicon className={`spinning`} glyph='refresh' /> }</div>
        <div className='table-record-count-label'>Total Records</div>
      </div>
  </Link>
  )
}

const LiteWalletGridItem = ({
  item
}) => {
  return (
    <Link to={item.link} className='grid-item'>
      <div className='grid-header-container'>
        <div className='status-icon'><StatusIcon status={item.status} error={item.error} /></div>
        <div className='table-name'>{item.name}</div>
      </div>
      <div className='grid-info-container'>
        <div className='table-desc'>{item.description}</div>
        <div className='table-status'>Status: <StatusLabel item={item} /></div>
        <div>Type: {item.type} - {item.actionType}</div>
        <div className='table-record-count'>{item.wallets.length}</div>
        <div className='table-record-count-label'>Internal Wallets</div>
      </div>
  </Link>
  )
}

const LiteUIGridItem = ({
  item
}) => {
  return (
    <Link to={item.link} className='grid-item liteui-item'>
      <div  className='status-light-container'><div></div></div>
      <div className='grid-header-container'>
        <div className='table-name'>{item.name}</div>
      </div>
      <div className='grid-info-container'>
        <div className='table-date'>Created on {item.date}</div>
      </div>
    </Link>
  )
}

const TriggerGridItem = ({
  item
}) => {
  return (
    <Link to={item.link} className='grid-item'>
      <div className='grid-header-container'>
        <div className='status-icon'><StatusIcon status={item.status} error={item.error} /></div>
        <div className='table-name'>{item.name}</div>
      </div>
      <div className='grid-info-container'>
        <div className='table-desc'>{item.description}</div>
        <div className='table-status'>Status: <StatusLabel item={item} /></div>
        <div className='table-status'>Action dTable: {item.input.action.table}</div>
        <div className='table-status'>Action Method: {item.input.action.method}</div>


        <div className='table-date'>Date Created: {item.date}</div>
      </div>
    </Link>
  )
}

const DashboardGrid = ({
  source,
  loading,
  type,
  noContent
}) => {
  if(loading){
    return (
      <Loading />
    )
  }else{
    if(source.length){
      return (
        <div className="dashboard-grid-container">
          {source.map((item,i) => {
            //const key = `${new Date().getTime()}${i}`;
            const key = `${i}`;
            switch(type){
              case 'trigger':
                return <TriggerGridItem key={key} item={item} />;
              break;
              case 'dtable':
                return <DtableGridItem key={key} item={item}/>;
              break;
              case 'liteui':
                return <LiteUIGridItem key={key} item={item} />;
              break;
              case 'litewallet':
                return <LiteWalletGridItem key={key} item={item} />;
              break;
            }
          })}
        </div>
      )
    }else{
      return noContent;
    }
  }
}

export default DashboardGrid;
