import React, { Component } from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Logo from "../Branding/atra_nobg.svg";
import ExploreIcon from "./explore_button.svg";
import { Nav, Navbar, NavItem, MenuItem, NavDropdown } from "react-bootstrap";
import HamburgerIcon from "./HamburgerIcon";
import { connect } from "react-redux";
import EnvironmentsBar from "../Environments";
import FeatureFlags from "../FeatureFlags";

const AtraNav = ({
  username,
  handleLogout,
  history,
  toggleMobileNav,
  isAuthenticated,
  closeMobileNav,
  fullwidth,
  subscription,
}) => {
  if (subscription && Object.keys(subscription).length === 0) {
    subscription = null;
  }
  return (
    <div className={`sitenav ${isAuthenticated ? "authenticated" : ""}`}>
      {FeatureFlags.Environments && isAuthenticated ? (
        <EnvironmentsBar />
      ) : null}
      <div className="nav-container">
        <div className="mobile-hamburger">
          <HamburgerIcon onClick={toggleMobileNav} />
        </div>
        <div
          className={`navbar-wrapper container ${fullwidth ? "fullwidth" : ""}`}
        >
          <Navbar fluid collapseOnSelect className="topbar">
            <Navbar.Header>
              <Navbar.Brand>
                <Link onClick={closeMobileNav} to="/">
                  <img className="logo" src={Logo} alt="Atra" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav pullLeft>
                <NavDropdown title="Services" id="services">
                  <LinkContainer to="/dtables">
                    <MenuItem>
                      dTables
                      <div className="service-subtitle">Database Tables</div>
                    </MenuItem>
                  </LinkContainer>
                  <MenuItem divider />
                  <LinkContainer to="/triggers">
                    <MenuItem>
                      Triggers
                      <div className="service-subtitle">API Endpoints</div>
                    </MenuItem>
                  </LinkContainer>
                  <MenuItem divider />
                  <LinkContainer to="/liteui">
                    <MenuItem>
                      LiteUI
                      <div className="service-subtitle">Instant Website</div>
                    </MenuItem>
                  </LinkContainer>
                  <MenuItem divider />
                  <LinkContainer to="/awallet">
                    <MenuItem>
                      aWallet
                      <div className="service-subtitle">
                        Create and Manage Wallets
                      </div>
                    </MenuItem>
                  </LinkContainer>
                </NavDropdown>
                <LinkContainer to="/docs">
                  <NavItem>Docs</NavItem>
                </LinkContainer>
                <NavItem target="_blank" href="/app/explore">
                  <img className="explore-icon" src={ExploreIcon} />
                </NavItem>
                {isAuthenticated && subscription && FeatureFlags.Paywall ? (
                  <LinkContainer to="/account/subscription">
                    <NavItem>
                      {!subscription ? "Upgrade Now!" : subscription.name}
                    </NavItem>
                  </LinkContainer>
                ) : null}
              </Nav>
              <Nav pullRight>
                <NavItem
                  tag="a"
                  href="https://medium.com/atra-blockchain-services/tagged/release-notes"
                  target="_blank"
                >
                  What's New?
                </NavItem>
                <NavDropdown title="Community" id="community">
                  <MenuItem
                    tag="a"
                    href="https://twitter.com/atrablockchain"
                    target="_blank"
                  >
                    Twitter
                  </MenuItem>

                  <MenuItem divider />
                  <MenuItem
                    tag="a"
                    href="https://www.reddit.com/r/AtraBlockchain/"
                    target="_blank"
                  >
                    Reddit
                  </MenuItem>

                  <MenuItem divider />
                  <MenuItem
                    tag="a"
                    href="https://medium.com/atra-blockchain-services"
                    target="_blank"
                  >
                    Medium
                  </MenuItem>
                </NavDropdown>
                <NavDropdown title="Support" id="support">
                  <MenuItem
                    onClick={() => history.push("/docs/support/feedback")}
                  >
                    Feedback
                  </MenuItem>
                  <MenuItem divider />
                  <MenuItem
                    onClick={() => history.push("/docs/support/report-issue")}
                  >
                    Report An Issue
                  </MenuItem>
                  <MenuItem divider />
                  <MenuItem onClick={() => history.push("/docs/support/faq")}>
                    FAQ
                  </MenuItem>
                </NavDropdown>
                {isAuthenticated ? (
                  <NavDropdown title={username} id="account">
                    <LinkContainer to="/account">
                      <MenuItem>Account</MenuItem>
                    </LinkContainer>
                    <MenuItem divider />
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </NavDropdown>
                ) : (
                  <LinkContainer to="/login">
                    <MenuItem>Login</MenuItem>
                  </LinkContainer>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div className="mobile-right-spacer"></div>
      </div>
    </div>
  );
};

class DesktopNav extends Component {
  render() {
    const toggleMobileNav = () => {
      if (this.props.nav.open) {
        this.props.closeNav();
      } else {
        this.props.openNav();
      }
    };
    return (
      <AtraNav
        fullwidth={this.props.fullwidth}
        username={this.props.account.user.username}
        handleLogout={this.props.handleLogout}
        history={this.props.history}
        toggleMobileNav={toggleMobileNav}
        isAuthenticated={this.props.auth.isAuthenticated}
        closeMobileNav={this.props.closeNav}
        subscription={this.props.account.user.subscription}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nav: state.nav,
    account: state.account,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openNav: (data) => dispatch({ type: "UPDATE_NAV_OPEN", data }),
    closeNav: (data) => dispatch({ type: "UPDATE_NAV_CLOSED", data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(DesktopNav);
