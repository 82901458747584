import React, { Component } from "react";
import { Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';

import AtraAPI from '../../../apiv2';
import Methods from '../methods/view';
import EnvMethods from '../../../components/Environments/methods';

import Tabs from "../components/Tabs/Tabs";
import NewRecordModal from "../components/NewRecordModal";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { ViewDetails } from "../../../components/ServiceView";

import LoaderButton from "../../../components/Buttons/LoaderButton";

const PointerColumns = ({
  pointers
}) => {
  if(pointers.length){
    return (
      <div className='view-details-container'>
        <div className='view-details'>
          <h4>Pointer Columns</h4>
          {pointers.map((column,i)=>{
            return (
              <div key={i}>
                dTable: <Link to={`/dtables/view/${column.tableId}`}>{column.name}</Link>
              </div>
            )
          })}
        </div>
      </div>
    )
  }else{
    return null;
  }

}

const Archive = ({
  onClick,
  loading,
  archived
}) => {
  if(archived){
    return (
      <LoaderButton
        isLoading={loading}
        loadingText={'Unarchiving'}
        disabled={loading}
        className='secondary-button'
        onClick={()=>onClick(false)}
        text={'Unarchive'}
      />
    )
  }else{
    return (
      <LoaderButton
        isLoading={loading}
        loadingText={'Archiving'}
        disabled={loading}
        className='secondary-button'
        onClick={()=>onClick(true)}
        text={'Archive'}
      />
    )
  }
}

class dTablesView extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    this.props.updateEnvironmentsEnabled({ enabled: false });
  }

  componentDidMount = () => {
    this.start();
  }

  componentWillUnmount = () => {
    this._mounted = false;
    this.props.clearState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.start();
    }
  }

  start = () => {
    this._mounted = true;
    this.props.clearState();
    //const tableId = this.props.match.params[0];
    const tableId = this.props.match.params.id;
    this.props.updateTableId({ value: tableId });
    AtraAPI.GetTable(tableId).then((table) => {
      this.props.updateTable({ value: table });
      this.props.updateLoadingInfo({ value: false });
    },(err) => {

    });
  }

  handleChange = event => {
    const { id, value } = event.target;
    this.props.updateInput({ value: { id, value } });
  };

  handleShowNewRecordModal = event => {
    this.props.showNewRecordModal({ value: true, timezone: this.props.account.user.timezone });
  };
  handleCloseNewRecordModal = event => {
    this.props.showNewRecordModal({ value: false, timezone: this.props.account.user.timezone });
  };

  handleBack = event => {
    this.props.history.push("/");
  };

  handleArchive = async (archive) => {
    this.props.updateArchiving({ value: true });
    AtraAPI.ArchiveResource('dtables', this.props.dTablesView.table.id, archive).then(res=>{
      this.props.updateArchiving({ value: false });
      let table = {...this.props.dTablesView.table, archived: archive};
      this.props.updateTable({ value: table });
    },err=>{
      console.log(err);
      this.props.updateArchiving({ value: false });
    });

  }

  render() {
    const breadcrumbs = [];
    breadcrumbs.push({ text: 'dTables', to: '/dtables', icon: "dtables"  });
    breadcrumbs.push({ text: this.props.dTablesView.table.name, to: `/dtables/view/${this.props.dTablesView.table.id}` });

    return (
      <div className="dtables-view-container container">

          <BreadCrumbs
            links={breadcrumbs}
          />

          <ViewDetails
            loading={this.props.dTablesView.loadingInfo}
            instance={this.props.dTablesView.table}
            actionButton={
              {
                handleClick: this.handleShowNewRecordModal,
                text: 'New Record'
              }
            }
          />

          <PointerColumns
            pointers={this.props.dTablesView.table.columns.filter( c => c.type === 'pointer' )}
             />

          <Archive
            onClick={this.handleArchive}
            loading={this.props.dTablesView.archiving}
            archived={this.props.dTablesView.table.archived}
          />

          <NewRecordModal />

          {!this.props.dTablesView.loadingInfo ?
          <Tabs />
          : null }

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dTablesView: state.dTablesView,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return {
      ...Methods({dispatch}),
      ...EnvMethods({dispatch})
    }
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(dTablesView));
