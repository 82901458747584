import React from "react";
import { CreateWeb3Object } from "../components/Web3";

const GetRecordById = (recordAddress, table) => {
  return new Promise((resolve, reject) => {
    try {
      var web3 = new CreateWeb3Object();
      let contractInstance = new web3.eth.Contract(JSON.parse(table.abi), table.goerli);

      contractInstance.methods.GetById(recordAddress).call().then(res=>{
        console.log(res);
        resolve(res);
      },err=>{
        console.log(err);
        reject(err);
      })
    } catch (err) {
      reject(err);
    }

  });
}

const GetRecordCount = (table) => {
  return new Promise((resolve, reject) => {
    try {
      var web3 = new CreateWeb3Object();
      let contractInstance = new web3.eth.Contract(JSON.parse(table.abi), table.goerli);
      contractInstance.methods.GetLength().call().then(res=>{
        resolve(res);
      },err=>{
        console.log(err);
        reject(err);
      })
    } catch (err) {
      reject(err);
    }

  });
}

export { GetRecordById, GetRecordCount };
