
class DefaultState {
  constructor(isAuthenticated = false,isAuthenticating = true){
    this.isAuthenticated = isAuthenticated;
    this.isAuthenticating = isAuthenticating
  }
}

export default (state = new DefaultState(), action) => {
  switch (action.type) {
    case 'AUTH_USER_LOGOUT':
      return new DefaultState(false, false);
    break;
    case 'AUTH_USER_LOGIN':
      return {...state};
    break;
    case 'AUTH_UPDATE_IS_AUTHENTICATING':
      return {...state, isAuthenticating: action.data.value };
    case 'AUTH_UPDATE_IS_AUTHENTICATED':
      return {...state, isAuthenticated: action.data.value };
    default:
      return state;
  }
};
