import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../../components/Buttons/LoaderButton";
import Logo from "../../components/Branding/Logo";
import { forgotPassword, login } from "./actions/actionTypes";
import NotificationActions from "../../components/Notifications/actions/notifications";
import passwordvalidator from "./validation/password-validator";
import pwdErrMssgs from "./data/passwordErrorMessages";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.props.resetState();
  }

  validationStateNewPassword() {
    if (
      this.props.forgotPassword.newPasswordErrors.length === 0 &&
      this.props.forgotPassword.newPassword.length > 0
    ) {
      return "success";
    }
    return this.props.forgotPassword.newPasswordErrors.length > 0
      ? "error"
      : null;
  }

  validationStateConfirmNewPassword() {
    if (
      this.props.forgotPassword.confirmNewPassword.length === 0 &&
      this.props.forgotPassword.newPassword.length === 0
    ) {
      return null;
    }
    return this.props.forgotPassword.confirmNewPassword ===
      this.props.forgotPassword.newPassword &&
      this.props.forgotPassword.confirmNewPassword.length > 0
      ? "success"
      : "error";
  }

  /* Password validation and matching */
  validatePassword(password) {
    Object.entries(pwdErrMssgs.rules).forEach(rule => {
      passwordvalidator[rule[0]](password.split(""))
        ? this.props.removeNewPasswordErrors({ value: rule[1] })
        : this.props.updateNewPasswordErrors({ value: rule[1] });
    });
  }

  validatePasswordsMatch(value, originInputField) {
    if (value === originInputField) {
      this.props.updateConfirmNewPasswordError({ value: "" });
    } else if (value.length > 0) {
      this.props.updateConfirmNewPasswordError({
        value: pwdErrMssgs.match
      });
    }
  }

  /* handle form changes */
  handleEmailChange = event => {
    this.props.updateEmailError({ value: "" });
    this.props.updateEmail({ value: event.target.value });
  };

  handleConfirmationCodeChange = event => {
    this.props.updateConfirmationCodeError({ value: "" });
    this.props.updateConfirmationCode({ value: event.target.value });
  };

  handleNewPasswordChange = event => {
    this.props.clearNewPasswordErrors();
    this.props.updateNewPassword({ value: event.target.value });
    this.validatePassword(event.target.value);
    this.validatePasswordsMatch(
      event.target.value,
      this.props.forgotPassword.confirmNewPassword
    );
  };

  handleConfirmNewPasswordChange = event => {
    this.props.updateConfirmNewPassword({ value: event.target.value });
    this.validatePasswordsMatch(
      event.target.value,
      this.props.forgotPassword.newPassword
    );
  };

  handleSendCodeSubmit = async event => {
    event.preventDefault();
    this.props.updateLoading({ value: true });
    try {
      Auth.forgotPassword(this.props.forgotPassword.email.toLowerCase()).then(() => {
          this.props.updateShowState({ value: "reset" });
          this.props.updateLoading({ value: false });
        }).catch(err => {
          this.props.updateLoading({ value: false });
          err.message
            ? this.props.updateEmailError({ value: err.message })
            : this.props.updateEmailError({ value: err });
        });
    } catch (e) {
      this.props.updateLoading({ value: false });
    }
  };

  handleChangePasswordSubmit = async event => {
    event.preventDefault();

    this.props.updateLoading({ value: true });

    if (!this.props.forgotPassword.confirmNewPasswordError) {
      if (!this.props.forgotPassword.confirmationCode) {
        this.props.updateConfirmationCodeError({
          value: "Please enter the confirmation code."
        });
      } else {
        try {
          // Collect confirmation code and new password, then
          this.props.updateLoading({ value: true });
          Auth.forgotPasswordSubmit(
            this.props.forgotPassword.email.toLowerCase(),
            this.props.forgotPassword.confirmationCode,
            this.props.forgotPassword.newPassword
          ).then(() => {
            this.props.updateLoading({ value: true });
            this.props.clearForgotPasswordState();
            this.props.notificationsAddPopupMessage({
              message: 'Success! - New Password Set',
              remove: (id)=>{this.props.notificationsRemovePopupMessage({ value: id })}
            });
            this.props.history.push("/login");
          }).catch(err => {
            if (err && err === "Code cannot be empty") {
              this.props.updateConfirmationCodeError({ value: err });
            } else if (
              (err && err.code && err.code === "ExpiredCodeException") ||
              (err && err.code && err.code === "CodeMismatchException")
            ) {
              this.props.updateConfirmationCodeError({ value: err.message });
            }
          });
        } catch (e) {
          this.props.clearNewPasswordErrors();
          this.props.updateNewPasswordErrors({ value: e.message });
        }
      }
    }
    return this.props.updateLoading({ value: false });
  };

  renderEnterEmailForm() {
    return (
      <form onSubmit={this.handleSendCodeSubmit}>
          <h3>Forgot Password?</h3>
          <FormGroup
            controlId="email"
            bsSize="large"
            validationState={
              this.props.forgotPassword.emailError ? "error" : null
            }
          >
            <ControlLabel className="label">Email</ControlLabel>
            <FormControl
              className="input"
              type="email"
              placeholder='enter email'
              value={this.props.forgotPassword.email}
              onChange={this.handleEmailChange}
              autoComplete="email"
            />
            <FormControl.Feedback />
            <HelpBlock key={1}>
              {this.props.forgotPassword.emailError}
            </HelpBlock>
          </FormGroup>
          <div className="flex-center-container">
            <LoaderButton
              block
              className="button"
              bsSize="large"
              type="submit"
              isLoading={this.props.forgotPassword.isLoading}
              text="Send Reset Code"
              loadingText="Sending Code"
            />
          </div>
        </form>
    );
  }

  renderResetForm() {
    return (
      <form autoComplete="off" onSubmit={this.handleChangePasswordSubmit}>
        <h3>Set New Password</h3>
        <FormGroup
          controlId="confirmationCode"
          validationState={
            this.props.forgotPassword.confirmationCodeError ? "error" : null
          }
        >
          <ControlLabel className="label">Confirmation Code</ControlLabel>
          <FormControl
            className="input"
            autoComplete="confirmation-code"
            type="text"
            placeholder='enter code'
            value={this.props.forgotPassword.confirmationCode}
            onChange={this.handleConfirmationCodeChange}
          />
          <HelpBlock>
            {this.props.forgotPassword.confirmationCodeError ||
              "Please check your email for the code."}
          </HelpBlock>
        </FormGroup>
        <FormGroup
          controlId="newPassword"
          validationState={this.validationStateNewPassword()}
        >
          <ControlLabel className="label">New Password</ControlLabel>
          <FormControl
            className="input"
            type="password"
            placeholder='enter new password'
            autoComplete="atra-new-password"
            value={this.props.forgotPassword.newPassword}
            onChange={this.handleNewPasswordChange}
          />
          <FormControl.Feedback />
          <ul>
            {this.props.forgotPassword.newPasswordErrors.map((error, index) => {
              return (
                <HelpBlock bsStyle="danger" key={index}>
                  {error}
                </HelpBlock>
              );
            })}
          </ul>
        </FormGroup>
        <FormGroup
          controlId="newPasswordConfirm"
          validationState={this.validationStateConfirmNewPassword()}
        >
          <ControlLabel className="label">Confirm New Password</ControlLabel>
          <FormControl
            className="input"
            type="password"
            placeholder='confirm password'
            autoComplete="atra-new-new-password"
            value={this.props.forgotPassword.confirmNewPassword}
            onChange={this.handleConfirmNewPasswordChange}
          />
          <FormControl.Feedback />
          <HelpBlock bsStyle="danger">
            {this.props.forgotPassword.confirmNewPasswordError}
          </HelpBlock>
        </FormGroup>
        <div className="flex-center-container">
          <LoaderButton
            block
            className="button"
            bsSize="large"
            type="submit"
            isLoading={this.props.forgotPassword.isLoading}
            text="Submit"
            loadingText="Verifying"
          />
        </div>
      </form>
    );
  }

  render() {
    return (

      <div className="auth">
        <div className="auth-container-wrapper">
          <Logo />
            <div className="auth-container">
              {this.props.forgotPassword.showState === "enterEmail"
                ? this.renderEnterEmailForm()
                : this.renderResetForm()}
              <div className="account-links-container">
                <Link to="login" className="purple-link">
                  Return to Log In
                </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    forgotPassword: state.forgotPassword,
    notifications: state.notifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetState: () =>dispatch({ type: forgotPassword.FORGOTPASSWORD_DEFAULT_STATE }),
    /* forms */
    updateEmail: data =>dispatch({ type: forgotPassword.FORGOTPASSWORD_UPDATE_EMAIL, data }),
    updateNewPassword: data =>dispatch({type: forgotPassword.FORGOTPASSWORD_UPDATE_NEW_PASSWORD,data}),
    updateConfirmNewPassword: data =>dispatch({type: forgotPassword.FORGOTPASSWORD_UPDATE_CONFIRM_NEW_PASSWORD,data}),
    updateConfirmationCode: data =>dispatch({type: forgotPassword.FORGOTPASSWORD_UPDATE_CONFIRMATION_CODE,data}),
    /* errors */
    updateEmailError: data =>dispatch({type: forgotPassword.FORGOTPASSWORD_UPDATE_EMAIL_ERROR,data}),
    updateNewPasswordErrors: data =>dispatch({type: forgotPassword.FORGOTPASSWORD_UPDATE_NEW_PASSWORD_ERRORS,data}),
    removeNewPasswordErrors: data => dispatch({type: forgotPassword.FORGOTPASSWORD_REMOVE_NEW_PASSWORD_ERRORS,data}),
    clearNewPasswordErrors: data => dispatch({type: forgotPassword.FORGOTPASSWORD_CLEAR_NEW_PASSWORD_ERRORS,data}),
    updateConfirmNewPasswordError: data => dispatch({type: forgotPassword.FORGOTPASSWORD_UPDATE_CONFIRM_NEW_PASSWORD_ERROR,data}),
    updateConfirmationCodeError: data => dispatch({ type: forgotPassword.FORGOTPASSWORD_UPDATE_CONFIRMATION_CODE_ERROR,data}),
    /* etc */
    updateShowState: data => dispatch({ type: forgotPassword.FORGOTPASSWORD_UPDATE_SHOW_STATE, data }),
    updateAlertMessage: data => dispatch({ type: login.LOGIN_UPDATE_ALERT_MESSAGE, data }),
    updateLoading: data => dispatch({ type: forgotPassword.FORGOTPASSWORD_UPDATE_IS_LOADING, data }),
    clearForgotPasswordState: data => dispatch({ type: forgotPassword.FORGOTPASSWORD_CLEAR_FORGOTPASSWORD_STATE, data}),
    onSuccess: data => dispatch({ type: forgotPassword.FORGOTPASSWORD_ON_SUCCESS, data }),

    // Notifications
    notificationsAddPopupMessage: data => dispatch({ type: NotificationActions.NOTIFICATIONS_ADD_POPUP_MESSAGE, data }),
    notificationsRemovePopupMessage: data => dispatch({ type: NotificationActions.NOTIFICATIONS_REMOVE_POPUP_MESSAGE, data })

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
