import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from '../Layouts/LayoutWithNav';

export default ({ component: Component, props: Props, sidebar, ...rest }) => (
  <Route
    {...rest}
   render={() =>
       <Layout className={rest.className} sidebar={sidebar}>
          <Component {...Props} />
       </Layout>
   }
 />

);
