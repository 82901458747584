import React from 'react';


const Feedback = ({

}) => {
  return (
    <div className='doc-section-content-container'>
      <h2>Feedback</h2>
      <p>Atra loves communicating with our users, please send us your feedback. If you have a feature request or anything thoughts on improvements send them over.</p>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSdfKQ6W7lLFMIXs1xjJWrDQTXFHKTsqDCKTJ__BLhcjZLKVRg/viewform" target='_blank'>Feedback Form</a>
    </div>
  )
}

export default Feedback;
