import Actions from '../actions/notifications';

class DefaultState {
  constructor(messages = []){
    this.popupMessages = messages;
  }
}
class PopUpNotificationMessage {
  constructor(message, type = 'success'){
    this.type = type;
    this.id = new Date().getTime();
    this.message = message;
  }
}

export default (state = new DefaultState(), action) => {
  switch (action.type) {
    case Actions.NOTIFICATIONS_ADD_POPUP_MESSAGE:
      var newState = {...state};
      const message = new PopUpNotificationMessage(action.data.message, action.data.type);
      newState.popupMessages.push(message);
      setTimeout(function(){
        action.data.remove(message.id);
      },5000);
      return newState;
    case Actions.NOTIFICATIONS_REMOVE_POPUP_MESSAGE:
      var newState = {...state};
      newState.popupMessages.splice(newState.popupMessages.findIndex(p => p.id === action.data.value), 1);
      return newState;
    default:
      return state;
  }
};
