import { API } from "aws-amplify";
import FeatureFlags from "../components/FeatureFlags";

const GetBilling = () => {
  return new Promise((resolve, reject) => {
    try {
      const { StripeProd } = FeatureFlags;
      let data = {
        response: true,
        body: null,
        queryStringParameters: {
          isProd: StripeProd,
        },
      };

      API.get("billing", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const UpdateBilling = (billingData) => {
  return new Promise((resolve, reject) => {
    try {
      const { StripeProd } = FeatureFlags;
      let data = {
        response: true,
        body: { ...billingData, isProd: StripeProd },
      };

      API.put("billing", "", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const Checkout = ({ checkoutData }) => {
  return new Promise((resolve, reject) => {
    try {
      const { StripeProd } = FeatureFlags;
      let data = {
        response: true,
        body: { ...checkoutData, isProd: StripeProd },
      };

      API.post("billing/checkout", "", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

export default {
  Checkout,
  GetBilling,
  UpdateBilling,
};
