import React from 'react';
import { Link } from "react-router-dom";
import { Glyphicon, Jumbotron, Button } from "react-bootstrap";

const DashboardGridNoContent = ({
  title,
  description,
  buttonText,
  onClick,
  learnMoreClick
}) => {
  return (
    <Jumbotron className='dashboard-no-content-container'>
      <h1>{title}</h1>
      {description}
      <div className='action-button-container'>
        {learnMoreClick ? <Button className='learn-more' onClick={learnMoreClick}>Learn More</Button> : null}
        <Button onClick={onClick}>{buttonText}</Button>
      </div>
    </Jumbotron>
  )
}

export default DashboardGridNoContent;
