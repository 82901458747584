import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Alert,
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";

import Methods from "../methods/view";
import AtraAPI from "../../../apiv2";
import NotificationMethods from "../../../components/Notifications/methods";
import AccountMethods from "../../account/methods";

import {
  ConvertEthToWei,
  ConvertWeiToEth,
  ToHex,
  IsAddress,
  CheckAddressChecksum,
} from "../../../helpers/Eth";
import { GetEpochFromTimeZoneDate } from "../../../helpers/Date";

import PriceInput from "../../../components/PriceInput/PriceInput";
import DateInput from "../../../components/DateInput/DateInput";
import AddressInput from "../../../components/AddressInput/AddressInput";
import LoaderButton from "../../../components/Buttons/LoaderButton";

import moment from "moment-timezone";

const RenderError = ({ value }) => {
  if (value && value.status && !value.status.valid) {
    return <div className="value-error">{value.status.message}</div>;
  } else {
    return null;
  }
};
const RenderInput = ({ column, handleChange, index, value }) => {
  const childProps = {
    "data-index": index,
    "data-valuetype": column.type,
  };

  let dataSets = [];
  dataSets["valueType"] = column.type;
  dataSets["index"] = index;

  switch (column.type) {
    case "price":
      return (
        <PriceInput
          value={value.value}
          onChange={handleChange}
          childProps={childProps}
        />
      );
      break;
    case "date":
      return (
        <DateInput
          datasets={dataSets}
          value={value.value}
          onChange={handleChange}
        />
      );
      break;
    case "address":
      return (
        <AddressInput
          childProps={childProps}
          value={value.value}
          onChange={handleChange}
          datasets={dataSets}
        />
      );
      break;
    default:
      return (
        <FormControl
          type="text"
          data-valuetype={column.type}
          data-index={index}
          data-name={column.name}
          placeholder={column.type}
          onChange={handleChange}
        />
      );
      break;
  }
};

class NewRecordModal extends Component {
  constructor(props) {
    super(props);
  }

  resetData = () => {
    let record = [];
    record = this.props.dTablesView.table.columns.map((column, i) => {
      return {
        value: null,
        status: {
          valid: true,
          message: null,
        },
      };
    });
    this.props.updateNewRecord({ value: record });
    this.props.updateNewRecordModalError({ value: "" });
  };

  formatRecordData = (record) => {
    return record.map((value, i) => {
      //based on the type of input format
      switch (this.props.dTablesView.table.columns[i].type) {
        case "price":
          const wei = ConvertEthToWei(value.value);
          return ToHex(wei);
        case "number":
          return ToHex(parseInt(value.value));
        case "date":
          return GetEpochFromTimeZoneDate(
            value.value,
            this.props.account.user.timezone.code
          );
        default:
          return value.value;
      }
    });
  };

  validate = (record) => {
    let valid = true;
    record.map((value, i) => {
      switch (this.props.dTablesView.table.columns[i].type) {
        case "price":
          break;
        case "number": {
          //is a number
          if (!value.value) {
            this.props.updateNewRecordValueStatus({
              index: i,
              valid: false,
              message: "Number Required",
            });
            valid = false;
          } else if (Number.isNaN(parseInt(value.value))) {
            this.props.updateNewRecordValueStatus({
              index: i,
              valid: false,
              message: "Number Required",
            });
            valid = false;
          } else {
            //Valid
            this.props.updateNewRecordValueStatus({
              index: i,
              valid: true,
              message: null,
            });
          }
          break;
        }
        case "date": {
          break;
        }
        case "address": {
          if (!IsAddress(value.value)) {
            this.props.updateNewRecordValueStatus({
              index: i,
              valid: false,
              message: "Invalid Address",
            });
            valid = false;
          } else if (!CheckAddressChecksum(value.value)) {
            this.props.updateNewRecordValueStatus({
              index: i,
              valid: false,
              message: "Invalid Address Checksum",
            });
            valid = false;
          } else {
            this.props.updateNewRecordValueStatus({
              index: i,
              valid: true,
              message: null,
            });
          }
          break;
        }
      }
    });

    return valid;
  };

  handleSubmit = (event) => {
    if (!this.validate(this.props.dTablesView.newRecord.record)) {
      console.log("not valid");
    } else {
      const record = this.formatRecordData(
        this.props.dTablesView.newRecord.record
      );
      this.props.updateLoadingNewRecordSubmit({ value: true });
      this.props.updateNewRecordModalError({ value: "" });

      AtraAPI.InsertRecord(this.props.dTablesView.table.id, record)
        .then((res) => {
          // Keep track of records that are sent from this page,
          // and when they are not pending anymore pop a notification and remove from the list
          this.props.addPendingTransaction({
            value: res.recordId,
          });
          this.resetData();
          this.close();
          this.props.updateLoadingNewRecordSubmit({ value: false });
          this.props.notificationsAddPopupMessage({
            message: "Success! - New Record Inserted",
            remove: (id) => {
              this.props.notificationsRemovePopupMessage({ value: id });
            },
          });
        })
        .catch((err) => {
          if (err == "limit-reached") {
            this.props.updateShowUsageAlertModal({ value: true });
          }
          this.props.updateNewRecordModalError({ value: err });
          this.props.updateLoadingNewRecordSubmit({ value: false });
        });
    }
  };

  close = (event) => {
    this.resetData();
    this.props.showNewRecordModal({
      value: false,
      timezone: this.props.account.user.timezone,
    });
  };

  handleChange = (event) => {
    let record = [...this.props.dTablesView.newRecord.record];
    let value = event.target.value;
    record[event.target.dataset["index"]].value = value;
    this.props.updateNewRecord({ value: record });
  };

  render() {
    return (
      <Modal
        backdrop="static"
        show={this.props.dTablesView.newRecord.show}
        onHide={this.close}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">New Record</Modal.Title>
        </Modal.Header>
        <Modal.Body className="new-record-modal-container">
          {this.props.dTablesView.newRecord.error ? (
            <Alert bsStyle="danger">
              {this.props.dTablesView.newRecord.error}
            </Alert>
          ) : (
            ""
          )}
          {this.props.dTablesView.table.columns.map((column, i) => (
            <FormGroup key={column.name}>
              <ControlLabel>{column.name}</ControlLabel>
              <RenderInput
                value={this.props.dTablesView.newRecord.record[i]}
                column={column}
                handleChange={this.handleChange}
                index={i}
              />
              <RenderError value={this.props.dTablesView.newRecord.record[i]} />
            </FormGroup>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Link to="#" className="link-1" onClick={this.close}>
            Cancel
          </Link>
          <LoaderButton
            block
            className="secondary-button"
            type="submit"
            isLoading={this.props.dTablesView.newRecord.loading}
            text="Insert Record"
            loadingText="Inserting…"
            onClick={this.handleSubmit}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dTablesView: state.dTablesView,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...Methods({ dispatch }),
    ...NotificationMethods({ dispatch }),
    ...AccountMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewRecordModal);
