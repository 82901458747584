import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LiteForm, LiteList, LiteText, LiteChat } from "../index";
import FeatureFlags from '../../../../../components/FeatureFlags';


const RenderUIElements = ({
  mode,
  view,
  UIElementHandlers,
  isDetailView,
  history,
  location
}) => {
  if(mode === 'public'){
    return (
      <div>
        {view.elements.map((element,i)=>{
          switch(element.type){
            case 'form':
             return <LiteForm
               mode={mode}
               key={element.id}
               remove={UIElementHandlers.Element.Delete}
               edit={UIElementHandlers.LiteForm.Configure}
               handlers={UIElementHandlers.LiteForm}
               element={element}
               history={history}
               location={location}
               />;
            case 'list':
             return <LiteList
               mode={mode}
               key={element.id}
               remove={UIElementHandlers.Element.Delete}
               ShowEditModal={UIElementHandlers.LiteList.Configure}
               element={element}
               history={history}
               location={location}
               />;
           case 'text':
            return <LiteText
              mode={mode}
              key={element.id}
              remove={UIElementHandlers.Element.Delete}
              edit={UIElementHandlers.LiteText.Configure}
              element={element}
              history={history}
              location={location}
              />;
            case 'chat':
              if(FeatureFlags.Chat){
                return <LiteChat
                  mode={mode}
                  key={element.id}
                  remove={UIElementHandlers.Element.Delete}
                  edit={UIElementHandlers.LiteChat.Configure}
                  element={element}
                  history={history}
                  location={location}
                  />;
              }else{
                return null;
              }
            default:
              return null;
          }
        })}
      </div>
    )
  }else{
    return (
      <div>
        {view.elements.map((element,i)=>{
          switch(element.type){
            case 'form':
             return <Draggable draggableId={element.id} key={element.id} index={i}>
                 {(provided, snapshot) => (
                   <div
                     ref={provided.innerRef}
                     {...provided.draggableProps}
                     >
                     <LiteForm
                       dragHandleProps={provided.dragHandleProps}
                       mode={mode}
                       key={element.id}
                       remove={UIElementHandlers.Element.Delete}
                       edit={UIElementHandlers.LiteForm.Configure}
                       handlers={UIElementHandlers.LiteForm}
                       element={element}
                       history={history}
                       />
                   </div>
                  )}
              </Draggable>;
            case 'list':
             return <Draggable draggableId={element.id} key={element.id} index={i}>
               {(provided, snapshot) => (
                 <div
                   ref={provided.innerRef}
                   {...provided.draggableProps}
                   >
                   <LiteList
                   dragHandleProps={provided.dragHandleProps}
                   mode={mode}
                   key={element.id}
                   remove={UIElementHandlers.Element.Delete}
                   ShowEditModal={UIElementHandlers.LiteList.Configure}
                   element={element}
                   history={history}
                   />
                 </div>
           )}
             </Draggable>;
           case 'text':
            return <Draggable draggableId={element.id} key={element.id} index={i}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  >
                  <LiteText
                  dragHandleProps={provided.dragHandleProps}
                  mode={mode}
                  key={element.id}
                  remove={UIElementHandlers.Element.Delete}
                  edit={UIElementHandlers.LiteText.Configure}
                  element={element}
                  history={history}
                  />
                </div>
              )}
            </Draggable>;
            case 'chat':
              if(FeatureFlags.Chat){
                return <Draggable draggableId={element.id} key={element.id} index={i}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      >
                      <LiteChat
                      dragHandleProps={provided.dragHandleProps}
                      mode={mode}
                      key={element.id}
                      remove={UIElementHandlers.Element.Delete}
                      edit={UIElementHandlers.LiteChat.Configure}
                      element={element}
                      history={history}
                      />
                    </div>
                  )}
                </Draggable>;
              }else{
                return null;
              }
            
            default:
              return null;
          }
        })}
      </div>
    )
  }

}

export default RenderUIElements;
