import React from "react";
import { Next } from "../Utils";
import Logo from "../../../../../components/Branding/ServiceIcons/litewallet.svg";

const LiteUI = ({}) => {
  return (
    <div className="doc-section-content-container">
      <div className="service-branding-container">
        <h2>aWallet</h2>
        <img src={Logo} />
      </div>

      <section>
        <h3>Overview</h3>
        <p>Generate, fund, and distribute wallets in bulk</p>
        <p>aWallet allows a user to manage multiple wallets at once.</p>
      </section>

      <section>
        <h3>Quick Start</h3>
        <p>Navigate to the aWallet dashboard and click create aWallet.</p>
      </section>

      <section>
        <h3>Use Case Examples</h3>
        <h4>Test Wallets</h4>
        <p>
          Create an aWallet with the type of AccessPass to begin testing your
          dapp from multiple different views.
        </p>
        <h4>User Onboarding</h4>
        <p>
          There is no need for a software or hardware wallet, assign wallet to a
          user's email and that's all they need to get strated using your dapp.
        </p>
      </section>

      <Next title={"dTables API"} link={"/docs/api/dtables"} />
    </div>
  );
};

export default LiteUI;
