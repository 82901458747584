import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DropdownButton,
  MenuItem,
  ControlLabel,
  FormControl
} from "react-bootstrap";
import { ModalContainer } from '../Modals';
import AtraAPI from '../../apiv2';
import Methods from './methods';
import AccountMethods from '../../containers/account/methods';
import FeatureFlags from '../FeatureFlags';

const EnvironmentsBarElement = ({
  handleAdd,
  handleChange,
  selectedEnvironment,
  selectedType,
  environments,
  enabled
}) => {
  return (
    <div className='env-bar-container'>
      <DropdownButton
        id='env-select'
        title={selectedEnvironment}
        onSelect={handleChange}
        disabled={!enabled}
      >
        <MenuItem eventKey={''}>Default Environment</MenuItem>
        {environments.map((env,i)=>{
          return <MenuItem key={i} eventKey={env.id}>{env.name}</MenuItem>
        })}
        <MenuItem divider />
        <MenuItem eventKey={'add_button'} onClick={handleAdd}>Add Environment +</MenuItem>
      </DropdownButton>
      <div className='env-network'>
        {selectedType}
      </div>
    </div>
  )
}

class EnvironmentsBar extends Component {
  constructor(props){
    super(props)
    this.state = {
      addEnvModal: {
        show: false
      },
      submitting: false,
      type: 'dev',
      name: ''
    }
    let envId = window.localStorage.getItem('activeEnvironment');
    this.getEnvironments(envId);
  }

  getEnvironments = async (env, reload = false) => {
    try {
      const environments = await AtraAPI.GetEnvironments();
      this.props.updateEnvironments({ environments });
      if(env){
        this.handleChange(env, true, reload);
      }
    }catch(err){
      console.log(err);
    }
  }

  handleAdd = (event) => {
    const addEnvModal = {...this.state.addEnvModal, show: true };
    this.setState({
      type: 'dev',
      name: ''
    })
    this.setState({addEnvModal});
  }

  handleClose = (event) => {
    const addEnvModal = {...this.state.addEnvModal, show: false };
    this.setState({
      type: 'dev',
      name: ''
    })
    this.setState({addEnvModal});
  }

  handleCreate = async (event) => {
    try {
      this.setState({
        submitting: true
      })
      // Send API request to create new ENV
      const env = await AtraAPI.CreateEnvironment({
        name: this.state.name,
        type: this.state.type
      });

      this.setState({
        type: 'dev',
        name: '',
        submitting: false
      })

      console.log(env);
      this.getEnvironments(env.id, true);
      this.handleClose();
      // Switch ENV to newly created one using the ID returned by request
    }catch(err){
      if(err == 'limit-reached'){
        this.props.updateShowUsageAlertModal({ value: true });
        this.setState({ submitting: false });
      }
      console.log(err);
    }
  }

  handleChange = (env, e, reload = true) => {
    if(e && env !== 'add_button'){
      console.log(env);
      let environments = this.props.environments.environments;
      environments = environments.map(e=>{
        return {
          ...e,
          active: e.id == env
        }
      })
      this.props.updateEnvironments({ environments });
      window.localStorage.setItem('activeEnvironment', env);
      if(reload){
        window.location.reload();
      }
    }
  }

  handleNameChange = event => {
    this.setState({name: event.target.value})
  }

  handleTypeChange = (event) => {
    this.setState({type: event.target.value})
  }

  render() {
    let active = this.props.environments.environments.find( e => e.active ) || { name: 'Default Environment', type: 'dev'};
    switch(active.type){
      case 'dev':
        active.type = 'Development';
      break;
      case 'prod':
        active.type = 'Production';
      break;
    }
    return (
      <div>
        <EnvironmentsBarElement
          handleChange={this.handleChange}
          handleAdd={this.handleAdd}
          selectedEnvironment={active.name}
          selectedType={active.type}
          environments={this.props.environments.environments}
          enabled={this.props.environments.enabled && !this.props.environments.loading}
         />
       <ModalContainer
         show={this.state.addEnvModal.show}
         title={'New Environment'}
         handleClose={this.handleClose}
         handleSave={this.handleCreate}
         saveButtonText={'Create'}
         isLoading={this.state.submitting}
        >
          <div>
            <ControlLabel>Name:</ControlLabel>
            <FormControl
              value={this.state.name}
              onChange={this.handleNameChange}
              type='text'
              />
            <ControlLabel>Ethereum Network:</ControlLabel>
            <FormControl
              value={this.state.type}
              componentClass="select"
              onChange={this.handleTypeChange}
            >
              <option value='dev'>Development</option>
              { FeatureFlags.Production ?
                  <option value='prod'>Production</option>
              : null}
            </FormControl>

          </div>
          <div className='env-type-description'>
            { this.state.type === 'prod' ?
                <p>Production Environments use the Ethereum Main Network. Charges for Ethereum will apply.</p>
               : null}
          </div>
        </ModalContainer>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    environments: state.environments,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...Methods({dispatch}),
    ...AccountMethods({dispatch})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnvironmentsBar);
