import React, { Component } from "react";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Alert
} from "react-bootstrap";
import LoaderButton from "../../components/Buttons/LoaderButton";
import { Link } from "react-router-dom";
import Logo from "../../components/Branding/Logo";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { login, signup } from "./actions/actionTypes";
import { UserLogin } from './helpers/login';
import AccountActions from '../../containers/account/actions/account-actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    this.props.resetState();
  }
  async componentDidMount() {
    this.props.updateLoading({ value: false });
    if (this.props.login.alertMessage) {
      setTimeout(() => {
        this.props.updateAlertMessage({ value: "" });
      }, 3000);
    }
    this._mounted = true;
  }
  async componentWillUnmount() {
    this._mounted = false;
  }

  handleEmailChange = event => {
    this.props.updateEmail_loginError({ value: "" });
    this.props.updateEmail({ value: event.target.value });
  };

  handlePasswordChange = event => {
    this.props.updatePassword_loginError({ value: "" });
    this.props.updatePassword({ value: event.target.value });
  };

  validateInput = id => {
    return (
      document.getElementById(id) &&
      document.getElementById(id).value &&
      document.getElementById(id).value.length > 0
    );
  };

  checkUserInputAndUpdate() {
    return new Promise((resolve, reject) => {
      let err = false;
      if (this._mounted) {
        if (this.validateInput("email")) {
          this.props.updateEmail({
            value: document.getElementById("email").value
          });
        } else {
          err = true;
          this.props.updateEmail_loginError({ value: "Email required." });
        }

        if (this.validateInput("password")) {
          this.props.updatePassword({
            value: document.getElementById("password").value
          });
        } else {
          err = true;
          this.props.updatePassword_loginError({ value: "Password required." });
        }
      }
      err ? reject() : resolve();
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.props.updateLoading({ value: true });
    this.props.clearErrors();
    this.checkUserInputAndUpdate().then(async res => {
        try {
          UserLogin({
            email: this.props.login.email.toLowerCase(),
            password: this.props.login.password,
            accountUpdateLoadingUser: this.props.accountUpdateLoadingUser,
            accountUpdateUser: this.props.accountUpdateUser,
            authUpdateIsAuthenticated: this.props.authUpdateIsAuthenticated,
            accountUpdateUserName: this.props.accountUpdateUserName,
            accountUpdateSubscription: this.props.accountUpdateSubscription
          }).then(res=>{
            this.props.history.push("/");
          }, err=>{
            this.handleSubmitError(err.code);
          });
        } catch (err) {
          console.log(err);
          this.handleSubmitError(err);
        }
      },
      err => {
        this.props.updateLoading({ value: false });
      }
    );
  };

  handleSubmitError(errorType) {
    switch (errorType) {
      case "NotAuthorizedException":
        this.props.updatePassword_loginError({
          value: "Incorrect email or password"
        });
        break;
      case "UserNotFoundException":
        this.props.updateEmail_loginError({
          value: "Email not associated with an account"
        });
        break;
      case "UserNotConfirmedException":
        this.props.emailNotConfirmed({
          email: this.props.login.email,
          message: "To complete account creation please confirm your email."
        });
        this.props.history.push("/signup");
        break;
      default:
        this.props.updatePassword_loginError({ value: "Error logging in" });
        break;
    }
    this.props.updateLoading({ value: false });
  }

  render() {
    const alert = (
      <Alert bsStyle="success">{this.props.login.alertMessage}</Alert>
    );
    return (
      <div className="auth">
        <div className="auth-container-wrapper">
          <Logo />
          <div className="auth-container">
            {this.props.login.alertMessage ? alert : null}
            <form onSubmit={this.handleSubmit}>
              <h3>Log In</h3>
              <FormGroup
                controlId="email"
                validationState={this.props.login.emailError ? "error" : null}
              >
                <ControlLabel className="label">Email</ControlLabel>
                <FormControl
                  className="input"
                  type="email"
                  placeholder='enter email'
                  value={this.props.login.email}
                  onChange={this.handleEmailChange}
                  autoComplete="email"
                />
                <FormControl.Feedback />
                <HelpBlock key={1} bsStyle="danger">
                  {this.props.login.emailError}
                </HelpBlock>
              </FormGroup>
              <FormGroup
                className="password-form"
                controlId="password"
                validationState={
                  this.props.login.passwordError ? "error" : null
                }
              >
                <ControlLabel className="label">Password</ControlLabel>
                <FormControl
                  className="input"
                  placeholder='enter password'
                  type="password"
                  value={this.props.login.password}
                  onChange={this.handlePasswordChange}
                  autoComplete="password"
                />
                <FormControl.Feedback />
                <HelpBlock key={1} bsStyle="danger">
                  {this.props.login.passwordError}
                </HelpBlock>
                <Link to="forgotpassword" className="forgot-password">
                  Forgot Password?
                </Link>
              </FormGroup>
              <div className="flex-center-container">
                <LoaderButton
                  block
                  className="button"
                  bsSize="large"
                  type="submit"
                  isLoading={this.props.login.isLoading}
                  text="Log In"
                  loadingText="Logging in"
                />
              </div>
              <div className="account-links-container">
                <Link to="signup" className="purple-link">
                  Create an account
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    login: state.login,
    auth: state.auth,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    emailNotConfirmed: data =>
      dispatch({ type: signup.SIGNUP_LOGIN_NOT_CONFIRMED, data }),
    clearErrors: () => dispatch({ type: login.LOGIN_CLEAR_ERRORS }),
    resetState: () => dispatch({ type: login.LOGIN_DEFAULT_STATE }),
    updateEmail: data => dispatch({ type: login.LOGIN_UPDATE_EMAIL, data }),
    updatePassword: data =>
      dispatch({ type: login.LOGIN_UPDATE_PASSWORD, data }),
    updateEmail_loginError: data =>
      dispatch({ type: login.LOGIN_UPDATE_EMAIL_ERROR, data }),
    updatePassword_loginError: data =>
      dispatch({ type: login.LOGIN_UPDATE_PASSWORD_ERROR, data }),
    updateLoading: data =>
      dispatch({ type: login.LOGIN_UPDATE_IS_LOADING, data }),
    updateAlertMessage: data =>
      dispatch({ type: login.LOGIN_UPDATE_ALERT_MESSAGE, data }),

      // Auth Actions
      userLogout: data => dispatch({ type: "AUTH_USER_LOGOUT", data }),
      authUpdateIsAuthenticating: data => dispatch({ type: "AUTH_UPDATE_IS_AUTHENTICATING", data }),
      authUpdateIsAuthenticated: data => dispatch({ type: "AUTH_UPDATE_IS_AUTHENTICATED", data }),

      // Account Action
      accountUpdateLoadingUser: data => dispatch({ type: AccountActions.UPDATE_LOADING_USER, data }),
      accountUpdateUserName: data => dispatch({ type: AccountActions.UPDATE_USERNAME, data }),
      accountUpdateUser: data => dispatch({ type: AccountActions.UPDATE_USER, data }),
      accountUpdateSubscription: data => dispatch({ type: AccountActions.UPDATE_USER_SUBSCRIPTION, data })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
