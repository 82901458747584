import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FormatDate } from "../../helpers/Date";

const SubscriptionPicker = ({
  subscription,
  plans,
  handlePlanSelect = () => {},
  startingOrderPlanId,
}) => {
  const [selectedOrderPlanId, setSelectedOrderPlanId] = useState(
    subscription.orderPlanId
  );

  const handlePlanChage = (orderPlanId) => {
    setSelectedOrderPlanId(orderPlanId);
    handlePlanSelect({
      orderPlanId,
    });
  };

  return (
    <div className="subscription-picker-container">
      <h3>Ethereum Goerli Testnet Plans</h3>
      <div className="pricing-options-container">
        {plans.map((plan, i) => {
          const isCurrentPlan = plan.orderPlanId == subscription.orderPlanId;
          const isSelectedPlan = plan.orderPlanId == selectedOrderPlanId;
          const isNextSubscription =
            plan.orderPlanId == subscription.pendingDowngradeOrderPlanId;
          return (
            <div
              key={i}
              className="plan-container"
              onClick={() => {
                handlePlanChage(plan.orderPlanId);
              }}
              value={plan.orderPlanId}
            >
              <div
                className={`plan-name-container ${
                  isCurrentPlan ? "current-plan" : null
                }`}
              >
                <div className="name-container">
                  <div
                    className={`plan-radio ${isSelectedPlan ? "active" : null}`}
                  />
                  <div className={`plan-name-text`}>{plan.name}</div>
                </div>

                <div className="plan-info-container">
                  {isNextSubscription && (
                    <div className="starting-text">
                      Starting: {FormatDate(subscription.pendingDowngradeDate)}
                    </div>
                  )}
                  {subscription.pendingDowngradeOrderPlanId &&
                    isCurrentPlan && (
                      <div className="ending-text">
                        Ending: {FormatDate(subscription.pendingDowngradeDate)}
                      </div>
                    )}
                  {isCurrentPlan &&
                    !subscription.pendingDowngradeOrderPlanId && (
                      <div>Current Plan</div>
                    )}
                </div>
              </div>
              <div className="plan-details-container">
                <div>{plan.environments}</div>
                <div className="plan-features">
                  {plan.features.map((feature, i) => {
                    if (i === plan.features.length - 1) {
                      return <span key={`plan-features${i}`}>{feature}</span>;
                    } else {
                      return <span key={`plan-features${i}`}>{feature}, </span>;
                    }
                  })}
                </div>
                <div>{plan.support}</div>
              </div>
              <div className="plan-price-container">
                {plan.priceString}
                {plan.priceString != "Free" && "/mo"}
              </div>
            </div>
          );
        })}
      </div>
      <div className="cancel-tip-container">
        <span>
          Upgrading is easy! Plus, you can cancel anytime by downgrading your
          account to the Free plan.
        </span>
      </div>
    </div>
  );
};

export default SubscriptionPicker;
