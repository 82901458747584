import React, { Component } from 'react';
import { connect } from "react-redux";

import { Modal, FormGroup, FormControl, ControlLabel, Button, Checkbox } from 'react-bootstrap';
import UIModals from './';
import LiteUIMethods from "../../methods";
import NotificationMethods from "../../../../components/Notifications/methods";

const ConfigureLiteFormModal = ({
  id,
  title,
  name,
  show,
  handleClose,
  handleSave,
  handleNameChange,
  handlePointerAutoFillChange,
  autofillPointer,
  autofillCompatible
}) => {

  return (
    <UIModals.ModalContainer
      title={title}
      handleClose={handleClose}
      handleSave={handleSave}
      show={show}
      >
      <section>
        <h3>Form Details</h3>
        <FormGroup controlId={'title'}>
          <ControlLabel>Name:</ControlLabel>
          <FormControl
            type='text'
            onChange={handleNameChange}
            defaultValue={name}
            />
        </FormGroup>
      </section>
      {autofillCompatible ?
        <section>
          <div className='filter-container'>
            <h3>Pointer Column Mapping</h3>
            <div>
              <Checkbox
                id='triggerRecordIdAutoFillCheckBox'
                checked={autofillPointer}
                onChange={handlePointerAutoFillChange}
                />
              <ControlLabel for={'triggerRecordIdAutoFillCheckBox'}>Auto Fill with Detail View Record Id</ControlLabel>

            </div>
          </div>
          <div>This setting will fill in the record Id for the pointer using the current detail view. It will also hide the input.</div>
        </section>
      : null}
    </UIModals.ModalContainer>
  );
}

class ConfigureLiteForm extends Component {

  Handlers = {
    NameChange: event => {
      this.props.updateEditFormName({
        value: event.target.value
      });
    },
    Save: (event) => {
      const selectedView = this.props.liteui.ux.selectedView;
      this.props.saveFormModalEdits({
        view: selectedView
      });
      this.props.updateShowEditFormModal({
        value: false
      });
    },
    Close: event => {
      this.props.updateShowEditFormModal({
        value: false
      });
    },
    AutoFillPointerChange: event => {
      this.props.updateShowEditFormAutofillPointer({
        value: !this.props.liteui.ux.showEditFormModal.element.config.autofillPointer
      })
    }
  }

  render(){
    if(this.props.liteui.ux.showEditFormModal.show){
      return (
        <ConfigureLiteFormModal
          show={this.props.liteui.ux.showEditFormModal.show}
          name={this.props.liteui.ux.showEditFormModal.element.title}
          id={this.props.liteui.ux.showEditFormModal.id}
          title='Configure Form'
          handleClose={this.Handlers.Close}
          handleSave={this.Handlers.Save}
          handleNameChange={this.Handlers.NameChange}
          handlePointerAutoFillChange={this.Handlers.AutoFillPointerChange}
          autofillPointer={this.props.liteui.ux.showEditFormModal.element.config.autofillPointer}
          autofillCompatible={this.props.liteui.ux.showEditFormModal.autofillCompatible}
        />
      )
    }else{
      return null;
    }

  }
}

const mapStateToProps = state => {
  return {
    liteui: state.liteui,
    notifications: state.notifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...LiteUIMethods({dispatch}),
    ...NotificationMethods({dispatch})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigureLiteForm);
