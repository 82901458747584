import React, { Component } from 'react';
import { connect } from "react-redux";
import { Modal, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import UIModals from './';
import LiteUIMethods from "../../methods";

const ReadOnlyWarningModal = ({
  show,
  handleClose
}) => {

  return (
    <UIModals.Error
      show={show}
      error={<div>Read Only Mode: Install a Browser Wallet</div>}
      handleClose={handleClose}
    />
  );
}

class ReadOnlyWarning extends Component {


  render(){
    if(this.props.liteui.ux.readOnlyWarning.show){
      return <ReadOnlyWarningModal
        show={true}
        handleClose={()=>{
          this.props.updateShowReadOnlyWarning({ value: false });
        }}
        />
    }else{
      return null;
    }


  }
}

const mapStateToProps = state => {
  return {
    liteui: state.liteui
  };
};

const mapDispatchToProps = dispatch => {
  return LiteUIMethods({dispatch});
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadOnlyWarning);
