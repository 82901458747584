import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import HamburgerIcon from "./HamburgerIcon";
import { connect } from "react-redux";
import { NavData } from "../Docs/Navigation";
import ExploreIcon from "./explore_button.svg";
import FeatureFlags from "../FeatureFlags";

class MobileNav extends Component {
  closeMenu = () => {
    this.props.closeNav();
  };

  handleMenuStateChange = (menuState) => {
    if (menuState.isOpen) {
      this.props.openNav();
    } else {
      this.props.closeNav();
    }
  };

  render() {
    const subscription = this.props.account.user.subscription;
    return (
      <div
        className={`mobilenav-container ${this.props.nav.open ? "open" : ""} ${
          this.props.auth.isAuthenticated ? "authenticated" : ""
        }`}
      >
        <Menu
          customBurgerIcon={false}
          customCrossIcon={false}
          width={260}
          pageWrapId={"main_content_area"}
          outerContainerId={"App"}
          menuClassName={"mobile-nav-menu-container"}
          isOpen={this.props.nav.open}
          onStateChange={(menuState) => this.handleMenuStateChange(menuState)}
        >
          {/* Explore Page */}
          <div className="menu-item">
            <a
              className="just-a-link purple"
              onClick={() => this.closeMenu()}
              target="_blank"
              href="/app/9139ab3d-0b76-4fe9-b5a8-036424e69351"
            >
              <img className="explore-image" src={ExploreIcon} />
            </a>
          </div>
          {/* Subscription Level */}
          {this.props.auth.isAuthenticated && FeatureFlags.Paywall ? (
            <div className="menu-item">
              <a
                className="just-a-link"
                onClick={() => this.closeMenu()}
                href="/account/subscription"
              >
                {!subscription ? "Upgrade Now!" : subscription.name}
              </a>
            </div>
          ) : null}
          {/* My Account */}
          <div className="menu-item">
            {this.props.auth.isAuthenticated ? (
              <ul className="nav navbar-nav navbar-left">
                <NavDropdown title="My Account" id="account-side">
                  <div
                    className="menu-subitem-wrapper"
                    onClick={() => this.closeMenu()}
                  >
                    <Link className="menu-subitem" to="/account">
                      Account Settings
                    </Link>
                  </div>
                  <div
                    className="menu-subitem-wrapper"
                    onClick={this.props.handleLogout}
                  >
                    <Link className="menu-subitem" to="#">
                      Log Out
                    </Link>
                  </div>
                </NavDropdown>
              </ul>
            ) : (
              <Link
                className="just-a-link purple"
                onClick={() => this.closeMenu()}
                to="/login"
              >
                Login
              </Link>
            )}
          </div>
          {/* Services */}
          <div className="menu-item">
            <ul className="nav navbar-nav navbar-left">
              <NavDropdown title="Services" id="services-side">
                <div
                  className="menu-subitem-wrapper"
                  onClick={() => this.closeMenu()}
                >
                  <Link className="menu-subitem" to="/dtables">
                    <img
                      className="icon"
                      src={require("../Branding/ServiceIcons/dtables.svg")}
                    />
                    dTables
                  </Link>
                </div>
                <div
                  className="menu-subitem-wrapper"
                  onClick={() => this.closeMenu()}
                >
                  <Link className="menu-subitem" to="/triggers">
                    <img
                      className="icon"
                      src={require("../Branding/ServiceIcons/triggers.svg")}
                    />
                    Triggers
                  </Link>
                </div>
                <div
                  className="menu-subitem-wrapper"
                  onClick={() => this.closeMenu()}
                >
                  <Link className="menu-subitem" to="/liteui">
                    <img
                      className="icon"
                      src={require("../Branding/ServiceIcons/liteui.svg")}
                    />
                    LiteUI
                  </Link>
                </div>
                <div
                  className="menu-subitem-wrapper"
                  onClick={() => this.closeMenu()}
                >
                  <Link className="menu-subitem" to="/awallet">
                    <img
                      className="icon"
                      src={require("../Branding/ServiceIcons/litewallet.svg")}
                    />
                    aWallet
                  </Link>
                </div>
              </NavDropdown>
            </ul>
          </div>
          {/* Docs */}
          <div className="menu-item">
            <ul className="nav navbar-nav navbar-left">
              <NavDropdown title="Docs" id="docs-side">
                {NavData.map((section, i) => {
                  return (
                    <div key={i}>
                      <div
                        className="menu-subitem-wrapper no-link"
                        target="_blank"
                      >
                        {section.title}
                      </div>
                      {section.links.map((link, x) => {
                        return (
                          <div
                            key={x}
                            className="menu-subitem-wrapper"
                            target="_blank"
                          >
                            <Link
                              className="menu-sub-subitem"
                              onClick={() => this.closeMenu()}
                              to={`/docs/${section.id}/${link.id}`}
                            >
                              {link.title}
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </NavDropdown>
            </ul>
          </div>
          {/* Community */}
          <div className="menu-item">
            <ul className="nav navbar-nav navbar-left">
              <NavDropdown title="Community" id="community-side">
                <div className="menu-subitem-wrapper" target="_blank">
                  <a
                    href="https://twitter.com/atrablockchain"
                    className="menu-subitem"
                    target="_blank"
                  >
                    Twitter
                  </a>
                </div>
                <div className="menu-subitem-wrapper" target="_blank">
                  <a
                    href="https://www.reddit.com/r/AtraBlockchain/"
                    className="menu-subitem"
                    target="_blank"
                  >
                    Reddit
                  </a>
                </div>
                <div className="menu-subitem-wrapper" target="_blank">
                  <a
                    href="https://medium.com/atra-blockchain-services"
                    className="menu-subitem"
                    target="_blank"
                  >
                    Medium
                  </a>
                </div>
              </NavDropdown>
            </ul>
          </div>
          {/* Support */}
          <div className="menu-item">
            <ul className="nav navbar-nav navbar-left">
              <NavDropdown title="Support" id="support-side">
                <div className="menu-subitem-wrapper" target="_blank">
                  <Link
                    className="menu-subitem"
                    onClick={() => this.closeMenu()}
                    to="/docs/support/feedback"
                  >
                    Feedback
                  </Link>
                </div>
                <div className="menu-subitem-wrapper" target="_blank">
                  <Link
                    className="menu-subitem"
                    onClick={() => this.closeMenu()}
                    to="/docs/support/report-issue"
                  >
                    Report An Issue
                  </Link>
                </div>
                <div className="menu-subitem-wrapper" target="_blank">
                  <Link
                    className="menu-subitem"
                    onClick={() => this.closeMenu()}
                    to="/docs/support/faq"
                  >
                    FAQ
                  </Link>
                </div>
              </NavDropdown>
            </ul>
          </div>
          {/* What's New? */}
          <div className="menu-item">
            <a
              className="just-a-link"
              onClick={() => this.closeMenu()}
              target="_blank"
              href="https://medium.com/atra-blockchain-services/tagged/release-notes"
            >
              What's New?
            </a>
          </div>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nav: state.nav,
    account: state.account,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openNav: (data) => dispatch({ type: "UPDATE_NAV_OPEN", data }),
    closeNav: (data) => dispatch({ type: "UPDATE_NAV_CLOSED", data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileNav);
