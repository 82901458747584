import React, { Component } from "react";
import { connect } from "react-redux";
import { ModalContainer } from "../Modals";
import AccountMethods from "../../containers/account/methods";
import { withRouter } from "react-router-dom";
import MainImage from "./rocket_bg.png";
import { LoaderButton } from "../Buttons";

import { Modal, Button } from "react-bootstrap";

const UsageModal = ({
  show,
  title,
  handleClose,
  children,
  saveButtonText = "Save",
  isLoading,
  className,
  loadingText = "Saving",
  saveButtonId,
  cancelButtonId,
}) => {
  return (
    <Modal
      backdrop="static"
      className={`${className}`}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>{title}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <div className="subscription-modal-footer-container">
          <a id={cancelButtonId} className="link-button" onClick={handleClose}>
            no thanks, maybe later
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

class SubscriptionUsageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClose = (event) => {
    this.props.updateShowUsageAlertModal({ value: false });
  };

  handleViewPlansClick = () => {
    this.props.updateShowUsageAlertModal({ value: false });
    this.props.history.push("/account/subscription");
  };

  render() {
    if (!this.props.account.user.subscription) {
      return null;
    }
    if (this.props.account.ux.showUsageAlertModal) {
      return (
        <UsageModal
          saveButtonId={"limit_modal_click_upgrade"}
          cancelButtonId={"limit_modal_click_cancel"}
          show={true}
          title={"You've been busy!"}
          handleClose={this.handleClose}
          saveButtonText={"Upgrade"}
          isLoading={false}
          className={"white-modal"}
        >
          <div className="subscription-modal-container">
            <div style={{ "text-align": "center" }}>
              You've reached the limit on the{" "}
              <span className="tier-name">
                {this.props.account.user.subscription.priceString}{" "}
                {this.props.account.user.subscription.name} Plan.
              </span>{" "}
              Upgrade now to continue building.
            </div>
            <img src={MainImage} />
            <LoaderButton
              id="limit_modal_click_upgrade"
              className="green-button"
              text="View Plans"
              onClick={this.handleViewPlansClick}
            />
          </div>
        </UsageModal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return AccountMethods({ dispatch });
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscriptionUsageModal)
);
