import React, { useState, useEffect } from "react";
import { LoaderButton } from "../Buttons";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import FeatureFlags from "../FeatureFlags";

const cardOptions = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

//const stripePromise = loadStripe("pk_test_1n8oSjV4BM6rhqODzJ9DfRd4007LjK4eOO");

const stripePromise = loadStripe(
  FeatureFlags.StripeProd
    ? "pk_live_51F1hilIUhHmO2a9LWbvqlhaXCAyWeJk1kJaq7QCNdxx2wHlGoSuGPVEOedS2ND7bNwT0csCsZZog6RaYPPovXdxF00yS0UVjyI"
    : "pk_test_1n8oSjV4BM6rhqODzJ9DfRd4007LjK4eOO"
);

const CreditCard = ({
  withSave,
  handlePaymentMethodUpdate,
  handleCancel,
  forceCreateNewPaymentMethod,
  forceCreateNewPaymentMethodHook,
  handleStripeElementsReady = () => {},
}) => {
  const [saving, setSaving] = useState(false);
  const [loadingStripeElement, setLoadingStripeElement] = useState(true);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (forceCreateNewPaymentMethod && !loadingStripeElement) {
      handleSavePaymentMethod();
    }
  }, [forceCreateNewPaymentMethod]);

  const stripeElementReady = () => {
    setLoadingStripeElement(false);
    handleStripeElementsReady();
  };

  const handleSavePaymentMethod = async () => {
    setSaving(true);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    setSaving(false);
    if (forceCreateNewPaymentMethod) {
      forceCreateNewPaymentMethodHook({ paymentMethod, error });
    } else {
      handlePaymentMethodUpdate({ paymentMethod, error });
    }
  };

  return (
    <div>
      <CardElement
        onReady={stripeElementReady}
        classes={{
          base: "stripe-card-element-class",
        }}
        options={cardOptions}
      />

      {withSave ? (
        <div className="edit-card-number-buttons-container">
          <LoaderButton
            isLoading={saving}
            text={"Save"}
            loadingText={"Saving ..."}
            className="purple-button-small"
            disabled={loadingStripeElement}
            onClick={handleSavePaymentMethod}
          />
          <LoaderButton
            isLoading={false}
            text={"Cancel"}
            loadingText={"Saving ..."}
            className="white-button-small"
            disabled={false}
            onClick={handleCancel}
          />
        </div>
      ) : null}
    </div>
  );
};

const PaymentMethod = ({
  card,
  handlePaymentMethodUpdate,
  invokePaymenthMethodHook = false,
  createPaymentMethodHook = () => {},
  handleStripeElementsReady,
}) => {
  const [mode, setMode] = useState(card ? "summary" : null);
  const [paymentMethodCard, setPaymentMethodCard] = useState(card);

  // useEffect(() => {
  //   if (invokePaymenthMethodHook) {
  //     invokePaymenthMethodHook = false;
  //
  //     createPaymentMethodHook({
  //       paymentMethod: "test",
  //     });
  //   }
  // }, [invokePaymenthMethodHook]);

  const handleEditClick = () => {
    setMode("edit");
  };

  const handleCancelEdit = () => {
    setMode("summary");
  };

  const handleNewPaymentMethod = ({ paymentMethod, error }) => {
    if (!error) {
      setMode("summary");
      setPaymentMethodCard(paymentMethod.card);
      handlePaymentMethodUpdate({ paymentMethod });
    }
  };

  switch (mode) {
    case "edit":
      return (
        <div>
          <Elements stripe={stripePromise}>
            <CreditCard
              withSave={true}
              handleCancel={handleCancelEdit}
              handlePaymentMethodUpdate={handleNewPaymentMethod}
              forceCreateNewPaymentMethod={invokePaymenthMethodHook}
              forceCreateNewPaymentMethodHook={createPaymentMethodHook}
              handleStripeElementsReady={handleStripeElementsReady}
            />
          </Elements>
        </div>
      );
      break;
    case "summary":
      return (
        <div className="card-summary-container">
          <div>
            <b>{paymentMethodCard.brand}</b> *{paymentMethodCard.last4} (expires
            on {paymentMethodCard.exp_month}/{paymentMethodCard.exp_year})
          </div>
          <LoaderButton
            isLoading={false}
            text={"Edit Card"}
            loadingText={"Saving ..."}
            className="white-button-small"
            disabled={false}
            onClick={handleEditClick}
          />
        </div>
      );
      break;
    default:
      return (
        <Elements stripe={stripePromise}>
          <CreditCard
            handlePaymentMethodUpdate={handlePaymentMethodUpdate}
            forceCreateNewPaymentMethod={invokePaymenthMethodHook}
            forceCreateNewPaymentMethodHook={createPaymentMethodHook}
            handleStripeElementsReady={handleStripeElementsReady}
          />
        </Elements>
      );
      break;
  }
};

export default PaymentMethod;
