import React, { Component } from "react";
import {connect} from 'react-redux';
import {
  Alert,
  FormGroup,
  FormControl,
  Radio,
  ControlLabel,
  Button
} from "react-bootstrap";
import {Link} from 'react-router-dom';
import PriceInput from '../../../../components/PriceInput/PriceInput';
import AddressInput from '../../../../components/AddressInput/AddressInput';
import ToolTip from '../../../../components/Tooltips';

import { ActionSummary } from './';

import NotificationMethods from "../../../../components/Notifications/methods";
import TriggerMethods from '../../methods/create';

const DefaultPlaceHolder = () => {
  return (
    <div className="alert-message">
      Complete the Set Action step before proceeding
    </div>
  );
};

const SelectPaid = ({
  change,
  enabled,
  requiredByAction,
  requiredByCondition
}) => {
  const disabled = requiredByAction || requiredByCondition;
  return (
    <div>
      {requiredByAction ? (
        <Alert>
          Amount of ETH Sent by User was used in the <b>Action</b>,
          Payment is required.
        </Alert>
      ) : null}
      {requiredByCondition ? (
        <Alert>
          Amount of ETH Sent by User was used in a <b>Condition</b>,
          Payment is required.
        </Alert>
      ) : null}
      <FormGroup>
        <Radio
          className={`option-radio-box ${enabled ? 'checked' : ''}`}
          disabled={disabled}
          onChange={change}
          checked={enabled}
          name="radioGroup"
          value="yes"
          inline
        >
          Yes
        </Radio>
        <Radio
          className={`option-radio-box ${!enabled ? 'checked' : ''}`}
          disabled={disabled}
          onChange={change}
          checked={!enabled}
          name="radioGroup"
          value="no"
          inline
        >
          No
        </Radio>
      </FormGroup>
    </div>
  );
};

const StaticRange = ({ min, max, change, minError, maxError }) => {
  return (
    <div>
      <FormGroup controlId="range">
        <ControlLabel>Minimum Amount</ControlLabel>
        <PriceInput
          childProps={{"data-range":'min'}}
          id='static'
          value={min}
          onChange={change}
        />
      </FormGroup>
      {minError ? <div className='error'>{minError}</div> : null}

      <FormGroup controlId="range">
        <ControlLabel>Maximum Amount</ControlLabel>
        <PriceInput
          childProps={{"data-range":'max'}}
          id='static'
          value={max}
          onChange={change}
        />
      </FormGroup>
      {maxError ? <div className='error'>{maxError}</div> : null}
    </div>
  );
}

const StaticValue = ({ value, change }) => {
  return (

    <div>
      <FormGroup>
        <ControlLabel>Static Value</ControlLabel>
        <FormControl value={value} onChange={change} type="text" />
      </FormGroup>
    </div>
  );
};

const TableColumns = ({ columns, value, change, label }) => {
  return (
    <div>
      <FormGroup controlId="dtable-column">
        <ControlLabel>Select dTable Column</ControlLabel>
        {label}
        <FormControl value={value} onChange={change} componentClass="select">
          {columns.map((column, i) => {
            return (
              <option key={i} value={column.name}>
                {column.name}
              </option>
            );
          })}
        </FormControl>
      </FormGroup>
    </div>
  );
};

const Source = ({ options, source, typeChange, valueChange }) => {
  return (
    <div>
      <FormGroup>
        <ControlLabel>Payment Amount</ControlLabel>
        <p>The amount of ETH a user will be charged to execute this trigger.</p>
        <FormControl
          value={source.selectedOption.value}
          onChange={typeChange}
          componentClass="select"
        >
          {options
            .filter(o => !o.disabled)
            .map((option, i) => {
              return (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              );
            })}
        </FormControl>
      </FormGroup>
      {source.selectedOption.value === "static" ? (
        <div>
          <PriceInput
            id='static'
            value={source.selectedOption.rawValue}
            onChange={valueChange}
          />
          {source.selectedOption.error ? <div className='error'>{source.selectedOption.error}</div> : null}
        </div>
      ) : null}
      {source.selectedOption.value === "range" ? (
        <div>
          {source.selectedOption.error ? <div className='error'>{source.selectedOption.error}</div> : null}
          <StaticRange
            min={source.selectedOption.rawValue.min}
            max={source.selectedOption.rawValue.max}
            minError={source.selectedOption.min.error}
            maxError={source.selectedOption.max.error}
            change={valueChange}
          />
        </div>

      ) : null}
      {source.selectedOption.value === "dtable" ? (
        <TableColumns
          label={<p>The value from the record you're updating that will be used as the payment amount.</p>}
          value={source.selectedOption.selectedColumn}
          columns={source.selectedOption.columns}
          change={valueChange}
        />
      ) : null}
    </div>
  );
};

const Destination = ({ options, destination, typeChange, valueChange }) => {
  return (
    <div>
      <FormGroup>
        <ControlLabel>Payment Destination</ControlLabel>
        <p>The address the payment will be transfered to.</p>
        <FormControl
          value={destination.selectedOption.value}
          onChange={typeChange}
          componentClass="select"
        >
          {options
            .filter(o => !o.disabled)
            .map((option, i) => {
              return (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              );
            })}
        </FormControl>
      </FormGroup>
      <FormGroup>
        {destination.selectedOption.value === "static" ? (
          <div>
            <AddressInput
              value={destination.selectedOption.rawValue}
              onChange={valueChange}
            />
          {destination.selectedOption.error ? <div className='error'>{destination.selectedOption.error}</div> : null}
          </div>

        ) : null}
        {destination.selectedOption.value === "dtable" ? (
          <TableColumns
            label={<p>The value from the record you're updating that will be used as the payment destination.</p>}
            value={destination.selectedOption.selectedColumn}
            columns={destination.selectedOption.columns}
            change={valueChange}
          />
        ) : null}
      </FormGroup>
    </div>
  );
};

class Price extends Component {
  handleNext = () => {
    this.props.updateSelectedStep({ value: 'deploy' });
  }
  handlePrev = () => {
    this.props.updateSelectedStep({ value: 'conditions' });
  }
  isValid = () => {
    let valid = true;
    //check source
    if(this.props.price.enabled){
      if(this.props.price.source.selectedOption.error){
        valid = false;
      }
      if(this.props.price.source.selectedOption.value === 'range'){
        if(this.props.price.source.selectedOption.min.error || this.props.price.source.selectedOption.max.error ){
          valid = false;
        }
      }

      //check destination
      if(this.props.price.destination.selectedOption.error){
        valid = false;
      }

    }

    return valid;
  }
  render() {
    const actionTable = this.props.tables.find(t => t.selectedByAction);
    return (
      <div className='price-container section-container'>
        <form autoComplete="off">
          <h3 className="section-title">Configure Payment</h3>
          <ActionSummary
            table={actionTable}
             />
          <div className='section-content'>
            <div className='header-container'>
              <h4>Payment Options</h4>
              <p>Require users to pay for a trigger to be executed</p>
            </div>
            <fieldset disabled={this.props.action.editing}>
            <ControlLabel>Will you be charging users ETH to complete this action?</ControlLabel>
            <SelectPaid
              requiredByAction={this.props.price.requiredByAction}
              requiredByCondition={this.props.price.requiredByCondition}
              enabled={this.props.price.enabled}
              change={this.props.enablePriceChange}
            />
            {this.props.price.enabled ? (
              <div>
                <Source
                  options={this.props.priceOptions.source}
                  source={this.props.price.source}
                  typeChange={this.props.sourceTypeChange}
                  valueChange={this.props.sourceValueChange}
                />
                <Destination
                  options={this.props.priceOptions.destination}
                  destination={this.props.price.destination}
                  typeChange={this.props.destinationTypeChange}
                  valueChange={this.props.destinationValueChange}
                />
              </div>
            ) : null}
          </fieldset>
          </div>
        </form>
        <div className="submit-buttons">
          <Link to='/triggers'>Cancel</Link>
          <Button
            className='black-button'
            onClick={this.handlePrev}
          >
            Previous
          </Button>
          <Button
            className='secondary-button'
            onClick={this.handleNext}
            disabled={!this.isValid()}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    trigger: state.triggersCreate
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...TriggerMethods({ dispatch }),
    ...NotificationMethods({ dispatch })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Price);
