import React from 'react';
import {Link} from 'react-router-dom';
import Note from '../helpers/Note';
import Direction from '../helpers/Direction';
import ScreenShot from './ScreenShot';

const triggers = ({

}) => {
  return (
  <div className='triggers-section-container'>
    <h3>Triggers</h3>
    <p><b>Adding Functionality</b></p>

    <p>In this part of the tutorial we will add the underlying functionality <i>(mechanics, business logic, or logic)</i> for users to post messages on the BlockTalk dApp we are building. These are pieces of our dApp that enforce rules in our application. We create these rules with no code using the Triggers service. <b>Any time we want to enforce logic in our applications, we must create a smart contract and deploy that to Ethereum.</b> In the end, this is presented to the user in the state of a form on your dApp interface for them to interact with.</p>

    <Direction>
      <p>In the navigation select Triggers from the services dropdown.</p>
    </Direction>

    <p>Before we create our first trigger, let’s outline what we are trying to achieve with this trigger. Our goal is to allow a user to post to the BlockTalk board via a transaction to the blockchain. Through the trigger, we will be giving them the ability to use their Metamask wallet to pay for a  transaction that creates a new post record in the MessageBoard dTable we deployed earlier.</p>

    <p>Now, let’s review a summary of what our trigger will consist of (we’ll go more into these points below):</p>
    <ul>
      <li>Action type: Insert Record</li>
      <li>Configuration - User Input and User's Address</li>
      <li>Conditions - none</li>
      <li>Payment - 0.001 ETH to post</li>
    </ul>

    <Direction>
      <p>From Triggers Dashboard, click the ‘Create Trigger’ button.</p>
    </Direction>

    <p>You will land on the Set Actions tab in the Create trigger page. This is where you will select the outcome of your trigger, from the “Insert Record” “Update Record” and “Delete Record” options. In this tutorial, we will only be using the Insert action, but you can learn about all actions in the <a target='_blank' href='/docs/user-guides/triggers'>Triggers overview</a>.
    </p>

    <p>Remember, we want to allow the user to create a <i>brand new</i> post on the board meaning we need to allow them to <b>insert</b> the record of that data into our MessageBoard dTable. Update would allow them to edit their message, and delete would allow them to delete their message.</p>

    <Direction>
      <p>On the Select Action tab, under Action Options</p>
      <p>Select ‘Insert a Record’ as the Method Type.</p>
      <p>Select the dTable ‘MessageBoard’ as the table we want to modify.</p>
    </Direction>

    <p>Configuring how each record value will map to user input is important for our trigger to work correctly in the form of a text form on our dApp interface. Based on what you choose, the user will either be able to fill in the text form with their own value, see a static value that you set which they cannot edit, or, if an address, the option to automatically record their user address.</p>

    <Direction>
      <p>Let’s configure the value mapping to the values below.</p>
      <p><b>Post (text):</b> User Input</p>
      <p><i>The User Input option tells the trigger to allow the user to decide that goes in this field.</i></p>
      <p><b>UserName (text):</b> User Input</p>
      <p><b>UserAddress (address):</b> User’s Address</p>
      <p><i>The User’s Address option does not present the user with the option to decide what goes in this field. The User's Metamask wallet address will automatically be filled in for them.</i></p>
    </Direction>

    <p>The first two sections of your trigger should look like the screen shot.</p>

    <ScreenShot name='trigger_set_action'/>

    <Direction>
      <p>Now click ‘Next’</p>
    </Direction>

    <p>You will land on the Conditions tab, which is where you can set conditions that must be met for your trigger to execute. In this tutorial, we will not use conditions.</p>


    <Direction>
      <p>We will not be using conditions in this tutorial, hit ‘Next’ to the Configure Payments tab.</p>
    </Direction>

    <p>This is where we will configure how the Ether sent on top of the gas cost in a transaction is handled. We want to charge users ETH to post to the message board, therefore we will be completing this section. We will create a static price to post a message, so that every user pays the same price and cannot decide on the price.</p>

    <Direction>
      <p>We want to charge users to pay ETH to post on the message board, so click ‘yes’.</p>
      <p>In the Payment Amount section, select ‘Static’.</p>
      <p>Enter “0.001” into the ETH form - you will see it translated to USD real time.</p>
    </Direction>

    <Direction>
      <p>For Payment Destination, we want the user’s ETH to go to your wallet address.</p>
      <p>Select ‘Static Ethereum Address’ and paste your wallet address into the field.</p>
    </Direction>

    <p>The Set Payment section should look similar to the screenshot below</p>

    <ScreenShot name='trigger_set_payment'/>

    <Direction>
      <p>Now click ‘Next’</p>
    </Direction>

    <p>We’re almost done - we just have to name and review the Trigger.</p>

    <Direction>
      <p>Enter “InsertMessage” in the Name field</p>
      <p>For the description, enter “Allow user to post a message to the board for the cost of 0.001 ETH”</p>
      <p>Make sure the review summary for your trigger looks correct</p>
    </Direction>

    <p>The Review section should look like the screenshot below.</p>

    <ScreenShot name='trigger_review'/>

    <Direction>
      <p>Click ‘Deploy’</p>
      <p>Wait for the trigger to have the status of ‘Live’</p>
    </Direction>

    <p>The newly created trigger should reflect the image below.</p>

    <ScreenShot name='trigger_live'/>

    <Direction>
      <p>Click on the InsertMessage Trigger</p>
    </Direction>

    <p>Our trigger is live on the Ethereum network, but it won’t work unless we give the underlying smart contract permission to interact with the dTable’s underlying smart contract.</p>


    <ScreenShot name='trigger_grant_permission' />

    <Direction>
      <p>In the details tab, click ‘Grant Permission’</p>
      <p>Allow the button to go through the ‘saving’ and ‘pending’ stages.</p>
    </Direction>

    <p>Our first trigger is finished! We can now have public users post messages on our application using the InsertMessage trigger.</p>

    <p><b>Invoking the Trigger</b></p>

    <p><b>Optional step:</b> We can directly invoke this trigger with our own Metamask wallet to test it out within the trigger’s page, before we hook it up through our interface. If you’d like to test it, follow along with this step. If you’d like to skip and try this feature out later, proceed to the LiteUI section.</p>

    <p>We are now going to directly invoke the trigger using our metamask wallet, this will automatically  insert our wallet address, and Ether amount just like we configured the trigger to do.</p>

    <Direction>
      <p>Click the ‘Invoke’ tab.</p>
    </Direction>

    <p>Make sure your status reads as ‘connected’. For your protection, Metamask requires the website request access to connect to your wallet. Select ‘connect’ if you are prompted. Users are still completely in control of their wallet and will have to approve every transaction moving forward. </p>

    <Direction>
      <p>Click ‘Connect’ in the metamask dialog.</p>
      <p>If no dialog appears check the extension by clicking on it.</p>
    </Direction>

    <Direction>
      <p>In the Manually Invoke Trigger section</p>
      <p>Set the Post field to ‘Hello world!’</p>
      <p>Set the UserName to the name you used in your last post, or change it up.</p>
      <p>You will see our static cost of 0.001 ETH stated below.</p>
      <p>Click ‘Invoke’</p>
    </Direction>

    <ScreenShot name='trigger_invoke' />

    <p>This will popup Metamask (if not click the extension) and request for you to approve the transaction. </p>

    <Direction>
      <p>In Metamask</p>
      <p>Click ‘Confirm’</p>
    </Direction>

    <Note>
      <p>If your transaction is taking too long, you can speed it up</p>
      <p>Open metamask extension, select the transaction, and click speed up.</p>
    </Note>

    <p>Metamask will popup a notification when the transaction was successful. You’ve just sent your first transaction with metamask, congrats!</p>

    <Direction>
      <p>Navigate to the MessageBoard dTable page to view the record you inserted by invoking the trigger.</p>
    </Direction>

  </div>
  )

}

export default triggers;
