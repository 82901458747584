import React from "react";
import ExploreIcon from "../../../components/Navigation/explore_button.svg";
import ExploerDapps from "./explore_button.png";
import { Link } from "react-router-dom";

const Explore = ({}) => {
  return (
    <div className="section-container">
      <div className="explore-header">
        <img src={ExploreIcon} />
      </div>
      <p>BUILD. SHARE. EXPLORE.</p>
      <p className="explore-content">
        The Atraverse is a dApp that houses all dApps made on the Atra platform.
        Explore, share and interact with the dApps using test Ethereum. Vote on
        your favorite dApps and watch them rise to the top of the list. Then,
        build your own dApp and spread the word!
      </p>
      <div className="explore-big-button-container">
        <a target="_blank" href="/app/explore">
          <img src={ExploerDapps} />
        </a>
      </div>
    </div>
  );
};

export default Explore;
