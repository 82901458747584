import React, { Component } from "react";
import { connect } from "react-redux";

import Methods from '../../methods/view';
import AtraAPI from '../../../../apiv2';

import TablePermissions from "../TablePermissions";
import AddPermissionForm from "../AddPermissionForm";
import LoadingTab from '../LoadingTab';
import { RefreshMiliseconds } from '../../../../helpers/Timers';

class PermissionsView extends Component {

  constructor(props){
    super(props);
    this._mounted = false;
  }

  componentDidMount = () => {
    this._mounted = true;
    this.pollPermissions();
  }

  componentWillUnmount = () => {
    this._mounted = false;
  }

  async pollPermissions() {
    if (this._mounted) {
      await this.getPermissions().then(() => {
        setTimeout(async () => {
          if(this._mounted)
            await this.pollPermissions();
        }, RefreshMiliseconds);
      },err=>{
        //catch error
      });
    }
  }

  getPermissions = () => {
    return new Promise((resolve,reject)=>{
      const tableId = this.props.dTablesView.table.id;
      AtraAPI.GetPermissions(tableId).then(permissions=>{
        this.props.updatePermissions({ value: permissions });
        this.props.updateLoadingTab({ value: false });
        resolve();
      },err=>{
        this.props.updateLoadingTab({ value: false });
        resolve();
      });
    })
  }


  render() {
    if(this.props.dTablesView.loadingTab){
      return <LoadingTab title={'Permissions'} />;
    }
    return (
      <div className="permissions-view-container">
        <h3>Manage Permissions</h3>
        <p>
          Manage the ethereum smart contracts and user addresses that have permission to modifying this table. Trigger addresses will show up in the permissions list.
        </p>
        {this.props.dTablesView.newPermission.show ? (
          <AddPermissionForm
            tableId={this.props.tableId}
            onSubmit={this.getPermissions}
          />
        ) : (
          <TablePermissions tableId={this.props.tableId} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dTablesView: state.dTablesView
  };
};

const mapDispatchToProps = dispatch => {
  return Methods({dispatch});
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionsView);
