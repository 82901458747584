import React from 'react';
import {Link} from 'react-router-dom';
import Note from '../helpers/Note';
import Direction from '../helpers/Direction';
import ScreenShot from './ScreenShot';

const dtables = ({}) => {
  return (
  <div className='dtables-section-container'>
    <h3>dTables</h3>
    <p><b>Overview</b></p>

    <p>The first thing any application needs, whether decentralized or centralized, is the ability to store information. In the case of this tutorial, we are storing user’s posts to our application on the Ethereum blockchain. Atra has a service specifically built to help us with that, dTables. dTables is very similar to an excel file on the blockchain - we can create a table that has columns and we can insert records into that table. Whenever we want to show who has posted to our dApp’s feed we pull that information from our dTable.</p>

    <Direction>
      <p>Navigate to the dTables service dashboard using the services dropdown in the nav, and selecting the dTables option.</p>
    </Direction>

    <Note>
      <p><b>Note:</b> Make sure to open the links as a new tab so we can keep our place here.</p>
    </Note>

    <p><b>Creating the Foundation</b></p>

    <p>We are going to create a table to store a list of user’s posts for our application. The table we create will actually be what we see in our interface, styled in a slightly different format.</p>

    <Direction>
      <p>Click the ‘Create Table’ button.</p>
      <p>In the first section dTable info, set the Name field to ‘MessageBoard’ and set the Description field to ‘A list of user's posts for our application state’.</p>
    </Direction>

    <p>Below the info section we have the Columns. We will add a column for each piece of information we want to store about a user’s post.
    </p>

    <Direction>
      <p>Let’s add the first column.</p>
      <p>Click the ‘Add Column’ button.</p>
      <p>The new column displays a dropdown for the column’s data type and an input box for the column’s name.</p>
      <p>Set the dropdown to ‘Text’ and set the column name to ‘Post’.</p>
    </Direction>

    <p>
      We’ve just added the first column in the table.<br/>
      Add the rest of the columns for the information we want to store about a post.<br/>
      The column names and types are below (the first one is made).
    </p>

    <Direction>
      <p>Add Columns</p>
      <ul>
        <li>Name: Post</li>
        <ul><li>Type: Text</li></ul>
        <li>Name: UserName</li>
        <ul><li>Type: Text</li></ul>
        <li>Name: UserAddress</li>
        <ul><li>Type: Address</li></ul>
      </ul>
    </Direction>

    <p>Your screen should match the image below.</p>

    <ScreenShot name='dtable_create'/>

    <p>Once you’ve added each of the columns above and you’ve selected to correct types</p>

    <Direction>
      <p>Click ‘Create dTable’.</p>
    </Direction>

    <Note>
      <p><b>Note:</b> you can not go back and edit a table once created</p>
    </Note>

    <p>Congrats! Your dTable is now being deployed to the Ethereum Goerli network. You’ll be able to monitor the status of the dTable deployment on the dTables Dashboard. It can take a minute or two for your table to completely deploy to the network. Once the green lighting bolt is showing in the top left hand corner, you’re all ready to test out the new table and insert records.</p>


    <p>You’re ready to move on to the next part when your table is live.</p>

    <ScreenShot name='dtable_live'/>

    <p><b>Viewing the Smart Contract</b></p>

    <p>Your dTable has been deployed to the Ethereum Goerli test network.</p>

    <Direction>
      <p>Click into your newly created table.</p>
      <p>Click the ‘Contract’ tab</p>
    </Direction>

    <p>When you create a new dTable, Atra is automating the creation and deployment of an Ethereum smart contract, written in solidity. This is a common piece of decentralized development on Ethereum, and without Atra, users would need to write these contracts themselves.</p>

    <ScreenShot name='dtable_contract'/>
    <p>The dTables contract is specifically designed to store records. The contract offers methods for inserting, reading, updating, and deleting records. These contract methods are controlled by permissions, which can be seen in the ‘Permissions’ tab in the dTable page.
    </p>

    <p>Let’s see these contract methods in action while we insert a record into our table.</p>

    <p><b>Insert Test Data</b></p>

    <p>Let’s jump right in and create some test data in the table.</p>

    <Direction>
      <p>Click the ‘New Record’ button.</p>
    </Direction>

    <p>This will open a modal on the page where you’ll see inputs for each column. We are manually going to insert a record.</p>

    <Direction>
      <p>With the modal open fill in the inputs as listed below</p>
      <ul>
        <li>Post: First test post! We will remove this later.</li>
        <li>UserName: (enter your own made up name)</li>
        <li>UserAddress: (copy and paste your address from the metamask extension)</li>
      </ul>
    </Direction>

    <p>Your modal should look like the one below</p>

    <ScreenShot name='dtable_insert_record'/>

    <Direction>
      <p>Click ‘Insert Record’</p>
      <p>Switch from the Contract tab to the Records tab</p>
    </Direction>

    <p>You should receive a ‘success’ notification in the top right hand corner of the page.
After a couple seconds you’ll notice the new record appear in the table, it will need to go from pending to live just like the dTable itself when we created it.</p>

    <Note>
      <p><b>Note:</b> Live dTable records are notated by having a Record Id</p>
    </Note>

    <p>Woo hoo! You’re storing data on the blockchain!</p>

    <p><b>Let’s recap what we’ve done so far</b></p>
    <ul>
      <li>We’ve set up our wallets and received test Ether to interact with the Ethereum blockchain</li>
      <li>We created the base of our application using the dTable service, a service for storing data that our dApp will display to users.</li>
      <li>We then manually inserted records into that table that’s on the Ethereum blockchain.</li>
    </ul>

    <p>
    In the next section, we will start to build functionality to allow users the ability to modify the table themselves. In this case, your users would be sending and paying the gas fee for their transactions to your table.
    </p>

</div>
)

}

export default dtables;
