import React, { Component } from "react";
import { connect } from "react-redux";

import TriggerViewMethods from "../methods/view";
import EnvMethods from "../../../components/Environments/methods";
import AtraAPI from "../../../apiv2";

import { Tabs, Invoke, Contract, TriggerInfo } from "../components/view";
import { Container } from "../components";
import { ViewDetails } from "../../../components/ServiceView";

import LoaderButton from "../../../components/Buttons/LoaderButton";

const Archive = ({ onClick, loading, archived }) => {
  if (archived) {
    return (
      <LoaderButton
        isLoading={loading}
        loadingText={"Unarchiving"}
        disabled={loading}
        className="secondary-button"
        onClick={() => onClick(false)}
        text={"Unarchive"}
      />
    );
  } else {
    return (
      <LoaderButton
        isLoading={loading}
        loadingText={"Archiving"}
        disabled={loading}
        className="secondary-button"
        onClick={() => onClick(true)}
        text={"Archive"}
      />
    );
  }
};

class TriggersView extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    this.props.updateEnvironmentsEnabled({ enabled: false });
  }

  componentDidMount = () => {
    this._mounted = true;
    const triggerId = this.props.match.params[0];
    this.props.newTrigger({ value: triggerId });
    this.props.updateLoadingDetails({ value: true });
    AtraAPI.GetTrigger(triggerId)
      .then((trigger) => {
        const formattedTrigger = {
          ...trigger,
          input: JSON.parse(trigger.input),
        };
        this.props.updateTrigger({ value: formattedTrigger });
        AtraAPI.GetPermissions(
          this.props.triggersView.trigger.input.action.tableId
        )
          .then((res) => {
            this.props.updateLoadingDetails({ value: false });
            if (this._mounted === true) {
              let hasPermission = true;
              const triggerAddress = this.props.triggersView.trigger.goerli;

              if (res.live.includes(triggerAddress)) {
                console.log("trigger has permission");
                this.props.updateTriggerDtablePermission({ value: true });
              } else if (
                res.data.find(
                  (p) =>
                    p.data.address === triggerAddress && p.status === "Pending"
                )
              ) {
                console.log("trigger permission is pending");
                this.props.updateTriggerDtablePermissionPending({
                  value: true,
                });
              } else {
                hasPermission = false;
                console.log("trigger does not have permission");
                this.props.updateTriggerDtablePermission({ value: false });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        this.props.updateLoadingDetails({ value: false });
      });
  };

  componentWillUnmount = () => {
    this._mounted = false;
    this.props.resetState();
  };

  back = (event) => {
    this.props.history.push("/triggers");
  };

  handleTabSelect = (key) => {
    this.setState({ activeTab: key });
    if (key === "permissions") {
      this.getPermissions();
    }
  };
  handleArchive = async (archive) => {
    this.props.updateArchiving({ value: true });
    AtraAPI.ArchiveResource(
      "triggers",
      this.props.triggersView.trigger.id,
      archive
    ).then(
      (res) => {
        this.props.updateArchiving({ value: false });
        let trigger = { ...this.props.triggersView.trigger, archived: archive };
        this.props.updateTrigger({ value: trigger });
      },
      (err) => {
        console.log(err);
        this.props.updateArchiving({ value: false });
      }
    );
  };

  render() {
    const breadcrumbs = [];
    breadcrumbs.push({ text: "Triggers", to: "/triggers", icon: "triggers" });
    breadcrumbs.push({
      text: this.props.triggersView.trigger.name,
      to: `/triggers/view/${this.props.triggersView.trigger.id}`,
    });

    return (
      <Container className="triggers-view-container " breadcrumbs={breadcrumbs}>
        <ViewDetails
          loading={this.props.triggersView.loading.details}
          instance={this.props.triggersView.trigger}
        />

        {!this.props.triggersView.loading.details ? (
          <Archive
            onClick={this.handleArchive}
            loading={this.props.triggersView.archiving}
            archived={this.props.triggersView.trigger.archived}
          />
        ) : null}

        {!this.props.triggersView.loading.details ? <Tabs /> : null}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    triggersView: state.triggersView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...TriggerViewMethods({ dispatch }),
    ...EnvMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TriggersView);
