import React from 'react';


const FAQ = ({

}) => {
  return (
    <div className='doc-section-content-container'>
      <h2>FAQ</h2>
        <h4>What blockchain does Atra use?</h4>
        <p>Public Ethereum Blockchain</p>
        <h4>Why do I need Metamask?</h4>
        <p>Metamask is used to store your Ether and interact with the Ethereum applications you make with the Atra services</p>
        <h4>What is a smart contract?</h4>
        <p>Smart contracts are programs written in solidity and deployed to the Ethereum blockchain</p>
        <h4>What Ethereum networks does Atra support?</h4>
        <p>Atra supports the Ethereum Goerli Test Net for the Beta</p>
        <h4>Does Atra allow the use of private Ethereum blockchains?</h4>
        <p>Not at the moment</p>
    </div>
  )
}

export default FAQ;
