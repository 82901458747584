import React from 'react';
import { Modal, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import UIModals from './';

const ConfigureLiteText = ({
  id,
  title,
  name,
  text,
  show,
  handleClose,
  handleSave,
  handleChange
}) => {

  return (
    <UIModals.ModalContainer
      title={title}
      handleClose={handleClose}
      handleSave={handleSave}
      show={show}
      >
      <FormGroup controlId={'title'}>
        <ControlLabel>Header:</ControlLabel>
        <FormControl
          type='text'
          onChange={handleChange}
          defaultValue={name}
          />
      </FormGroup>
      <FormGroup controlId={'source'}>
        <ControlLabel>Body:</ControlLabel>
        <div>
          <textarea id={'source'} onChange={handleChange} value={text}></textarea>
        </div>
      </FormGroup>
    </UIModals.ModalContainer>
  );
}

export default { ConfigureLiteText };
