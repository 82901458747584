import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Actions from "../../../actions/triggers-view";
import DashboardGridNoContent from '../../../../../components/ServiceDashboard/DashboardGridNoContent';
import LoadingTab from './LoadingTab';
import { Table, Alert } from 'react-bootstrap';
import { ConvertWeiToEth, FromHex } from '../../../../../helpers/Eth';
import { RefreshMiliseconds } from '../../../../../helpers/Timers';
import { FormatDate, FormatDateTime, GetEpochFromTimeZoneDate } from "../../../../../helpers/Date";
import LoaderButton from '../../../../../components/Buttons/LoaderButton';

import AtraAPI from '../../../../../apiv2';
import Methods from '../../../methods/view';
import NotificationMethods from "../../../../../components/Notifications/methods";

const FormatValue = ({
  type,
  value,
  timezone
}) => {
  switch(type){
    case 'price':
      return `${ConvertWeiToEth(value)} ETH`;
    break;
    case 'date':
      return FormatDateTime(value, timezone);
    default:
      return value;
    break;
  }
}

const FormatCondition = ({
  condition,
  timezone
}) => {
  return (
    <div>
      <span>{condition.base.groupLabel}</span>
      <span> {condition.base.label}</span>
      <span> {condition.operator}</span>
      {condition.target.groupId === 'static' ?
        <span> <FormatValue type={condition.target.value} value={condition.target.rawValue} timezone={timezone} /></span>
      :
        condition.target.groupId === 'list' ?
          <span> dTable: {condition.target.tableId} Column: {condition.target.column}</span>
          :
          <span> {condition.target.label}</span>

      }
    </div>
  )
}

const TablePermission = ({
  hasPermission,
  submittingPermission,
  pendingPermission,
  actionTableName,
  triggerName,
  handleGrantPermission
}) => {
  if(!hasPermission){
    return (
      <Alert bsStyle='warning'>
        <p>This trigger '{triggerName}' does not have permission to access the '{actionTableName}' dTable. Grant Permission to complete the setup of this trigger.</p>
        <LoaderButton
          isLoading={submittingPermission || pendingPermission}
          loadingText={pendingPermission ? 'Pending' : 'Saving'}
          onClick={handleGrantPermission}
          className='secondary-button'
          text='Grant Permission'
          />
      </Alert>
    )
  }else{
    return null;
  }
}



const ActionDetails = ({
  action,
  timezone
}) => {
  return (
    <div>
      <h2>Action</h2>
      <p>Actions can Insert, Edit, or Delete single records from a dTable</p>
      <div>
        <span>dTable: </span><span> <Link to={`/dtables/view/${action.tableId}`}>{action.table}</Link></span>
      </div>
      <div>
        <span>Method: </span><span> {action.method}</span>
      </div>
      <h4>Value Mappings</h4>
      <p>Value mappings show how the value for a record column will be defined before executing the action method on the dTable.</p>
      {action.mappings ?
        <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Column</th>
                <th>Value</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {action.mappings.map((mapping, i)=>{
                return (
                  <tr key={i}>
                    <td>{i}</td>
                    <td>{mapping.name}</td>
                    <td>{mapping.value === 'static' ? <span> <FormatValue type={mapping.valueType} value={mapping.rawValue} timezone={timezone} /></span> : mapping.value}</td>
                    <td>{mapping.valueType}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>

      :
        <div>Value Mapping not supported by action method</div>
      }

    </div>
  )
}

const ConditionDetails = ({
  conditions,
  timezone
}) => {
  return (
    <div className='conditions-container'>
      <h2>Conditions</h2>
      <p>The caller of the trigger must meet all of the conditions and payment requirements to execute the action</p>
      {conditions.map((condition, i)=>{
        return (
          <div key={i} className='condition-container'>
            <h4>{i+1}.</h4>
            {condition.description ?

              <div>
                <h4>Description</h4>
                <p>{condition.description}</p>
              </div>

            : null}
            <div className='condition-breakdown-container'>
              <h4>Condition</h4>
              <FormatCondition condition={condition} timezone={timezone} />
            </div>
          </div>
        )
      })}

    </div>
  )
}

const PaymentDetails = ({
  payment
}) => {
  if(payment.enabled){
    let sourceValue = payment.source.value;
    let destinationValue = payment.destination.value;

    switch(payment.source.type){
      case 'static':
        sourceValue = `${ConvertWeiToEth(sourceValue)} ETH`;
      break;
      case 'range':
        sourceValue = `Range: ${ConvertWeiToEth(payment.source.value.min)} - ${ConvertWeiToEth(payment.source.value.max)} ETH`;
      break;
      case 'unlimited':
        sourceValue = 'Unlimited';
      break;
      case 'dtable':
        sourceValue = `Action Table: ${payment.source.value} column`;
      break;
    }

    switch(payment.destination.type){
      case 'dtable':
        destinationValue = `Action Table: ${payment.destination.value} column`;
      break;
    }
    return (
      <div>
        <h2>Payment</h2>
        <p>Payment must be paid to the trigger by the caller, along with the required conditions for the action to execute</p>
        <h4>Amount</h4>
        <div>
          <span>{sourceValue}</span>
        </div>
        <h4>Destination Address</h4>
          <span>{destinationValue}</span>
      </div>
    )
  }else{
    return (
      <div>
        <h2>Payment</h2>
        <p>Payment must be paid to the trigger by the caller, along with the required conditions for the action to execute</p>
        <h4>Free</h4>
      </div>
    )
  }
}
const Details = ({
  triggerName,
  hasPermission,
  submittingPermission,
  pendingPermission,
  action,
  conditions,
  payment,
  handleGrantPermission,
  timezone
}) => {
  return (
    <div>
      <TablePermission
        hasPermission={hasPermission}
        pendingPermission={pendingPermission}
        submittingPermission={submittingPermission}
        triggerName={triggerName}
        actionTableName={action.table}
        handleGrantPermission={handleGrantPermission}
        />
      <ActionDetails
        timezone={timezone}
        action={action}
        />
      <ConditionDetails
        timezone={timezone}
        conditions={conditions}
        />
      <PaymentDetails
        timezone={timezone}
        payment={payment}
        />

    </div>
  )
}

class DetailsView extends Component {

  constructor(props){
    super(props);
    this._mounted = false;
  }

  componentDidMount(){
    this._mounted = true;
    this.props.updateLoadingTab({ value: false });
    //poll permissions if pending status
    if(this.props.triggersView.pendingPermission){
      this.pollPermissions();
    }
  }

  componentWillUnmount(){
    this._mounted = false;
  }


  handleGrantPermission = async event => {
    this.props.updateTriggerDtablePermissionSubmitting({value: true});
    AtraAPI.AddPermission(
      this.props.triggersView.trigger.input.action.tableId,
      this.props.triggersView.trigger.goerli
    ).then(res=>{
      this.props.updateTriggerDtablePermissionSubmitting({value: false});
      this.props.updateTriggerDtablePermissionPending({ value: true });
      // start polling the action tables permissions until it's been added
      this.pollPermissions();

    }).catch(err=>{
      this.props.updateTriggerDtablePermissionSubmitting({value: false});
    })
  }


  async pollPermissions() {
    if (this._mounted) {
      await this.getPendingPermissions().then(async () => {
        if(this._mounted){
          setTimeout(async () => {
            await this.pollPermissions();
          }, RefreshMiliseconds);
        }
      },err=>{
        //do nothing
      });
    }
  }

  getPendingPermissions = () => {
    return new Promise((resolve, reject)=>{
      const tableId = this.props.triggersView.trigger.input.action.tableId;
      AtraAPI.GetPermissions(tableId).then(res=>{
        if(this._mounted) {
          const triggerAddress = this.props.triggersView.trigger.goerli;
          if(res.data.find( p => p.data.address === triggerAddress && p.status === 'Pending')){

            console.log('trigger permission is pending');
            this.props.updateTriggerDtablePermissionPending({ value: true });
            //continue polling
            resolve();

          }else if(res.live.includes(triggerAddress)){

            console.log('trigger has permission');
            this.props.updateTriggerDtablePermission({ value: true });
            this.props.updateTriggerDtablePermissionPending({ value: false });
            this.props.notificationsAddPopupMessage({
              message: 'Success! - Trigger Permissioned',
              remove: (id)=>{this.props.notificationsRemovePopupMessage({ value: id })}
            });
            //stop polling
            reject();
          }else{
            //avoid race condition
            resolve();
          }

        }
      }).catch(err=>{
        console.log(err);
        resolve();
      });
    });
  }

  render() {

    if(this.props.triggersView.loading.tab){
      return <LoadingTab title={'Details'} />;
    }else{
      return (
        <div className="details-tab-container">
          <Details
            triggerName={this.props.triggersView.trigger.name}
            hasPermission={this.props.triggersView.hasPermission}
            submittingPermission={this.props.triggersView.submittingPermission}
            pendingPermission={this.props.triggersView.pendingPermission}
            action={this.props.triggersView.trigger.input.action}
            conditions={this.props.triggersView.trigger.input.conditions}
            payment={this.props.triggersView.trigger.input.payment}
            handleGrantPermission={this.handleGrantPermission}
            timezone={this.props.account.user.timezone.code}
            />
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    triggersView: state.triggersView,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...Methods({dispatch}),
    ...NotificationMethods({dispatch})
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsView);
