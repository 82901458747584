let defaultState = {
  open: false
}

const nav = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case "UPDATE_NAV_OPEN":
      return { ...state, open: true };
    case "UPDATE_NAV_CLOSED":
      return { ...state, open: false };
    default:
      return state;
  }
};

export default nav;
