import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const SignUpBox = ({ handleSignUp }) => {
  return (
    <div className="signup-box-container">
      <div className="box-header">
        The Atra Beta is FREE. Start building now.
      </div>
      <div className="signup-container">
        <Button onClick={handleSignUp} className="create-account-button">
          Create FREE Account
        </Button>
      </div>
      <div className="login-container">
        <Link to="/login">Or Log In to the console</Link>
      </div>
      <div className="foot-note-container">
        The beta release of the Atra Platform is live on the Goerli test
        network. All transactions are free and no payment info required to
        create account.
      </div>
    </div>
  );
};

export default SignUpBox;
