import React from 'react';
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export default ({
  className,
  breadcrumbs,
  rightContent,
  children
}) => {
  return (
    <div className={`container ${className}`}>
      <BreadCrumbs
        links={breadcrumbs}
        border={true}
        rightContent={rightContent}
      />
    {children}
    </div>
  )
}
