import Actions from '../actions/notifications';

const Methods = ({ dispatch }) => {
  return {
    notificationsAddPopupMessage: data => dispatch({ type: Actions.NOTIFICATIONS_ADD_POPUP_MESSAGE, data }),
    notificationsRemovePopupMessage: data => dispatch({ type: Actions.NOTIFICATIONS_REMOVE_POPUP_MESSAGE, data })
  }
}

export default Methods;
