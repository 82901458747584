import React from 'react';

const Header = ({

}) => {
  return (
    <div className='doc-section-header'>
      <h1>Tutorials</h1>
    </div>
  )
}

export default Header;
