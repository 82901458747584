import React from 'react';

const SectionContainer =  ({
  header,
  description,
  content
})=>{
  return(
    <div className='account-section-container'>
      <div className='section-header'>{header}</div>
      <div className='section-description'>{description}</div>
      <div className='section-content'>{content}</div>
    </div>
  )
}

export default SectionContainer;
