import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import SectionContainer from "./section-container";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import LoaderButton from "../../../components/Buttons/LoaderButton";
import Actions from "../actions/account-actions";

import AtraAPI from "../../../apiv2";

const TimeZoneSelectBox = ({ handleChange, value }) => {
  return (
    <TimezonePicker
      value={value}
      absolute={true}
      placeholder="Select timezone..."
      onChange={handleChange}
    />
  );
};

const TimeZoneContainer = ({
  onChange,
  user,
  timezone,
  handleSave,
  isSaving,
  loading,
}) => {
  const description = (
    <p>
      All dates and times on the platform will show up in this Time Zone
      selected. The default Time Zone is (GMT +00:00). Time Zone labels do not
      account for DST.
    </p>
  );
  let content = (
    <div>
      {description}
      <p>loading...</p>
    </div>
  );
  if (!loading) {
    content = (
      <div>
        {description}
        {user.timezone ? (
          <div>
            <TimeZoneSelectBox
              value={user.timezone.code}
              handleChange={onChange}
            />
            <LoaderButton
              disabled={timezone.validationMessage}
              isLoading={isSaving}
              loadingText="Updating"
              className="secondary-button"
              onClick={handleSave}
              text="Update"
            />
          </div>
        ) : (
          <div>
            <h4>Set Your Time Zone</h4>
            <TimeZoneSelectBox value={timezone.code} handleChange={onChange} />
            <LoaderButton
              disabled={timezone.validationMessage}
              isLoading={isSaving}
              loadingText="Saving"
              className="secondary-button"
              onClick={handleSave}
              text="Save"
            />
          </div>
        )}
        {timezone.validationMessage ? (
          <div>{timezone.validationMessage}</div>
        ) : null}
      </div>
    );
  }
  return <SectionContainer header={"Time Zone"} content={content} />;
};

class TimeZoneSection extends Component {
  componentDidMount = () => {
    this._mounted = true;
  };
  componentWillUnmount = () => {
    this._mounted = false;
  };
  handleTimeZoneChange = async (timeZone) => {
    try {
      await this.validateTimeZone(timeZone);
      var localDate = moment.tz("2014-06-01 12:00", timeZone);
      const offset = localDate.utcOffset() / 60;
      this.props.updateTimeZone({ offset: offset, code: timeZone });
    } catch (err) {
      this.props.updateTimeZone({
        offset: 0,
        code: timeZone,
        validationMessage: err,
      });
    }
  };
  saveTimeZone = async () => {
    if (!this.props.account.timezone.validationMessage) {
      this.props.updateSavingTimeZone({ value: true });
      AtraAPI.UpdateUserTimeZone(this.props.account.timezone).then(
        (res) => {
          AtraAPI.GetUser().then((user) => {
            this.props.updateSavingTimeZone({ value: false });
            //update user
            this.props.updateUser({ value: user });
            this.props.updateLoadingUser({ value: false });
          });
        },
        (err) => {
          console.log(err);
          this.props.updateSavingTimeZone({ value: false });
        }
      );
    }
  };

  validateTimeZone = (timezone) => {
    return new Promise((resolve, reject) => {
      try {
        if (moment.tz.zone(timezone)) {
          resolve();
        } else {
          throw "Invalid Time Zone";
        }
      } catch (err) {
        reject(err);
      }
    });
  };

  render() {
    return (
      <TimeZoneContainer
        loading={this.props.account.loading.user}
        onChange={this.handleTimeZoneChange}
        user={this.props.account.user}
        timezone={this.props.account.timezone}
        handleSave={this.saveTimeZone}
        isSaving={this.props.account.isSubmitting.timezone}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch({ type: Actions.UPDATE_USER, data }),
    updateLoadingUser: (data) =>
      dispatch({ type: Actions.UPDATE_LOADING_USER, data }),
    updateTimeZone: (data) =>
      dispatch({ type: Actions.UPDATE_USER_TIMEZONE, data }),
    updateSavingTimeZone: (data) =>
      dispatch({ type: Actions.UPDATE_SAVING_USER_TIMEZONE, data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeZoneSection);
