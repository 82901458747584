import React, { useState } from "react";
import { ModalContainer } from "../Modals";

const DowngradeSubscriptionWarningModal = ({
  show,
  handleCancel,
  handleConfirm,
  isLoading = false,
}) => {
  return (
    <ModalContainer
      show={show}
      title={"Downgrade"}
      handleClose={handleCancel}
      handleSave={handleConfirm}
      saveButtonText={"Checkout"}
      isLoading={isLoading}
      className={""}
    >
      <div>
        Downgrading will end your current plan at the end of the billing cycle.
      </div>
    </ModalContainer>
  );
};

export default DowngradeSubscriptionWarningModal;
