import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { LiteElementHeader } from "./index";
import PriceInput from "../../../../components/PriceInput/PriceInput";
import DateInput from "../../../../components/DateInput/DateInput";
import AddressInput from "../../../../components/AddressInput/AddressInput";
import {
  ConvertWeiToEth,
  ConvertEthToWei,
  IsAddress,
  ToHex,
} from "../../../../helpers/Eth";
import {
  GetEpochFromTimeZoneDate,
  GetNewDateWithTimeZoneOffset,
} from "../../../../helpers/Date";
import { GetWeb3Instance } from "../Eth";
import { UpdateBalance } from "../LiteWallet";

import LoaderButton from "../../../../components/Buttons/LoaderButton";

import LiteUIMethods from "../../methods";
import NotificationMethods from "../../../../components/Notifications/methods";

import AtraAPI from "../../../../apiv2";

const RenderPaymentInput = ({ payment, value, onChange }) => {
  if (payment.enabled) {
    switch (payment.source.type) {
      case "static":
        return (
          <div>
            <label>Cost: {ConvertWeiToEth(payment.source.value)} ETH</label>
          </div>
        );
        break;
      case "dtable":
        return (
          <div>
            {!value ? (
              <label>Cost: Enter Record Address to calculate cost</label>
            ) : (
              <label>Cost: {value}</label>
            )}
          </div>
        );
      case "unlimited":
        return (
          <div>
            <label>Cost: Unlimited</label>
            <RenderInputField
              value={value}
              handleChange={onChange}
              type={"price"}
            />
          </div>
        );
        break;
      case "range":
        return (
          <div>
            <label>
              Cost: Range between {ConvertWeiToEth(payment.source.value.min)} -{" "}
              {ConvertWeiToEth(payment.source.value.max)} ETH
            </label>
            <RenderInputField
              value={value}
              handleChange={onChange}
              type={"price"}
            />
          </div>
        );
        break;
    }
  }
  return (
    <div className="cost-container">
      <label>Cost: Free</label>
    </div>
  );
};

const RenderInputField = ({ type, value, index, handleChange, mode }) => {
  const childProps = {
    "data-index": index,
    "data-valuetype": type,
  };

  let dataSets = [];
  dataSets["valueType"] = type;
  dataSets["index"] = index;

  switch (type) {
    case "price":
      return (
        <div className="input-price-wrapper">
          <PriceInput
            value={value}
            onChange={handleChange}
            childProps={childProps}
          />
        </div>
      );
      break;
    case "date":
      return (
        <div className="input-date-wrapper">
          <DateInput
            datasets={dataSets}
            value={value}
            onChange={handleChange}
          />
        </div>
      );
      break;
    case "address":
      return (
        <div className="input-address-wrapper">
          <AddressInput
            childProps={childProps}
            value={value}
            onChange={handleChange}
            datasets={dataSets}
          />
        </div>
      );
      break;
    default:
      return (
        <div className="input-text-wrapper">
          <FormControl
            type="text"
            value={value}
            data-valuetype={type}
            data-index={index}
            placeholder={type}
            onChange={handleChange}
          />
        </div>
      );
      break;
  }
};

const RenderElement = ({
  trigger,
  invoke,
  handleRecordAddressChange,
  handleInputChange,
  handlePaymentChange,
  mode,
}) => {
  return (
    <div className="form-container">
      {invoke.inputs.map((input, i) => {
        if (input.input) {
          return (
            <div className="form-input-container" key={i}>
              <label>{input.name}</label>
              <RenderInputField
                value={input.value}
                index={i}
                handleChange={(event) =>
                  handleInputChange(i, event.target.value)
                }
                type={input.type}
                mode={mode}
              />
            </div>
          );
        }
      })}

      <RenderPaymentInput
        payment={trigger.input.payment}
        value={invoke.eth}
        onChange={handlePaymentChange}
      />
    </div>
  );
};

class LiteForm extends Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    const trigger = this.props.element.source.option;

    this.state = {
      trigger,
      isSubmitting: false,
      config: {},
      invoke: {
        eth: 0,
        index: 0,
        inputs: [],
        isSubmitting: false,
        metamask: {
          loading: true,
          error: false,
          code: null,
        },
      },
    };

    this.setTriggerPaymentState(trigger, (cost) => {
      this.state.invoke.eth = cost;
    });

    var inputs = this.getDefaultInputsState(this.state.trigger.input.action);
    this.state.invoke.inputs = inputs;

    this.state.config = this.props.element.config;
  }

  resetInputsToDefault = () => {
    var invoke = this.state.invoke;
    var inputs = this.getDefaultInputsState(this.state.trigger.input.action);
    invoke.inputs = inputs;
    this.setState({ invoke });
  };

  getDefaultInputsState = (triggerInputAction) => {
    return triggerInputAction.mappings.map((input, i) => {
      let newInput = {
        input: input.value === "input",
        value: "",
        type: input.valueType,
        name: input.name,
        index: i,
      };
      let timezone = undefined;
      if (this.props.auth.isAuthenticated) {
        timezone = this.props.account.user.timezone.code;
      }
      switch (input.valueType) {
        case "date":
          newInput.value = GetNewDateWithTimeZoneOffset(timezone);
          break;
        case "price":
          newInput.value = 0;
          break;
        case "number":
          newInput.value = 0;
          break;
        case "address":
          newInput.value = newInput.input
            ? ""
            : "0x0000000000000000000000000000000000000000";
          break;
        case "pointer":
          if (this.props.element.config.autofillPointer) {
            newInput.value = this.props.liteui.ux.detailView.recordId;
            newInput.input = false;
          } else {
            newInput.value = newInput.input
              ? ""
              : "0x0000000000000000000000000000000000000000";
          }

          break;
      }

      return newInput;
    });
  };

  async componentDidMount() {
    this._mounted = true;
  }

  async componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate = () => {
    if (this.state.config != this.props.element.config) {
      let inputs = this.getDefaultInputsState(this.state.trigger.input.action);
      let invoke = { ...this.state.invoke, inputs };
      this.setState({ config: this.props.element.config, invoke });
    }
  };

  setTriggerPaymentState = (trigger, updateCost) => {
    if (trigger.input.payment.enabled) {
      switch (trigger.input.payment.source.type) {
        case "static":
          updateCost(trigger.input.payment.source.value);
          break;
        case "unlimited":
          break;
        case "range":
          break;
        case "dtable":
          break;
      }
    }
  };

  handlePaymentChange = (event) => {
    if (event.target.value) {
      this.setState({
        invoke: { ...this.state.invoke, eth: event.target.value },
      });
    } else {
      this.setState({ invoke: { ...this.state.invoke, eth: 0 } });
    }
  };

  handleInputChange = (index, value) => {
    let inputs = this.state.invoke.inputs;
    inputs[index].value = value;
    this.setState({ inputs: inputs });
  };

  handleRecordAddressChange = (event) => {
    const { trigger, invoke } = this.props.triggersView;
    this.props.updateInvokeIndex({ value: event.target.value });
  };

  sendNotification = async (liteuiId, triggerId, txHash) => {
    try {
      const input = {
        liteuiId,
        triggerId,
        txHash,
      };
      const res = await AtraAPI.SendNotification({ input });
    } catch (err) {
      console.log(err);
    }
  };

  invokeWithMeataMask = async (event) => {
    const readOnlyMode = this.props.metamask.status.code !== 200;

    if (readOnlyMode) {
      console.log("read only mode");
      this.props.updateShowReadOnlyWarning({ value: true });
      return;
    }

    const web3React = GetWeb3Instance(
      this.props.liteui.liteWallet,
      window.ethereum
    );

    const trigger = this.state.trigger;
    const invoke = this.state.invoke;

    this.setState({ isSubmitting: true });

    let account = "";

    if (
      this.props.liteui.liteWallet.enabled &&
      !this.props.liteui.liteWallet.disabled
    ) {
      account = this.props.liteui.liteWallet.address;
    } else {
      const res = await web3React.eth.getAccounts();
      account = res[0];
    }

    let contractInstance = new web3React.eth.Contract(
      JSON.parse(trigger.abi),
      trigger.goerli
    );

    let sendData = {
      from: account,
    };

    let timezone = undefined;
    if (this.props.auth.isAuthenticated) {
      timezone = this.props.account.user.timezone.code;
    }
    //format inputs
    const inputs = invoke.inputs.map((input, i) => {
      switch (input.type) {
        case "date":
          return GetEpochFromTimeZoneDate(input.value, timezone);
        case "price":
          return ConvertEthToWei(input.value);
        default:
          return input.value;
      }
    });

    //if trigger uses value
    const payment = trigger.input.payment;
    if (payment.enabled) {
      switch (payment.source.type) {
        case "static": {
          const value = ToHex(payment.source.value);
          sendData.value = value;
          break;
        }
        case "range": {
          const value = ToHex(ConvertEthToWei(invoke.eth));
          sendData.value = value;
          break;
        }
        case "unlimited": {
          const value = ToHex(ConvertEthToWei(invoke.eth));
          sendData.value = value;
          break;
        }
        case "dtable": {
          const value = ToHex(ConvertEthToWei(invoke.eth));
          sendData.value = value;
          break;
        }
      }
    }

    //make request to contract

    try {
      if (
        trigger.input.action.method === "update" ||
        trigger.input.action.method === "delete"
      ) {
        let invokeWithIndex = contractInstance.methods
          .invoke(invoke.index, [...inputs])
          .send(sendData)
          .on("transactionHash", (hash) => {
            this.resetInputsToDefault();
            this.setState({ isSubmitting: false });
            this.props.notificationsAddPopupMessage({
              message: "Success! - Transaction Sent",
              remove: (id) => {
                this.props.notificationsRemovePopupMessage({ value: id });
              },
            });
            //Add to pending list
            this.props.addPendingTransaction({
              txHash: hash,
              contractAddress: trigger.goerli,
              date: new Date(),
              data: `${this.props.element.title} - ${trigger.input.action.method}`,
              gas: 0,
              complete: false,
            });

            //Send out notification if public mode
            if (this.props.liteui.mode === "public") {
              this.sendNotification(this.props.liteui.id, trigger.id, hash);
            }
          })
          .on("confirmation", (confirmationNumber, receipt) => {
            if (confirmationNumber === 1) {
              //Remove from pending list
              this.props.removePendingTransaction({
                txHash: receipt.transactionHash,
              });
              UpdateBalance(
                this.props.liteui.liteWallet,
                this.props.updateLiteWallet
              );

              // Refresh LiteList tables
              //TODO
              // this.props.updateRefreshLiteList({ value: true });

              //Popup notification
              this.props.notificationsAddPopupMessage({
                message: "Success! - Transaction Confirmed",
                remove: (id) => {
                  this.props.notificationsRemovePopupMessage({ value: id });
                },
              });
            }
          })
          .on("receipt", (receipt) => {})
          .on("error", (err) => {
            console.log(err);
            this.setState({ isSubmitting: false });
            this.props.notificationsAddPopupMessage({
              type: "warning",
              message: "Failure! - Transaction Failed",
              remove: (id) => {
                this.props.notificationsRemovePopupMessage({ value: id });
              },
            });
          });
      } else {
        sendData = {
          ...sendData,
          // gasPrice: "3000000000", //price per unit in Wei
        };
        // const gas = await contractInstance.methods
        //   .invoke([...inputs])
        //   .estimateGas(sendData);
        sendData = {
          ...sendData,
          // gas,
        };
        debugger;
        let invoke = contractInstance.methods
          .invoke([...inputs])
          .send(sendData)
          .on("transactionHash", (hash) => {
            this.resetInputsToDefault();
            this.setState({ isSubmitting: false });
            this.props.notificationsAddPopupMessage({
              message: "Success! - Transaction Sent",
              remove: (id) => {
                this.props.notificationsRemovePopupMessage({ value: id });
              },
            });

            //Add to pending list
            this.props.addPendingTransaction({
              txHash: hash,
              contractAddress: trigger.goerli,
              date: new Date(),
              data: `${this.props.element.title} - ${trigger.input.action.method}`,
              gas: 0,
              complete: false,
            });

            //Send out notification if public mode
            if (this.props.liteui.mode === "public") {
              this.sendNotification(this.props.liteui.id, trigger.id, hash);
            }
          })
          .on("confirmation", (confirmationNumber, receipt) => {
            if (confirmationNumber === 1) {
              //Remove from pending list
              this.props.removePendingTransaction({
                txHash: receipt.transactionHash,
              });
              UpdateBalance(
                this.props.liteui.liteWallet,
                this.props.updateLiteWallet
              );

              //Popup notification
              this.props.notificationsAddPopupMessage({
                message: "Success! - Transaction Confirmed",
                remove: (id) => {
                  this.props.notificationsRemovePopupMessage({ value: id });
                },
              });
            }
          })
          .on("receipt", (receipt) => {
            //console.log(receipt);
          })
          .on("error", (err) => {
            console.log(err);
            this.setState({ isSubmitting: false });
            this.props.notificationsAddPopupMessage({
              type: "warning",
              message: "Failure! - Transaction Failed",
              remove: (id) => {
                this.props.notificationsRemovePopupMessage({ value: id });
              },
            });
          });
      }
    } catch (err) {
      console.log("error", err);
      this.setState({ isSubmitting: false });
      this.props.notificationsAddPopupMessage({
        type: "warning",
        message: "Failure! - Transaction Failed",
        remove: (id) => {
          this.props.notificationsRemovePopupMessage({ value: id });
        },
      });
    }
  };

  render() {
    const element = this.props.element;

    return (
      <div className="lite-form liteui-element">
        <LiteElementHeader
          dragHandleProps={this.props.dragHandleProps}
          mode={this.props.mode}
          edit={() => this.props.edit(this.props.element.id)}
          remove={() => this.props.remove(this.props.element.id)}
          title={element.title}
        />
        <RenderElement
          trigger={this.state.trigger}
          invoke={this.state.invoke}
          handleRecordAddressChange={this.handleRecordAddressChange}
          handleInputChange={this.handleInputChange}
          handlePaymentChange={this.handlePaymentChange}
          mode={this.props.liteui.mode}
          config={this.props.element.config}
        />
        <div className="liteui-submit-container">
          <LoaderButton
            className="secondary-button"
            isLoading={this.state.isSubmitting}
            loadingText={"Pending"}
            text={"Submit"}
            onClick={this.invokeWithMeataMask}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    liteui: state.liteui,
    notifications: state.notifications,
    metamask: state.metamask,
    account: state.account,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...LiteUIMethods({ dispatch }),
    ...NotificationMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiteForm);
