import React from 'react';
import Icon from '../../../components/Icons/avatar_icon.svg';

const UserProfileSection = ({
  email,
  loading
}) => {
  if(loading){
    return (
      <div className='user-profile-container'>
        <img src={Icon} />
        <div>Loading...</div>
      </div>
    )
  }else{
    return (
      <div className='user-profile-container'>
        <img src={Icon} />
        <div className='user-email'>{email}</div>
      </div>
    )
  }
}

export default UserProfileSection;
