import React from 'react';
import KeyFeatures from './KeyFeatures';
import HelpingPoints from './HelpingPoints';
import QuickLinks from './QuickLinks';

const LoggedInMainBlurb = ({
  loggedIn,
  keyFeatures
}) => {

  return (
    <div className='main-blurb-container'>
      <div className='blurb-header'>
        {loggedIn.header}
      </div>
      <QuickLinks quicklinks={loggedIn.quicklinks} />
    </div>
  )
}

export default LoggedInMainBlurb;
