import React from 'react';
import { Glyphicon } from 'react-bootstrap';

const Loading = ({

}) => {
  return (
    <div className='main-loader-container'>
      <div className="loading-container">
        <Glyphicon glyph="refresh" className="spinning" /> Loading
      </div>
    </div>
  )
}


export {
  Loading
}
