import React, { Component } from 'react';
import Container from '../../Container';
import { Support } from '../../../components/Content';
import Start from '../../Start';

export default class Documentation extends Component {
  render(){
    return (
      <Container selected={['support','feedback']}>
        <Start />
        <Support.Header />
        <Support.Feedback />
      </Container>
    )
  }
}
