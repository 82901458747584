import React from 'react';
import { Link } from 'react-router-dom';
import { NavData } from './';

const NavSection = ({
  section,
  selected
}) => {
  return (
    <div className='section-container'>
      <div className='section-header'>
        {section.title}
      </div>
      <div className='section-content'>
        {section.links.map((link,i)=>{
          return <Link key={i} to={`/docs/${section.id}/${link.id}`} className={`section-link ${section.id === selected[0] && link.id === selected[1] ? 'selected' : ''}`}>{link.title}</Link>
        })}
      </div>
    </div>
  )
}

const Navigation = ({
  selected = ['','']
}) => {
  return (
    <div>
      <div id='docs-nav-container' className='docs-nav-container'>
        {NavData.map((section,i)=>{
          return <NavSection key={i} section={section} selected={selected} />
        })}
      </div>
    </div>

  )
}

export default Navigation;
