import Actions from '../actions/triggers-dashboard';
const Methods = ({ dispatch }) => {
    return {
      toggleFilter: data => dispatch({ type: Actions.TOGGLE_FILTER, data }),
      updateTriggers: data => dispatch({ type: Actions.UPDATE_TRIGGERS, data }),
      loadingTriggers: data => dispatch({ type: Actions.UPDATE_LOADING_TRIGGERS, data }),
      clearState: data => dispatch({ type: Actions.CLEAR_STATE, data })
    }
}
export default Methods;
