import React from 'react';
import { Next } from '../Utils';

import JSONPretty from 'react-json-pretty';
import Content from  './';

const LiteUI = ({

}) => {
  //Code examples
  const getLiteUIResponse = [
      {
          "created": 1553566489567,
          "id": "22f65a1b-972f-4755-88d5-4412f9742899",
          "name": "dFlea Market",
          "status": "Live"
      },
      {
          "created": 1554055546293,
          "id": "82c7face-08c6-4613-be86-3ad25df905d0",
          "name": "My Art Gallery",
          "status": "Live"
      }
  ]

  const createLiteUIRequest = {

  }

  const createLiteUIResponse = {

  }
  return (
    <div className='doc-section-content-container'>
      <h2>LiteUI</h2>
        <Content.Headers/>

        <section>
          <h5>Get All LiteUIs</h5>
          <h2>GET /liteui</h2>
          <div>Returns all LiteUIs for your account</div>

          <h5>Response JSON (Array)</h5>
          <ul>
            <li><b>name</b> - (string): The name of the LiteUI</li>
            <li><b>id</b> - (string): The ID of the LiteUI in the Atra system</li>
            <li><b>status</b> - (string): The status of the LiteUI</li>
            <li><b>created</b> - (number): Unix timestamp (UTC-0)</li>
          </ul>

          <h5>Example Response</h5>
          <JSONPretty id="json-pretty" data={getLiteUIResponse}></JSONPretty>

        </section>

        <Next
          title={'FAQ'}
          link={'/docs/support/faq'}
           />

    </div>
  )
}

export default LiteUI;
