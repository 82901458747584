import { createStore, applyMiddleware, combineReducers } from "redux";

//reducers
import login from "../../containers/auth/reducers/loginReducer";
import signup from "../../containers/auth/reducers/signupReducer";
import auth from "../../containers/auth/reducers/auth";
import forgotPassword from "../../containers/auth/reducers/forgotpasswordReducer";
import { triggersCreate, triggersDashboard, triggersView } from "../../containers/triggers/reducers";
import { liteWalletCreate, liteWalletDashboard, liteWalletView } from "../../containers/litewallet/reducers";
import dTablesCreate from "../../containers/dtables/reducers/dTablesCreateReducer";
import dTablesDashboard from "../../containers/dtables/reducers/dTablesDashboardReducer";
import dTablesView from "../../containers/dtables/reducers/dTablesViewReducer";
import liteui from "../../containers/liteui/reducers/liteui";
import liteuiDashboard from "../../containers/liteui/reducers/liteui-dashboard";
import priceInput from "../../components/PriceInput/reducers/priceInput";
import nav from "../../components/Navigation/reducers/nav";
import account from "../../containers/account/reducers/account";
import notifications from "../../components/Notifications/reducers/notifications";
import metamask from "../../components/MetaMask/reducers";
import environments from "../../components/Environments/reducers";

const appReducer = combineReducers({
  auth,
  account,
  login,
  signup,
  forgotPassword,
  triggersCreate,
  triggersView,
  triggersDashboard,
  liteWalletCreate,
  liteWalletDashboard,
  liteWalletView,
  liteui,
  liteuiDashboard,
  dTablesDashboard,
  dTablesCreate,
  dTablesView,
  priceInput,
  nav,
  notifications,
  metamask,
  environments
});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_USER_LOGOUT') {
    state = {};
 }
 return appReducer(state, action)
}

const middlewares = [];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
