import dTables from "./dtables";
import Triggers from "./triggers";
import LiteUI from "./liteui";
import User from "./user";
import Contracts from "./contracts";
import API from "./api";
import Services from "./services";
import LiteWallet from "./litewallet";
import Environments from "./environments";
import Billing from "./billing";
import Subscription from "./subscription";

export default {
  ...dTables,
  ...Triggers,
  ...LiteUI,
  ...User,
  ...Contracts,
  ...API,
  ...Services,
  ...LiteWallet,
  ...Environments,
  ...Billing,
  ...Subscription,
};
