import React from 'react';
import { Intro, Steps } from './AccountSetup/';
import { Next } from '../Utils';

const AccountSetup = ({

}) => {
  return (
    <div className='doc-section-content-container docs-tutorial-container '>
      <h2>Setting up your Atra Account</h2>

      <Intro />
      <Steps />

      <Next
        title={'My First dApp'}
        link={'/docs/tutorials/my-first-dapp'}
         />


    </div>
  )
}



export default AccountSetup;
