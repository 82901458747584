export default {
  min: pwd => {
    return pwd.length >= 8;
  },
  uppercase: pwd => {
    return pwd.some(pwd => pwd.charCodeAt() >= 65 && pwd.charCodeAt() <= 90);
  },
  lowercase: pwd => {
    return pwd.some(pwd => pwd.charCodeAt() >= 97 && pwd.charCodeAt() <= 122);
  },
  number: pwd => {
    return pwd.some(pwd => pwd.charCodeAt() >= 48 && pwd.charCodeAt() <= 57);
  },
  symbol: pwd => {
    return pwd.some(
      pwd =>
        (pwd.charCodeAt() >= 33 && pwd.charCodeAt() <= 47) ||
        (pwd.charCodeAt() >= 58 && pwd.charCodeAt() <= 64) ||
        (pwd.charCodeAt() >= 91 && pwd.charCodeAt() <= 96) ||
        (pwd.charCodeAt() >= 123 && pwd.charCodeAt() <= 126)
    );
  }
};
