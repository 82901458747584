import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { Table, Alert } from 'react-bootstrap';
import { ConvertWeiToEth } from '../../../../helpers/Eth';

import { FormatDate, FormatDateTime, GetEpochFromTimeZoneDate } from "../../../../helpers/Date";

const RenderRawValue = ({
  type,
  value,
  timezone
}) => {
  switch(type){
    case 'date': {
      return <FormatValue timezone={timezone.code} value={value} type={'date'} />
      break;
    }
    default : {
      return value;
    }
  }
}

const FormatValue = ({
  type,
  value,
  timezone
}) => {
  switch(type){
    case 'price':
      return `${ConvertWeiToEth(value)} ETH`;
    break;
    case 'date':
      return FormatDateTime(GetEpochFromTimeZoneDate(value, timezone), timezone);
    default:
      return value;
    break;
  }
}

const ActionDetails = ({
  action,
  timezone
}) => {
  const url = `/dtables/view/${action.id}`;
  return (
    <div>
      <h4>Action</h4>
      <div>
        <span>dTable: </span><span> <Link to={url}>{action.name}</Link></span>
      </div>
      <div>
        <span>Method: </span><span> {action.actionMethod[0].toUpperCase() + action.actionMethod.substring(1)}</span>
      </div>

      {action.mappings.length && action.actionMethod != 'delete' ?
        <div>
          <label>Mapping</label>
          <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Column</th>
                  <th>Value</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {action.mappings.map((mapping, i)=>{
                  return (
                    <tr key={i}>
                      <td>{i}</td>
                      <td>{mapping.name}</td>
                      <td>{mapping.value === 'static' ? <span><RenderRawValue timezone={timezone} type={mapping.valueType} value={mapping.rawValue} /></span> : mapping.value}</td>
                      <td>{mapping.valueType}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
        </div>

      :
        null
      }

    </div>
  )
}

const FormatCondition = ({
  condition,
  timezone
}) => {
  return (
    <div>
      <span>{condition.base.groupLabel}</span>
      <span> {condition.base.label}</span>
      <span> {condition.operator.label}</span>
      {condition.target.groupId === 'static' ?
        <span> <RenderRawValue type={condition.target.value} value={condition.target.rawValue} timezone={timezone} /></span>
      :
        condition.target.groupId === 'list' ?
          <span> dTable: {condition.target.tableId} Column: {condition.target.column}</span>
          :
          <span> {condition.target.label}</span>

      }
    </div>
  )
}

const ConditionDetails = ({
  conditions,
  timezone
}) => {
  return (
    <div className='conditions-container'>
      <h4>Conditions</h4>
      {conditions.length ? null :
        <div>No Conditions Set</div>
      }
      {conditions.map((condition, i)=>{
        return (
          <div key={i} className='condition-container'>
            <label>{i+1}.</label>
            {condition.description ?

              <div>
                <label>Description</label>
                <p>{condition.description}</p>
              </div>

            : null}
            <div className='condition-breakdown-container'>
              <label>Condition</label>
              <FormatCondition condition={condition} timezone={timezone} />
            </div>
          </div>
        )
      })}

    </div>
  )
}

const PaymentDetails = ({
  payment
}) => {
  if(payment.enabled){
    let source = payment.source.selectedOption;
    let destination = payment.destination.selectedOption;

    let sourceValue = '';
    let destinationValue = '';
    switch(source.value){
      case 'static':
        sourceValue = `${source.rawValue} ETH`;
      break;
      case 'range':
        sourceValue = `Range: ${source.rawValue.min} - ${source.rawValue.max} ETH`;
      break;
      case 'unlimited':
        sourceValue = 'Unlimited';
      break;
      case 'dtable':
        sourceValue = `Action dTable Column: ${source.selectedColumn}`;
      break;
    }

    switch(destination.value){
      case 'static':
        destinationValue = `${destination.rawValue}`;
      break;
      case 'dtable':
        destinationValue = `Action dTable Column: ${destination.selectedColumn}`;
      break;
    }
    return (
      <div>
        <h4>Payment</h4>
        <label>Amount</label>
        <div>{sourceValue}</div>
        <label>Destination</label>
        <div>{destinationValue}</div>
      </div>
    )
  }else{
    return (
      <div>
        <h4>Payment</h4>
        <label>Free</label>
      </div>
    )
  }
}

const TriggerDetails = ({
  actionTable,
  conditions,
  payment,
  timezone
}) => {
  return (
    <fieldset>
      <ActionDetails
        timezone={timezone}
        action={actionTable}
        />
      <ConditionDetails
        timezone={timezone}
        conditions={conditions}
        />
      <PaymentDetails
        timezone={timezone}
        payment={payment}
        />
    </fieldset>
  )
}

class TriggerReview extends Component {

  constructor(props){
    super(props);
    this._mounted = false;
  }

  componentDidMount(){
    this._mounted = true;
  }

  componentWillUnmount(){
    this._mounted = false;
  }

  render() {

    const actionTable = this.props.trigger.tables.find(t => t.selectedByAction);
    const timezone = this.props.account.user.timezone;
    return (
      <TriggerDetails
        actionTable={actionTable}
        conditions={this.props.trigger.conditions}
        payment={this.props.trigger.price}
        timezone={timezone}
        />
    );

  }
}
const mapStateToProps = state => {
  return {
    trigger: state.triggersCreate,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TriggerReview);
