import { login } from "../actions/actionTypes";

let defaultState = {
  isLoading: false,
  email: "",
  password: "",
  emailError: "",
  passwordError: "",
  alertMessage: ""
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case login.LOGIN_CLEAR_ERRORS:
      return {...state, emailError: '', passwordError: '' }
    case login.LOGIN_DEFAULT_STATE:
      return defaultState;
    case login.LOGIN_UPDATE_EMAIL:
      return {
        ...state,
        email: action.data.value
      };
    case login.LOGIN_UPDATE_PASSWORD:
      return {
        ...state,
        password: action.data.value
      };
    case login.LOGIN_UPDATE_EMAIL_ERROR:
      return {
        ...state,
        emailError: action.data.value
      };
    case login.LOGIN_UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        passwordError: action.data.value
      };
    case login.LOGIN_UPDATE_IS_LOADING:
      return {
        ...state,
        isLoading: action.data.value
      };
    case login.LOGIN_UPDATE_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: action.data.value
      };
    default:
      return state;
  }
};
