import React from 'react';
import { Next } from '../Utils';

const about = ({

}) => {
  return (
    <div className='doc-section-content-container'>
      <h2>About the Platform</h2>
      <section>
        <h3>What is the Atra Platform?</h3>
        <p>The Atra platform is a blockchain cloud computing platform. Use the platform’s fully managed web services to build, deploy, and maintain public Ethereum applications.</p>
        <h4>The Atra Platform is currently in Beta, and includes the following:</h4>
        <ul>
          <li>Access to Ethereum Goerli Test Network</li>
          <li>Full access to the dTables, Triggers and LiteUI Services</li>
          <li>Atra API Access to build and explore applications</li>
          <li>Deploy Live contracts on the Goerli Test Net</li>
        </ul>
      </section>

      <h2>About the Services</h2>

      <section>
        <p>The Atra Platform houses services that help the user build and maintain decentralized applications. The services in the Atra Platform beta are specifically designed to be used together to build an end-to-end application. View the User Guides for each service for more information on how to use them.</p>

        <h3>Atra dTables</h3>
        <h4>A Decentralized Database Made Easy</h4>

        <p>Atra dTables are decentralized relational database tables deployed on Ethereum. They are fully managed, customizable, and deployed with a single click. Atra dTables makes it easy to do business on the blockchain. Store supply chain information, financial data, business transactions, record keeping, or your application state. Atra dTables can easily be integrated with other services on the platform to build full applications, or be used stand alone with the Atra API.</p>

        <h3>Atra Triggers</h3>
        <h4>Decentralized API Endpoints with Built In Logic</h4>

        <p>Atra Triggers are decentralized API endpoints with built in and configurable business logic for controlling access, accepting payments, and mapping user input values. Seamlessly connect users to your application’s resources through safe and secure touch points. Atra Triggers enables your application to be used by an external user’s Ethereum wallet. Allowing a user to own their data on your application has never been so simple.</p>

        <h3>Atra LiteUI</h3>
        <h4>Instant Interfaces Made with No Code</h4>

        <p>Interact with your application instantly, no front-end resources required. LiteUI is a powerful website builder that connects to the rest of your Atra services, bringing them together in an easily configurable interface. LiteUI can serve as a prototyping tool or a production level application. LiteUI lessens the time needed to validate your data structures and business logic by giving you the means to test the functionality from a user’s point of view in minutes.</p>



      </section>

      <Next
        title={'Getting Started'}
        link={'/docs/overview/getting-started'}
         />


    </div>
  )
}

export default about;
