import React, { Component } from 'react';
import { connect } from "react-redux";

import { Modal, FormGroup, FormControl, ControlLabel, Button, Table } from 'react-bootstrap';
import UIModals from './';
import LiteUIMethods from "../../methods";
import NotificationMethods from "../../../../components/Notifications/methods";


const TableBody = ({
  transations
}) => {
  return(
    <tbody>
      {transations.map((tx, x)=>{
        const etherScanUrl = `https://goerli.etherscan.io/tx/${tx.txHash}`;
        return <tr key={x}>
          <td>{tx.data}</td>
          <td><a href={etherScanUrl} target='_blank'>View</a></td>
        </tr>;
      })}
    </tbody>
  )
}
const TableHeader = ({
  columns
}) => {
  return (
    <thead>
      <tr>
        <th>Action</th>
        <th>Etherscan</th>
      </tr>
    </thead>
  )
}


class Wallet extends Component {

  handlers = {
    close: () => {
      this.props.updateShowTransactionView({ value: false });
    }
  }
  handleToggleDisableWallet = () => {
    const wallet = {...this.props.liteui.liteWallet, disabled: !this.props.liteui.liteWallet.disabled};
    this.props.updateLiteWallet({wallet});
  }

  render(){
    if(this.props.liteui.ux.showTransactionsView){
      return (
        <UIModals.ModalContainerOK
          className='litewallet-modal'
          title={'Goerli Test Network'}
          handleClose={this.handlers.close}
          show={true}
          >
          <div className='liteui-wallet-container'>
            <div className='wallet-address-container'>
              <div>
                <span>Address: </span>
                <a target="_blank" href={`https://goerli.etherscan.io/address/${this.props.liteui.liteWallet.address}`}>
                {this.props.liteui.liteWallet.address.substring(0,6)}&hellip;{this.props.liteui.liteWallet.address.substring(this.props.liteui.liteWallet.address.length-4,this.props.liteui.liteWallet.address.length)}
                </a>
              </div>
              <div>

              {this.props.liteui.liteWallet.disabled ?
                <Button onClick={this.handleToggleDisableWallet} className='green-button-small'>Enable Wallet</Button>
                :
                <Button onClick={this.handleToggleDisableWallet} className='red-button-small'>Disable Wallet</Button>
              }

              </div>
            </div>
            <div className='wallet-balance-container'>
              <h1 className='eth-container'>{this.props.liteui.liteWallet.balance}<span className='eth-symbol'>ETH</span></h1>
            </div>
            <div className='wallet-history-container'>
              <div className='wallet-history-label'>History</div>
              {!this.props.liteui.ux.pendingTransactions.length ?
                <div className='no-history'>No History</div>
                 :
                 <Table striped condensed responsive>
                   <TableHeader />
                   <TableBody
                     transations={this.props.liteui.ux.pendingTransactions}
                     />
                 </Table>
               }
            </div>

          </div>
        </UIModals.ModalContainerOK>
      )
    }else{
      return null;
    }

  }
}

const mapStateToProps = state => {
  return {
    liteui: state.liteui,
    notifications: state.notifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...LiteUIMethods({dispatch}),
    ...NotificationMethods({dispatch})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wallet);
