import React from 'react';


const ReportIssue = ({

}) => {
  return (
    <div className='doc-section-content-container'>
      <h2>Report Issue</h2>
      <p>Are you experiencing an issue? Let us know right away using the form below, and we will fix is as soon as we can.</p>
      <a href="https://goo.gl/forms/UB4Ai2T6ybsJPTic2" target='_blank'>Report Issue Form</a>
    </div>
  )
}

export default ReportIssue;
