import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import LiteUIMethods from "../../methods";

import { ConvertWeiToEth, FromBigNumber } from "../../../../helpers/Eth";
import { FormatDate, FormatDateTime } from "../../../../helpers/Date";

import { RenderUIElements } from './';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { CreateWeb3Object } from '../../../../components/Web3';


const FormatRecordValue = ({
  type,
  value,
  timezone
}) => {
  switch(type){
    case 'price':
      return `${ConvertWeiToEth(value)} ETH`;
    break;
    case 'date':
      return FormatDateTime(parseInt(value),timezone);
    case 'number':
      return FromBigNumber(value);
    default:
      return value;
    break;
  }
}

const DetailsBox = ({
  columns,
  values,
  id,
  timezone,
  header
}) => {
  return (
    <div className='detailview-box-container'>
      <div className='detail-header-container'>
        <h3>{header.source}</h3>

      </div>
      <div className='details-container'>
        {columns.map((col,i)=>{
          return (
            <div key={i}><b>{col.name}: </b>
              <FormatRecordValue value={values[i]} type={col.type} timezone={timezone}/>
            </div>
          )
        })}
      </div>
      <div className='source-container'>
        <div>Source Table: {header.source}</div>
        <div>Record Id: {id}</div>
      </div>
    </div>
  )
}


class DetailView extends Component {
  constructor(props){
    super(props);
    this._mounted = false;
    this.state = {
      record: {},
      recordloaded: false
    }
  }
  componentDidMount(){
    this._mounted = true;
  }
  async componentWillMount() {
    //get record from recordId
    const parentElement = this.props.detailView.isParentInsideDetailView
      ? this.props.liteui.detailViewElements.find(e => e.id == this.props.detailView.parentElementId)
      : this.props.liteui.views[this.props.liteui.ux.selectedView].elements.find(e => e.id == this.props.detailView.parentElementId);

    const recordId = this.props.detailView.recordId;
    let recordData = await this.GetRecordFromContract(parentElement.source.option, recordId, 'goerli');
    this.setState({ record: recordData, recordloaded: true });
  };

  async componentWillUnmount() {
    this._mounted = false;
    this.setState({ recordloaded: false });
  }

  handleBack = () => {

    const baseUrl = this.props.liteui.baseUrl;
    const selectedView = this.props.liteui.ux.selectedView;

    if(this.props.liteui.mode === 'public'){
      const id = this.props.liteui.id;
      this.props.history.push(`${baseUrl}${id}/${selectedView}`);
    }else{
      this.props.updateDetailView({ value: {...this.props.liteui.ux.detailView, show: false} });
    }

  }

  GetRecordFromContract = (table, recordId, network) =>{
    return new Promise((resolve, reject) =>{
      try {
        let web3react = CreateWeb3Object();
        let contract = new web3react.eth.Contract(JSON.parse(table.abi), table[network]);
        contract.methods.GetById(recordId).call().then((res)=>{
            const pointersLength = table.columns.filter(c => c.type === 'pointer' ).length;
            for(let x = 0; x < pointersLength; x++){
             res.record = [...res.record, ...res[x+2]];
            }
            resolve(res);
        },(err)=>{
          //alert(err);
          console.log(err);
          reject();
        });
      }catch(err){
        console.log(err);
        reject();
      }
    });
  }

  render() {

    if(this.state.recordloaded){
      let timezone = undefined;
      if(this.props.auth.isAuthenticated){
        timezone = this.props.account.user.timezone.code;
      }
      const parentElement = this.props.detailView.isParentInsideDetailView
        ? this.props.liteui.detailViewElements.find(e => e.id == this.props.detailView.parentElementId)
        : this.props.liteui.views[this.props.liteui.ux.selectedView].elements.find(e => e.id == this.props.detailView.parentElementId);

      const detailViewElements = this.props.liteui.detailViewElements.filter( v => v.parentElementId == parentElement.id) || [] ;

      let columns = parentElement.source.option.columns;

      const detailViewRecordPointerColumns = columns.filter( c => c.type === 'pointer' );

      detailViewRecordPointerColumns.forEach(pointer=>{

        const table = parentElement.config.pointerTables.find(t => t.id === pointer.tableId);
        columns = [...columns, ...table.columns];

      });

      let recordData = this.state.record;

      for(let x = 0; x < detailViewRecordPointerColumns.length; x++){
        recordData.record = [...recordData.record, ...recordData[x+2]];
      }

      const view = {
        elements: detailViewElements
      }
      const mode = this.props.liteui.mode;

      const header = {
        source: parentElement.title,
        title: 'Title'
      }
      return (

          <div className='liteui-detailview-container'>
            <Button onClick={this.handleBack}>
              <FontAwesomeIcon icon={faArrowLeft} />{' '}
              Back
            </Button>
            <DetailsBox
              header={header}
              timezone={timezone}
              columns={columns}
              values={recordData.record}
              id={this.props.detailView.recordId}
              />
            <RenderUIElements mode={mode} history={this.props.history} UIElementHandlers={this.props.UIElementHandlers} view={view} />
          </div>

      )
    }else{
      return null;
    }



  }
}

const mapStateToProps = state => {
  return {
    liteui: state.liteui,
    auth: state.auth,
    account: state.account
  }
};

const mapDispatchToProps = dispatch => {
  return {
    ...LiteUIMethods({dispatch})
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailView);
