import React from "react";
import { LoaderButton } from "../Buttons";

const OrderSummary = ({
  plan = null,
  submitEnabled = false,
  submitText = "Checkout",
  handleSubmit = () => {},
  isLoading = false,
  helperText,
}) => {
  if (!plan) {
    return null;
  }

  return (
    <div className="billing-order-summary-container">
      <h3>Upgrade Summary</h3>

      <div className="order-details">
        <div className="order-total">
          <h4>Order Total</h4>
          <h4>
            {plan.priceString} {plan.priceString != "Free" && "/month"}
          </h4>
        </div>
        <div className="plan-container">
          <div className="plan-name">{plan.name}</div>
          <div>{plan.environments}</div>
          <div className="plan-features">
            {plan.features.map((feature, index) => {
              return <div key={`plan-features-${index}`}>{feature}</div>;
            })}
          </div>
          <div>{plan.support}</div>
        </div>
        <div className="checkout-button-container">
          <LoaderButton
            isLoading={isLoading}
            text={submitText}
            loadingText={"Processing"}
            className=""
            disabled={!submitEnabled}
            onClick={() => {
              handleSubmit({ orderPlanId: plan.orderPlanId });
            }}
          />
        </div>
        <div className="helper-text">{helperText}</div>
      </div>
      <div className="contact-us-conainer">
        We charge in USD. Do you want to pay in Ethereum?{" "}
        <a
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdfKQ6W7lLFMIXs1xjJWrDQTXFHKTsqDCKTJ__BLhcjZLKVRg/viewform"
        >
          Contact us.
        </a>
      </div>
    </div>
  );
};

export default OrderSummary;
