import React from 'react';
import LoaderButton from '../Buttons/LoaderButton';
import { Link } from 'react-router-dom';

const Submit = ({
  text,
  loadingText,
  onClick,
  isLoading,
  disabled,
  cancelPath
}) => {
  return (
    <div className="submit-container">
        <Link className='spacer' to={cancelPath}>Cancel</Link>
        <LoaderButton
          block
          bsSize="large"
          disabled={disabled}
          onClick={onClick}
          isLoading={isLoading}
          text={text}
          loadingText={loadingText}
        />
      <div className='spacer' />
    </div>
  )
}

export default Submit;
