import React, { Component } from "react";
import Highlight from "react-highlight";
import { connect } from "react-redux";

import Actions from "../../../actions/triggers-view";
import AtraAPI from '../../../../../apiv2'

import LoadingTab from './LoadingTab';

const Contract = ({ contract }) => {
  if(contract){
    return <Highlight className="javascript">{contract}</Highlight>;
  }else{
    return null;
  }
};

class ContractView extends Component {
  constructor(props){
    super(props);
    this._mounted = false;
    this._rendered = false;
  }

  shouldComponentUpdate(){
    return !this._rendered;
  }

  componentDidMount = () => {
    this._mounted = true;
    if(!this.props.triggersView.contract){
      AtraAPI.GetContract('triggers', this.props.triggersView.trigger.id).then(contract=>{
        if(this._mounted){
          this.props.updateContract({ value: contract });
          this.props.updateLoadingTab({ value: false });
        }
      },err=>{

      })
    }else{
      this.props.updateLoadingTab({ value: false });
    }
  }

  componentWillUnmount = () => {
    this._mounted = false;
  }

  render() {
    if(this.props.triggersView.loading.tab || !this.props.triggersView.contract.length){
      return <LoadingTab title={'Contract'} />;
    }else{
      this._rendered = true;
      return <Contract
        contract={this.props.triggersView.contract}
       />
    }
  }

}

const mapStateToProps = state => {
  return {
    triggersView: state.triggersView
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateContract: data => dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_CONTRACT, data }),
    updateLoadingTab: data => dispatch({ type: Actions.TRIGGERS_VIEW_UPDATE_LOADING_TAB, data })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractView);
