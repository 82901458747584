import React, { Component } from "react";
import { connect } from "react-redux";

import Methods from "../methods/create";

import { Container } from "../components";
import AtraAPI from "../../../apiv2";
import QRCode from "qrcode.react";
import { CreateWeb3Object } from "../../../components/Web3";
import { ToolTipContainer } from "../../../components/Tooltips";
import PriceInput from "../../../components/PriceInput/PriceInput";
import { Info, Submit } from "../../../components/ServiceCreate/";
import { ConvertWeiToEth, ConvertEthToWei } from "../../../helpers/Eth";
import EnvMethods from "../../../components/Environments/methods";
import AccountMethods from "../../account/methods";

import {
  Alert,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from "react-bootstrap";

class LiteWalletCreate extends Component {
  constructor(props) {
    super(props);
    const breadcrumbs = [];
    let nums = [];
    for (let x = 1; x <= 10; x++) {
      nums.push(x);
    }
    breadcrumbs.push({
      text: "aWallet",
      to: "/awallet",
      icon: "litewallet",
    });
    breadcrumbs.push({ text: "Create", to: "/awallet/create" });
    this.state = {
      nums,
      breadcrumbs,
    };

    this.props.clearState();
    this.props.updateEnvironmentsEnabled({ enabled: false });
  }

  componentDidMount = async () => {
    try {
      //load liteuis
      let envId = window.localStorage.getItem("activeEnvironment");
      const res = await AtraAPI.GetLiteUIs(envId);
      this.props.updateLiteUIs({
        liteuis: res.data.sort((a, b) => b.created - a.created),
      });
    } catch (err) {
      console.log(err);
    }
  };

  generateWallets = async () => {
    let selected = { ...this.props.create.options.find((o) => o.selected) };
    let loading = { ...this.props.create.loading, submitting: true };
    this.props.updateLoading({ loading });
    let options = {
      type: selected.type,
      actionType: selected.actionType,
      liteui: selected.liteui,
      numberOfWallets: parseInt(selected.numberOfWallets),
      amountPerWallet: parseInt(ConvertEthToWei(selected.amountPerWallet)),
      name: selected.name,
      description: selected.description,
    };
    let envId = window.localStorage.getItem("activeEnvironment");
    if (envId) {
      options.environmentId = envId;
    }
    try {
      const res = await AtraAPI.CreateWallets(options);
      loading = { ...this.props.create.loading, submitting: false };
      this.props.updateLoading({ loading });
      this.props.history.push(`/awallet`);
    } catch (err) {
      console.log(err);
      if (err == "limit-reached") {
        this.props.updateShowUsageAlertModal({ value: true });
      } else {
        this.props.updateSubmitError({ error: `Create Failed: ${err}` });
      }
      loading = { ...this.props.create.loading, submitting: false };
      this.props.updateLoading({ loading });
    }
  };

  changeWalletAmount = (event) => {
    let options = { ...this.props.create.options.find((o) => o.selected) };
    options.amountPerWallet = event.target.value;
    this.props.updateOptions({ options });
    this.validateAmount(event.target.value);
  };

  handleOptionsChange = (event) => {
    let options = { ...this.props.create.options.find((o) => o.selected) };
    options[event.target.id] = event.target.value;
    if (event.target.id === "actionType" && event.target.value === "liteui") {
      options.liteui = this.props.create.liteuis[0].id;
    }
    this.props.updateOptions({ options });

    //validate
    switch (event.target.id) {
      case "name":
        this.validateName(event.target.value);
        break;
      case "description":
        this.validateDescription(event.target.value);
        break;
    }
  };

  getWalletBalance = (walletAddress) => {
    return new Promise(async (resolve, reject) => {
      try {
        const web3react = CreateWeb3Object();
        const balance = await web3react.eth.getBalance(walletAddress);
        console.log(balance);
        resolve(ConvertWeiToEth(balance));
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };

  validateName = (value) => {
    let valid = true;
    let message = "";
    if (value.length === 0) {
      valid = false;
      message = "Name Required";
    } else if (value.length > 50) {
      valid = false;
      message = "Name Max Length 50 Characters";
    }

    const status = {
      valid,
      message,
    };

    this.props.updateInputStatus({ input: "name", status });
  };

  validateDescription = (value) => {
    let valid = true;
    let message = null;
    if (value.length > 124) {
      valid = false;
      message = "Description Max Length 124 Characters";
    }
    const status = {
      valid,
      message,
    };

    this.props.updateInputStatus({ input: "description", status });
  };

  validateAmount = (value) => {
    let valid = true;
    let message = null;
    if (!value) {
      valid = false;
      message = "Value Required";
    }
    if (isNaN(value)) {
      valid = false;
      message = "Invalid Number";
    }
    if (parseFloat(value) > 0.01) {
      valid = false;
      message = "Amount Max 0.01 ETH";
    }

    if (parseFloat(value) < 0.0001) {
      valid = false;
      message = "Amount Min 0.0001 ETH";
    }

    const status = {
      valid,
      message,
    };

    this.props.updateInputStatus({ input: "amountPerWallet", status });
  };

  calcTotal = () => {
    try {
      const selected = this.props.create.options.find((o) => o.selected);
      const perWallet = ConvertEthToWei(selected.amountPerWallet);
      const amount = selected.numberOfWallets;
      return ConvertWeiToEth(perWallet * amount);
    } catch (err) {}
  };

  render() {
    const total = this.calcTotal();
    const selected = this.props.create.options.find((o) => o.selected);
    return (
      <Container
        className="litewallet-create-container"
        breadcrumbs={this.state.breadcrumbs}
      >
        <p>
          An aWallet is a container that can manage many individual wallets at
          once. Generate, fund, and manage wallets for different use cases using
          this service.
        </p>
        <Info
          name={selected.name}
          nameError={
            this.props.create.ux.inputs.find((i) => i.input === "name").status
              .message
          }
          descriptionError={
            this.props.create.ux.inputs.find((i) => i.input === "description")
              .status.message
          }
          description={selected.description}
          onChange={this.handleOptionsChange}
          service={"aWallet"}
          tooltip={`The Name and Description will be associated with the individual wallets as they are distributed. These fields will be seen by users for them to identify what the wallet is for.`}
        />

        <div>
          <section>
            <ToolTipContainer
              tooltip={
                "aWallets can only manage one type of individual wallet. Select the wallet type this container will manage."
              }
            >
              <h3>Select Wallet Type</h3>
            </ToolTipContainer>
            <FormControl
              id="type"
              value={this.props.create.options.find((o) => o.selected).type}
              componentClass="select"
              placeholder="select"
              onChange={this.handleOptionsChange}
            >
              {this.props.create.options.map((option, i) => {
                return (
                  <option
                    disabled={option.disabled}
                    key={i}
                    value={option.type}
                  >
                    {option.type}
                  </option>
                );
              })}
            </FormControl>
            <p>{selected.tooltip}</p>
          </section>

          <section>
            <ToolTipContainer
              tooltip={"Configure how the wallets will be created, and funded."}
            >
              <h3>Configure {selected.type} Wallets</h3>
            </ToolTipContainer>

            <div>
              <ToolTipContainer
                tooltip={
                  "The wallet action is what happens when a user scans the QR code."
                }
              >
                <ControlLabel>Wallet Action</ControlLabel>
              </ToolTipContainer>

              <FormControl
                id="actionType"
                value={selected.actionType}
                componentClass="select"
                placeholder="select"
                onChange={this.handleOptionsChange}
              >
                <option value="privateKey">Expose Private Key</option>
                <option value="liteui">Navigate to LiteUI</option>;
              </FormControl>
            </div>
          </section>

          {selected.actionType == "privateKey" ? (
            <p>
              <b>Expose Private Key:</b> Exposing the wallet's private key
              allows the user to import and manage the wallet manually from a
              software wallet like metamask using the mobile app or browser
              extension.
            </p>
          ) : null}

          {selected.actionType == "liteui" ? (
            <section>
              <p>
                <b>Navigate to LiteUI:</b> AccessPass wallets can navigate a
                user directly to a LiteUI. Once they land on the LiteUI page the
                AccessPass wallet will be imported into the LiteUI. Users will
                be able to access all app functionally instantly from phone or
                desktop browser.
              </p>
              <ControlLabel>LiteUI</ControlLabel>
              <FormControl
                id="liteui"
                value={selected.liteui}
                componentClass="select"
                placeholder="select"
                onChange={this.handleOptionsChange}
              >
                {this.props.create.liteuis.map((liteui, i) => {
                  return (
                    <option key={i} value={liteui.id}>
                      {liteui.name}
                    </option>
                  );
                })}
              </FormControl>
            </section>
          ) : null}
        </div>

        <section>
          <ToolTipContainer
            tooltip={
              "This is the number of generated AccessPass wallets that your aWallet will manage. A aWallet can hold upto 100 AccessPass wallets inside of it."
            }
          >
            <ControlLabel>Number of Wallets</ControlLabel>
          </ToolTipContainer>
          <FormControl
            id="numberOfWallets"
            value={selected.numberOfWallets}
            componentClass="select"
            placeholder="select"
            onChange={this.handleOptionsChange}
          >
            {this.state.nums.map((num, i) => {
              return (
                <option key={num} value={num}>
                  {num}
                </option>
              );
            })}
          </FormControl>
        </section>

        <section>
          <ToolTipContainer
            tooltip={
              "This is the inital amount of ETH pre-loaded onto the wallets after they are generated."
            }
          >
            <ControlLabel>Initial ETH Amount</ControlLabel>
          </ToolTipContainer>

          <PriceInput
            value={selected.amountPerWallet}
            onChange={this.changeWalletAmount}
          />

          <div className="info-error">
            {
              this.props.create.ux.inputs.find(
                (i) => i.input === "amountPerWallet"
              ).status.message
            }
          </div>
        </section>

        <section>
          <h4>Total: {total} ETH</h4>
        </section>

        <p>
          <b>Note:</b> After you click create the funding process will begin.
          Once all individual wallets are funded they will be ready for use.
          Users can then scan the QR codes with their phones and instatly use
          the funds on a dapp.
        </p>

        <div>
          <HelpBlock className="error">
            {this.props.create.submitError}
          </HelpBlock>
          <Submit
            onClick={this.generateWallets}
            isLoading={this.props.create.loading.submitting}
            disabled={
              this.props.create.ux.inputs.find((i) => !i.status.valid)
                ? true
                : false
            }
            text={"Create"}
            loadingText={"Creating"}
            cancelPath={"/awallet"}
          />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    create: state.liteWalletCreate,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...Methods({ dispatch }),
    ...EnvMethods({ dispatch }),
    ...AccountMethods({ dispatch }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiteWalletCreate);
