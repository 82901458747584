import React from "react";
import logo_white from "../Branding/atra_nobg.svg";
import logo_black from "../Branding/atra_black.svg";
import { Link } from "react-router-dom";

const Footer = ({ sidebar }) => {
  return (
    <footer
      className={`footer-container container ${sidebar ? "sidebar" : ""}`}
    >
      <div className="branding-name-container">
        <Link to="/">© Atra Blockchain Services</Link>
      </div>
      <div className="branding-logo-container">
        <Link to="/">
          <img className="logo white" src={logo_white} alt="Atra" />
        </Link>
        <Link to="/">
          <img className="logo black" src={logo_black} alt="Atra" />
        </Link>
      </div>
      <div className="links-container">
        <Link to="/docs/support/feedback">Feedback</Link>
        <Link to="/docs">Docs + API</Link>
        <Link to="/docs/support/report-issue">Report Issue</Link>
        <a target="_blank" href="https://atra.io/privacy">
          Privacy
        </a>
        <a target="_blank" href="https://atra.io/terms">
          Terms
        </a>
      </div>
    </footer>
  );
};

export default Footer;
