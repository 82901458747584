import React, { Component } from 'react';
import { CreateWeb3Object } from "../Web3";
import { connect } from "react-redux";
import Methods from './methods';

class MetaMask extends Component {
  constructor(props){
    super(props)
    this._mounted = false;
    this.web3 = null;
  }
  async componentDidMount(){
    this._mounted = true;
    if(this.props.liteui.liteWallet.enabled && !this.props.liteui.liteWallet.disabled){
      const status = {
        loading: false,
        error: false,
        code: 200,
        message: 'Wallet Connected',
        address: this.props.liteui.liteWallet.address
      }
      this.props.updateMetaMaskStatus({ value: status });
    }else{
      this.EnableMetaMask();
    }
  }
  componentWillUnmount(){
    this._mounted = false;
  }

  EnableMetaMask = async () => {
    if(window.ethereum && window.ethereum){
      try {
        const status = {
          loading: true,
          error: false,
          message: 'Pending Permission'
        }
        this.props.updateMetaMaskStatus({ value: status });
        await window.ethereum.enable();
        this.web3 = new CreateWeb3Object(window.ethereum);
        //console.log('metmask module web3', this.web3);
        this.PollMetaMaskStatus();
      }catch(err){
        console.log(err);
        const status = {
          loading: false,
          error: true,
          message: 'Allow Permission'
        }
        this.props.updateMetaMaskStatus({ value: status });
      }
    }else{
      const status = {
        loading: false,
        error: true,
        message: 'No Wallet Detected'
      }
      this.props.updateMetaMaskStatus({ value: status });
    }
  }

  GetMetaMaskStatus = () => {
    return new Promise((resolve, reject)=>{
      if(this.web3){
        this.web3.eth.getAccounts((err, res) => {
          if(err){
            reject({
              code: 500,
              message: 'Error Getting Wallet Data'
            });
          }else if(res.length){
            this.web3.eth.net.getNetworkType().then((network)=>{
              if(network === 'goerli'){
                resolve({
                  code: 200,
                  message: 'Wallet Connected',
                  address: res[0]
                });
              }else{
                resolve({
                  code: 400,
                  message: 'Switch to Goerli Network',
                  address: res[0]
                });
              }
            });
          } else {
            reject({
              code: 401,
              message: 'Login to Wallet'
            });
          }
        });
      }else{
        reject({
          code: 404,
          message: 'Wallet not found'
        });
      }
    })
  }

  SetMetaMaskStatus = async () => {
    if (this._mounted) {
      await this.GetMetaMaskStatus().then(res=>{
        const status = {
          loading: false,
          error: false,
          ...res
        }
        this.props.updateMetaMaskStatus({ value: status });
      },err=>{
        const status = {
          loading: false,
          error: true,
          ...err
        }
        this.props.updateMetaMaskStatus({ value: status });
      });
    }
  }

  PollMetaMaskStatus = async () => {
    if (this._mounted) {
      await this.GetMetaMaskStatus().then(res=>{
        const status = {
          loading: false,
          error: false,
          ...res
        }
        this.props.updateMetaMaskStatus({ value: status });
      },err=>{
        const status = {
          loading: false,
          error: true,
          ...err
        }
        this.props.updateMetaMaskStatus({ value: status });
        setTimeout(async () => {
          await this.PollMetaMaskStatus();
        }, 2000);
      });
    }
  }

  render() {
    return null;
  }
}


const mapStateToProps = state => {
  return {
    metamask: state.metamask,
    liteui: state.liteui
  };
};

const mapDispatchToProps = dispatch => {
  return Methods({ dispatch })
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetaMask);
