import React, { useState, useEffect } from "react";
import AtraAPI from "../../../apiv2";
import { LoaderButton } from "../../../components/Buttons";
import { OrderSummary, PaymentMethod } from "../../../components/Billing";

const Checkout = (props) => {
  const orderPlanId = props.match.params.plan;
  const [billing, setBilling] = useState({});
  const [loadingBilling, setLoadingBilling] = useState(true);
  const [plan, setPlan] = useState({});
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState();
  const [isStripeReady, setIsStripeReady] = useState(false);
  const [invokePaymentHook, setInvokePaymentHook] = useState();

  useEffect(() => {
    //Get Plans
    getPlans();
    //Get User Billing
    getBilling();
  }, []);

  const getPlans = async () => {
    try {
      const { plans } = await AtraAPI.GetPlans();
      const plan = plans.find((p) => p.orderPlanId === orderPlanId);

      if (plan) {
        setPlan(plan);
      } else {
        console.log("plan-not-supported");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBilling = async () => {
    try {
      const billing = await AtraAPI.GetBilling();
      console.log(billing);
      setBilling(billing);
      setLoadingBilling(false);
    } catch (err) {
      console.log(err);
    }
  };

  const checkout = async ({ checkoutData }) => {
    try {
      const res = await AtraAPI.NewSubscription({ checkoutData });
      console.log(res);
      // this.props.history.push("/account/subscription");
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreatePaymentMethodHook = ({ paymentMethod, error }) => {
    setInvokePaymentHook(false);

    if (error) {
      console.log(error);
      setSubmitting(false);
      setError("error processing request");
    } else {
      const checkoutData = {
        paymentMethodId: paymentMethod.id,
        orderPlanId: plan.orderPlanId,
      };

      console.log(checkoutData);
      checkout({ checkoutData });
    }
  };

  const handleStripeElementsReady = () => {
    console.log("stripe-ready-enable-checkout");
    setIsStripeReady(true);
  };

  const handleSubmit = async () => {
    setInvokePaymentHook(true);
    setSubmitting(true);
  };

  if (!plan || loadingBilling) {
    return null;
  }

  return (
    <div className="checkout-container container">
      <h1>Upgrading to the {plan.name}</h1>
      {false ? null : (
        <p>
          Enter your billing info below to upgrade to the Pro Builder Plan. Your
          card will be charged upon hitting submit. You can manage and edit your
          billing information and view your billing history in the Billing tab
          in your Account Settings.
        </p>
      )}
      <PaymentMethod
        card={billing.card}
        handlePaymentMethodUpdate={({ paymentMethod }) => {
          console.log(paymentMethod);
        }}
        invokePaymenthMethodHook={invokePaymentHook}
        createPaymentMethodHook={handleCreatePaymentMethodHook}
        handleStripeElementsReady={handleStripeElementsReady}
      />
      {false ? null : (
        <OrderSummary
          plan={plan}
          isLoading={submitting}
          submitEnabled={isStripeReady}
          submitText={plan.price}
          handleSubmit={handleSubmit}
        />
      )}
      {error ? <div>{error}</div> : null}
    </div>
  );
};

export default Checkout;
