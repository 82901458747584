import React, { Component } from 'react';
import axios from 'axios';
import {
  Alert,
  FormGroup,
  FormControl,
  Radio,
  ControlLabel
} from "react-bootstrap";
import { connect } from "react-redux";

class PriceInput extends Component {

  constructor(props){
    super(props)
    this._mounted = false;
  }

  componentDidMount(){
    this._mounted = true;
    //go get the price of eth from coinbase using axios
    axios.get(`https://api.coinbase.com/v2/prices/ETH-USD/spot`, {
      responseType: 'json'
    }).then((res)=>{
      if(this._mounted){
        this.props.setEthPrice({ price: res.data.data.amount });
      }
    }).catch((err)=>{
      console.log(err);
    });
  }

  componentWillUnmount(){
    this._mounted = false;
  }

  render() {
    const ethPrice = this.props.priceInput.eth.price;
    let calculatedValue = 0.00;
    let inputValue = this.props.value ? this.props.value : '';

    try {
      if(inputValue){
        calculatedValue = parseFloat(parseFloat(inputValue) * ethPrice).toFixed(2)
      }else{
        calculatedValue = `0.00` ;
      }
    }catch(err){
      calculatedValue = `0.00` ;
    }
    return (
      <div>
        <div className='priceinput'>
          <div className='value-input'>
            <FormControl value={inputValue} onChange={this.props.onChange} type="text" placeholder='ETH' {...this.props.childProps} />
            <div>ETH</div>
          </div>
          <div className='spacer'>=</div>
          <div className='value-input'>
            <FormControl value={calculatedValue} disabled type="text" placeholder='ETH' />
            <div>USD</div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    priceInput: state.priceInput
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setEthPrice: data => dispatch({ type: "UPDATE_ETHUSD_PRICE", data })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceInput);
