import React from "react";
import { Link } from 'react-router-dom';

const BreadCrumbs = ({
  links,
  rightContent,
  border
}) => {
  return (
    <div className={`breadcrumbs ${border ? 'border' : ''}`}>
      <div className='crumbs-container'>
        {links.map((link)=>{
          return (
            <div key={link.to} className='crumb'>
              <Link to={link.to}>
                {link.icon ? <img className='service-icon' src={require(`../Branding/ServiceIcons/${link.icon}.svg`)} /> : null }
                {link.text}
              </Link>
            </div>
          )
        })}
      </div>
      {rightContent ?
        <div>{rightContent}</div>
      : null }
    </div>
  )
}

export default BreadCrumbs;
