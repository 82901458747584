import React from 'react';
import { Button, Jumbotron } from 'react-bootstrap';

const NoTablesWarning = ({
  onClick,
  breadcrumbs
}) => {
  return (
    <Jumbotron>
      <h1>You must have at least one dTable to create a Trigger</h1>
      <p>
        Triggers perform Insert, Update, and Delete actions on dTables.
      </p>
      <p>
        <Button onClick={onClick} bsStyle="primary">
          Go Create a dTable
        </Button>
      </p>
    </Jumbotron>
  )
}

export default NoTablesWarning;
