import React from "react";
import ExploreImage from "../../../components/Navigation/explore_button.svg";
import LiteWallet from "./LiteWallet";

const ExplorePage = ({ handleChange, enabled, mode }) => {
  if (mode === "create") {
    return (
      <div className="explore-picker-container">
        <input type="checkbox" onChange={handleChange} checked={enabled} />
        <div>Feature on</div>
        <img src={ExploreImage} />
      </div>
    );
  } else {
    if (enabled) {
      return (
        <div className="explore-picker-container">
          <div>Featured on</div>
          <img src={ExploreImage} />
        </div>
      );
    } else {
      return null;
    }
  }
};

const EthStatus = ({ status }) => {
  //console.log('Web3 Status', status);
  const Container = ({ statusClass, children }) => {
    return (
      <div className={`liteui-user-status-container ${statusClass}`}>
        <div>{children}</div>
      </div>
    );
  };
  switch (status.code) {
    case 200:
      return <Container statusClass="good">Status: Connected</Container>;
    case 400:
      return (
        <Container statusClass="warning">Status: {status.message}</Container>
      );
    default:
      return (
        <Container statusClass="warning">Status: {status.message}</Container>
      );
  }
};

const LiteWalletLink = ({ pending, onClick }) => {
  return (
    <div
      className={`pending-transactions-link ${pending ? "pending" : ""}`}
      onClick={onClick}
    >
      aWallet <LiteWallet />
    </div>
  );
};

const StatusBanner = ({
  status,
  pending,
  onClick,
  mode,
  explorePageChange,
  explorePageEnabled,
}) => {
  return (
    <div className="liteui-status-banner-container">
      <div className="banner-content container">
        <div>
          {mode !== "public" ? (
            <ExplorePage
              mode={mode}
              handleChange={explorePageChange}
              enabled={explorePageEnabled}
            />
          ) : null}
        </div>
        <div>
          <EthStatus status={status} />
          <LiteWalletLink pending={pending} onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default StatusBanner;
