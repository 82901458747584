import React, { Component } from 'react';
import { connect } from 'react-redux';
import Methods from "../../methods";
import Tooltip from '../../../../components/Tooltips';
import { ResourceStrings } from "./index";
import UIModals from '../UIModals';
import { OverlayTrigger, Popover, Glyphicon, Nav, NavItem, Navbar, Button } from "react-bootstrap";

const LiteNavComponent = ({
  mode,
  name,
  views,
  handlers,
  resourceStrings,
  selectedPage
}) => {
  if(mode === 'create' || mode === 'edit'){
    return (
      <Navbar className='liteui-navbar'>
        <Nav>
          {views.map((view, i) => {
            let selected = false;
            if(selectedPage == i){
              selected = true;
            }
            return (
              <NavItem onClick={()=>handlers.SelectPage(i)} className={selected ? 'active liteui-navbar-item-container':'liteui-navbar-item-container'} key={i} eventKey={i}>
                <div className='liteui-navbar-item-wrapper'>
                  <div className='liteui-navbar-link-wrapper'>
                    <div className='liteui-navbar-link'>{view.name}</div>
                  </div>
                </div>
              </NavItem>
            )
          })}
          <NavItem onClick={handlers.ShowAddPageModal} eventKey={'addView'}>
            <div className='liteui-navbar-item-wrapper'>
              <div className='liteui-navbar-link-wrapper'>
                <div className='liteui-navbar-link'>
                  Add Page <img src={require('../Icons/dark_plus.svg')}/>
                </div>
              </div>
            </div>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }else if(mode === 'public'){
    return (
      <Navbar className='liteui-navbar'>
        {/*
          <div class='public-header-container'>
            <h1>{name}</h1>
            <Navbar.Toggle />
          </div>
          */}

        {/*<Navbar.Collapse>*/}
          <Nav>
            {views.map((view, i) => {
              let selected = false;
              if(selectedPage == i){
                selected = true;
              }
              return (
                <NavItem onClick={()=>handlers.SelectPage(i)} className={selected ? 'active liteui-navbar-item-container':'liteui-navbar-item-container'}  key={i} eventKey={i}>
                  <div className='liteui-navbar-item-wrapper'>
                    <div className='liteui-navbar-link-wrapper'>
                      <div className='liteui-navbar-link'>{view.name}</div>
                    </div>
                  </div>
                </NavItem>
              )
            })}
          </Nav>
        {/*</Navbar.Collapse>*/}
      </Navbar>
    );
  }

};

class LiteNav extends Component {
  handlers = {
    ShowAddPageModal: () =>{
      this.props.updateShowAddPageModalShow({ value: true });
    },
    CloseAddPageModal: () => {
      this.props.updateShowAddPageModalShow({ value: false });
    },
    AddPage: () => {
      this.props.addPage();
      this.props.updateShowAddPageModalShow({ value: false });
      // this.props.updateDetailView({ value: {...this.props.liteui.ux.detailView, show: false} });
      if(this.props.liteui.mode === 'public'){
        this.props.history.push({pathname:`${this.props.liteui.baseUrl}${this.props.liteui.id}/${this.props.liteui.ux.selectedView}`, search: this.props.location.search});
      }else{
        this.props.updateSelectedPage({ value: this.props.liteui.ux.selectedView });
      }
    },
    ChangeName: (event) => {
      this.props.updateShowAddPageModalName({ value: event.target.value });
    },
    SelectPage: (page) => {
      if(this.props.liteui.mode === 'public'){
        this.props.history.push({pathname:`${this.props.liteui.baseUrl}${this.props.liteui.id}/${page}`, search: this.props.location.search});
      }else{
        this.props.updateSelectedPage({ value: page});
      }
    }
  }
  render() {
    return (
      <div>
        <LiteNavComponent
          resourceStrings={ResourceStrings}
          mode={this.props.liteui.mode}
          views={this.props.liteui.views}
          name={this.props.liteui.name}
          handlers={this.handlers}
          selectedPage={this.props.liteui.ux.selectedView}
           />
       <UIModals.AddPage
         name={this.props.liteui.ux.showAddPageModal.name}
         show={this.props.liteui.ux.showAddPageModal.show}
         handleClose={this.handlers.CloseAddPageModal}
         handleSave={this.handlers.AddPage}
         handleChange={this.handlers.ChangeName}
         />
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    liteui: state.liteui
  };
};

const mapDispatchToProps = dispatch => {
  return Methods({dispatch});
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiteNav);
