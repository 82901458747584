import React from "react";

class Section {
  constructor(title, id, links) {
    this.title = title;
    this.id = id;
    this.links = links;
  }
}

class Link {
  constructor(title, id) {
    this.title = title;
    this.id = id;
  }
}

let Sections = [];

const overviewLinks = [
  new Link("About", "about"),
  new Link(`Getting Started`, "getting-started"),
];

Sections.push(new Section("Atra Docs", "overview", overviewLinks));

const tutorialProjectLinks = [
  new Link("Account Setup", "account-setup"),
  new Link("My First dApp", "my-first-dapp"),
  new Link("Supply Chain Traceability", "supply-chain-Traceability"),
  new Link("Decentralized Organization", "decentralized-organization"),
  new Link("Create AccessPass Wallets", "create-accesspass-wallets"),
  new Link("Buy and Sell Items", "buy-and-sell-items"),
];

Sections.push(new Section("Tutorials", "tutorials", tutorialProjectLinks));

const userGuidesLinks = [
  new Link("dTables", "dtables"),
  new Link("Triggers", "triggers"),
  new Link("LiteUI", "liteui"),
  new Link("aWallet", "awallet"),
];

Sections.push(new Section("User Guides", "user-guides", userGuidesLinks));

const apiReferenceLinks = [
  new Link("dTables", "dtables"),
  new Link("Triggers", "triggers"),
  new Link("LiteUI", "liteui"),
  // new Link('LiteWallet','litewallet')
];

Sections.push(new Section("API References", "api", apiReferenceLinks));

const supportLinks = [
  new Link("FAQ", "faq"),
  new Link("Feedback", "feedback"),
  new Link("Report Issue", "report-issue"),
];

Sections.push(new Section("Support", "support", supportLinks));

export default Sections;
