import React from 'react';
import {Link} from 'react-router-dom';
import Note from '../helpers/Note';
import Direction from '../helpers/Direction';
import ScreenShot from './ScreenShot';

export default ({

}) => {
  return (
    <section>

    <Note>
      <div>
        <h3>Thank you for taking the My First dApp Tutorial</h3>
        Help us make the tutorial better by filling our a very short survey.
      </div>
      <div>
        <a target="_blank" href="https://forms.gle/3naeVFjYAyohvJzz6">
          <h3>My First dApp Survey</h3>
        </a>
      </div>
    </Note>

    </section>

  )

}
