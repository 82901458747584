import { API } from "aws-amplify";

const GetEnvironments = () => {
  return new Promise((resolve, reject) => {
    try {

      let data = {
        response: true
      };
      API.get("environments", "", data).then( res => {
        resolve(res.data.Items);
      }).catch(err => {
        reject(err);
      });
    } catch (err) {
      reject(err);
    }
  });
};

const CreateEnvironment = (options) => {
  return new Promise((resolve, reject) => {
    try {

      let data = {
        response: true,
        body: options
      }

      API.post('environments', '', data).then(res => {
        resolve(res.data);
      }).catch(err => {
        if (err && err.response && err.response.data && err.response.data.error) {
          reject(err.response.data.error);
        }else{
          reject('error');
        }
      });

    } catch (err) {
      reject(err);
    }

  });
}

export default { GetEnvironments, CreateEnvironment };
