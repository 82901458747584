import React from "react";
import { Modal, Button } from "react-bootstrap";
import LoaderButton from "../Buttons/LoaderButton";

const ModalContainerOK = ({
  show,
  title,
  handleClose,
  children,
  className,
}) => {
  return (
    <Modal
      backdrop="static"
      className={`${className}`}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button className="white-button" onClick={handleClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalContainer = ({
  show,
  title,
  handleClose,
  handleSave,
  children,
  saveButtonText = "Save",
  isLoading,
  className,
  loadingText = "Saving",
  saveButtonId,
  cancelButtonId,
}) => {
  return (
    <Modal
      backdrop="static"
      className={`${className}`}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button
          id={cancelButtonId}
          className="white-button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <LoaderButton
          id={saveButtonId}
          className="secondary-button"
          isLoading={isLoading}
          loadingText={loadingText}
          text={saveButtonText}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { ModalContainer, ModalContainerOK };
